import Box from "@mui/material/Box/Box"
import { CircularProgress, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { Fragment, useEffect, useState } from 'react'
import useGetUserProject from '../../hooks/userData/useGetUserProject'

const InactivePage = () => {
    const { project, loading } = useGetUserProject()
    const [pageLoading, setPageLoading] = useState(true);
    const analytics = getAnalytics();
    const navigate = useNavigate();

    useEffect(() => {
        if (loading === false) {
            if (project?.data?.isActive == null || project?.data?.isActive === true) {
                navigate("/home", { replace: true });
            }
            setPageLoading(false);
        }
    }, [loading, project])

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Inactive',
            page_path: window.location.pathname,
        });
    }, [])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '50px' }}>
                {pageLoading ? (
                    <Box>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {/* If-statement in case FM-rejection should have a different message. */}
                        {project?.data?.alreadyBookedFMRejected === true ? (
                            <>
                                <Box>
                                    <h5>Inactive</h5>
                                </Box>
                                <Box fontSize={'16px'} textAlign={'center'} maxWidth={'1060px'}>
                                    You have been marked as inactive on the Krinova Business Growth platform. Contact your assigned Business Designer to request re-activation.
                                    If you have been inactive for more than 12 months, your account will be permanently deleted.
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box>
                                    <h5>Inactive</h5>
                                </Box>
                                <Box fontSize={'16px'} textAlign={'center'} maxWidth={'1060px'}>
                                    You have been marked as inactive on the Krinova Business Growth platform. Contact your assigned Business Designer to request re-activation.
                                    If you have been inactive for more than 12 months, your account will be permanently deleted.
                                </Box>
                            </>
                        )}

                    </>
                )}

            </Stack>

        </Box>
    )
}

export default InactivePage;