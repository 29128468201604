import { IconButton, TableCell, Tooltip } from "@mui/material"
import { ExitReasonsLabels, ProcessStepLabels, TrackLabels } from "../../../../constants/labels/labels"

import PendingIcon from '@mui/icons-material/Pending';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { PendingExitDialog } from "../TableRowFunctions/PendingExitDialog";
import { PendingProgressDialog } from "../TableRowFunctions/PendingProgressDialog";
import { TimeReportDialog } from "../../../TimeLogs/TimeReportDialog";
import { ConfirmFMBooking } from "../TableRowFunctions/ConfirmFMBooking";
import StepperPopOver from "../../../Stepper/StepperPopOver";
import { useNavigate } from "react-router-dom";

export const TableRowProgress = ({ project, bdOwners, projectSettings }) => {
    const navigate = useNavigate();

    function goToFMStep() {
        navigate('/home#step2', { state: { projectData: project } })
    }

    function showProgress() {
        if (project?.activeTrack != null && (project?.trackParticipation == null || project?.trackParticipation[project.activeTrack] == null ||
            (Number(project.trackParticipation[project.activeTrack]) === 0 && project.activeTrack !== TrackLabels.start.key))) {
            if (project.activeTrack === TrackLabels.exit.key) {
                return (
                    <span style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>

                        <div style={{ marginRight: '13px' }}>
                            Pending
                        </div>

                        <PendingExitDialog projData={project} />
                    </span>
                )
            }
            else {
                return (
                    <span style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>

                        <div style={{ marginRight: '13px' }}>
                            Pending
                        </div>

                        {project.activeTrack === TrackLabels.start.key ? (
                            <Tooltip arrow title={project.activeTrack === TrackLabels.start.key ? ("Assign Homework 1 to activate track") : ("")}>
                                <IconButton sx={{ height: '22px', width: '22px', marginRight: '-2px' }} onClick={goToFMStep} size='small'>
                                    <PendingIcon htmlColor='#efb236' />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <PendingProgressDialog projData={project} bdowners={bdOwners} />
                        )}
                    </span>
                )
            }

        }
        else if (project.activeTrack === TrackLabels.incubator.key || project.activeTrack === TrackLabels.scale.key) {
            return (
                <TimeReportDialog projData={project} projectSettings={projectSettings} showBar />
            )
        }
        else if (project.activeTrack === TrackLabels.exit.key) {
            
            return (
                <span style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>

                    <div style={{ marginRight: '13px' }}>
                        {ExitReasonsLabels[project.exitReason] != null ? (
                            <>
                                {ExitReasonsLabels[project.exitReason]}
                            </>
                        ) : (" - ")}
                    </div>
                    <IconButton sx={{ height: '22px', width: '22px', marginRight: '-2px' }} size='small'>
                        <DirectionsRunIcon htmlColor='#a9bc52' />
                    </IconButton>
                </span>
            )
        }
        else if (project.currentStep === ProcessStepLabels.PreMeet.key && project.alreadyBookedFM === true) {
            return (
                <span style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>

                    <div style={{ marginRight: '13px' }}>
                        Confirm FM
                    </div>

                    <ConfirmFMBooking projData={project} bdowners={bdOwners} />
                </span>
            )
        }
        else {
            return (
                <span style={{ display: 'flex', justifyContent: 'right' }}>

                    <div style={{ marginRight: '5px' }}>
                        {ProcessStepLabels[project.currentStep] ? ProcessStepLabels[project.currentStep].label : project.currentStep}
                    </div>

                    {ProcessStepLabels[project.currentStep]?.stepGraphic !== false ? (
                        <StepperPopOver projData={project} />
                    ) : (<div style={{ width: '26px' }}></div>)}

                </span>
            );
        }
    }
    
    return (
        <TableCell sx={{ textAlign: "center" }}>
            {showProgress()}
        </TableCell>
    )
}