import { Box, Divider, IconButton, List, ListItem, ListItemText, Modal, Stack } from "@mui/material";
import { Fragment, useState } from "react";
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import EditIcon from '@mui/icons-material/Edit';

import { useAuth } from "../../../contexts/AuthContext";
import UpdateCompInfo from "../../UpdateData/UpdateCompInfo";
import { StyledListItemEntryStep } from "../../../helper/styledComponents";
import { OwnerTypes } from "../../../constants/labels/labels";
import { UpdateCompInfo_v2 } from "../../UpdateData/UpdateCompInfo_v2";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const DisplayCompanyInfo = ({ companyInfo, projectData, updateFrame }) => {
    const { currentUser } = useAuth();

    const [openModalComp, setOpenModalComp] = useState(false);
    const handleModalCompOpen = () => {
        setOpenModalComp(true);
    }

    const handleModalCompClose = () => {
        updateFrame();
        setOpenModalComp(false);
    }

    const displayCompCity = () => {
        if (companyInfo != null && companyInfo.compCity != null) {
            if (String(companyInfo.compCity).includes(';')) {
                let cityData = String(companyInfo.compCity).split(';');
                if (cityData[0] != null && cityData[0] !== '') {
                    return String(cityData[0] + " - " + cityData[1]);
                }
                else {
                    return String(cityData[1]);
                }
            }
            else {
                return String(companyInfo.compCity);
            }
        }
        else {
            return "Not Set";
        }
    }

    const displayCompanyChoice = () => {
        if (projectData?.existingComp === "Yes, in a newly formed company based on the idea" || projectData?.existingComp === "Yes, within an established company") {
            return true;
        }
        else if (projectData?.existingComp === 'yes_new' || projectData?.existingComp === 'yes_established') {
            return true;
        }
        else {
            return false;
        }
    }


    const displayExistingCompanyAnswer = () => {
        if (projectData?.existingComp === 'no' || projectData?.existingComp === 'No/Not yet') {
            return "No/Not yet";
        }
        else {
            return "";
        }
    }

    const displayWebsite = () => {
        if (projectData?.website != null) {
            if (String(projectData?.website).startsWith('http://') || String(projectData?.website).startsWith('https://')){
                return <a href={projectData?.website} target="_blank">{projectData?.website} <OpenInNewIcon sx={{height: '18px', marginTop: '-2px'}} /></a>
            }
            else{
                return <a href={'//' + projectData?.website} target="_blank">{projectData?.website} <OpenInNewIcon sx={{height: '18px', marginTop: '-2px'}} /></a>
            }

        }
        else {
            return "Not Set"
        }
    }

    const displayHeaderText = () => {
        if (projectData?.existingComp === "Yes, in a newly formed company based on the idea" || projectData?.existingComp === "Yes, within an established company") {
            return "Company info:";
        }
        else if (projectData?.existingComp === 'yes_new' || projectData?.existingComp === 'yes_established') {
            return "Company info:";
        }
        else {
            return "Project info:";
        }
    }

    return (
        <Fragment>
            <Box sx={{ paddingBottom: '30px' }}>
                {currentUser.role >= 1 ? (
                    <span>
                        <strong>{displayHeaderText()}</strong>

                        <IconButton
                            className="edit-icon-right"

                            onClick={handleModalCompOpen}>

                            <EditIcon style={{ width: "18px", height: "fit-content" }} />
                        </IconButton>
                    </span>
                ) : (
                    <span>
                        <strong>{displayHeaderText()}</strong>
                    </span>
                )}

                <List disablePadding style={{ width: '80%' }}>


                    {displayCompanyChoice() ? (
                        <>
                            <ListItem>
                                <StyledListItemEntryStep primary="Company Name" secondary={companyInfo != null ? companyInfo.compName : "Not Set"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Website" secondary={displayWebsite()} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Headquarter" secondary={displayCompCity()} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Company form" secondary={companyInfo != null && companyInfo.compForm != null ? companyInfo.compForm : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Organization number" secondary={companyInfo != null && companyInfo.orgNum != null ? companyInfo.orgNum : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Founded" secondary={companyInfo != null && companyInfo.founded != null ? companyInfo.founded : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Role in the company" secondary={companyInfo != null && companyInfo.compRole != null ? companyInfo.compRole : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Revenue" secondary={companyInfo != null && companyInfo.revenue != null ? companyInfo.revenue : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Num of Employees" secondary={companyInfo != null && companyInfo.numEmployees != null ? companyInfo.numEmployees : "Not answered"} />
                            </ListItem>
                            <Divider />

                            {companyInfo != null && companyInfo.compOwnership != null && companyInfo.compOwnership.length > 0 ? (
                                <>
                                    <ListItem>
                                        <StyledListItemEntryStep primary="Company ownership" />
                                    </ListItem>
                                    <Stack sx={{ width: '70%', color: '#6a6a6a', paddingLeft: '15px', marginTop: '-10px', marginBottom: '15px' }}>
                                        {companyInfo.compOwnership.map((item) => (
                                            <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                                                <div style={{ textAlign: 'left', width: '40%' }}>
                                                    {OwnerTypes[item.ownerType]}
                                                </div>
                                                <div style={{ textAlign: 'left', width: '40%' }}>
                                                    {item.text}
                                                </div>
                                                <div style={{ textAlign: 'left', width: '20%' }}>
                                                    {item.procent}%
                                                </div>
                                            </Stack>
                                        ))}
                                    </Stack>
                                    <Divider />
                                </>
                            ) : ("")}
                        </>
                    ) : (
                        <>
                            <ListItem>
                                <StyledListItemEntryStep primary="Project name" secondary={projectData.value != null ? projectData.value : "Not Set"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Website" secondary={displayWebsite()} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Do you have a registered company based around this idea?" secondary={displayExistingCompanyAnswer()} />
                            </ListItem>
                            <Divider />
                        </>
                    )}
                </List>
            </Box>

            <Modal
                open={openModalComp}
                onClose={handleModalCompClose}
            >
                <UpdateCompInfo_v2 projectData={projectData} compInfo={companyInfo} closeModal={handleModalCompClose} />
            </Modal>
        </Fragment>
    )
};