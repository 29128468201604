import { IconButton, Menu, MenuItem, TableCell, TableSortLabel } from "@mui/material"
import { useState } from "react";

import FilterListIcon from '@mui/icons-material/FilterList';
import { OrderByLabels } from "../../../../../constants/labels/labels";

export const AssignedBDHeaderCell = ({ bdFilter, setBDFilter, orderBy, orderByDir, handleSetOrderBy }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        setAnchorEl(null);
        if (value != null) {
            setBDFilter(value);
        }
    };


    return (
        <TableCell style={{ textAlign: "left"}}>
            <IconButton onClick={handleOpen}>
                <FilterListIcon style={{ width: "18px", height: "fit-content" }} />
            </IconButton>
            <Menu
                id="bd-filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(null)}
                MenuListProps={{
                    'aria-labelledby': 'bd-filter-button',
                }}
            >
                <MenuItem style={{ color: bdFilter === "assignedMe" || bdFilter === "" ? "black" : "gray" }} value='assignedMe' onClick={() => handleClose("assignedMe")}>
                    Assigned to me
                </MenuItem>
                <MenuItem style={{ color: bdFilter === "assignedMeAndUnassigned" ? "black" : "gray" }} value='assignedMeAndUnassigned' onClick={() => handleClose("assignedMeAndUnassigned")}>
                    Assigned to me + Unassigned
                </MenuItem>
                <MenuItem style={{ color: bdFilter === "assigned" ? "black" : "gray" }} value='assigned' onClick={() => handleClose("assigned")}>
                    All assigned
                </MenuItem>
                <MenuItem style={{ color: bdFilter === "all" ? "black" : "gray" }} value='all' onClick={() => handleClose("all")}>
                    All
                </MenuItem>
            </Menu>

            <TableSortLabel
                active={orderBy === OrderByLabels.BD}
                direction={orderBy === OrderByLabels.BD ? orderByDir : 'desc'}
                onClick={() => handleSetOrderBy(OrderByLabels.BD)}
            >
                BD
            </TableSortLabel>
        </TableCell>
    )
}