import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList'


export const useGetIboNames = () => {
    const [iboNames, setIboNames] = useState([]);

    useEffect(() => {
        const getAllIboNames = async () => {
            const ref = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1)), (querySnapshot) => {
                let arr = [];
                querySnapshot.forEach((doc) => {
                    if (doc.data().role === 1) {
                        if (doc.data().disabled === true){
                            let fullUserName = "Deactivated Account"
                            arr.push({ id: doc.id, value: fullUserName })
                        }
                        else{
                            let fullUserName = doc.data().firstName + " " + doc.data().lastName
                            arr.push({ id: doc.id, value: fullUserName })
                        }
                        
                    }
                });
                setIboNames(arr)

            });

            firebaseRefList.push(ref);
        }
        getAllIboNames();

        return () => {setIboNames([])}
    }, []);
    return [iboNames];
};