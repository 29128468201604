import { Box, Button, Divider, InputAdornment, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useGetProjectSettingsData } from "../../hooks/settings/useGetProjectSettingsData";
import { setDefaultValues } from "../../firebase/cloudFunctions";
import { AlertBox } from "../../helper/AlertBox";
import PropTypes from 'prop-types';
import { SystemDefaultMatrix } from "../MatrixGrading/SystemDefault/SystemDefaultMatrix";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const SetDefaultTab = () => {
    const { loading, projectSettings } = useGetProjectSettingsData();

    const [isLoading, setIsLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [timePotIncubator, setTimePotIncubator] = useState(0);
    const [timePotScale, setTimePotScale] = useState(0);
    const [periodicityVal, setPeriodicityVal] = useState(0);
    const [inactivityVal, setInactivityVal] = useState(0);

    const [openAlert, setOpenAlert] = useState(false);
    const [isError, setIsError] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        if (loading === false && projectSettings != null) {
            console.log("Values: ", projectSettings)
            if (projectSettings.timePool != null) {
                if (projectSettings.timePool.incubator != null) {
                    setTimePotIncubator(projectSettings.timePool.incubator)
                }

                if (projectSettings.timePool.scale != null) {
                    setTimePotScale(projectSettings.timePool.scale)
                }
            }

            if (projectSettings.periodicity != null) {
                setPeriodicityVal(projectSettings.periodicity)
            }

            if (projectSettings.inactivity != null) {
                setInactivityVal(projectSettings.inactivity)
            }

            setChanged(false);
        }
    }, [loading, projectSettings]);

    const handleSetValue = (value, type) => {
        const valNum = Number(value);

        if (isNaN(valNum) === false && valNum >= 0) {
            if (changed === false) {
                setChanged(true);
            }

            if (type === "incubator") {
                setTimePotIncubator(valNum);
            }
            else if (type === "scale") {
                setTimePotScale(valNum);
            }
            else if (type === "periodicity") {
                setPeriodicityVal(valNum);
            }
            else if (type === "inactivity") {
                setInactivityVal(valNum);
            }
        }
    }

    const updateNewValues = () => {
        setIsLoading(true);
        setDefaultValues({
            timePoolScale: timePotScale,
            timePoolIncubator: timePotIncubator,
            inactivity: inactivityVal,
            periodicity: periodicityVal,
        }).then(() => {
            setIsError(false);
            setOpenAlert(true);
            setChanged(false);
        }).catch((err) => {
            console.error("Could not save data: ", err);
            setIsError(true);
            setOpenAlert(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <Box sx={{ width: '100%', justifyContent: "center" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px' }}>
                    <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab label="Default Values" {...a11yProps(0)} />
                        <Tab label="System Milestones Values" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={selectedTab} index={0}>
                    <Stack spacing={1}>
                        <Stack sx={{ width: '610px' }} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                            <Box fontSize={'1rem'}>
                                Timepot for INCUBATOR
                            </Box>
                            <TextField
                                sx={{
                                    width: '105px',
                                    '& input[type=number]': {
                                        '-mozAppearance': 'textfield'
                                    },
                                }}
                                size="small"
                                type="number"
                                value={timePotIncubator}
                                onChange={(e) => handleSetValue(e.target.value, 'incubator')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>,
                                }}
                            />
                        </Stack>
                        <Divider />
                        <Stack sx={{ width: '610px' }} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                            <Box fontSize={'1rem'}>
                                Timepot for SCALE
                            </Box>
                            <TextField
                                sx={{
                                    width: '105px',
                                    '& input[type=number]': {
                                        '-mozAppearance': 'textfield'
                                    },
                                }}
                                size="small"
                                type="number"
                                value={timePotScale}
                                onChange={(e) => handleSetValue(e.target.value, 'scale')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>,
                                }}
                            />
                        </Stack>
                        <Divider />
                        <Stack sx={{ width: '610px' }} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                            <Box fontSize={'1rem'}>
                                Periodicity to confirm company info
                            </Box>
                            <TextField
                                sx={{
                                    width: '105px',
                                    '& input[type=number]': {
                                        '-mozAppearance': 'textfield'
                                    },
                                }}
                                size="small"
                                type="number"
                                value={periodicityVal}
                                onChange={(e) => handleSetValue(e.target.value, 'periodicity')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">days</InputAdornment>,
                                }}
                            />
                        </Stack>
                        <Divider />
                        <Stack sx={{ width: '610px' }} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                            <Box fontSize={'1rem'}>
                                Number of days of inactivity before marking IBO as inactive
                            </Box>
                            <TextField
                                sx={{
                                    width: '105px',
                                    '& input[type=number]': {
                                        '-mozAppearance': 'textfield'
                                    },
                                }}
                                size="small"
                                type="number"
                                value={inactivityVal}
                                onChange={(e) => handleSetValue(e.target.value, 'inactivity')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">days</InputAdornment>,
                                }}
                            />
                        </Stack>
                        <Divider />
                        <Stack sx={{ width: '610px' }} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                            <Box fontSize={'1rem'}>

                            </Box>
                            <Button onClick={updateNewValues} disabled={changed === false} variant="outlined">
                                Update values
                            </Button>
                        </Stack>
                    </Stack>
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                    <SystemDefaultMatrix />
                </TabPanel>


            </Box>

            <AlertBox open={openAlert} setOpen={setOpenAlert} isError={isError} errorMsg={"Could not save default values!"} successMsg={"Default values updated!"} />
        </>
    )
}