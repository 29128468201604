import { Box, Checkbox, DialogContent, Divider, Button, FormControl, InputLabel, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, IconButton, FormControlLabel, Switch, Tooltip } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../../helper/bootstrapDialog"
import { DEFAULT_MILESTONE_TEXT, GRADING_TABLE_HEADERS } from "../../../constants/grading"
import { GRADING_CELL_INFOTEXT } from "../../../constants/lang/eng/grading"
import { useEffect, useState } from "react"

import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CommentDialogGradingCellInternal from "../../Comments/CommentDialogGradingCellInternal"

/**
 * 
 * @param {object} param0 
 * @param {boolean} param0.open
 * @param {() => void} param0.handleClose
 * @param {import("../../../types/Grading").GradingCell} param0.cell
 * @param {boolean} param0.update
 * @param {(tag: string, level: number, milestones: any) => void} param0.handleSaveMilestoneData
 */
export const SystemDefaultMilestonesDialog = ({
    open,
    handleClose,
    cell,
    update,
    handleSaveMilestoneData,
}) => {
    const [toggleVal, setToggleVal] = useState('');
    const [isEdited, setIsEdited] = useState(false);
    const [updateLocal, setUpdateLocal] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [milestones, setMilestones] = useState([
        { text: DEFAULT_MILESTONE_TEXT, checked: false }
    ]);

    useEffect(() => {
        if (cell?.levelData != null) {
            if (cell?.levelData?.Milestones != null) {
                let localList = [];

                for (let index = 0; index < cell?.levelData?.Milestones.length; index++) {
                    const value = cell?.levelData?.Milestones[index];
                    let checked = false;

                    if (cell?.levelData?.MilestoneCompletion != null && cell?.levelData?.MilestoneCompletion[index]) {
                        checked = cell?.levelData?.MilestoneCompletion[index];
                    }

                    localList.push({ text: value, checked: checked })
                }

                setMilestones(localList);
                setUpdateLocal(!updateLocal);
            }
            else {
                setMilestones([{ text: DEFAULT_MILESTONE_TEXT, checked: false }]);
                setUpdateLocal(!updateLocal);
            }

            if (cell?.levelData?.LevelCompletion != null) {
                setIsCompleted(true);
            }
            else {
                setIsCompleted(false);
            }
        }
        else {
            setMilestones([{ text: DEFAULT_MILESTONE_TEXT, checked: false }]);
            setUpdateLocal(!updateLocal);
            setIsCompleted(false);
        }

        setIsEdited(false);
    }, [cell])

    const getInfoText = () => {
        let text = "";
        if (cell.tag != null && GRADING_CELL_INFOTEXT[cell.tag] != null) {
            if (GRADING_CELL_INFOTEXT[cell.tag][cell.level] != null) {
                text = GRADING_CELL_INFOTEXT[cell.tag][cell.level];
            }
        }

        return text;
    }

    const handleChangeText = (index, value) => {
        let local = milestones;

        if (local[index] != null) {
            local[index].text = value;
        }
        setIsEdited(true);
        setMilestones(local);
        setUpdateLocal(!updateLocal);
    }

    const addMilestoneRow = () => {

        let local = milestones;

        local.push({
            text: DEFAULT_MILESTONE_TEXT,
            checked: false
        });

        setIsEdited(true);
        setMilestones(local);
        setUpdateLocal(!updateLocal);
    }

    const removeMilestoneRow = (index) => {
        let local = milestones;

        local.splice(index, 1);
        setIsEdited(true);
        setMilestones(local);
        setUpdateLocal(!updateLocal);
    }

    const handleClickSave = () => {
        if (cell != null) {
            handleSaveMilestoneData(cell.tag, cell.level, milestones);
        }
        handleClose();
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <Box width={'500px'}>
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ padding: '10px 10px 0px 10px' }}><strong>{GRADING_TABLE_HEADERS[cell.tag]} - Level: {cell.level}</strong></h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent>
                    <Stack spacing={1}>
                        <Typography sx={{ width: '95%' }}>
                            {getInfoText()}
                        </Typography>
                        <Divider />
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography>
                                Default milestones
                            </Typography>
                        </Stack>
                        {milestones.map((item, index) => {
                            return (
                                <Stack key={index} direction={'row'} alignItems={'center'}>
                                    <Checkbox checked={item.checked} disabled />

                                    <TextField
                                        sx={{ width: '380px' }}
                                        size="small"
                                        value={item.text}
                                        onChange={(e) => handleChangeText(index, e.target.value)}
                                    />

                                    {index > 0 ? (
                                        <IconButton onClick={() => removeMilestoneRow(index)}>
                                            <HighlightOffRoundedIcon />
                                        </IconButton>
                                    ) : ("")}
                                </Stack>
                            );

                        })}

                        <Stack sx={{ paddingLeft: '5px' }} direction={'row'} alignItems={'center'}>
                            <Button onClick={addMilestoneRow}>
                                <Stack direction={'row'} spacing={1}>
                                    <ControlPointRoundedIcon />
                                    <Box>
                                        Add Milestone
                                    </Box>
                                </Stack>

                            </Button>
                        </Stack>
                    </Stack>


                    <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                        <Button size="small" onClick={handleClose} variant="outlined">
                            {isEdited ? (
                                "Close without saving"
                            ) : (
                                "Close"
                            )}
                        </Button>
                        {isEdited ? (
                            <Button size="small" onClick={handleClickSave} variant="outlined">
                                Save
                            </Button>
                        ) : ("")}

                    </Stack>
                </DialogContent>
            </Box>

        </BootstrapDialog>
    )
}