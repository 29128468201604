import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList';
import { useGetUserNames } from '../userData/useGetUserNames';

export const useGetFiles = (projectID, location = null, questionKey = null, orderByVal = null, ascending = true) => {
    const [usernames] = useGetUserNames();

    const [files, setfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const order = () => {
        if (ascending === true) {
            return 'asc'
        }
        else {
            return 'desc'
        }
    }

    function GetNames(userId) {
        let username;
        usernames.forEach((userName) => {
            if (userId === userName.id) {
                username = userName.value;
            }
        })
        return username
    }

    useEffect(() => {
        if (projectID == null || usernames == null || usernames.length === 0) {
            setIsLoading(false);
            return;
        }

        let fsCollection = collection(db, "Projects/" + projectID + "/Files");
        let fsQuery;


        const getCommentsAssigned = (query) => {
            setIsLoading(true);
            const refFunc = onSnapshot(query, async (snapshot) => {
                let arr = [];
                snapshot.forEach((file) => {
                    let nameUser = GetNames(file.data().uploadedBy)
                    arr.push({ id: file.id, createdUserName: nameUser, ...file.data() })
                })

                setfiles(arr)
            });

            firebaseRefList.push(refFunc);
            setIsLoading(false);
        }

        if (location != null && questionKey != null) {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("location", "==", location), where("assignedID", "==", questionKey), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("location", "==", location), where("assignedID", "==", questionKey))
            }

        }
        else if (location != null) {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("location", "==", location), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("location", "==", location))
            }
        }
        else {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection)
            }
            fsQuery = query(fsCollection)
        }

        getCommentsAssigned(fsQuery);

    }, [projectID, usernames, location, orderByVal, ascending, questionKey])

    return { files, isLoading };
};

export const useGetInternalFiles = (projectID, location = null, questionKey = null, orderByVal = null, ascending = true) => {
    const [usernames] = useGetUserNames();

    const [files, setfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const order = () => {
        if (ascending === true) {
            return 'asc'
        }
        else {
            return 'desc'
        }
    }

    function GetNames(userId) {
        let username;
        usernames.forEach((userName) => {
            if (userId === userName.id) {
                username = userName.value;
            }
        })
        return username
    }

    useEffect(() => {
        if (projectID == null || usernames == null || usernames.length === 0) {
            setIsLoading(false);
            return;
        }

        let fsCollection = collection(db, "Projects/" + projectID + "/Files");
        let fsQuery;


        const getCommentsAssigned = (query) => {
            setIsLoading(true);
            const refFunc = onSnapshot(query, async (snapshot) => {
                let arr = [];
                snapshot.forEach((file) => {
                    let nameUser = GetNames(file.data().uploadedBy)
                    arr.push({ id: file.id, createdUserName: nameUser, ...file.data() })
                })

                setfiles(arr)
            });

            firebaseRefList.push(refFunc);
            setIsLoading(false);
        }

        if (location != null && questionKey != null) {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("location", "==", location), where("assignedID", "==", questionKey), where("isExternal", "==", false), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("location", "==", location), where("assignedID", "==", questionKey), where("isExternal", "==", false))
            }

        }
        else if (location != null) {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("location", "==", location), where("isExternal", "==", false), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("location", "==", location), where("isExternal", "==", false))
            }
        }
        else {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("isExternal", "==", false), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("isExternal", "==", false))
            }
            fsQuery = query(fsCollection)
        }

        getCommentsAssigned(fsQuery);

    }, [projectID, usernames, location, orderByVal, ascending, questionKey])

    return { files, isLoading };
};

export const useGetExternalFiles = (projectID, location = null, questionKey = null, orderByVal = null, ascending = true) => {
    const [usernames] = useGetUserNames();

    const [files, setfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const order = () => {
        if (ascending === true) {
            return 'asc'
        }
        else {
            return 'desc'
        }
    }

    function GetNames(userId) {
        let username;
        usernames.forEach((userName) => {
            if (userId === userName.id) {
                username = userName.value;
            }
        })
        return username
    }

    useEffect(() => {
        if (projectID == null || usernames == null || usernames.length === 0) {
            setIsLoading(false);
            return;
        }

        let fsCollection = collection(db, "Projects/" + projectID + "/Files");
        let fsQuery;


        const getCommentsAssigned = (query) => {
            setIsLoading(true);
            const refFunc = onSnapshot(query, async (snapshot) => {
                let arr = [];
                snapshot.forEach((file) => {
                    let nameUser = GetNames(file.data().uploadedBy)
                    arr.push({ id: file.id, createdUserName: nameUser, ...file.data() })
                })

                setfiles(arr)
            });

            firebaseRefList.push(refFunc);
            setIsLoading(false);
        }

        if (location != null && questionKey != null) {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("location", "==", location), where("assignedID", "==", questionKey), where("isExternal", "==", true), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("location", "==", location), where("assignedID", "==", questionKey), where("isExternal", "==", true))
            }

        }
        else if (location != null) {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("location", "==", location), where("isExternal", "==", true), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("location", "==", location), where("isExternal", "==", true))
            }
        }
        else {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("isExternal", "==", true), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("isExternal", "==", true))
            }
            fsQuery = query(fsCollection)
        }

        getCommentsAssigned(fsQuery);

    }, [projectID, usernames, location, orderByVal, ascending, questionKey])

    return { files, isLoading };
};