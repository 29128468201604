import { Alert, Snackbar } from "@mui/material"
import { getAnalytics, logEvent } from "firebase/analytics";
import { Fragment, useEffect } from "react"


export const ErrorAlert = ({ isError, setIsError, errorMsg, errorInfo = null }) => {
    const analytics = getAnalytics();

    useEffect(() => {
        if (isError === true) {
            logEvent(analytics, 'exception', {
                description: errorInfo || 'Error Alert',
                fatal: false,
            })
        }
    }, [isError])

    return (
        <Fragment>
            <Snackbar open={isError} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setIsError(false)}>
                <Alert severity="error" color="error">
                    {errorMsg || "Something went wrong!"}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}