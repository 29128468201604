import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { sendInvitationalEmail } from '../../../firebase/cloudFunctions';
import { isEmail } from '../../../helper/formValidation';
import { ErrorAlert } from '../../../helper/errorAlert';

import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Dialog, DialogTitle, IconButton, DialogContent, TextField, Grid, Box, CircularProgress, Button, Typography } from '@mui/material';
import { AlertBox } from '../../../helper/AlertBox';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function AddParticipant({projID, headerText = "Add participant"}) {
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [email, setEmail] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);

    const handleChange = (event) => {
        setEmail(event.target.value);

        if (isEmail.test(event.target.value) === false){
            setFormIsValid(false);
        }
        else{
            setFormIsValid(true);
        }
    }

    //this function is called when clicking the `Send` button
    const submitForm = async () => {
        //set `isLoading` state to true, so the spinner is rendered
        setIsLoading(true);

        // call the cloud function. 
        sendInvitationalEmail({
            projectID: projID,
            receiver: email,
            baseUrl: window.location.origin
        }).then(() => {
            setEmail('');
            handleClose();
            setOpenAlert(true);
        }).catch((error) => {
            setErrorInfo(error);
            setErrorMsg('Something went wrong! Could not send invitation.');
            setError(true);
            setOpenAlert(true);
        }).finally(() => {
            setIsLoading(false);
        })
    };



    return (
        <>
            <Button
                variant="contained"
                onClick={handleClickOpen}
            >
                <ControlPointIcon style={{ marginRight: "8px", fontSize: "17px" }} />
                {headerText}
            </Button>


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6><strong>{headerText}</strong></h6>
                </BootstrapDialogTitle>
                <DialogContent>
                    <Typography>
                        Enter an e-mail adress to invite a new participant to the project:
                    </Typography>
                    <form>
                        <TextField
                            className='add-paticipant-field'
                            fullWidth
                            value={email}
                            placeholder='E-mail'
                            onChange={handleChange}
                            type={'email'}
                            variant={'standard'}
                            error={formIsValid === false && email.length > 0}
                            helperText={formIsValid === false && email.length > 0 ? ("Please enter a valid email") : ("")}
                            />
                        <Grid container justifyContent="center">
                            <Box mt="2rem">
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <div>
                                        <Button
                                            onClick={submitForm}
                                            disabled={!formIsValid}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Invite
                                        </Button>
                                    </div>
                                )}
                            </Box>
                        </Grid>
                    </form>
                </DialogContent>

            </BootstrapDialog>

            <AlertBox open={openAlert} setOpen={setOpenAlert} isError={error} successMsg={"Invitation-link sent!"} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    );
}
