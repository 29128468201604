export const COUNTRY_LIST = [
    { label: "Denmark" },
    { label: "Norway" },
    { label: "Finland" },
    { label: "Other EU/EES-country" },
    { label: "Other country outside EU/EES" },
]

export const REGION_LIST = [
    { label: "Stockholm" },
    { label: "Uppsala" },
    { label: "Sörmland" },
    { label: "Östergötland" },
    { label: "Jönköping Län" },
    { label: "Kronoberg" },
    { label: "Kalmar Län" },
    { label: "Gotland" },
    { label: "Blekinge" },
    { label: "Skåne" },
    { label: "Halland" },
    { label: "Västra Götaland" },
    { label: "Värmland" },
    { label: "Örebro Län" },
    { label: "Västmanland" },
    { label: "Dalarna" },
    { label: "Gävleborg" },
    { label: "Västernorrland" },
    { label: "Jämtland Härjedalen" },
    { label: "Västerbotten" },
    { label: "Norrbotten" },
    { label: "Outside Sweden" },
]

export const COUNTY_LIST = [
    { label: "Bjuv" },
    { label: "Bromölla" },
    { label: "Burlöv" },
    { label: "Eslöv" },
    { label: "Helsingborg" },
    { label: "Höganäs" },
    { label: "Hörby" },
    { label: "Höör" },
    { label: "Klippan" },
    { label: "Kristianstad" },
    { label: "Kävlinge" },
    { label: "Landskrona" },
    { label: "Lomma" },
    { label: "Lund" },
    { label: "Malmö" },
    { label: "Osby" },
    { label: "Perstorp" },
    { label: "Simrishamn" },
    { label: "Sjöbo" },
    { label: "Skurup" },
    { label: "Svedala" },
    { label: "Tomelilla" },
    { label: "Trelleborg" },
    { label: "Vellinge" },
    { label: "Ystad" },
    { label: "Åstorp" },
    { label: "Ängelholm" },
    { label: "Örkelljunga" },
    { label: "Östra Göinge" },
]