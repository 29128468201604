import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './css/TextFieldCreator.css'
import TabMenuQuestionCreator from "./TabMenuQuestionCreator"
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { db } from '../../firebase';
import { Checkbox } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { doc, getDoc } from "firebase/firestore";


function ContentCreator() {
    const [questionTitle, setQuestionTitle] = useState('');
    const [questionTag, setQuestionTag] = useState('');
    const [questionPerk, setQuestionPerk] = useState(false);
    const [tags, setTags] = useState([]);
    const [perks, setPerks] = useState([]);

    const handleQuestionTag = (event) => {
        setQuestionTag(event.target.value);
    }

    const handleQuestionPerk = (event) => {
        setQuestionPerk(event.target.checked);
    }

    useEffect(() => {
        getDoc(doc(db, "Category/question")).then((querySnapshot) => {
            setTags(querySnapshot.data().category);
            setPerks(querySnapshot.data().perks);
        });
    }, []); // empty dependencies array => useEffect only called once

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container justifyContent={'flex-start'} spacing={1}>
                    <Grid item xs={12} md={6} lg={4}>
                        <form className="rich-text-short-desc">
                            <p>1. Name your object</p>
                            <TextField
                                value={questionTitle}
                                onChange={e => setQuestionTitle(e.target.value)}
                                required={true}
                                id='standard-helperText shortDesc-field'
                                label='Title'
                                variant='standard'
                                helperText="This information is only used internally"
                            />
                        </form>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <div style={{ marginTop: "40px", marginBottom: "40px" }}>
                            <p>2. Choose your tag</p>

                            <FormControl fullWidth size="small">
                                <InputLabel id="choose-tag-id">Choose Tag</InputLabel>
                                <Select
                                    labelId="choose-tag-id"
                                    label="Choose a tag"
                                    value={questionTag}
                                    onChange={handleQuestionTag}
                                    id="select-question-tag"
                                    required
                                    style={{ width: 300 }}>
                                    {tags.map((tag) => {
                                        return (
                                            <MenuItem key={tag} value={tag}>{tag}</MenuItem>)
                                    })
                                    }
                                </Select>

                            </FormControl>

                        </div>

                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <div style={{ marginTop: "40px", marginBottom: "40px" }}>
                            <p>3. Choose Perks</p>

                            <FormControlLabel
                                control={<Checkbox
                                    value={"Infinity"}
                                    checked={questionPerk}
                                    onChange={handleQuestionPerk}
                                    name="Infinity"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="Infinity"
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>

            <div>
                <TabMenuQuestionCreator shortDesc={questionTitle} tag={questionTag} perk={questionPerk} />
            </div>
        </div>

    );
}

export default ContentCreator;
