import { useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
//import TableIbo from './Table'
import '../../assets/scss/app.scss'
import TabMenu from './TabMenuAdmin';
import useGetUserData from '../../hooks/userData/useGetUserData'
import { Box, CircularProgress } from '@mui/material'

const HomeAdmin = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { loading } = useGetUserData();

    useEffect(() => {
        if (!loading) {
            if (currentUser != null) {
                if (currentUser.role !== 3) {
                    if (currentUser.role === 2) {
                        navigate("/home-bd", { replace: true });
                    }
                    else {
                        navigate("/home", { replace: true });
                    }
        
                }
            }
            else {
                navigate("/login");
            }
        }

    }, [loading]);
    


    return (
        <>
            {!loading ? (
                <>
                    <Box  className='jumbotron'>

                        <div className="text-center">
                            <h5>Welcome to the Admin dashboard!</h5>

                            <p className="jumbo-text">You are logged in as <strong>{currentUser.email}</strong></p>

                        </div>

                    </Box>
                    <>
                        <TabMenu />
                    </>
                </>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            )}


        </>
    )
}

export default HomeAdmin