import { Autocomplete, Box, Button, DialogActions, DialogContent, Divider, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"

import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from "react";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { updateDeadlineStep } from "../../firebase/cloudFunctions";
import { BootstrapDialog, BootstrapDialogTitle } from "../../helper/bootstrapDialog";
import { generateTimeslots } from "../../helper/timeslotGenerator";
import dayjs from "dayjs";

export const DatePickerUpdateDeadline = ({ projectData, stepdata }) => {
    const [loading, setLoading] = useState(false);
    const [selectedTime, setSelectedTime] = useState('00:00');
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
    const [selectedTimeDate, setSelectedTimeDate] = useState("");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }


    useEffect(() => {
        if (stepdata?.nextDeadline != null) {
            const dateSplit = String(stepdata?.nextDeadline).split(' ');
            const curDate = new Date(Date.parse(dateSplit[0] + " " + dateSplit[dateSplit.length - 1]));
            setSelectedTime(dateSplit[dateSplit.length - 1]);
            setSelectedDate(dayjs(curDate));
            setSelectedTimeDate(curDate.toUTCString());
        }
    }, [stepdata])

    const handleTimeChange = (newValue) => {
        //console.log(new Date(selectedDate).toDateString() + " " + new Date(newValue.time).toTimeString());
        //console.log("Time: ", newValue)
        if (newValue != null) {
            // @ts-ignore
            setSelectedTime(newValue);
            setSelectedTimeDate(selectedDate.toDate().toDateString() + " " + newValue);
        }
        else {
            setSelectedTime(null)
            setSelectedTimeDate(selectedDate.toDate().toDateString());
        }
    };

    const handleDateChange = (newValue) => {
        console.log(new Date(newValue).toDateString() + " " + new Date(selectedTime).toTimeString());
        if (newValue != null) {
            setSelectedDate(newValue)
            setSelectedTimeDate(new Date(newValue).toDateString() + " " + selectedTime);
        }
        else {
            setSelectedDate(null)
            setSelectedTimeDate(new Date().toDateString() + " " + selectedTime);
        }
    };

    const updateTime = () => {
        const newDate = new Date(Date.parse(selectedTimeDate));
        //console.log("FinalValue: ", newDate.toUTCString(), projectData?.id, stepdata?.key);

        setLoading(true);
        updateDeadlineStep({
            time: newDate.toUTCString(),
            projectID: projectData.id,
            step: stepdata?.key,
        }).then(() => {
            handleClose();
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Box>
            <IconButton sx={{ marginLeft: '10px' }} onClick={handleOpen} size='small'><EditIcon /></IconButton>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ marginTop: 15, marginLeft: 15, }}>Change deadline for {projectData?.value} - <i>{stepdata?.name}</i></h6>
                </BootstrapDialogTitle>
                <Divider />
                <DialogContent dividers>
                    <Box sx={{ paddingBottom: '15px' }}>
                        {stepdata?.nextTime != null ? (
                            <Typography color={'GrayText'}>
                                Current deadline: <i><strong>{stepdata?.nextDeadline}</strong></i>
                            </Typography>

                        ) : (
                            <Typography color={'GrayText'}>
                                Current deadline: <i>No current deadline.</i>
                            </Typography>
                        )}
                    </Box>

                    <Divider />

                    <Box sx={{ padding: '15px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Typography sx={{ paddingBottom: '15px', fontSize: '14px !important' }}>
                                New deadline:
                            </Typography>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <DatePicker
                                    label="Date"
                                    value={selectedDate}
                                    disablePast
                                    format={'ddd YYYY-MM-DD'}
                                    onChange={handleDateChange}
                                    slotProps={{ textField: { size: 'small', sx: { width: 200 } } }}
                                />

                                <Autocomplete
                                    freeSolo
                                    size="small"
                                    disableClearable
                                    value={selectedTime}
                                    onChange={(event, newValue) => handleTimeChange(newValue)}
                                    options={generateTimeslots().map((option) => option.title)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Time"
                                            sx={{ width: 140 }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: <InputAdornment position="end"><IconButton size="small"><AccessTimeRoundedIcon /></IconButton></InputAdornment>,
                                            }}
                                        />
                                    )}
                                />
                                {/* 
                                <DatePicker
                                    defaultValue={selectedDate}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    format={'ddd yyyy-MM-DD'}
                                    disabledDate={disabledDate}
                                />

                                <TimePicker
                                    minuteStep={15}
                                    defaultValue={selectedTime}
                                    value={selectedTime}
                                    format='HH:mm'
                                    onChange={handleTimeChange}
                                    onSelect={handleTimeChange}
                                    style={{ fontSize: '13px' }}
                                />*/}
                            </Stack>
                        </LocalizationProvider>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={updateTime} disabled={loading}>
                        Change time
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}