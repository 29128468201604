import { useEffect, useState } from "react";
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TableCell, TableSortLabel } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { OrderByLabels } from "../../../../../constants/labels/labels";

export const IBOHeaderCell = ({ searchVal, setSearchVal, orderBy, orderByDir, handleSetOrderBy }) => {
    const [openFilter, setOpenFilter] = useState(false);
    const [updateSearch, setUpdateSearch] = useState(false);
    const [searchValString, setSearchValString] = useState('');

    useEffect(() => {
        if (updateSearch === true) {
            setSearchVal(searchValString);
            setUpdateSearch(false);
        }
    }, [updateSearch])


    const handleClick = (event) => {
        if (openFilter === true) {
            setOpenFilter(false);
            setSearchVal('');
        }
        else {
            setOpenFilter(true);
        }

    };

    const handleSearchValChange = (event) => {
        setSearchValString(event.target.value);
        if (updateSearch === false) {
            const delayDebounceFn = setTimeout(() => {
                setUpdateSearch(true);
            }, 800);

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
    }

    const clearSearch = () => {
        setOpenFilter(false);
        setSearchValString('');
        setSearchVal('');
    }

    return (
        <TableCell sx={{ minWidth: '100px', maxWidth: '250px' }}>
            <Stack direction={'row'}>
                <IconButton onClick={handleClick}>
                    {searchVal != null && searchVal !== '' ? (
                        <SearchIcon style={{ width: "18px", height: "fit-content" }} htmlColor='#4eacc1' />
                    ) : (
                        <SearchIcon style={{ width: "18px", height: "fit-content" }} />
                    )}
                </IconButton>

                <TableSortLabel
                    active={orderBy === OrderByLabels.IBO}
                    direction={orderBy === OrderByLabels.IBO ? orderByDir : 'desc'}
                    onClick={() => handleSetOrderBy(OrderByLabels.IBO)}
                >
                    IBO
                </TableSortLabel>

                {openFilter === true ? (
                    <Box>
                        <FormControl sx={{ mb: '-4px', width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor='search-box'>Search</InputLabel>
                            <OutlinedInput
                                id='search-box'
                                label="Search"
                                size='small'
                                sx={{ width: '150px' }}
                                value={searchValString}
                                onChange={handleSearchValChange}
                                onBlur={(e) => { setSearchVal(e.target.value) }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchVal(searchValString);
                                    }
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle clear search"
                                            onClick={clearSearch}
                                            edge="end"
                                        >
                                            <ClearIcon fontSize='small' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                ) : ("")}
            </Stack>
        </TableCell>
    )
}