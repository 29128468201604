import { collection, doc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";

/**
 * 
 * @param {string} projectID 
 * @param {('incubator'|'scale')} [track] 
 * @returns 
 */
export const useGetTimeLogData = (projectID, track = null) => {
    const [loading, setLoading] = useState(true);
    const [timeLog, setTimeLog] = useState([]);

    useEffect(() => {
        if (!projectID) return;
        let dbQuery;
        if (track != null){
            dbQuery = query(collection(db, "Projects/" + projectID + "/TimeLog/"), where('track', '==', track), orderBy('date', 'asc'))
        }
        else{
            dbQuery = query(collection(db, "Projects/" + projectID + "/TimeLog/"), orderBy('date', 'asc'));
        }

        const ref = onSnapshot(dbQuery, (snapshot) => {
            let timeLogList = [];
            snapshot.forEach((child) => {
                timeLogList.push({id: child.id, ...child.data()})
            })
            setTimeLog(timeLogList);
            setLoading(false);
        })

        firebaseRefList.push(ref);
    }, [projectID, track])


    return { loading, timeLog };
}