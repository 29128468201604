import { Box, Dialog, DialogContent, Divider } from "@mui/material"
import { BootstrapDialogTitle } from "../../helper/bootstrapDialog"
import { TrackLabels } from "../../constants/labels/labels"
import { ScaleStepperContent } from "../Stepper/Scale/ScaleStepperContent"
import { IncubatorStepperContent } from "../Stepper/Incubator/IncubatorStepperContent"
import { StartStepperContent } from "../Stepper/Start/StartStepperComponent"


export const StepperDialog = ({ open, handleClose, track, project }) => {

    const openTrack = () => {
        switch (track) {
            case TrackLabels.start.key:
                return (<StartStepperContent project={project} isPopup />);
            case TrackLabels.incubator.key:
                return (<IncubatorStepperContent project={project} isPopup />)
            case TrackLabels.scale.key:
                return (<ScaleStepperContent project={project} isPopup />)
            default:
                return (<StartStepperContent project={project} isPopup  />);
        }
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={false}
        >
            <Box sx={{ width: '80vw', height: '85vh' }}>
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ padding: '10px 10px 0px 10px' }}><strong>{TrackLabels[track]?.label}</strong></h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent sx={{ width: '80vw', height: '95%' }}>
                    <Box >
                        {openTrack()}
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}