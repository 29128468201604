import { useState } from "react"
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom'
import logo from '../../assets/images/Krinova_logo.png'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useAuth } from "../../contexts/AuthContext";
import useGetUserData from "../../hooks/userData/useGetUserData";
import testVersionImg from '../../assets/images/test-version-red.png'


const Navigation = () => {
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate()
    const { loading } = useGetUserData();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleLogout = () => {
        handleClose();
        logout();
        navigate("/", { replace: true });
    }

    const location = useLocation();
    const path = location.pathname;


    /*
    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }*/

    function userInfo() {
        if (currentUser != null) {
            if (currentUser.role === 2) {
                return (
                    <>
                        <NavLink className="homeBtn" to="/home-bd">
                            <HomeRoundedIcon />
                        </NavLink>

                        <NavDropdown title={currentUser.displayName || currentUser.email} id="basic-nav-dropdown">
                            <NavLink to="/account-settings" className="dropdown-item" onClick={handleClose} style={{ fontSize: '0.8rem' }}>Account Settings</NavLink>
                            <NavLink to="/login" className="dropdown-item" onClick={handleLogout} style={{ fontSize: '0.8rem' }}>Log Out</NavLink>
                        </NavDropdown>
                    </>)
            }
            if (currentUser.role === 3) {
                if (path === "/admin") { }
                return (
                    <>
                        <NavLink className="homeBtn" to="/home-bd">
                            <HomeRoundedIcon /></NavLink>

                        <NavDropdown show={open} onToggle={() => { setOpen(!open) }} title={currentUser.displayName || currentUser.email} id="basic-nav-dropdown">
                            {path === "/admin" ? (
                                <NavLink to="/home-bd" className="dropdown-item" onClick={handleClose} style={{ fontSize: '0.8rem' }}>BD Mode</NavLink>
                            ) : (
                                <NavLink to="/admin" className="dropdown-item" onClick={handleClose} style={{ fontSize: '0.8rem' }}>Admin Mode</NavLink>
                            )}
                            <NavLink to="/account-settings" className="dropdown-item" onClick={handleClose} style={{ fontSize: '0.8rem' }}>Account Settings</NavLink>
                            <NavLink to="/login" className="dropdown-item" onClick={handleLogout} style={{ fontSize: '0.8rem' }}>Log Out</NavLink>
                        </NavDropdown>
                    </>)
            }
            else {
                return (
                    <>
                        {path !== "/awaiting-confirm" && path !== '/inactive' ? (
                            <NavLink className="homeBtn" to="/home">
                                <HomeRoundedIcon />
                            </NavLink>
                        ) : ("")}

                        <NavDropdown title={currentUser.displayName || currentUser.email} id="basic-nav-dropdown">
                            {/* <NavLink to="/update-profile" className="dropdown-item">Update Profile</NavLink>
											<NavDropdown.Divider /> */}
                            <NavLink to="/account-settings" className="dropdown-item" onClick={handleClose} style={{ fontSize: '0.8rem' }}>Account Settings</NavLink>
                            <NavLink to="/login" className="dropdown-item" onClick={handleLogout} style={{ fontSize: '0.8rem' }}>Log Out</NavLink>
                        </NavDropdown>
                    </>)
            }
        }

        return (
            <>
                <NavLink to="/login" className="nav-link">Login</NavLink>
                <NavLink to="/sign-up" className="nav-link">Sign Up</NavLink>
            </>
        )
    }

    return (

        <div>
            <Navbar collapseOnSelect expand="sm" bg="transparent">
                <Container>
                    <Link to="/">
                        <Navbar.Brand>
                            <img
                                alt="logo"
                                src={logo}
                                width="107px"
                                height="30px"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                    </Link>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">

                        <Nav className="ml-auto justify-content-end flex-grow-1">
                            {!loading && userInfo()}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Navigation