import { useState, useEffect } from "react";
import { db } from '../../../firebase';
import Box from '@mui/material/Box';
import { useGetAllQuestions } from "../../../hooks/questionData/useGetAllQuestions";
import { useGetIdeaQuestions } from "../../../hooks/questionData/useGetIdeaQuestions";
import Card from "@mui/material/Card";
//import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';

import { CircularProgress } from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";


const ExitSummaryIBO = ({ projectID }) => {
    const questions = useGetAllQuestions();
    const ideaQuestions = useGetIdeaQuestions();

    const [allQuestions] = useState({});
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        const getData = async () => {
            await getDocs(query(collection(db, "Projects/" + projectID + "/Steps"), orderBy('order'))).then(async (documents) => {
                for (let index = 0; index < documents.docs.length; index++) {
                    const element = documents.docs[index];
                    if (element.id === 'a') {
                        await getDocs(collection(db, element.ref.path + "/Questions")).then((questionData) => {
                            const questionArray = {};
                            questionData.docs.forEach((questionSnap) => {
                                if (questionSnap.data().answer != null) {
                                    const questionInfo = questionSnap.data();
                                    if (ideaQuestions[questionSnap.id] != null) {
                                        questionInfo["text"] = ideaQuestions[questionSnap.id].text;
                                        questionInfo["order"] = ideaQuestions[questionSnap.id].order;
                                    }
                                    //console.log("ID: ", questionSnap.id);
                                    questionArray[questionSnap.id] = questionInfo
                                }
                            });

                            allQuestions[element.data().name] = questionArray;
                        });
                    }
                    else {
                        await getDocs(collection(db, element.ref.path + "/AssignedQuestions")).then((questionData) => {
                            const questionArray = {};
                            questionData.docs.forEach((questionSnap) => {
                                const question = questions.find(({ id }) => id === questionSnap.data().questionID);

                                const questionInfo = questionSnap.data();
                                if (question != null) {
                                    questionInfo["text"] = question.value;
                                    questionInfo["order"] = question.order;
                                    questionInfo["type"] = question.type;
                                    questionInfo["perks"] = question.perks;
                                }

                                questionArray[questionSnap.id] = questionInfo
                            });

                            allQuestions[element.data().name] = questionArray;
                        });
                    }
                }

                setIsLoading(false);
            });
        }

        getData();
    }, [projectID, questions]);


    function ascendingComparator(a, b) {
        if (b["order"] > a["order"]) {
            return -1;
        }
        if (b["order"] < a["order"]) {
            return 1;
        }
        return 0;
    }

    function showQuestionCard(step, question, key) {
        if (step === "Entry") {
            if (question.answer != null && question.answer !== "") {

                return (
                    <div key={key}>
                        <Card variant="outlined" sx={{ padding: 2 }}>
                            <span><strong>Question: </strong></span><br />
                            {question.text}<br />
                            <span><strong>Answer:</strong></span><br />
                            {question.answer}
                        </Card>
                    </div>
                );
            }
            else {
                return (
                    <div key={key}>
                        <Card variant="outlined" sx={{ padding: 2 }}>
                            <span><strong>Question: </strong></span><br />
                            {question.text}<br />
                            <span><strong>Answer:</strong></span><br />
                            No Answer!
                        </Card>
                    </div>
                );
            }
        }
        else {
            if (question.type === "EmbededLinkInput" || question.type === "RichTextInput" || question.type === "RichTextInput" || question.type === "miro" || question.perks === "Infinity") {
                return (<div key={key}></div>);
            }
            else {
                if (question.answer != null && question.answer !== "") {
                    return (
                        <div key={key}>
                            <Card variant="outlined" sx={{ padding: 2 }}>
                                <span><strong>Question: </strong></span><br />
                                {parse(question.text)}
                                <span><strong>Answer:</strong></span><br />
                                {question.answer}<br />
                            </Card>
                        </div>
                    );
                }
                else {
                    return (
                        <div key={key}>
                            <Card variant="outlined" sx={{ padding: 2 }}>
                                <span><strong>Question: </strong></span><br />
                                {parse(question.text)}
                                <span><strong>Answer:</strong></span><br />
                                <i>No Answer!</i>
                            </Card>
                        </div>
                    );
                }
            }
        }
    }

    return (
        <div>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
            ) : (
                <>

                    {Object.keys(allQuestions).map((step) => {
                        return (
                            <div key={step} style={{ display: 'flex', justifyContent: 'center' }}>
                                {Object.keys(allQuestions[step]).length > 0 ? (
                                    <Box
                                        sx={{
                                            width: '44%',
                                            justifyContent: 'center',
                                        }}>
                                        <div style={{ paddingLeft: '15px', paddingBottom: '5px', paddingTop: '20px' }}><h5>{step}:</h5></div><>
                                            {Object.keys(allQuestions[step]).sort((a, b) => ascendingComparator(allQuestions[step][a], allQuestions[step][b])).map((questionKey) => {
                                                return showQuestionCard(step, allQuestions[step][questionKey], questionKey);
                                            })}
                                        </>

                                    </Box>
                                ) : (
                                    ''
                                )}
                            </div>

                        );
                    })}

                </>
            )
            }

        </div >
    )
};

export default ExitSummaryIBO;