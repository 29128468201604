import { TableCell, TableRow } from '@mui/material';

import { FileOverview } from '../../Files/FileOverview';

import { TableRowDeleteIBO } from './TableRowCells/TableRowDeleteIBO';
import { TableRowIsActive } from './TableRowCells/TableRowIsActive';
import { TableRowIBOName } from './TableRowCells/TableRowIBOName';
import { TableRowContactPerson } from './TableRowCells/TableRowContactPerson';
import { TableRowBD } from './TableRowCells/TableRowBD';
import { TableRowTrack } from './TableRowCells/TableRowTrack';
import { TableRowProgress } from './TableRowCells/TableRowProgress';
import { TableRowNextDeadline } from './TableRowCells/TableRowNextDeadline';

import AddMiroLink from './TableRowFunctions/AddMiroLink';
import CommentDialogLog from '../../Comments/CommentDialogLogInternal';
import CommentDialogLogExternal from '../../Comments/CommentDialogLogExternal';

export const StepTableRow = ({ project, bdowners, iboNames, isAdmin, projectSettings }) => {
    if (!project) return null

    return (
        <TableRow key={project.id}>
            {isAdmin ? (
                <TableRowDeleteIBO project={project} />
            ) : ("")}

            {/* IsActive */}
            <TableRowIsActive project={project} />

            {/* IBO */}
            <TableRowIBOName project={project} />

            {/* Contact Person */}
            <TableRowContactPerson project={project} iboNames={iboNames} />

            {/* BD */}
            <TableRowBD project={project} bdOwners={bdowners} />

            {/* Track */}
            <TableRowTrack project={project} />

            {/* Progress */}
            <TableRowProgress project={project} bdOwners={bdowners} projectSettings={projectSettings} />

            {/* Next deadline/meeting */}
            <TableRowNextDeadline project={project} />

            {/* Miro Link */}
            <TableCell sx={{ textAlign: "center" }} padding='none'>
                <AddMiroLink projID={project.id} projData={project} />
            </TableCell>

            {/* Comments */}
            <TableCell sx={{ textAlign: "center" }} padding='none'>
                <span style={{ display: 'flex', justifyContent: 'center' }}>
                    <CommentDialogLog projID={project.id} projData={project} />
                    <CommentDialogLogExternal projID={project.id} projData={project} />
                </span>
            </TableCell>

            {/* Files */}
            <TableCell><FileOverview projData={project} /></TableCell>
        </TableRow>
    )
}