export const GRADING_CELL_INFOTEXT = {
    CR: {
        1: "Hypothesizing on possible needs in market.", 
        2: "Identified specific needs in market",  
        3: "First market feedback established.",  
        4: "Confirmed problem/needs from several customers or users.", 
        5: "Established interest for product and relations with target customers.", 
        6: "Benefits of product confirmed through partnerships or first customer testing.", 
        7: "Customers in extended product testing or first test sales.", 
        8: "First products sold and increased structured sales efforts.", 
        9: "Widespread product sales that scale.",
    },
    TeamR: {
        1: "Little insight into the need for a team (typically an individual). Lack of necessary competencies/resources.", 
        2: "Insight and first idea on necessary competencies or external resources (e.g. partners).",  
        3: "A few of necessary competencies/resources are present. Defined needed competencies/resources (and plan for finding).",  
        4: "Champion present. Several needed competencies in place. Initiated plan for recruiting or securing additional key resources.", 
        5: "Initial founding team with main needed competencies. Team agrees on ownership and roles and has aligned goals.", 
        6: "Complementary, diverse and committed team with necessary competencies/resources, both business and tech.",
        7: "Team and culture fully in place and proactively developed. Updated plan for building necessary team on longer term.", 
        8: "Management and CEO in place. Professional use of board/advisors. Activated plan and recruitment for building long term team.", 
        9: "High performing, well-structured team and organization that is maintained and performs over time",
    },
    BR: {
        1: "Hypothesizing on possible business concept. Little knowledge or insight into market and comepetition.", 
        2: "First possible business concept described (e.g. NABC). Identified overall market and some competitors/alternatives.",  
        3: "Draft of business model in canvas (excl. revenues/costs). Described market potential and complete competitive overview.", 
        4: "First version of full business model in canvas (incl. revenues/costs). First projections economic viability and market potential.", 
        5: "Parts of business model tested on market and canvas updated. First version of revenue model incl. pricing hypotheses. Verified competitive position/uniqueness through market feedback.", 
        6: "Full business model incl. pricing verified on customers (by test sales).", 
        7: "Product/market fit and customers payment willingness demonstrated Attractive revenue vs cost projections (validated by data and sales).", 
        8: "Sales and metrics show business model holds and can scale. Business model is fine-tuned to explore more revenue options.",  
        9: "Business model is final and is scaling with growing recurring revenues that results in a profitable and sustainable business.",
    },
    SR: {
        1: "Awareness of sustainability in relation to the business idea.", 
        2: "Recognising sustainability as a critical business issue.",  
        3: "Identification of relevant sustainability issues.", 
        4: "Impact Analysis according to the relevant international guidelines including UN SDG.", 
        5: "Sustainability Strategy including strategic goals or KPIs and distributed responsibility.", 
        6: "Stakeholder dialogue to validate analysis and strategy.", 
        7: "Reporting and communication according to relevant framework, goals and KPIs.", 
        8: "Relevant verification of the sustainability approach.",  
        9: "Proven sustainable affect. Optimised contribution to sustainable development, making money in a sustainable way.",
    },
    FR: {
        1: "Initial business idea with vague description. No clear view on funding needs and funding options.", 
        2: "Description of business concept (e.g. NABC). Defined funding needs and funding options for initial milestones.",  
        3: "Well described business concept and initial verification plan. First small soft funding secured.", 
        4: "Good pitch and short presentation of the business in place. Plan in place with different funding options over time.", 
        5: "Investor oriented presentation and supporting material tested. Applied for and secured additional larger funding (soft or other).", 
        6: "Improved investor presentation in place incl. business/financials. Decided on seeking private investors and initial contacts taken.", 
        7: "Team presents a solid investment case incl. status and plans. Discussions with potential investors on-going around an offer.", 
        8: "There is corporate order and structure enabling investment. Term sheet discussions with interested investor(s).", 
        9: "Investment obtained. Additional investment needs and options continuously considered.",
    },
    TechR: {
        1: "Basic principles observed.", 
        2: "Technology concept and/or application formulated.",  
        3: "Analytical and experimental proof-of-concept of critical function and/or characteristics.", 
        4: "Technology validation in laboratory.", 
        5: "Technology validation in relevant environment.", 
        6: "Technology demonstration in a relevant environment.", 
        7: "Technology prototype demonstration in an operational environment.", 
        8: "Actual Technology system completed and qualified through test and demonstration.", 
        9: "Actual Technology system qualified through successful mission operations.",
    },
    IPRR: {
        1: "Hypothesizing on possible IPR you might have (such as patents, software, copyright, designs, trade secrets etc.).", 
        2: "Identified different forms of possible IPR that you have. Ownership is clarified and you clearly own/control IPR", 
        3: "Detailed description of possible key IPR (e.g. invention or code). Initial search of technical field and existing IPR.", 
        4: "Confirmed if protection possible and for what (e.g. patentability). Decided why to protect certain IPR (business relevance).", 
        5: "Draft of IPR/patent strategy in place to use IPR for business. Filed first complete patent application (or other IP registrations)", 
        6: "IPR/patent strategy implemented and supporting business. Positive response on filed applications. Initial assessment of freedom-to-operate (or landscape).", 
        7: "All relevant IPR filed (e.g. additional patents). Patent entry into national/regional phase.", 
        8: "IPR strategy and IP management fully implemented. More complete assessment of freedom-to-operate.", 
        9: "Strong IPR support and protection for business. Patent granted in relevant countries and maintained in force.",
    },
}