import { ListItemIcon, Menu, MenuItem } from "@mui/material"
import { TrackLabels } from "../../../constants/labels/labels"
import { useState } from "react"
import { StepperDialog } from "../../StepperDialog/stepperDialog";

export const TrackSelectMenu = ({ anchorEl, open, handleClose, options, project }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTrack, setSelectedTrack] = useState('');

    const handleOpenDialog = (track) => {
        setSelectedTrack(track);
        setOpenDialog(true);
        handleClose();
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedTrack('');
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            background: '#f5f5f5',
                            border: `1px solid #4fadc2`,
                            borderRadius: 1.7,
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 0.8,
                            ml: -2.7,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -10,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                color: '#c9c9c9',
                                top: -0.6,
                                right: 62,
                                width: 9,
                                height: 9,
                                bgcolor: '#f5f5f5',
                                borderTop: `1px solid #4fadc2`,
                                borderLeft: `1px solid #4fadc2`,
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {options.map((key) => {
                    if (TrackLabels[key] != null) {
                        return (
                            <MenuItem onClick={() => handleOpenDialog(key)} dense key={key}>
                                {TrackLabels[key].label}
                            </MenuItem>
                        );
                    }
                })}
            </Menu>

            <StepperDialog open={openDialog} handleClose={handleCloseDialog} track={selectedTrack} project={project} />
        </>
    )
}