import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";


export const useGetEntryStepInfo = (projectID) => {
    const [loading, setLoading] = useState(true);
    const [entryStepData, setEntryStepData] = useState({});

    useEffect(() => {
        if (!projectID) return;

        const ref = onSnapshot(doc(db, "Projects/" + projectID + "/Steps/a"), (snapshot) => {
            if (snapshot.exists()) {
                setEntryStepData({ id: snapshot.id, ...snapshot.data() })
            }
            else {
                setEntryStepData({})
            }

            setLoading(false);
        })

        firebaseRefList.push(ref);
    }, [projectID])


    return { loading, entryStepData };
}