import { Checkbox, FormControlLabel } from "@mui/material"
import parse from 'html-react-parser';

export const TemplateOptionCard = ({ assignedQuestion }) => {
    return (
        <div style={{ marginLeft: '20px' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={assignedQuestion.answer || false}
                        name={assignedQuestion.key}
                        //name="checked"
                        color="primary"

                    />
                }
                labelPlacement={assignedQuestion.labelPlacement}
                label={parse(assignedQuestion.text)}
            />
        </div>
    )
}