import { Box, Card, CardContent, TextField } from "@mui/material"
import Explanation from "../../../Questions/Explanation"
import parse from 'html-react-parser';

export const TemplateTextInputCard = ({assignedQuestion}) => {

    return (
        <Card className="characters" key={assignedQuestion.key} sx={{ maxWidth: 600, marginBottom: '0rem', paddingBottom: '0rem' }}>
            <CardContent>

                {parse(assignedQuestion.text)}

                {assignedQuestion.questionExplanation || assignedQuestion.explanation !== "" ? (
                    <Explanation key={assignedQuestion.key} data={assignedQuestion} />
                ) : ('')}

                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { mb: '1rem', mt: '0.5rem', width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="question_form_textfield"
                >
                    {assignedQuestion.answer != null ? (
                        <TextField id="standard-multiline-static"
                            label=""
                            defaultValue={assignedQuestion.answer || null}
                            name={assignedQuestion.key}
                            multiline
                            rows={4}
                            variant="standard"
                        />
                    ) : (
                        <TextField id="standard-multiline-static"
                            label="Answer"
                            name={assignedQuestion.key}
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="standard"
                        />
                    )}
                </Box>
            </CardContent>
        </Card>
    )
}