import { Fragment, useEffect, useState } from 'react'

import { useAuth } from '../../../contexts/AuthContext'
import { db } from '../../../firebase'
import { CircularProgress, Box, List, ListItem, Divider, Grid } from '@mui/material';

import { doc, getDoc } from 'firebase/firestore';
import CommentDialogEntryStep from '../../Comments/CommentDialogEntryStep';
import CommentDialogStepInternal from '../../Comments/CommentDialogStepInternal';
import { useGetProjectEntryQuestions } from '../../../hooks/projectData/useGetProjectEntryQuestions';
import { useGetProjectLinkStep } from '../../../hooks/projectData/useGetProjectLinkStep';
import { useGetCompanyInfo } from '../../../hooks/projectData/useGetCompanyInfo';
import { DisplayUserInfo } from '../EntryStep/displayUserInfo';
import { DisplayCompanyInfo } from '../EntryStep/displayCompanyInfo';
import { DisplayEntryQuestions } from '../EntryStep/displayEntryQuestions';
import { FileOverviewStepInternal } from '../../Files/FileOverviewStepInternal';


export const EntryStep = ({ projectData }) => {
    const { currentUser } = useAuth();
    const { questionList, isLoading: loadingQuestions } = useGetProjectEntryQuestions(projectData?.id);
    const { stepData, isLoading: loadingStep } = useGetProjectLinkStep(projectData?.id, 'a');
    const { companyInfo, isLoading: loadingCompInfo } = useGetCompanyInfo(projectData?.id);

    const [isLoading, setIsLoading] = useState(false);
    const [loadedUserInfo, setLoadedUserInfo] = useState(false);


    const [allIBOData, setAllIBOData] = useState([]);
    const [currentIBO, setCurrentIBO] = useState({});
    const [update, setUpdate] = useState(false);
    const [otherIBOUsers, setOtherIBOUsers] = useState([]);

    const updateFrame = () => {
        setUpdate(!update);
    }


    useEffect(() => {
        if (projectData != null) {
            const getAllUserInfo = async () => {
                if (projectData != null && projectData?.IBOOwner != null) {
                    const localArray = [];

                    if (currentUser.role >= 2) {
                        for (let index = 0; index < projectData.IBOOwner.length; index++) {
                            const userID = projectData.IBOOwner[index];

                            const userData = await getDoc(doc(db, 'Users/' + userID));

                            if (userData.exists()) {
                                const data = { id: userID, data: userData.data() }
                                localArray.push(data);

                            }
                        }
                    }
                    else {
                        const otherIBOs = [];

                        for (let index = 0; index < projectData.IBOOwner.length; index++) {
                            const userID = projectData.IBOOwner[index];

                            if (userID === currentUser.uid) {
                                const userData = await getDoc(doc(db, 'Users/' + userID));

                                if (userData.exists()) {
                                    const data = { id: userID, data: userData.data() }
                                    localArray.push(data);

                                }
                            }
                            else {
                                const userData = await getDoc(doc(db, 'UsersPublic/' + userID));

                                if (userData.exists() && userData.data()?.firstName != null && userData.data()?.lastName != null) {
                                    otherIBOs.push(userData.data().firstName + " " + userData.data().lastName);
                                }
                            }
                        }

                        setOtherIBOUsers(otherIBOs);
                    }

                    setCurrentIBO(localArray.find(user => user.id === currentUser.uid))
                    setAllIBOData(localArray);
                    setLoadedUserInfo(true);
                }


            }

            // return cleanup function
            getAllUserInfo();
        }
    }, [projectData]);

    useEffect(() => {
        if (loadingStep === false && loadingQuestions === false && loadingCompInfo === false && loadedUserInfo === true) {
            setIsLoading(false);
        }
    }, [loadingStep, loadingQuestions, loadingCompInfo, loadedUserInfo])


    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }

    return (
        <div className="step-a">

            <Box sx={{
                width: 1, display: 'flex',
                flexDirection: 'row-reverse',
            }}>
                {currentUser.role >= 2 ? (
                    <Fragment>
                        <CommentDialogStepInternal projID={projectData.id} projData={projectData} step="a" location="Entry" />
                        <CommentDialogEntryStep projID={projectData.id} projData={projectData} step="a" location="Entry" />
                        {/*<FileOverviewStepInternal projData={projectData} step={"a"} location={"Entry"} />*/}
                    </Fragment>
                ) : (
                    <CommentDialogEntryStep projID={projectData.id} projData={projectData} step="a" location="Entry" />
                )}

            </Box>

            <Box>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={6} xl={6}>
                        <Fragment>

                            {allIBOData.map((user) => {
                                return (
                                    <div key={user.id}>
                                        <DisplayUserInfo user={user} updateFrame={updateFrame} />
                                    </div>
                                );
                            })}

                            {currentUser.role === 1 && otherIBOUsers.length > 0 ? (
                                <>
                                    <span><strong>Other Participants:</strong></span>
                                    <List disablePadding style={{ width: '80%' }}>
                                        {otherIBOUsers.map((userName) => {
                                            return (
                                                <div key={userName}>
                                                    <ListItem key={userName + "ls"}>
                                                        {userName}
                                                    </ListItem>

                                                    <Divider style={{ marginBottom: "5px" }} />
                                                </div>
                                            )
                                        })}
                                    </List>
                                </>
                            ) : (
                                ''
                            )}
                        </Fragment>
                    </Grid>
                    <Grid item sm={12} md={6} lg={6} xl={6}>
                        <Fragment>
                            <DisplayCompanyInfo companyInfo={companyInfo} projectData={projectData} updateFrame={updateFrame} />
                            <DisplayEntryQuestions projectID={projectData.id} projData={projectData} questionList={questionList} updateFrame={updateFrame} />
                        </Fragment>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}