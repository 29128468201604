import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'

export const useGetIdeaQuestions = () => {
    const [questions, setQuestions] = useState({});

    useEffect(() => {
        const getAllQuestions = async () => {
            await getDocs(query(collection(db, "IdeaQuestions"), orderBy('order'))).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    questions[doc.id] = doc.data();
                });
            })
        }
        getAllQuestions()
    }, []);

    return questions;
};