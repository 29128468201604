import { TableCell, TableSortLabel } from "@mui/material"
import { OrderByLabels } from "../../../../../constants/labels/labels";


export const ContactPersonHeaderCell = ({ orderBy, orderByDir, handleSetOrderBy }) => {

    return (
        <TableCell style={{ textAlign: "left" }}>
            <TableSortLabel
                active={orderBy === OrderByLabels.Contact}
                direction={orderBy === OrderByLabels.Contact ? orderByDir : 'desc'}
                onClick={() => handleSetOrderBy(OrderByLabels.Contact)}
            >
                Contact Person
            </TableSortLabel>
        </TableCell>
    );
}