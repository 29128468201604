import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import logobig from '../../assets/images/Krinova_logo__vit.png'
import CookieConsent from "react-cookie-consent";
import CircularProgress from '@mui/material/CircularProgress'
import { useAuth } from '../../contexts/AuthContext';
import useGetUserData from '../../hooks/userData/useGetUserData';

const Landing = () => {
    const { currentUser, loading } = useAuth()
    const { userData, loadingUser } = useGetUserData();
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);

    const setConsentStyle = () => {
        if (window.innerWidth < 600) {
            return {
                position: 'fixed',
                marginBottom: '10px',
                width: '100%',
                zIndex: 110,
                color: 'black',
                padding: '20px 20px',
                backgroundColor: '#fff',
                maxHeight: "900px",
                boxShadow: "0 0 48px 0 rgba(129, 127, 127, 0.637)",
                overflowY: 'auto'
            };
        }
        else {
            return {
                position: 'fixed',
                left: '50%',
                marginBottom: '10px',
                marginLeft: '-40%',
                width: '80%',
                zIndex: 110,
                color: 'black',
                padding: '20px 20px',
                backgroundColor: '#fff',
                maxHeight: "900px",
                boxShadow: "0 0 48px 0 rgba(129, 127, 127, 0.637)",
                overflowY: 'auto'
            };
        }

    }

    useEffect(() => {
        if (!loadingUser) {
            if (currentUser != null) {
                if (currentUser.role === 1) {
                    navigate("/home", { replace: true });
                }
                else if (currentUser.role === 2) {
                    navigate("/home-bd", { replace: true });
                }
                else if (currentUser.role === 3) {
                    navigate("/home-bd", { replace: true });
                }
            }
        }

    }, [loadingUser, userData, loading]);

    if (loadingUser || loading) {
        return <><div style={{ display: 'flex', justifyContent: 'center' }}>Logging in...<CircularProgress /></div></>
    }

    return (
        <>
            {currentUser != null ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>Logging in...<CircularProgress /></div>
            ) : (
                <div><div className="speechBubbleTop">
                    <svg xmlns="http://www.w3.org/2000/svg" width="57.167" height="57.5" viewBox="0 0 57.167 57.5">
                        <path fill="currentColor" d="M56.91 28.712C56.91 13.089 44.246.424 28.622.424S.333 13.089.333 28.712 12.999 57 28.622 57c4.838 0 9.391-1.218 13.374-3.357L53.721 57l-3.034-10.597a28.151 28.151 0 0 0 6.223-17.691"></path>
                    </svg>

                    <div className="text-bubble">
                        <p className="speechBubbleTop__title text-center">
                            “Welcome to Krinova, your innovation partner to help you develop and test your business idea”
                        </p>
                    </div>

                </div>
                    <div className="row landing-bg">
                        <div className="col-lg-12 mt-5 mb-5 text-center">
                            <img
                                alt="logo"
                                src={logobig}
                                width="auto"
                                height="100"
                                className="d-inline-block align-top mb-5"
                            />

                            <h1 className="mb-5 landing-headline">Welcome to Krinova</h1>
                            <div className="mb-3"> <Link to="/login"><Button variant="info" size='lg' className="landing-btn">Log in</Button></Link></div>

                            <div> <Link to="/sign-up"><Button variant="info" size='lg' className="landing-btn">Join us!</Button></Link></div>

                        </div>
                    </div>

                    <CookieConsent
                        location="bottom"
                        buttonText={
                            <>
                                <span>Stäng</span>
                                <span>
                                    <svg className="cookieInfo__closeIcon" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                                        <path fill="none" d="M-1-1h23v23H-1z"></path>
                                        <path fill="currentColor" d="M11.99 10.545l8.239-8.194a.992.992 0 0 0 0-1.414 1.016 1.016 0 0 0-1.428 0l-8.232 8.187L2.26.814a1.009 1.009 0 0 0-1.428 0 1.015 1.015 0 0 0 0 1.433l8.302 8.303-8.332 8.286a.994.994 0 0 0 0 1.414 1.016 1.016 0 0 0 1.428 0l8.325-8.28 8.275 8.277a1.009 1.009 0 0 0 1.428 0 1.015 1.015 0 0 0 0-1.432l-8.27-8.27z"></path>
                                    </svg>
                                </span>
                            </>
                        }
                        cookieName="KrinovaStartUp"
                        style={setConsentStyle()}
                        buttonStyle={{
                            background: "white",
                            color: "black",
                            fontSize: "13px",
                            fontWeight: "700",
                            letterSpacing: "2px",
                            textTransform: "uppercase",
                        }}
                        expires={150}
                    >
                        {<>
                            {visible ? (
                                <div className="read-more">
                                    <h5>Vår policy för cookies</h5>
                                    <p>Webbplatsen för Krinova Startup kan i likhet med många andra webbplatser lagra eller hämta information i din webbläsare, oftast i form av cookies. Denna information som kan handla om dig, dina intressen eller din Internetenhet (dator, surfplatta eller mobil) används i huvudsak för att webbplatsen ska fungera som du förväntar dig och ge dig en mera personlig upplevelse av webben, samt för att minnas dina tidigare val.</p>

                                    <h5>Cookies för prestanda</h5>
                                    <p>Med hjälp av dessa cookies får vi reda på hur besökarna använder webbplatsen, så att vi kan bedöma hur den fungerar och göra förbättringar. Vi kan exempelvis ta reda på vilka webbsidor som är mest och minst populära med hjälp av dessa cookies. De mäter antalet besökare, hur lång tid besökarna tillbringar på webbplatsen och hur de hittar den. Vi får veta vad vi är bra på och vad vi kan göra bättre, samt kan säkerställa att sidorna läses in snabbare och visas på ett korrekt sätt. Vi använder oss också av pixeltaggar för att jobba med segmenterad marknadsföring. På så sätt kan vi säkerställa att du som användare nås av relevant information från oss.</p>

                                    <p>All information som samlas in av dessa cookies är anonym och inte kopplad till någon personlig information om dig.</p>


                                    <h5>Välja bort cookies</h5>
                                    <p>Om vår användning av cookies är problematisk för dig kan du vidta åtgärder för att de inte ska tillämpas. Du kan även ändra inställningarna i din webbläsare så att vissa typer av cookies blockeras.</p>

                                    <p>Om du vill ha mer information om hur detta går till, kan du söka i webbläsarens hjälpinformation.</p>

                                    <span className="cookieInfo__readMore" onClick={() => setVisible(false)}>Dölj.</span>
                                </div>
                            ) : (
                                <div>
                                    <span>
                                        Vi använder cookies för att ge dig den bästa upplevelsen av vår webbplats. Genom att fortsätta använda webbplatsen godkänner du detta.
                                    </span>
                                    <span className="cookieInfo__readMore" onClick={() => setVisible(true)}>Läs mer.</span>
                                </div>
                            )}
                        </>}

                    </CookieConsent>

                </div >
            )}

        </>
    )
}

export default Landing