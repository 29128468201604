import { useState } from 'react'
import { IconButton, Box } from '@mui/material';
import { BootstrapDialog } from '../../helper/bootstrapDialog';
import { DatePickerBox } from './DatePickerBox';

import EditIcon from '@mui/icons-material/Edit';


export default function DatePickerMeets({projectData, stepdata}) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box>
            <IconButton sx={{ marginLeft: '10px' }} onClick={handleOpen} size='small'><EditIcon /></IconButton>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='xl'
            >
                <DatePickerBox projectData={projectData} stepdata={stepdata} handleClose={handleClose} />
            </BootstrapDialog>
        </Box>
    );
}
