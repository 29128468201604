import { FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material"
import { OwnerTypes } from "../../../constants/labels/labels"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const OwnershipCardRow = ({ item, index, handleChangeOwnerType, handleChangeText, handleChangeProcent, deleteRow, isOwnershipInvalid }) => {


    return (
        <Grid container spacing={1} sx={{ width: '100%', mb: '5px' }} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
            <Grid item xs={4}>
                <FormControl required fullWidth>
                    <InputLabel>Ownertype</InputLabel>
                    <Select
                        sx={{ mt: '2px', height: '38px' }}
                        value={item?.ownerType || ""}
                        size="small"
                        label="Ownertype"
                        onChange={(e) => handleChangeOwnerType(index, e.target.value)}
                        error={isOwnershipInvalid && (item?.ownerType == null || item?.ownerType === "")}
                    >
                        {Object.keys(OwnerTypes).map((key) => (
                            <MenuItem key={key} value={key}>{OwnerTypes[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <TextField
                    label=""
                    size="small"
                    value={item?.text || ""}
                    onChange={(e) => handleChangeText(index, e.target.value)}
                    error={isOwnershipInvalid && (item?.text == null || item?.text === "")}
                />
            </Grid>

            <Grid item xs={3}>
                <OutlinedInput
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    type="number"
                    size="small"
                    inputProps={{
                        min: 0,
                        max: 100
                    }}
                    sx={{ height: '40px' }}
                    value={item?.procent || 0}
                    onChange={(e) => handleChangeProcent(index, e.target.value)}
                />
            </Grid>

            <Grid item xs={1}>
                {index > 0 ? (
                    <IconButton onClick={deleteRow} sx={{height: '22px', width: '22px'}} size="small">
                        <HighlightOffIcon sx={{height: '22px'}} htmlColor="#ac2020" />
                    </IconButton>
                ) : (
                    <div style={{ width: '25px' }}></div>
                )}
            </Grid>

        </Grid>
    )
}