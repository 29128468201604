import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import { GRADING_CELL_INFOTEXT } from "../../../constants/lang/eng/grading";
import { GRADING_CELL_BACKGROUND, GRADING_CELL_LEVEL_COLOR, GRADING_COLUMNS_TAGS } from "../../../constants/grading";

export const SystemDefaultMatrixRow = ({ level, openCellDialog, selectedCell }) => {
    const setCellClassName = (tag) => {
        let className = "grade-table " + GRADING_CELL_BACKGROUND[tag][level];

        if (selectedCell.tag === tag && selectedCell.level === level) {
            className += " current-selected"
        }

        return className;
    }

    const handleClickOnCell = (tag) => {
        openCellDialog(level, tag);
    }

    return (
        <TableRow>
            <TableCell className="grade-table" style={{ backgroundColor: GRADING_CELL_LEVEL_COLOR[level], textAlign: "center" }}>
                {level}
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.CRTag)} sx={{ cursor: "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.CRTag) }}>
                <Box display={'flex'} justifyContent={'start'}>
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.CRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.TeamRTag)} sx={{ cursor: "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.TeamRTag) }}>
                <Box display={'flex'} justifyContent={'start'}>
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.TeamRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.BRTag)} sx={{ cursor: "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.BRTag) }}>
                <Box display={'flex'} justifyContent={'start'}>
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.BRTag][level]}
                        </Box>
                    </Box>
                </Box>

            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.SRTag)} sx={{ cursor: "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.SRTag) }}>
                <Box display={'flex'} justifyContent={'start'}>
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.SRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.FRTag)} sx={{ cursor: "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.FRTag) }}>
                <Box display={'flex'} justifyContent={'start'}>
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.FRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.TechRTag)} sx={{ cursor: "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.TechRTag) }}>
                <Box display={'flex'} justifyContent={'start'}>
                    <Box width={'160px'} minHeight={'70px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.TechRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.IPRR)} sx={{ cursor: "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.IPRR) }}>
                <Box display={'flex'} justifyContent={'start'}>
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.IPRR][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    );
}