import { useState } from "react";
import { IconButton, Menu, MenuItem, TableCell, TableSortLabel, Checkbox, Stack, Box } from "@mui/material"

import FilterListIcon from '@mui/icons-material/FilterList';
import { OrderByLabels, TrackLabels } from "../../../../../constants/labels/labels";

export const TrackHeaderCell = ({ trackFilter, setTrackFilter, orderBy, orderByDir, handleSetOrderBy }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectOption = (value) => {
        let localList = [...trackFilter];

        let listIndex = localList.findIndex(item => item === value)
        if (listIndex === -1) {
            localList.push(value);
        }
        else {
            localList.splice(listIndex, 1);
        }

        setTrackFilter(localList)
    }

    return (
        <TableCell
            style={{ textAlign: "left", width: '130px' }}
        >
            <IconButton
                onClick={handleOpen}
            >
                <FilterListIcon style={{ width: "18px", height: "fit-content" }} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem dense sx={{ height: '14px' }} onClick={() => handleSelectOption(TrackLabels.unassigned.key)}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Checkbox checked={trackFilter.findIndex(item => item === TrackLabels.unassigned.key) !== -1} size="small" />
                        <Box>
                            {TrackLabels.unassigned.selectLabel}
                        </Box>
                    </Stack>
                </MenuItem>
                <MenuItem dense sx={{ height: '14px' }} onClick={() => handleSelectOption(TrackLabels.start.key)}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Checkbox checked={trackFilter.findIndex(item => item === TrackLabels.start.key) !== -1} size="small" />
                        <Box>
                            {TrackLabels.start.selectLabel}
                        </Box>
                    </Stack>
                </MenuItem>
                <MenuItem dense sx={{ height: '14px' }} onClick={() => handleSelectOption(TrackLabels.incubator.key)}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Checkbox checked={trackFilter.findIndex(item => item === TrackLabels.incubator.key) !== -1} size="small" />
                        <Box>
                            {TrackLabels.incubator.selectLabel}
                        </Box>
                    </Stack>
                </MenuItem>
                <MenuItem dense sx={{ height: '14px' }} onClick={() => handleSelectOption(TrackLabels.scale.key)}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Checkbox checked={trackFilter.findIndex(item => item === TrackLabels.scale.key) !== -1} size="small" />
                        <Box>
                            {TrackLabels.scale.selectLabel}
                        </Box>
                    </Stack>
                </MenuItem>
                <MenuItem dense sx={{ height: '14px' }} onClick={() => handleSelectOption(TrackLabels.exit.key)}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Checkbox checked={trackFilter.findIndex(item => item === TrackLabels.exit.key) !== -1} size="small" />
                        <Box>
                            {TrackLabels.exit.selectLabel}
                        </Box>
                    </Stack>
                </MenuItem>
            </Menu>

            <TableSortLabel
                active={orderBy === OrderByLabels.Track}
                direction={orderBy === OrderByLabels.Track ? orderByDir : 'desc'}
                onClick={() => handleSetOrderBy(OrderByLabels.Track)}
            >
                Track
            </TableSortLabel>

        </TableCell>
    )
} 