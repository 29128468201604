import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

const AuthRoute = ({ children }) => {
    const { currentUser } = useAuth()
    let location = useLocation();
    
    if (currentUser == null){
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
}

export default AuthRoute