// @ts-ignore
import { useRef, useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { Alert, Stack } from 'react-bootstrap'
import useGetUserData from '../../hooks/userData/useGetUserData';
import CircularProgress from '@mui/material/CircularProgress'
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { Box, IconButton, InputAdornment, LinearProgress, OutlinedInput, TextField } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";



const Login = () => {
    const emailRef = useRef()
    const passwordRef = useRef()

    const [emailVal, setEmailVal] = useState('');
    const [passVal, setPassVal] = useState('');

    // @ts-ignore
    const { userData, loadingUser } = useGetUserData();
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    // @ts-ignore
    const { currentUser, login, loadingAuth } = useAuth()
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (!loadingUser) {
            if (currentUser != null) {
                if (currentUser.role === 1) {
                    navigate("/home", { replace: true });
                }
                else if (currentUser.role === 2) {
                    navigate("/home-bd", { replace: true });
                }
                else if (currentUser.role === 3) {
                    navigate("/admin", { replace: true });
                }
            }
        }

    }, [loadingUser, userData]);


    if (loadingUser || loadingAuth) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }

    const errorTexts = (error) => {
        if (error.code != null) {
            switch (error.code) {
                case 'auth/missing-email':
                    return 'No email was given.'
                case 'auth/user-not-found': case 'auth/wrong-password':
                    return 'Wrong email or password.'
                case 'auth/too-many-requests':
                    return 'Too many retries, try again later.'
                case 'auth/user-disabled':
                    return 'Account has been disabled.'
                default:
                    return 'Something went wrong';
            }
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)

            // @ts-ignore
            await login(emailVal, passVal)


            // @ts-ignore
            const firestoreRef = doc(db, "Users/" + auth.currentUser.uid)
            const userDataLocal = await getDoc(firestoreRef);

            if (userDataLocal != null) {
                if (userDataLocal.data().role === 1) {
                    navigate("/home")
                }
                else if (userDataLocal.data().role === 2) {
                    navigate("/home-bd")
                }
                else if (userDataLocal.data().role === 3) {
                    navigate("/home-bd")
                }
                else {
                    throw new Error('No role has been set!')
                }
            }

        } catch (error) {
            console.log("Error:", error.code)
            setError(errorTexts(error))
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box className="log-box">
            {currentUser == null ? (
                <form onSubmit={handleSubmit}>

                    <h6>Log in</h6>
                    {error && <Alert variant="danger">{error}</Alert>}

                    <Stack className="form-group">
                        <div>
                            <label>Email:</label>
                        </div>

                        <TextField
                            sx={{ width: "100%" }}
                            type="email"
                            value={emailVal}
                            onChange={(e) => { setEmailVal(e.target.value) }}
                            size="small"
                            placeholder="Enter email"
                            required
                        />
                    </Stack>

                    <Stack className="form-group">
                        <div>
                            <label>Password:</label>
                        </div>

                        <OutlinedInput
                            sx={{ width: "100%" }}
                            type={showPassword ? "text" : "password"}
                            value={passVal}
                            onChange={(e) => { setPassVal(e.target.value) }}
                            size="small"
                            placeholder="Enter password"
                            required
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Stack>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {loading ? (
                            <Box sx={{ width: '100%', marginBottom: "3px" }}>
                                <LinearProgress color="info" />
                            </Box>
                        ) : (
                            <Box sx={{ marginBottom: "7px" }}>

                            </Box>
                        )}

                    </div>
                    <button disabled={loading} type="submit" className="btn btn-md btn-info btn-block">Log in</button>
                    <p className="forgot-password text-right">
                        Don't have an account? <Link to="/sign-up">Sign up</Link>
                    </p>
                    <p className="forgot-password text-right">
                        <Link to="/reset">Forgot password?</Link>
                    </p>

                </form>) : (
                <div></div>
            )
            }
        </Box>)
}

export default Login