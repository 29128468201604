import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
const { styled } = require("@mui/material/styles");

export const StyledLinearProgressTrack = styled(LinearProgress)(() => ({
    height: 13,
    borderRadius: 7,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#e5ebcb',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 7,
      backgroundColor: '#a9bc52',
    },
  }));

  export const StyledLinearProgressWarning = styled(LinearProgress)(() => ({
    height: 13,
    borderRadius: 7,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#fae8c2',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 7,
      backgroundColor: '#efb236',
    },
  }));