import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { Fragment } from "react"
import { EditText } from "react-edit-text"
import { useGetCategories } from "../../../hooks/questionData/useGetCategories";
import { ReactComponent as AssembledBricks } from '../../../assets/images/krinova__AssembledBricks_line3_colour1.svg';

export const TemplateCurrentContentHeader = ({ columnId, groupTitle, setGroupTitle, selectedGroupCategory, handleNewSelectedGroupCategory, handleSave }) => {
    const { groupCategories } = useGetCategories();

    return (
        <Fragment>
            <div className="header-current-content"><AssembledBricks style={{ width: "30px", height: "fit-content", marginRight: "10px" }} />
                <span>
                    <strong>Current content</strong>
                </span>
            </div>

            <div style={{ whiteSpace: 'normal', marginTop: 0, width: 420 }}>
                <div style={{ display: "inline-block", marginTop: "7px", marginRight: "10px", width: '230px', marginBottom: "10px" }}>
                    <span className="mr-1">
                        <strong>Name:</strong>
                    </span>

                    <EditText
                        type="text"
                        style={{ width: 160 }}
                        value={groupTitle}
                        onChange={(e) => { setGroupTitle(e.target.value) }}
                        onSave={handleSave}
                        inline
                    />
                </div>

                <FormControl fullWidth size="small">
                    <InputLabel>Category</InputLabel>
                    <Select
                        value={selectedGroupCategory}
                        style={{ width: 200 }}
                        label="Category"
                        onChange={handleNewSelectedGroupCategory}
                    >
                        {groupCategories.map(groups => (
                            <MenuItem key={columnId + "-" + groups} value={groups}>{groups}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </Fragment>
    )
}