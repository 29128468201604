import { createTheme, responsiveFontSizes } from "@mui/material";


let theme = createTheme(({
    typography: {
        fontFamily: ['Montserrat', 'serif'].join(','),
    }

}))

theme = responsiveFontSizes(theme);


export default theme;