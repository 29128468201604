import { InputLabel, Select, MenuItem, FormHelperText, FormControl, FormLabel, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Fragment, useEffect, useState } from "react"
import dayjs from 'dayjs';

export const DatepickerBithday = ({ dobDate, handleChange, setErrorMessage, isBirthdayInvalid, setBirthdayInvalid }) => {
    const setInitYear = () => {
        if (dobDate != null && dobDate !== "") {
            const yearDate = new Date(Date.parse(dobDate));
            return String(yearDate.getFullYear());
        }
        else {
            return "";
        }
    }

    const setInitMonth = () => {
        if (dobDate != null && dobDate !== "") {
            return new Date(Date.parse(dobDate)).getMonth();
        }
        else {
            return "";
        }
    }

    const setInitDay = () => {
        if (dobDate != null && dobDate !== "") {
            return new Date(Date.parse(dobDate)).getDate();
        }
        else {
            return "";
        }
    }

    const [year, setYear] = useState(dayjs(dobDate));
    const [month, setMonth] = useState(setInitMonth());
    const [day, setDay] = useState(setInitDay());

    const [isYearInvalid, setYearInvalid] = useState(false);
    const [isMonthInvalid, setMonthInvalid] = useState(false);
    const [isDayInvalid, setDayInvalid] = useState(false);

    const [daysList, setDays] = useState(setInitialDates());

    function setInitialDates() {
        const date = new Date(Date.now());
        date.setMonth(0);
        date.setDate(31);

        if (year != null && year['$y'] != null && year['$y'] !== "" && month != null && month !== "") {
            // @ts-ignore
            date.setFullYear(year['$y']);
            // @ts-ignore
            date.setMonth(month + 1);
            date.setDate(0);
        }
        else if (month != null && month !== "") {
            // @ts-ignore
            date.setMonth(month + 1);
            date.setDate(0);
        }
        const localDays = []
        for (let i = 1; i <= date.getDate(); i++) {
            localDays.push(i);
        }

        return localDays;
    }

    function setDateList(localMonth, localYear) {
        console.log("Value: (Set DateList)", localYear['$y'], localMonth, day)
        const date = new Date(Date.now());
        let numOfDays = 31;

        if (localYear != null && localYear['$y'] != null && localYear['$y'] !== "" && localMonth != null && localMonth !== "" && isNaN(Number(localMonth)) === false) {
            date.setFullYear(localYear['$y'], Number(localMonth) + 1, 0);
            console.log("Dates (Both) = ", date.toLocaleDateString('se-SV'))
            numOfDays = date.getDate();
        }
        else if (localMonth != null && isNaN(Number(localMonth)) === false && localMonth !== "") {
            date.setFullYear(date.getFullYear(), Number(localMonth) + 1, 0);
            console.log("Dates (Month) = ", date.toLocaleDateString('se-SV'))
            numOfDays = date.getDate();
        }

        console.log("Days: ", numOfDays, date.getDate(), date)
        const localDays = []
        for (let i = 1; i <= numOfDays; i++) {
            localDays.push(i);
        }

        if (day != null && day !== "" && isNaN(Number(day)) === false) {
            if (Number(day) > numOfDays) {
                setDay(numOfDays)
            }
        }

        setDays(localDays);
    }

    const onChangeYear = (value) => {
        if (value != null && isNaN(value['$y']) === false) {
            console.log("Valid value", value, month, day)
            setYearInvalid(false)
            setBirthdayInvalid(false);
            setYear(value);
            if (month != null && isNaN(Number(month)) === false) {
                setDateList(month, value);
            }

        }
        else {
            setYearInvalid(true);
            setBirthdayInvalid(true);
        }

    }

    const onChangeMonth = (event) => {
        //console.log("Value (Month) ", year, event.target.value, day)

        setMonth(event.target.value);
        setDateList(event.target.value, year);
        setMonthInvalid(false)
    }

    const onChangeDay = (event) => {
        setDay(event.target.value);
        setDayInvalid(false)
    }

    const checkDateValid = () => {
        if (year != null && year['$y'] != null && year['$y'] !== "" && isNaN(Number(year)) === false && month != null && month !== "" && isNaN(Number(month)) === false && day != null && day !== "" && isNaN(Number(day)) === false) {
            setBirthdayInvalid(false);
        }
        else{
            setBirthdayInvalid(true);
        }
    }

    useEffect(() => {
        if (year != null && year['$y'] != null && year['$y'] !== "" && isNaN(Number(year)) === false && month != null && month !== "" && isNaN(Number(month)) === false && day != null && day !== "" && isNaN(Number(day)) === false) {
            let pickedDate = year['$y'] + "-" + String("0" + (Number(month) + 1)).slice(-2) + "-" + String("0" + String(day)).slice(-2);
            //console.log("Formatted: (Trigger)", pickedDate);

            if (pickedDate !== dobDate) {
                handleChange({ target: { name: "date", value: pickedDate } });
            }
        }
    }, [year, month, day])

    return (
        <Fragment>
            <FormControl error={isYearInvalid || isMonthInvalid || isDayInvalid || isBirthdayInvalid}>
                <FormLabel sx={{ marginTop: '-18px', marginBottom: '-5px' }}>Date of Birth*</FormLabel>
                {/*<span style={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6)' }}>Date of Birth</span>*/}
                <Stack direction={'row'} justifyContent="space-between" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack>
                            <FormControl margin="normal" style={{ minWidth: 100, maxWidth: 130 }} error={isYearInvalid}>
                                <DatePicker
                                    disableFuture
                                    views={['year']}
                                    label="Year"
                                    value={year}
                                    onChange={onChangeYear}
                                    slotProps={{ textField: { helperText: null, error: isYearInvalid, fullWidth: true, onBlur: checkDateValid, } }}
                                />
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl margin="normal" style={{ minWidth: 100, maxWidth: 160 }} error={isMonthInvalid}>
                                <InputLabel id='month-picker'>Month</InputLabel>
                                <Select
                                    id='month-picker'
                                    value={month}
                                    label="Month"
                                    onChange={onChangeMonth}
                                    onBlur={checkDateValid}
                                    error={isMonthInvalid}

                                >
                                    <MenuItem value={0}>Jan</MenuItem>
                                    <MenuItem value={1}>Feb</MenuItem>
                                    <MenuItem value={2}>Mar</MenuItem>
                                    <MenuItem value={3}>Apr</MenuItem>
                                    <MenuItem value={4}>May</MenuItem>
                                    <MenuItem value={5}>Jun</MenuItem>
                                    <MenuItem value={6}>Jul</MenuItem>
                                    <MenuItem value={7}>Aug</MenuItem>
                                    <MenuItem value={8}>Sep</MenuItem>
                                    <MenuItem value={9}>Oct</MenuItem>
                                    <MenuItem value={10}>Nov</MenuItem>
                                    <MenuItem value={11}>Dec</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </LocalizationProvider>
                    <Stack>
                        <FormControl margin="normal" style={{ minWidth: 80 }} error={isDayInvalid}>
                            <InputLabel id='day-picker'>Day</InputLabel>
                            <Select
                                id='day-picker'
                                value={day}
                                label="Day"
                                onBlur={checkDateValid}
                                onChange={onChangeDay}
                                error={isDayInvalid}

                            >
                                {daysList.map((item) => {
                                    return (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                <FormHelperText error={setErrorMessage('birthday') !== ""}>{setErrorMessage('birthday')}</FormHelperText>
            </FormControl>
        </Fragment>
    )
}