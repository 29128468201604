import { forwardRef, useState, useRef } from "react";
import JoditEditor from "jodit-react";
import { createQuestion } from '../../firebase/cloudFunctions';
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import './css/TextFieldCreator.css'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import { AlertBox } from "../../helper/AlertBox";


//const createQuestion = firebase.app().functions("europe-west1").httpsCallable('createQuestion');
//const editQuestion = firebase.functions().httpsCallable('editQuestion');

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//add CSS classes for Material UI components calling a function that returns another function
// const useStyles = makeStyles((theme) => ({
//     //the CSS class honeypot is added to the honeypot field later on, which is not displayed to users.
//     honeypot: {
//         display: 'none',
//     },

// }));

function TextFormater(props) {
    //const classes = useStyles();
    //const [formIsValid, setFormIsValid] = useState(false);
    const editor = useRef(null);
    const moreInfoEditor = useRef(null);

    const [error, setError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    // const location = useLocation()

    // let short = null
    // let text = null
    // let moreInfo = null
    // let questionId = null


    // if (location.state === null) {
    //     console.log('')


    // } else {

    //     ({ short, text, moreInfo, questionId } = location.state)

    // }

    const config = {
        readonly: false,
        placeholder: 'Compose your main content here..',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };

    const config2 = {
        readonly: false,
        placeholder: 'Compose your additional information here..',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };


    const [content, setContent] = useState("");
    const [moreInfoContent, setMoreInfoContent] = useState("");

    // call the cloud function
    const submitRichText = () => {

        setIsLoading(true);

        let dataSnap;
        if (props.perk === true) {
            dataSnap = {
                type: 'RichTextInput',
                text: content,
                questionExplanation: moreInfoContent,
                options: null,
                questionIcon: null,
                questionShortDesc: props.shortDesc,
                category: props.tag,
                perks: "Infinity",
            }
        }
        else {
            dataSnap = {
                type: 'RichTextInput',
                text: content,
                questionExplanation: moreInfoContent,
                options: null,
                questionIcon: null,
                questionShortDesc: props.shortDesc,
                category: props.tag,
            }
        }
        createQuestion(dataSnap)
            .then((data) => {
                console.log('data ' + data.data)

                //create a new `contactForm` object that looks like the original `contactForm` state
                // let originalShortDesc = {};
                // for (let key in shortDesc) {
                //     originalShortDesc[key] = {
                //         ...shortDesc[key],
                //         value: '',
                //         valid: key === 'honeypot' ? true : false,
                //         blur: false,
                //     };
                // }


                //reset `contactForm` state to its original value
                //setShortDesc(originalShortDesc);

                //reset the whole form validity to false
                //setFormIsValid(false);
                setIsLoading(false);
                setContent("")
                setMoreInfoContent("")

                //setShow(false)
                setOpenSnackbar(true);

            })

            .catch(() => {
                setError(true);

                setIsLoading(false);

                setOpenSnackbar(true);
            });
    }


    const resetFields = () => {

        setContent("")
        setMoreInfoContent("")

    };


    return (

        <div>
            <span><strong>Main content</strong></span>


            <Box className="new-group">
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)}
                    onChange={(newContent) => { }}
                />

            </Box>

            <div style={{ marginTop: 20 }}>
                {!show ? (<><ControlPointIcon style={{
                    fontSize: 17,
                }}
                    onClick={() => setShow(prev => !prev)}
                />  <span style={{ verticalAlign: 'base-line' }}><strong>"More info"-element</strong></span><InfoIcon style={{
                    color: "gray",
                    fontSize: 16,
                    marginLeft: 5,
                }} /></>) : (<><DoDisturbOnOutlinedIcon style={{
                    fontSize: 17,
                }} onClick={() => setShow(prev => !prev)} /> <span style={{ verticalAlign: 'base-line' }}><strong>"More info"-element</strong></span><InfoIcon style={{
                    color: "gray",
                    fontSize: 16,
                    marginLeft: 5,
                }} /></>)}</div>

            {show && <Box className="new-group">
                <JoditEditor
                    ref={moreInfoEditor}
                    value={moreInfoContent}
                    config={config2}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newInfoContent) => setMoreInfoContent(newInfoContent)}
                    onChange={(newInfoContent) => { }}

                />

            </Box>}

            <Grid container justifyContent="center">

                <div className="save-button-short-desc">
                    {isLoading ? (
                        <CircularProgress />
                    ) : (<><Button
                        //size="medium"
                        variant="contained"
                        //disabled={!formIsValid}
                        //color="primary"
                        onClick={resetFields} style={{ marginRight: 20 }}>Reset</Button>
                        <Button
                            //size="medium"
                            variant="contained"
                            disabled={!content}

                            //disabled={!formIsValid}
                            //color="primary"
                            onClick={submitRichText}>Save</Button></>)}
                </div>
            </Grid>

            <AlertBox open={openSnackbar} setOpen={setOpenSnackbar} isError={error} successMsg="Your text is saved!" errorMsg="Something went wrong!" />
        </div>

    );

}

export default TextFormater
