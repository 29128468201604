import { Box, Stack } from "@mui/material"
import { AvailableMatrixTags, TimeEventType } from "../../constants/labels/labels"


export const TimeLogRow = ({ logData, hoverID, onHover, onLeave }) => {
    const getTime = () => {
        let timeString = "";

        if (logData?.amountOfTime != null) {
            let timeSplit = logData?.amountOfTime.split(':');

            timeString = timeSplit[0] + "h " + timeSplit[1] + "min"
        }

        return timeString;
    }

    const getTagString = () => {
        let fullTagString = "";
        if (logData?.tags != null) {
            let tagList = logData?.tags.split(';');

            for (let index = 0; index < tagList.length; index++) {
                const tag = tagList[index];

                const tabObj = AvailableMatrixTags.find((item) => item.key === tag);

                if (tabObj != null) {
                    fullTagString += tabObj.label;

                    if (index + 1 < tagList.length) {
                        fullTagString += ", "
                    }

                }
            }
        }

        return fullTagString;
    }

    return (
        <Stack spacing={0.5} sx={{ padding: '6px 0' }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Box>
                    {logData?.date}
                </Box>
                <Box>
                    {getTime()}
                </Box>
            </Stack>
            {logData?.timelogType != null ? (
                <Box maxWidth={'320px'}>
                    <strong>Time used for:</strong> {TimeEventType[logData?.timelogType]?.label}
                </Box>
            ) : (
                <Box sx={{ height: '18px' }}>
                    <strong>Time used for:</strong> <i>Not set</i>
                </Box>
            )}
            {logData?.tags != null ? (
                <Box maxWidth={'320px'}>
                    <strong>Worked with:</strong> {getTagString()}
                </Box>
            ) : (
                <Box sx={{ height: '18px' }}>

                </Box>
            )}
        </Stack>
    )
}