import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";
import { useGetSystemDefaultMilestones } from "./useGetSystemDefaultMilestones";


export const useGetGradingCellData = (projectID, tag) => {
    const { loading: loadingDefault, levels: levelsDefault } = useGetSystemDefaultMilestones(tag);

    const [loading, setLoading] = useState(true);
    const [levels, setLevels] = useState(Object);

    useEffect(() => {
        if (!projectID || !tag || loadingDefault) return;

        const ref = onSnapshot(collection(db, "Projects/" + projectID + "/Ratings/ReadinessLevel/" + tag), (snapshot) => {
            let local = {};
            snapshot.docs.forEach((child) => {
                local[child.id] = child.data();
            });

            for (const key in levelsDefault) {
                if (Object.hasOwnProperty.call(levelsDefault, key)) {
                    const element = levelsDefault[key];
                    
                    if (local[key] == null){
                        local[key] = element;
                    }
                }
            }

            setLevels(local);
            setLoading(false);
        })

        firebaseRefList.push(ref);

    }, [projectID, tag, loadingDefault])


    return { loading, levels };
}