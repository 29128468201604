import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import format from 'date-fns/format'
import { useAuth } from '../../contexts/AuthContext'
import firebaseRefList from '../../firebase/firebaseRefList'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'


export const useGetBookedBdTimes = () => {
    const [bookedbdtimes, setBookedBdtimes] = useState([]);
    const { currentUser } = useAuth()

    useEffect(() => {
        const getBookedBdTimes = async () => {
            const today = new Date(Date.now());
            const ref = onSnapshot(query(collection(db, 'AvailableTimes'), where('ownerID', "==", currentUser.uid), where("status", "==", 1), where("startTime", ">=", today), orderBy('startTime')),
                (querySnapshot) => {
                    let arr = [];
                    querySnapshot.forEach((doc) => {
                        arr.push({ id: doc.id, startTime: format(new Date(doc.data().startTime.seconds * 1000), 'yyyy-MM-dd eeee HH:mm'), endTime: format(new Date(doc.data().endTime.seconds * 1000), 'HH:mm'), owner: doc.data().ownerID, bookedBy: doc.data().projectID })
                    });
                    setBookedBdtimes(arr)
                });

            firebaseRefList.push(ref);

        }
        getBookedBdTimes()
    }, [currentUser.uid]);

    return [bookedbdtimes]
};