import { forwardRef, Fragment, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { db } from '../../firebase';
import { complemendStep, answerQuestion, answerQuestions, setStepState } from '../../firebase/cloudFunctions';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from '../../contexts/AuthContext'
import { useConfirm } from 'material-ui-confirm';
import firebaseRefList from '../../firebase/firebaseRefList'
import './css/QuestionForm.css'

import { collection, getDocsFromServer, onSnapshot, orderBy, query } from "firebase/firestore";
import { TextInputCard } from "./Components/QuestionCards/TextInputCard";
import { EmbededLinkInputCard } from "./Components/QuestionCards/EmbededLinkInputCard";
import { MiroCard } from "./Components/QuestionCards/MiroCard";
import { RichTextInputCard } from "./Components/QuestionCards/RichTextInputCard";
import { OptionsCard } from "./Components/QuestionCards/OptionsCard";
import { CheckedCard } from "./Components/QuestionCards/CheckedCard";
import { RadioButtonCard } from "./Components/QuestionCards/RadioButtonCard";
import { SliderCard } from "./Components/QuestionCards/SliderCard";
import { FileUploadCard } from "./Components/QuestionCards/FileUploadCard";
import { AlertBox } from "../../helper/AlertBox";


const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function QuestionForm({ projectData, step, location, stepData }) {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [saving, setIsSaving] = useState(false);
    const [liveSaving, setLiveSaving] = useState(false);
    const [assignedQuestions, setAssignedQuestions] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [error, setError] = useState(false);
    const { currentUser } = useAuth();
    const [checked, setChecked] = useState();
    const [checkedMulti, setCheckedMulti] = useState();
    const confirm = useConfirm();
    const [checkBoxQuestions, setCheckBoxQuestions] = useState({});
    const [saveTrigger, setSaveTrigger] = useState('')

    // const location = useLocation()
    // const { projectData } = location.state
    const [update, setUpdate] = useState(false);
    const [answers, setAnswers] = useState({})
    const [sendAnswers, setSendAnswers] = useState({})
    const answerCheckbox = {}
    const answerMultiCheck = {};
    const answerSlider = {}
    const [answerRadio, setAnswerRadio] = useState({});

    function handleChange(evt) {
        const value = evt.target.value;
        const name = evt.target.name;
        //console.log(value + ' name ' + name)
        answers[name] = value;
        sendAnswers[name] = value;
        setSaveTrigger(value);
    }


    function handleOptionChange(event) {
        setChecked(event.target.checked);
        const value = event.target.checked;
        const name = event.target.name;

        setLiveSaving(true);
        answerQuestion({
            projectID: projectData.id,
            location: step,
            answerData: value,
            assignedID: name,
        }).then(() => {
            setLiveSaving(false);
        }).catch((e) => {
            //console.log("Something went wrong:", e);
            setLiveSaving(false);
            setError(true);
            setOpenSnackbar(true);
        });
        //console.log('event target value ' + event.target.checked + "name: " + event.target.name)

        answerCheckbox[name] = value;

    }

    function handleCheckedOptionChange(event) {
        setCheckedMulti(event.target.checked);

        const value = event.target.checked;
        const name = event.target.name;

        let localCheckBox;
        if (checkBoxQuestions[event.target.value] == undefined) {
            localCheckBox = {}
        }
        else {
            localCheckBox = checkBoxQuestions[event.target.value];
        }

        localCheckBox[event.target.name] = event.target.checked;

        setCheckBoxQuestions({
            ...checkBoxQuestions,
            [event.target.value]: localCheckBox
        });

        let answer = "";
        for (const key in localCheckBox) {
            if (Object.hasOwnProperty.call(localCheckBox, key)) {
                const element = Boolean(localCheckBox[key]);
                if (element) {
                    answer += key + ";"
                }
            }
        }

        answer = answer.slice(0, answer.length - 1);

        setLiveSaving(true);
        answerQuestion({
            projectID: projectData.id,
            location: step,
            answerData: answer,
            assignedID: event.target.value,
        }).then(() => {
            setLiveSaving(false);
        }).catch((e) => {
            //console.log("Something went wrong:", e);
            setLiveSaving(false);
            setError(true);
            setOpenSnackbar(true);
        });

        answerMultiCheck[name] = value;
    }

    function handleSingleCheckedOptionChange(event) {
        setCheckedMulti(event.target.checked);
        const value = event.target.checked;
        const name = event.target.name;

        let localCheckBox;
        if (checkBoxQuestions[event.target.value] == undefined) {
            localCheckBox = {}
        }
        else {
            localCheckBox = checkBoxQuestions[event.target.value];
        }

        localCheckBox[event.target.name] = event.target.checked;

        setCheckBoxQuestions({
            ...checkBoxQuestions,
            [event.target.value]: localCheckBox
        });

        setLiveSaving(true);
        answerQuestion({
            projectID: projectData.id,
            location: step,
            answerData: event.target.checked,
            assignedID: event.target.value,
        }).then(() => {
            setLiveSaving(false);
        }).catch((e) => {
            //console.log("Something went wrong:", e);
            setLiveSaving(false);
            setError(true);
            setOpenSnackbar(true);
        });

        answerMultiCheck[name] = value;
    }

    function handleRadioBtnChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        answerRadio[name] = value;
        setUpdate(!update);

        setLiveSaving(true);
        answerQuestion({
            projectID: projectData.id,
            location: step,
            answerData: value,
            assignedID: name,
        }).then(() => {
            setLiveSaving(false);
        }).catch((e) => {
            //console.log("Something went wrong:", e);
            setLiveSaving(false);
        });
    }

    function sliderChange(eventSlide, valueSlide) {
        const id = eventSlide;
        answerSlider[id] = valueSlide;

        answerQuestion({
            projectID: projectData.id,
            location: step,
            answerData: valueSlide,
            assignedID: id,
        })
    }

    useEffect(() => {
        const getQuestionsFromFirebase = [];
        //setLoading(true);
        const subscriber = onSnapshot(collection(db, 'Questions'), async (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                getQuestionsFromFirebase.push({
                    ...doc.data(), //spread operator
                    key: doc.id, // `id` given to us by Firebase
                });
            });

            setQuestions(getQuestionsFromFirebase);

            const getAssignedQuestionsFromFirebase = [];

            await getDocsFromServer(query(collection(db, "Projects/" + projectData.id + "/Steps/" + step + "/AssignedQuestions"), orderBy('order'))).then((querySnapshot) => {
                getAssignedQuestionsFromFirebase.length = 0;
                querySnapshot.forEach((doc) => {
                    getAssignedQuestionsFromFirebase.push({
                        ...doc.data(), //spread operator
                        key: doc.id, // `id` given to us by Firebase
                    });
                });

                setAssignedQuestions(getAssignedQuestionsFromFirebase)

                getAssignedQuestionsFromFirebase.forEach((assignedQuestion) => {
                    var question;
                    for (let i = 0; i < getQuestionsFromFirebase.length; i++) {
                        if (getQuestionsFromFirebase[i].key === assignedQuestion.questionID) {
                            question = questions[i];
                            i = getQuestionsFromFirebase.length;
                        }
                    }

                    if (question != null && question.type === 'Checked') {

                        let options = String(question.options).split(';');
                        let localCheckBox = {};
                        if (assignedQuestion.answer != null) {
                            let answers = String(assignedQuestion.answer).split(';');
                            for (let index = 0; index < options.length; index++) {
                                const value = options[index];
                                if (value === "" && options.length === 1) {
                                    const answerVal = answers[index];
                                    if (answerVal === "true") {
                                        localCheckBox[value] = true;
                                    }
                                    else {
                                        localCheckBox[value] = false;
                                    }

                                }
                                else {
                                    if (answers.indexOf(value) !== -1) {
                                        localCheckBox[value] = true;
                                    }
                                    else {
                                        localCheckBox[value] = false;
                                    }
                                }

                            }
                        }
                        else {
                            options.forEach((val) => {
                                localCheckBox[val] = false;
                            });
                        }

                        checkBoxQuestions[assignedQuestion.key] = localCheckBox
                    }

                    else if (question != null && question.type === 'RadioButton') {
                        if (assignedQuestion.answer != null) {
                            setAnswerRadio({
                                ...answerRadio,
                                [assignedQuestion.key]: assignedQuestion.answer
                            });
                        }
                    }
                })
                setLoading(false);
            })
        });

        firebaseRefList.push(subscriber);
        // return cleanup function
        //subscriber();
        return () => { setAssignedQuestions([]) }
    }, [projectData?.id, step]); // empty dependencies array => useEffect only called once

    const saveAnswersTimer = () => {
        setLiveSaving(true);
        answerQuestions({
            projectID: projectData?.id,
            location: step,
            answerData: sendAnswers,
        })
            .then((data) => {
                //console.info('data ' + data.data)
                setLiveSaving(false);
            })
            .catch(() => {
                setError(true);
                setLiveSaving(false);
                setOpenSnackbar(true);
            });

    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            saveAnswersTimer();
        }, 3000)

        return () => {
            clearTimeout(delayDebounceFn);
            setSendAnswers({})
        }
    }, [saveTrigger])

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
    }

    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }

    function splitCheckedOptions(assignedQuestion, question) {
        let options = String(question.options).split(';');
        let answers = checkBoxQuestions[assignedQuestion.key];

        let combined = [];
        for (let i = 0; i < options.length; i++) {

            if (answers != undefined) {
                const index = Object.keys(answers).indexOf(options[i]);
                if (index !== -1) {
                    combined.push({
                        option: options[i],
                        answer: answers[options[i]]
                    });
                }
                else {
                    combined.push({
                        option: options[i],
                        answer: false
                    });
                }

            }
            else {
                combined.push({
                    option: options[i],
                    answer: false
                });
            }

        }
        return combined;
    }

    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    const submitAnswers = () => {
        setIsSaving(true);
        setLoading(true);


        answerQuestions({
            projectID: projectData?.id,
            location: step,
            answerData: answers,
            submit: true,
        })
        setStepState({
            projectID: projectData?.id,
            stateType: 'IBOState',
            step: step,
            state: "Completed",

        })
            .then((data) => {
                //console.info('data ' + data.data)
                setLoading(false);
                setIsSaving(false);

                setOpenSnackbar(true);
            })

            .catch(() => {
                setError(true);
                setLoading(false);
                setIsSaving(false);

                setOpenSnackbar(true);
            });
    }

    const saveAnswers = () => {
        setIsSaving(true);

        answerQuestions({
            projectID: projectData?.id,
            location: step,
            answerData: answers,
        })
            .then((data) => {
                //console.info('data ' + data.data)

                setIsSaving(false);

                setOpenSnackbar(true);
            })
            .catch(() => {
                setError(true);

                setIsSaving(false);

                setOpenSnackbar(true);
            });
    }



    const changeStatusToCompletion = (event) => {
        confirm({ title: 'Please confirm', description: 'Are you sure you want to return the homework to be complemented?' })
            .then(() => {
                setIsLoading(true);


                complemendStep({
                    projectID: projectData.id,
                    step: step,

                })
                    .then(() => {
                        //set `isLoading` state to false, so the spinner is not rendered anymore
                        setIsLoading(false);

                        //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                        setOpenSnackbar(true);
                    })
                    //this code below runs when the message was NOT successfully sent from inside of the cloud function
                    .catch(() => {
                        //set `error` state to true
                        setError(true);

                        //set `isLoading` state to false, so the spinner is not rendered anymore
                        setIsLoading(false);

                        //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                        setOpenSnackbar(true);
                    });
            })
            .catch(() => { setIsLoading(false); });
    };

    const changeStatusToCompleted = (event) => {
        confirm({ title: 'Please confirm', description: 'Are you sure you want to set status to Passed?' })
            .then(() => {
                setIsLoading(true);


                setStepState({
                    projectID: projectData.id,
                    stateType: 'BDState',
                    step: step,
                    state: "Passed",

                })
                    .then(() => {
                        //set `isLoading` state to false, so the spinner is not rendered anymore
                        setIsLoading(false);

                        //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                        setOpenSnackbar(true);
                    })
                    //this code below runs when the message was NOT successfully sent from inside of the cloud function
                    .catch(() => {
                        //set `error` state to true
                        setError(true);

                        //set `isLoading` state to false, so the spinner is not rendered anymore
                        setIsLoading(false);

                        //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                        setOpenSnackbar(true);
                    });
            })
            .catch(() => { setIsLoading(false); });
    };


    return (
        <div className="container">
            {saving ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Fragment>
                    <ul className="characters">

                        {assignedQuestions.length > 0 ? (
                            assignedQuestions.map((assignedQuestion) => {
                                var i;
                                var question;
                                for (i = 0; i < questions.length; i++) {
                                    if (questions[i].key === assignedQuestion.questionID) {
                                        //console.log("Order-ny: " + assignedQuestion.order);
                                        question = questions[i];
                                    }
                                }
                                if (question === undefined) {
                                    return ('');
                                }
                                if (question.type === "TextInput") {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <TextInputCard projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} question={question} handleChange={handleChange} />
                                        </div>
                                    );
                                }
                                else if (question.type === "EmbededLinkInput") {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <EmbededLinkInputCard assignedQuestion={assignedQuestion} question={question} />
                                        </div>
                                    );
                                }
                                else if (question.type === "miro") {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <MiroCard projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} question={question} />
                                        </div>

                                    );
                                }
                                else if (question.type === "fileUpload") {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <FileUploadCard projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} question={question} handleChange={handleChange} />
                                        </div>

                                    );
                                }
                                else if (question.type === "RichTextInput") {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <RichTextInputCard assignedQuestion={assignedQuestion} question={question} />
                                        </div>
                                    );
                                } else if (question.type === "Option") {
                                    return (
                                        <div key={assignedQuestion.key} style={{ marginLeft: '20px' }}>
                                            Hej
                                            <OptionsCard assignedQuestion={assignedQuestion} question={question} handleOptionChange={handleOptionChange} />
                                        </div>

                                    );
                                }
                                else if (question.type === "Checked") {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <CheckedCard
                                                projectData={projectData}
                                                step={step}
                                                location={location}
                                                assignedQuestion={assignedQuestion}
                                                question={question}
                                                splitCheckedOptions={splitCheckedOptions}
                                                handleSingleCheckedOptionChange={handleSingleCheckedOptionChange}
                                                handleCheckedOptionChange={handleCheckedOptionChange} />
                                        </div>
                                    );
                                }
                                else if (question.type === "RadioButton") {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <RadioButtonCard projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} question={question} handleRadioBtnChange={handleChange} answerRadio={answerRadio} />
                                        </div>
                                    );
                                }
                                else if (question.type === "Slider") {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <SliderCard projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} question={question} sliderChange={sliderChange} />
                                        </div>
                                    );
                                }
                                else {
                                    return (
                                        <div key={assignedQuestion.key}>
                                            <span>No content has been set for this step.</span>
                                        </div>
                                    );
                                }
                            })
                        ) : (
                            <h3>Loading data for this step...</h3>
                        )}
                    </ul>
                    <Fragment>
                        {currentUser.role >= 2 ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Box mt="1rem">
                                    {assignedQuestions.length > 0 ? (
                                        <>
                                            <Button
                                                sx={{ marginRight: 5 }}
                                                size="medium"
                                                className="btn-orange"
                                                variant="contained"
                                                //color="primary"
                                                onClick={changeStatusToCompletion}
                                            >Complemend</Button>
                                            <Button
                                                //size="medium"
                                                variant="contained"
                                                //color="primary"
                                                onClick={changeStatusToCompleted}
                                            >Approve</Button>
                                        </>) : ('')}
                                </Box>
                            </div>
                        ) : (
                            <div>
                                {assignedQuestions.length > 0 ? (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {!liveSaving ? (
                                            <Button size="medium" variant="contained" onClick={saveAnswers}>Save</Button>
                                        ) : (
                                            <Button size="medium" variant="contained" onClick={saveAnswers} disabled>Saving...</Button>
                                        )}

                                        <Button
                                            sx={{ marginLeft: 5 }}
                                            //size="medium"
                                            variant="contained"
                                            //color="primary"
                                            onClick={submitAnswers}
                                            disabled={liveSaving}
                                        >Submit answers</Button>

                                        {stepData?.submitted != null &&
                                            <div style={{ justifyContent: 'right', color: 'grey', fontSize: '12px', marginLeft: '10px', paddingTop: '7px' }}>
                                                <i>Submitted: {new Date(stepData.submitted.seconds * 1000).toLocaleString('se-SV', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</i>
                                            </div>}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                    </Fragment>
                </Fragment>
            )}

            <AlertBox open={openSnackbar} setOpen={setOpenSnackbar} isError={error} successMsg={'Done!'} errorMsg={'Something went wrong!'} />
        </div >
    );
}