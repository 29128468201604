import { Card, CardContent } from "@mui/material"

export const TemplateMiroCard = ({ assignedQuestion }) => {
    return (
        <Card className="characters" key={assignedQuestion.key} sx={{ maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem' }}>
            <CardContent>
                <span>{assignedQuestion.text}</span>
            </CardContent>
        </Card>
    )
}