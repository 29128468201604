import { getBlob, getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";

export function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export async function fetchFile(fileData) {
    let response = await fetch(fileData.fileRef);
    let data = await response.blob();
    let metadata = {
        type: data.type,
    };

    return new File([data], fileData.fileName, metadata);
}

/**
 * 
 * @param {Array} files 
 */
export const getStorageBlobs = async (files) => {
    for (let index = 0; index < files.length; index++) {
        const element = files[index];
        
        if (element.fileRef != null && element['blob'] == null){
            await getBlob(ref(storage, element.fileRef)).then((blob) => {
                element['blob'] = blob;
            })
        }
    }
}

/**
 * 
 * @param {Object} file
 */
export const getStorageBlob = async (file) => {        
    if (file.fileRef != null && file['blob'] == null){
        await getBlob(ref(storage, file.fileRef)).then((blob) => {
            
            file['blob'] = blob;
        })
    }
}

/**
 * 
 * @param {Array} files 
 */
export const getStorageUrls = async (files) => {
    for (let index = 0; index < files.length; index++) {
        const element = files[index];
        
        if (element.fileRef != null && element['fileUrl'] == null){
            await getDownloadURL(ref(storage, element.fileRef)).then((url) => {
                element['fileUrl'] = url;
            })
        }
    }
}

/**
 * 
 * @param {Object} file
 */
export const getStorageUrl = async (file) => {        
    if (file.fileRef != null && file['fileUrl'] == null){
        await getDownloadURL(ref(storage, file.fileRef)).then((url) => {
            file['fileUrl'] = url;
        })
    }
}

/**
 * 
 * @param {Object} file
 */
export const canPreviewFile = (file) => {
    if (String(file.fileType).includes('image/') === true) {
        return true;
    }
    else if (String(file.fileType).includes('text/') === true) {
        return true;
    }
    else if (file.fileType === 'application/pdf') {
        return true;
    }
    else {
        return false;
    }
}