import { Box, Button, Checkbox, DialogContent, Divider, FormControlLabel, IconButton, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../helper/bootstrapDialog";
import PendingIcon from '@mui/icons-material/Pending';
import CloseIcon from '@mui/icons-material/Close';
import { TrackLabels } from "../../../../constants/labels/labels";
import { confirmTrackChangeAndAssignBD } from "../../../../firebase/cloudFunctions";

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useAuth } from "../../../../contexts/AuthContext";
import { useGetGradingTrackConfigurations } from "../../../../hooks/grading/useGetGradingTrackConfigurations";

/**
 * 
 * @param {object} param0 
 * @param {import("../../../../types/Project").IBOProject} param0.projData
 * @param {Array} param0.bdowners
 * @returns 
 */
export const PendingProgressDialog = ({ projData, bdowners }) => {
    const { currentUser } = useAuth();
    const { loading: loadingConfig, trackConfigurations } = useGetGradingTrackConfigurations(projData.id);
    const [loading, setLoading] = useState(false);
    const [gradingComplete, setGradingComplete] = useState(false);
    const [questionsAnswered, setQuestionsAnswered] = useState(false);
    const [questionsApproved, setQuestionsApproved] = useState(false);
    const [selectedBD, setSelectedBD] = useState('');

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (loadingConfig === false){
            console.log("Config: ", trackConfigurations)
            let track = "START";

            if (projData.activeTrack != null && projData.activeTrack !== ""){
                track = projData.activeTrack.toLocaleUpperCase();
            }

            if (trackConfigurations != null && trackConfigurations[track] != null && trackConfigurations[track]['startValues'] != null) {
                setGradingComplete(true);
            }
        }
    }, [loadingConfig]);


    const handleAssignBD = () => {
        setLoading(true);
        confirmTrackChangeAndAssignBD({
            projectID: projData.id,
            userID: selectedBD,
        }).then(() => {
            handleClose();
        }).catch((error) => {
            console.error('Error: ', error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <IconButton sx={{ height: '22px', width: '22px', marginRight: '-2px' }} size='small' onClick={handleClickOpen}>
                <PendingIcon htmlColor='#efb236' />
            </IconButton>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ padding: '10px 10px 0px 10px' }}><strong>{TrackLabels[projData.activeTrack].label} activation checklist</strong></h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent>
                    <Stack spacing={1}>
                        <Stack height={'30px'} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                            {currentUser?.isDev === true ? (
                                <Checkbox checked={gradingComplete} onChange={() => { setGradingComplete(!gradingComplete) }} />
                            ) : (
                                <>
                                    {gradingComplete ? (
                                        <CheckRoundedIcon htmlColor="#44a829" />
                                    ) : (
                                        <Box sx={{ width: '28px' }}>

                                        </Box>
                                    )}
                                </>
                            )}

                            <Box fontSize={'1rem'}>
                                {`Readiness level matrix - ${TrackLabels[projData.activeTrack].label} start values`}
                            </Box>
                        </Stack>

                        <Stack height={'30px'} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                            {currentUser?.isDev === true ? (
                                <Checkbox checked={selectedBD != null && selectedBD !== ""} />
                            ) : (
                                <Box sx={{ width: '28px' }}>
                                    {selectedBD != null && selectedBD !== "" ? (
                                        <CheckRoundedIcon htmlColor="#44a829" />
                                    ) : ("")}
                                </Box>
                            )}

                            <Box fontSize={'1rem'} >
                                {`${TrackLabels[projData.activeTrack].label} Business Designer`}
                            </Box>
                            <Select
                                id="select-bd"
                                value={selectedBD}
                                size="small"
                                sx={{ minWidth: '250px' }}
                                variant="outlined"
                                onChange={(event) => setSelectedBD(event.target.value)}>

                                <MenuItem value={''}></MenuItem>
                                {bdowners.map((owner) => {
                                    return (
                                        <MenuItem
                                            key={owner.id}
                                            value={owner.id}
                                        >
                                            {owner.value}
                                        </MenuItem>
                                    );
                                })}

                            </Select>
                        </Stack>

                        <Stack height={'30px'} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                            {currentUser?.isDev === true ? (
                                <Checkbox checked={questionsAnswered} onChange={() => { setQuestionsAnswered(!questionsAnswered) }} />
                            ) : (
                                <>
                                    {questionsAnswered ? (
                                        <CheckRoundedIcon htmlColor="#44a829" />
                                    ) : (
                                        <Box sx={{ width: '28px' }}>

                                        </Box>
                                    )}
                                </>
                            )}

                            <Box fontSize={'1rem'}>
                                Additional sign up questions answered by IBO
                            </Box>
                        </Stack>

                        <Stack height={'30px'} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                            {currentUser?.isDev === true ? (
                                <Checkbox checked={questionsApproved} onChange={() => { setQuestionsApproved(!questionsApproved) }} />
                            ) : (
                                <>
                                    {questionsApproved ? (
                                        <CheckRoundedIcon htmlColor="#44a829" />
                                    ) : (
                                        <Box sx={{ width: '28px' }}>

                                        </Box>
                                    )}
                                </>
                            )}

                            <Box fontSize={'1rem'}>
                                Additional sign up answers approved by BD
                            </Box>
                        </Stack>


                        <Stack sx={{ paddingTop: '20px' }} alignItems={'center'} justifyContent={'space-between'}>
                            <Button onClick={handleAssignBD} disabled={!gradingComplete || !gradingComplete || !gradingComplete || selectedBD == null || selectedBD === "" || loading} sx={{ width: '200px' }} variant="contained">
                                Activate
                            </Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </BootstrapDialog>
        </>
    );
}