import { useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';

import { Button, CircularProgress, IconButton, TableCell, Tooltip } from '@mui/material';

import { deleteProject, setActiveStatus } from '../../../../firebase/cloudFunctions';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { SetActiveStatusDialog } from '../TableRowFunctions/SetActiveStatusDialog';


const RedCircle = () => (
    <svg height="20" width="20">
        <circle cx="9" cy="9" r="8" stroke="black" strokeWidth="0" fill="#ed1c24c4" />
    </svg>
)

const OrangeCircle = () => (
    <svg height="20" width="20">
        <circle cx="9" cy="9" r="8" stroke="black" strokeWidth="0" fill="#fc9003e3" />
    </svg>
)


const GreenCircle = () => (
    <svg height="20" width="20">
        <circle cx="9" cy="9" r="8" stroke="black" strokeWidth="0" fill="#44a829b5" />
    </svg>
)



export const TableRowIsActive = ({ project }) => {
    const confirm = useConfirm();

    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }


    const handleActiveState = () => {
        switch (project?.isActive) {
            case 'blocked':
                return <RedCircle />;
            case 'paused':
                return <OrangeCircle />;
            case 'aborted':
                return <RedCircle />;
            case 'exit':
                return <RedCircle />;
            case 'transfered':
                return <RedCircle />;
            case false:
                return <RedCircle />;
            case true:
                return <GreenCircle />;
            default:
                return <GreenCircle />;
        }
    }

    const handleActiveStateTooltip = () => {
        switch (project?.isActive) {
            case 'blocked':
                return "Inactive (Blocked/not applicable)";
            case 'paused':
                return "Paused (Inactivity)";
            case 'aborted':
                return "Inactive (Aborted on IBOs request)";
            case 'exit':
                return "Has exited";
            case false:
                return "Inactive";
            case true:
                return "Active";
            default:
                return "Active";
        }
    }

    return (
        <>
            <TableCell>
                {isLoading === true ? (
                    <>
                        <CircularProgress size={'16px'} />
                    </>
                ) : (
                    <>
                        <Tooltip arrow title={handleActiveStateTooltip()} placement='top'>
                            <IconButton size='small' sx={{ height: '22px' }} onClick={handleOpenDialog}>
                                {handleActiveState()}
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </TableCell>

            <SetActiveStatusDialog project={project} open={openDialog} handleClose={handleCloseDialog} />
        </>

    )
}