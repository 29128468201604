import { Fragment, useState } from 'react'
import '../../assets/scss/app.scss'
import Box from '@mui/material/Box'
import { addNoteMetaData } from '../../firebase/cloudFunctions';
import format from 'date-fns/format'
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import Switch from '@mui/material/Switch';
import { useGetExternalComments } from '../../hooks/commentData/useGetExternalComments';
import { ErrorAlert } from '../../helper/errorAlert';
import { Stack } from '@mui/material';
import { useGetExternalCommentTexts } from '../../hooks/languageHooks/useGetExternalCommentTexts';

const CommentLogExternalIBO = ({ project }) => {
    const { comments } = useGetExternalComments(project.id, null, null, 'created');
    const sentences = useGetExternalCommentTexts();
    const [hideComments, setHideComments] = useState(true)
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const toggleShow = () => {
        setHideComments(!hideComments);
    };

    const hideComment = async (comment) => {
        comment['viewState'] = 'hidden';
        // call the cloud function.
        addNoteMetaData({
            messageID: comment.id,
            projectID: project.id,
            viewState: 'hidden',
            isExternal: true,
        }).catch((error) => {
            console.error('Error when trying to hide comment: ', error)
            setErrorInfo(error);
            setErrorMsg(sentences.errorMsgHide);
            setError(true);
        });
    };

    const showComment = async (comment) => {
        comment['viewState'] = null;
        // call the cloud function. 
        addNoteMetaData({
            messageID: comment.id,
            projectID: project.id,
            viewState: null,
            isExternal: true,
        }).catch((error) => {
            //console.log('Error when trying to show comment: ', error);
            setErrorInfo(error);
            setErrorMsg(sentences.errorMsgShow);
            setError(true);
        });
    };


    return (
        <>
            <div className="home-ibo-comments">
                <div>
                    <span style={{ marginBottom: "0px, marginRight: 5px" }}>
                        <strong>{sentences.header}</strong>
                    </span>
                    <Switch
                        size="small"
                        checked={!hideComments}
                        onChange={toggleShow}
                        inputProps={{ 'aria-label': 'controlled' }}
                        className="toggle-hidden"
                    />
                    <span className="show-hidden-text">{sentences.hiddenToggle}</span>
                    <hr className="solid" style={{ marginTop: "3px", width: "100%" }}></hr>
                </div>

                <div className="home-ibo-comment-log-box">
                    {comments.sort((a, b) => a.created?.seconds - b.created?.seconds).map(comment =>
                        <div key={comment.id}>
                            {hideComments === false || comment.viewState !== 'hidden' ? (
                                <Box>
                                    <Stack direction={'row'}>
                                        <span className="comment_username">
                                            {comment.createdUserName}
                                        </span>
                                        <span>
                                            {format(new Date(comment.created.seconds * 1000), 'yyyy-MM-dd HH:mm')}
                                        </span>
                                        <span style={{ color: 'grey', marginLeft: '4px' }}>
                                            {comment.location}
                                        </span>

                                        {comment.assignedID !== "undefined" && comment.assignedID != null ? (
                                            <Fragment>
                                                {comment.approved === false ? (
                                                    <span>
                                                        <QuizOutlinedIcon
                                                            style={{
                                                                fontSize: "17px",
                                                                marginLeft: "5px",
                                                                color: "#efb236",
                                                                //float: "middle",
                                                            }} />
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <QuizOutlinedIcon
                                                            style={{
                                                                fontSize: "17px",
                                                                marginLeft: "5px",
                                                                color: "#a9bc52",
                                                                //float: "middle",
                                                            }} />
                                                    </span>
                                                )}
                                            </Fragment>
                                        ) : ("")}
                                    </Stack>

                                    {comment.viewState === "hidden" ? (
                                        <VisibilityOffRoundedIcon

                                            className="show-hidden-comments-icon"
                                            onClick={() => {
                                                showComment(comment);
                                            }}
                                        />
                                    ) : (
                                        <VisibilityRoundedIcon
                                            className="hide-comments-icon"

                                            onClick={() => {
                                                hideComment(comment);
                                            }}
                                        />
                                    )}

                                    <p className="comment-ibo-box">
                                        {comment.message}
                                    </p>
                                </Box>
                            ) : ("")}
                        </div>
                    )}
                </div>
            </div>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    )
}

export default CommentLogExternalIBO
