import { Alert, Snackbar } from "@mui/material"
import { Fragment } from "react"


export const InfoAlert = ({ open, setIsOpen, message }) => {
    return (
        <Fragment>
            <Snackbar open={open} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setIsOpen(false)}>
                <Alert severity="success" color="success">
                    {message || "Completed!"}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}