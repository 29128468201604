import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import '../../assets/scss/app.scss'
import { useGetAllAvailableTimesAll } from '../../hooks/timeslotData/useGetAllAvailableTimes'
import { useGetBdTimes } from '../../hooks/timeslotData/useGetBdTimes'
import { useGetBookedBdTimes } from '../../hooks/timeslotData/useGetBookedBdTimes'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useConfirm } from 'material-ui-confirm';
import { deleteTimeSlot } from '../../firebase/cloudFunctions';
import { Link } from 'react-router-dom'
import { CircularProgress, Divider, Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { ErrorAlert } from '../../helper/errorAlert';
import { useGetProjects } from '../../hooks/projectData/useGetProjects';


function AvailableDates() {
    const [alltimes] = useGetAllAvailableTimesAll();
    const [bdtimes] = useGetBdTimes();
    const [bookedbdtimes] = useGetBookedBdTimes();
    const projects = useGetProjects();
    const confirmDelete = useConfirm();
    const [deleting, setDeleting] = useState({})
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const handleDeleteAvailableTime = async (allbd) => {
        confirmDelete({ title: 'Please confirm', description: 'Are you sure you want to delete this timeslot: ' + allbd.startTime + '-' + allbd.endTime + '?' })
            .then(() => {
                deleting[allbd.id] = true;
                setUpdate(!update);
                deleteTimeSlot({
                    timeSlotID: allbd.id
                }).catch((error) => {
                    setErrorInfo(error);
                    setErrorMsg('Something went wrong! Could not delete timeslot!');
                    setError(true);
                    deleting[allbd.id] = false;
                    setUpdate(!update);
                })
            })
            .catch(() => {
                //set `error` state to true
                return
            });
    }

    /**
     * 
     * @param {string} name 
     */
    const limitName = (name) => {
        if (name.length < 20){
            return name;
        }
        else{
            return name.slice(0, 18) + "...";
        }
    }

    return (

        <Box mt="3rem" mb="6rem"
            sx={{
                width: '100%',
            }}>
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={12} lg={6} >
                    <Box sx={{
                        marginLeft: '30px',
                        width: '530px',
                    }}>
                        <Box>
                            <strong>All available times:</strong>
                        </Box>
                        <Divider />

                        {alltimes.map((allavail) => {
                            const startSplit = String(allavail.startTime).split(' ');
                            return (
                                <Fragment>
                                    <div key={allavail.id} style={{ marginTop: '1px', marginBottom: '1px' }}>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent="flex-start" spacing={1.5}>
                                            <div style={{ minWidth: '95px', fontSize: '15px' }}>
                                                {startSplit[0]}
                                            </div>
                                            <div style={{ minWidth: '100px', fontSize: '15px' }}>
                                                {startSplit[1]}
                                            </div>
                                            <div style={{ minWidth: '120px', fontSize: '15px' }}>
                                                {startSplit[2]} - {allavail.endTime}
                                            </div>
                                            <div>
                                                <span className="avail-owner">{allavail.owner}</span>
                                            </div>
                                        </Stack>
                                    </div>
                                    <Divider />
                                </Fragment>
                            )
                        })}
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6} >
                    <Grid container spacing={5}>
                        <Grid item>
                            <Box sx={{
                                marginLeft: '30px',
                                width: '450px',
                            }}>
                                <Box>
                                    <strong>All your available times:</strong>
                                </Box>
                                <Divider />
                                {bdtimes.map((allbd) => {
                                    const startSplit = String(allbd.startTime).split(' ');

                                    return (
                                        <Fragment>
                                            <div key={allbd.id} style={{ marginTop: '1px', marginBottom: '1px' }}>
                                                <Stack direction={'row'} alignItems={'center'} justifyContent="flex-start" spacing={1.5}>
                                                    <div style={{ minWidth: '95px', fontSize: '15px' }}>
                                                        {startSplit[0]}
                                                    </div>
                                                    <div style={{ minWidth: '100px', fontSize: '15px' }}>
                                                        {startSplit[1]}
                                                    </div>
                                                    <div style={{ minWidth: '160px', fontSize: '15px' }}>
                                                        {startSplit[2]} - {allbd.endTime}
                                                    </div>
                                                    <div>
                                                        {deleting[allbd.id] !== true ? (
                                                            <HighlightOffOutlinedIcon
                                                                id="del-avail-time"
                                                                onClick={() => {
                                                                    handleDeleteAvailableTime(allbd)
                                                                }} />
                                                        ) : (
                                                            <CircularProgress size={12} />
                                                        )}

                                                    </div>
                                                </Stack>
                                            </div>
                                            <Divider />
                                        </Fragment>
                                    )
                                })}
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box sx={{ marginLeft: '30px', width: '550px' }}>
                                <Box>
                                    <strong>All your booked times:</strong>
                                </Box>
                                <Divider />
                                {bookedbdtimes.map((allbookedbd) => {
                                    let project = projects.find((item) => item.id === allbookedbd?.bookedBy);
                                    const startSplit = String(allbookedbd.startTime).split(' ');

                                    return (
                                        <Fragment>
                                            <div key={allbookedbd.id} style={{ marginTop: '1px', marginBottom: '1px' }}>
                                                <Stack direction={'row'} alignItems={'center'} justifyContent="flex-start" spacing={1.5}>
                                                    <div style={{ minWidth: '95px', fontSize: '15px' }}>
                                                        {startSplit[0]}
                                                    </div>
                                                    <div style={{ minWidth: '100px', fontSize: '15px' }}>
                                                        {startSplit[1]}
                                                    </div>
                                                    <div style={{ minWidth: '100px', fontSize: '15px' }}>
                                                        {startSplit[2]} - {allbookedbd.endTime}
                                                    </div>
                                                    {project != null ? (
                                                        <div style={{ minWidth: '100px', fontSize: '15px' }}>
                                                            IBO:
                                                            <Link
                                                                to='/home'
                                                                state={{ projectData: project }}
                                                                style={{ color: '#4eacc1', marginLeft: '5px' }}>
                                                                <strong><i>{limitName(project?.value) || ''}</i></strong>
                                                            </Link>
                                                        </div>
                                                    ) : ("")}

                                                </Stack>
                                            </div>
                                            <Divider />
                                        </Fragment>
                                    )
                                })}
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Box>
    );
}


export default AvailableDates;