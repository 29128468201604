import { FormControlLabel, FormControl, FormGroup, Radio } from "@mui/material"
import parse from 'html-react-parser';

export const TemplateRadioButtonCard = ({ assignedQuestion }) => {
    return (
        <div style={{ marginLeft: '20px' }}>
            <span style={{ display: 'block', marginTop: 10 }}>{parse(assignedQuestion.text)}</span>

            {String(assignedQuestion.options).split(';').map(element => (
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            checked={assignedQuestion.answer || false}
                            aria-label="gender"
                            name={assignedQuestion.key}
                            control={<Radio />}
                            labelPlacement={assignedQuestion.labelPlacement}
                            label={parse(element)}
                        />
                    </FormGroup>
                </FormControl>
            ))}
        </div>
    )
}