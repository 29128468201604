import { Card, CardContent } from "@mui/material"
import parse from 'html-react-parser';
import Explanation from "../../../Questions/Explanation";

export const TemplateRichTextCard = ({ assignedQuestion }) => {
    return (
        <Card className="characters" key={assignedQuestion.key} sx={{ marginBottom: '0rem', paddingBottom: '0rem' }}>
            <CardContent>
                {assignedQuestion.explanation !== "" || null ? (
                    <Explanation key={assignedQuestion.key} data={assignedQuestion} />
                ) : ('')}

                {parse(assignedQuestion.text)}
            </CardContent>
        </Card>
    )
}