import { Box, Button, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../helper/bootstrapDialog";
import { setActiveStatus } from "../../../../firebase/cloudFunctions";
import { InactiveReasonsLabels } from "../../../../constants/labels/labels";

export const SetActiveStatusDialog = ({ open, handleClose, project }) => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('');

    useEffect(() => {
        if (project?.isActive != null && project?.isActive !== true && project?.isActive !== false && reason === "") {
            setReason(project?.isActive);
        }
    }, [project]);

    const handleChangeReason = (event) => {
        setReason(event.target.value);
    }

    const handleSetToInactive = () => {
        setLoading(true);

        setActiveStatus({
            projectID: project?.id,
            isActive: reason,
            sendMail: true,
        }).then(() => {
            handleClose();
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleChangeReasonFB = () => {
        setLoading(true);

        setActiveStatus({
            projectID: project?.id,
            isActive: reason,
            sendMail: false,
        }).then(() => {
            handleClose();
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleSetToActive = () => {
        setLoading(true);

        setActiveStatus({
            projectID: project?.id,
            isActive: true,
        }).then(() => {
            handleClose();
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleActiveStateTooltip = () => {
        switch (project?.isActive) {
            case 'blocked':
                return "inactive (Blocked/not applicable)";
            case 'paused':
                return "paused (Inactivity)";
            case 'aborted':
                return "inactive (Aborted on IBOs request)";
            case 'exit':
                return "has exited";
            case false:
                return "inactive (No reason specified)";
            case true:
                return "Active";
            default:
                return "Active";
        }
    }

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <div style={{ fontSize: '1rem', padding: '10px 10px 0px 10px' }}>
                        <strong>Set {project?.value} to {project?.isActive === true ? "inactive" : "active"}?</strong>
                    </div>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent>
                    <Stack>
                        {project.isActive === true ? (
                            <>
                                <Box marginBottom={'15px'}>
                                    Please select the reason for inactivation and confirm
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <FormControl size="small" sx={{ width: '380px' }}>
                                        <InputLabel>Reason</InputLabel>
                                        <Select
                                            value={reason}
                                            label="Reason"
                                            onChange={handleChangeReason}
                                        >
                                            <MenuItem sx={{ height: '16px' }} value={''}></MenuItem>
                                            {Object.keys(InactiveReasonsLabels).map((key) => (
                                                <MenuItem key={key} value={key}>{InactiveReasonsLabels[key]}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                            </>

                        ) : (
                            <>
                                <Box marginBottom={'15px'}>
                                    Current status is set to {handleActiveStateTooltip()}
                                </Box>


                                <Box>
                                    <Typography fontWeight={700} paddingLeft={'5px'} marginBottom={'5px'}>
                                        Change reason:
                                    </Typography>

                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <FormControl size="small" sx={{ width: '380px' }}>
                                            <InputLabel>Reason</InputLabel>
                                            <Select
                                                value={reason}
                                                label="Reason"
                                                onChange={handleChangeReason}
                                            >
                                                <MenuItem sx={{ height: '16px' }} value={''}></MenuItem>
                                                {Object.keys(InactiveReasonsLabels).map((key) => (
                                                    <MenuItem key={key} value={key}>{InactiveReasonsLabels[key]}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                </Box>
                            </>

                        )}

                        <Stack direction={'row'} sx={{ paddingTop: '20px' }} alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
                            <Button onClick={handleClose} sx={{ width: '80px' }} variant="text">
                                Cancel
                            </Button>
                            {project.isActive === true ? (
                                <Button onClick={handleSetToInactive} disabled={reason == null || reason === "" || loading === true} sx={{ width: '90px' }} variant="contained">
                                    Confirm
                                </Button>
                            ) : (
                                <>
                                    <Button onClick={handleChangeReasonFB} disabled={loading === true || reason === "" || reason == null || reason === project?.isActive} variant="contained">
                                        Change reason
                                    </Button>
                                    <Button onClick={handleSetToActive} disabled={loading === true} variant="contained">
                                        Reactivate
                                    </Button>
                                </>
                            )}
                        </Stack>
                    </Stack>
                </DialogContent>
            </BootstrapDialog>
        </>
    );
}