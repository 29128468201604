import { useState, useRef, useMemo, useEffect } from "react";
//import { useLocation } from 'react-router-dom'
import JoditEditor from "jodit-react";
import { db } from '../../firebase';
import { editQuestion } from '../../firebase/cloudFunctions';
import './css/TextFieldCreator.css'

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { doc, getDoc } from "firebase/firestore";
import { Box, Select, TextField, InputLabel, MenuItem, CircularProgress, FormControl, Button } from "@mui/material";
import { AlertBox } from "../../helper/AlertBox";



function EditContent({ questionId, short, text, moreInfo, options, tag, type, labelPlacement, handleClose }) {
    const [openInfo, setOpenInfo] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const editor = useRef(null);
    const moreInfoEditorEdit = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [newLabelPlacement, setNewLabelPlacement] = useState(labelPlacement);
    const [inputList, setInputList] = useState(String(options).split(';'));
    const [questionTag, setQuestionTag] = useState(tag);
    const [tags, setTags] = useState([]);

    const handleQuestionTag = (event) => {
        setQuestionTag(event.target.value);
    }

    useEffect(() => {
        getDoc(doc(db, 'Category/question')).then((querySnapshot) => {
            setTags(querySnapshot.data().category)
        })
    }, []);

    const handleLabelPlacement = (event) => {
        setNewLabelPlacement(event.target.value);
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { value } = e.target;
        const list = [...inputList];
        list[index] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        const list = [...inputList];

        list.push("")
        //console.log('List: ' + inputList.length)
        setInputList(list);
    };

    const configEdit = {
        readonly: false,
        placeholder: 'Compose your main content here..',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };

    const config2Edit = {
        readonly: false,
        placeholder: 'Compose your additional information here..',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };

    const [content, setContent] = useState(text);
    const [moreInfoContentEdit, setMoreInfoContentEdit] = useState(moreInfo);
    const [shortVal, setShortVal] = useState(short);

    const updateRichText = () => {

        setIsLoading(true);

        editQuestion({
            questionID: questionId,
            type: type,
            text: content,
            questionExplanation: moreInfoContentEdit,
            questionIcon: null,
            questionShortDesc: shortVal,
            labelPlacement: newLabelPlacement,
            options: inputList.join(';'),
            category: questionTag
        }).then(() => {
            setOpenInfo(true);
            handleClose();
        }).catch((e) => {
            setErrorInfo(e);
            setErrorMsg('Something went wrong! Could not edit content.');
            setError(true);
            setOpenInfo(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    //console.log(type)
    function checkType(type) {
        if (type === 'RadioButton' || type === 'Checked') {
            return true;
        }
        else {
            return false;
        }
    }

    function checkTypeOptionVal(type) {

        if (type === 'RadioButton' || type === 'Checked' || type === 'Slider') {
            return true;
        }
        else {
            return false;
        }
    }

    const onTextChange = (e) => setContent(e.target.value);

    return (
        <div>
            <form className="edit-short-desc">
                <p>Edit the short description of this content: </p>
                <TextField
                    value={shortVal}
                    onChange={e => setShortVal(e.target.value)}
                    required={true}
                    id='standard-helperText shortDesc-field'
                    label='Title'
                    variant='standard'
                    helperText="This information is only used internally"
                />
            </form>
            <div style={{ marginBottom: "40px" }}>
                <p>Change tag</p>
                <FormControl>
                    <InputLabel id="choose-tag-id">Change Tag</InputLabel>

                    <Select
                        labelId="choose-tag-id"
                        label="Choose a tag"
                        value={questionTag}
                        onChange={handleQuestionTag}
                        id="select-question-tag"

                        style={{ width: 300 }}>

                        {tags.map((tag) => {
                            return (
                                <MenuItem value={tag}>{tag}</MenuItem>
                            )
                        })}
                    </Select>

                </FormControl>
            </div>

            {type === "EmbededLinkInput" ? (
                <>
                    <p>Edit link</p>
                    <TextField
                        onChange={onTextChange}
                        value={content}
                        label={"Edit link"} //optional
                        fullWidth
                        variant="standard"
                        helperText='NOTE! It is the embedded-specific link to a YouTube video that needs to be entered. Replace the "watch?v="-part of your normal YouTube-link with "embed/".'
                    />
                </>
            ) : (
                <Box className="new-group">
                    <span><strong>Edit main content</strong></span>
                    <JoditEditor
                        ref={editor}
                        value={content}
                        config={configEdit}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)}
                        onChange={(newContent) => { }}
                    />
                </Box>
            )}


            <div className="rich-text-info-creator">
                <span><strong>Edit additional information</strong></span>
                {useMemo(
                    () => (
                        <JoditEditor
                            ref={moreInfoEditorEdit}
                            value={moreInfoContentEdit}
                            config={config2Edit}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newInfoContent) => {
                                // setContent(newContent.target.innerHTML);
                            }}
                            onChange={(newInfoContent) => {
                                setMoreInfoContentEdit(newInfoContent);
                            }}
                        />
                    ), [])
                }
            </div>

            <div>
                {checkTypeOptionVal(type) &&
                    <div style={{ marginTop: 20 }}>
                        <span><strong>Edit option</strong></span>
                        {
                            <div className="box" style={{ marginTop: 10 }}>
                                {inputList.map(element => (
                                    <>
                                        <TextField
                                            className="mb10"
                                            label="Enter option"
                                            value={element}
                                            onChange={e => handleInputChange(e, inputList.indexOf(
                                                element))}
                                            size="small"
                                            style={{ marginTop: 15 }}
                                        />
                                        <HighlightOffOutlinedIcon
                                            className="mr10"
                                            style={{
                                                cursor: "pointer",
                                                textAlign: "center",
                                                marginTop: "23px",
                                                marginLeft: "8px",

                                            }}
                                            onClick={() => handleRemoveClick(inputList.indexOf(element))} />
                                        <br></br>
                                    </>

                                ))}

                                <div className="btn-box" style={{marginLeft: '10px', marginTop: '10px'}}>

                                    {<ControlPointIcon style={{
                                        cursor: "pointer",
                                        textAlign: "center",

                                    }} onClick={handleAddClick} />}
                                </div>
                            </div>

                        }
                    </div>
                }


            </div>

            {checkType(type) ? (
                <div style={{ display: 'block', justifyContent: 'left', marginTop: 20 }}>
                    <span><strong>Change label Placement</strong> (Optional)</span>
                    <Box sx={{ marginBottom: 4, marginTop: 2 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="combo-box-demo">Label placement</InputLabel>
                            <Select
                                id="combo-box-demo"
                                label="Label placement"
                                value={newLabelPlacement}
                                defaultValue={labelPlacement}
                                onChange={handleLabelPlacement}
                                style={{ width: 300 }}>
                                <MenuItem value="top">Top</MenuItem>
                                <MenuItem value="start">Start</MenuItem>
                                <MenuItem value="bottom">Bottom</MenuItem>
                                <MenuItem value="end">End (Default)</MenuItem>
                            </Select>

                        </FormControl>
                    </Box>
                </div>
            ) : ("")}

            <div className="save-button-short-desc">
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant="contained"
                        onClick={updateRichText}
                    >Update</Button>
                )}
            </div>
            
            <AlertBox open={openInfo} setOpen={setOpenInfo} isError={error} successMsg="Updated Content!" errorMsg={errorMsg} errorInfo={errorInfo} />
        </div>

    );
}

export default EditContent
