import { useState, useRef, forwardRef } from "react";
import JoditEditor from "jodit-react";
import { createQuestion } from '../../firebase/cloudFunctions';
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './css/TextFieldCreator.css'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Box from '@mui/material/Box';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';


//const createQuestion = firebase.app().functions("europe-west1").httpsCallable('createQuestion');

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//add CSS classes for Material UI components calling a function that returns another function
// const useStyles = makeStyles((theme) => ({
//     //the CSS class honeypot is added to the honeypot field later on, which is not displayed to users.
//     honeypot: {
//         display: 'none',
//     },

// }));

function TextFieldCreator(props) {

    //const classes = useStyles();
    //const [formIsValid, setFormIsValid] = useState(false);
    const questionEditor = useRef(null);
    const moreInfoQuestionEditor = useRef(null);

    const [error, setError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);


    const config = {
        readonly: false,
        placeholder: 'Compose your question here..',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };

    const config2 = {
        readonly: false,
        placeholder: 'Compose your additional information here..',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };


    const [contentQuestion, setQuestion] = useState("");
    const [moreInfoQuestion, setMoreInfoQuestion] = useState("");




    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    // call the cloud function
    const submitRichText = () => {

        setIsLoading(true);

        let dataSnap;
        if (props.perk === true) {
            dataSnap = {
                type: 'TextInput',
                text: contentQuestion,
                questionExplanation: moreInfoQuestion,
                options: null,
                questionIcon: null,
                questionShortDesc: props.shortDesc,
                category: props.tag,
                perks: "Infinity",
            }
        }
        else {
            dataSnap = {
                type: 'TextInput',
                text: contentQuestion,
                questionExplanation: moreInfoQuestion,
                options: null,
                questionIcon: null,
                questionShortDesc: props.shortDesc,
                category: props.tag
            }
        }

        createQuestion(dataSnap)
            .then((data) => {
                console.log('data ' + data.data)

                //create a new `contactForm` object that looks like the original `contactForm` state
                // let originalShortDesc = {};
                // for (let key in shortDesc) {
                //     originalShortDesc[key] = {
                //         ...shortDesc[key],
                //         value: '',
                //         valid: key === 'honeypot' ? true : false,
                //         blur: false,
                //     };
                // }


                //reset `contactForm` state to its original value
                //setShortDesc(originalShortDesc);

                //reset the whole form validity to false
                //setFormIsValid(false);
                setIsLoading(false);
                setQuestion("")
                setMoreInfoQuestion("")
                setOpenSnackbar(true);
            })

            .catch(() => {
                setError(true);

                setIsLoading(false);

                setOpenSnackbar(true);
            });
    }


    const resetFields = () => {

        setQuestion("")
        setMoreInfoQuestion("")

    };

    //console.log('here be contnent:' + content)
    return (
        <div>
            <span><strong>Question</strong></span>
            <Box className="new-group">
                <JoditEditor
                    ref={questionEditor}
                    value={contentQuestion}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setQuestion(newContent)}
                    onChange={(newContent) => { }}
                />
            </Box>

            <div style={{ marginTop: 20 }}>
                {!show ? (<><ControlPointIcon
                    style={{
                        fontSize: 17,
                    }}
                    onClick={() => setShow(prev => !prev)}
                />  <span style={{ verticalAlign: 'base-line' }}><strong>"More info"-element</strong></span><InfoIcon style={{
                    color: "gray",
                    fontSize: 17,
                    marginLeft: 5,
                }} /></>) : (<><DoDisturbOnOutlinedIcon
                    style={{
                        fontSize: 17,
                    }}
                    onClick={() => setShow(prev => !prev)} /> <span style={{ verticalAlign: 'base-line' }}><strong>"More info"-element</strong></span><InfoIcon style={{
                        color: "gray",
                        fontSize: 16,
                        marginLeft: 5,
                    }} /></>)}</div>

            {show && <Box className="new-group">
                <JoditEditor
                    ref={moreInfoQuestionEditor}
                    value={moreInfoQuestion}
                    config={config2}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newInfoContent) => setMoreInfoQuestion(newInfoContent)}
                    onChange={(newInfoContent) => { }}

                />

            </Box>}


            {/* <div>
                <form className="rich-text-short-desc">
                    <p align="left">Enter a short description of this question: </p>
                    {formElements}
                </form>
           
            </div> */}


            {/* <div>Preview HTML: {content}</div>
            <div>
                Preview Formatted:{" "}
                <span dangerouslySetInnerHTML={{ __html: content }} />
            </div> */}
            <Grid container justifyContent="center" style={{ marginBottom: "40px" }}>
                <div className="save-button-short-desc">
                    {isLoading ? (
                        <CircularProgress />
                    ) : (<><Button
                        //size="medium"
                        variant="contained"
                        //disabled={!formIsValid}
                        //color="primary"
                        onClick={resetFields} style={{ marginRight: 20 }}>Reset</Button>
                        <Button
                            //size="medium"
                            variant="contained"
                            disabled={!contentQuestion}
                            //disabled={!formIsValid}
                            //color="primary"
                            onClick={submitRichText}>Save</Button></>)}
                </div>
            </Grid>

            {error ? (
                <Snackbar
                    open={openSnackbar}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={closeSnackbar} severity="error">
                        Something went wrong!
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Your text is saved!</Alert>
                </Snackbar>
            )}
        </div>

    );
}

export default TextFieldCreator;
