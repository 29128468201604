import { collection, getDocs } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList'

export const useGetAllQuestions = () => {
    const [questions, setQuestions] = useState([]);


    useEffect(() => {
        const getAllQuestions = async () => {

            const ref = getDocs(collection(db, "Questions"))
                .then((querySnapshot) => {
                    let arr = [];
                    querySnapshot.forEach((doc) => {
                        arr.push({ id: doc.id, value: doc.data().text, description: doc.data().shortDesc, type: doc.data().type, explanation: doc.data().explanation, perks: doc.data().perks })
                    });
                    setQuestions(arr)
                });

            firebaseRefList.push(ref);
        }
        getAllQuestions()
    }, []);

    return questions;
};