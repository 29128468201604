import { useState, useRef, forwardRef } from "react";
//import { useLocation } from 'react-router-dom'
import JoditEditor from "jodit-react";
import { createQuestion } from '../../firebase/cloudFunctions';
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
//import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//import ReactHtmlParser from 'react-html-parser';
import './css/TextFieldCreator.css'
//import { id } from "date-fns/locale";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';



//const createQuestion = firebase.app().functions("europe-west1").httpsCallable('createQuestion');

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//add CSS classes for Material UI components calling a function that returns another function
// const useStyles = makeStyles((theme) => ({
//     //the CSS class honeypot is added to the honeypot field later on, which is not displayed to users.
//     honeypot: {
//         display: 'none',
//     },

// }));

function OptionCreator(props) {

    //const classes = useStyles();
    //const [formIsValid, setFormIsValid] = useState(false);
    const questionEditor = useRef(null);
    const moreInfoQuestionEditor = useRef(null);

    const [error, setError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [optionType, setOptionType] = useState('');
    const [labelPlacement, setLabelPlacement] = useState('');
    const [inputList, setInputList] = useState([]);
    //const [disable, setDisable] = React.useState(false);


    // handle input change
    const handleInputChange = (e, index) => {
        let { value } = e.target;
        const list = [...inputList];
        list[index] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, ""]);
    };

    const handleOptionType = (event) => {
        if (event.target.value === 'Slider') {
            const newList = [];
            newList.push("1");
            newList.push("2");
            newList.push("3");
            newList.push("4");
            newList.push("5");
            newList.push("6");
            newList.push("7");
            newList.push("8");
            newList.push("9");
            setInputList(newList);
        }
        else {
            const newList = [""];
            setInputList(newList);
        }

        setOptionType(event.target.value);
    }

    const handleLabelPlacement = (event) => {
        setLabelPlacement(event.target.value);
    }

    const config = {
        readonly: false,
        placeholder: 'Compose your option-based question here, or leave empty to only display option(s)',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };

    const config2 = {
        readonly: false,
        placeholder: 'Compose your additional information here..',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };


    const [contentQuestion, setQuestion] = useState("");
    const [moreInfoQuestion, setMoreInfoQuestion] = useState("");


    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    // call the cloud function
    const submitRichText = () => {

        setIsLoading(true);

        let dataSnap;
        if (props.perk === true) {
            dataSnap = {
                type: optionType,
                text: contentQuestion,
                questionExplanation: moreInfoQuestion,
                options: inputList.join(';'),
                questionIcon: null,
                questionShortDesc: props.shortDesc,
                labelPlacement: labelPlacement,
                category: props.tag,
                perks: "Infinity",
            }
        }
        else {
            dataSnap = {
                type: optionType,
                text: contentQuestion,
                questionExplanation: moreInfoQuestion,
                options: inputList.join(';'),
                questionIcon: null,
                questionShortDesc: props.shortDesc,
                labelPlacement: labelPlacement,
                category: props.tag
            }
        }

        createQuestion(dataSnap)
            .then((data) => {
                setIsLoading(false);
                setOptionType("")
                setQuestion("")
                setMoreInfoQuestion("")
                setLabelPlacement("")
                const newList = [];
                newList.push("");
                //newList[0] = "";
                setInputList(newList);

                setOpenSnackbar(true);
            })

            .catch(() => {
                setError(true);

                setIsLoading(false);

                setOpenSnackbar(true);
            });
    }

    function checkType(questionType) {
        if (questionType === 'RadioButton' || questionType === 'Checked') {
            return true;
        }
        else {
            return false;
        }
    }

    const resetFields = () => {

        setOptionType("")
        setQuestion("")
        setMoreInfoQuestion("")
        setLabelPlacement("")
        const newList = [];
        newList.push("");
        //newList[0] = "";
        setInputList(newList);

    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ marginBottom: 4, marginTop: 2 }}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="combo-box-demo">Choose option type</InputLabel>
                        <Select
                            id="combo-box-demo" label="Choose option type"
                            value={optionType}
                            onChange={handleOptionType}
                            style={{ width: 400 }}>
                            <MenuItem value="Checked">Checkbox</MenuItem> <MenuItem value="RadioButton">Radio Button</MenuItem>
                            <MenuItem value="Slider">Slider</MenuItem>
                        </Select>

                    </FormControl>

                </Box>
            </div>
            <span><strong>Option question</strong></span>
            <Box className="new-group">

                <JoditEditor
                    ref={questionEditor}
                    value={contentQuestion}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setQuestion(newContent)}
                    onChange={(newContent) => { }}
                />

            </Box>
            <div style={{ marginTop: 20 }}>
                {!show ? (<><ControlPointIcon
                    style={{
                        fontSize: 17,
                    }}
                    onClick={() => setShow(prev => !prev)}
                />  <span style={{ verticalAlign: 'base-line' }}><strong>"More info"-element</strong></span><InfoIcon style={{
                    color: "gray",
                    fontSize: 17,
                    marginLeft: 5,
                }} /></>) : (<><DoDisturbOnOutlinedIcon
                    style={{
                        fontSize: 17,
                    }}
                    onClick={() => setShow(prev => !prev)} /> <span style={{ verticalAlign: 'base-line' }}><strong>"More info"-element</strong></span><InfoIcon style={{
                        color: "gray",
                        fontSize: 17,
                        marginLeft: 5,
                    }} /></>)}</div>

            {show && <Box className="new-group">
                <JoditEditor
                    ref={moreInfoQuestionEditor}
                    value={moreInfoQuestion}
                    config={config2}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newInfoContent) => setMoreInfoQuestion(newInfoContent)}
                    onChange={(newInfoContent) => { }}

                />

            </Box>}
            <div style={{ marginTop: 20 }}>
                <span><strong>Option</strong></span>
                {inputList.map((x, i) => {
                    return (
                        <div className="box" component="form" style={{ marginTop: 10 }}>
                            <TextField
                                label="Enter option"
                                value={x}
                                onChange={e => handleInputChange(e, i)}
                                size="small"
                                required

                                errorMessage='Please add a value!'

                            />
                            {inputList.length !== 1 && <HighlightOffOutlinedIcon
                                className="mr10"
                                style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    marginTop: "8px",
                                    marginLeft: "8px",

                                }}
                                onClick={() => handleRemoveClick(i)} />}
                            <div className="btn-box">

                                {inputList.length - 1 === i && <ControlPointIcon style={{
                                    cursor: "pointer",
                                    textAlign: "center",


                                }} onClick={handleAddClick} />}
                            </div>
                        </div>
                    );
                })}

            </div>

            {checkType(optionType) &&
                <div style={{ display: 'block', justifyContent: 'left', marginTop: 20 }}>
                    <span><strong>Label Placement</strong> (Optional)</span>
                    <Box sx={{ marginBottom: 4, marginTop: 2 }}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="combo-box-demo">Label placement</InputLabel>
                            <Select
                                id="combo-box-demo" label="Label placement"
                                value={labelPlacement}
                                onChange={handleLabelPlacement}
                                style={{ width: 300 }}>
                                <MenuItem value="top">Top</MenuItem>
                                <MenuItem value="start">Start</MenuItem>
                                <MenuItem value="bottom">Bottom</MenuItem>
                                <MenuItem value="end">End (Default)</MenuItem>
                            </Select>

                        </FormControl>

                    </Box>
                </div>
            }

            <Grid container justifyContent="center" style={{ marginBottom: "40px" }}>
                <div className="save-button-short-desc">
                    {isLoading ? (
                        <CircularProgress />
                    ) : (<><Button
                        //size="medium"
                        variant="contained"
                        //disabled={!formIsValid}
                        //color="primary"
                        onClick={resetFields} style={{ marginRight: 20 }}>Reset</Button>
                        <Button
                            //size="medium"
                            variant="contained"
                            disabled={!optionType}

                            //disabled={!formIsValid}
                            //color="primary"
                            onClick={submitRichText}>Save</Button></>)}
                </div>

            </Grid>

            {error ? (
                <Snackbar
                    open={openSnackbar}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={closeSnackbar} severity="error">
                        Something went wrong!
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Your option is saved!</Alert>
                </Snackbar>
            )}
        </div>

    );
}

export default OptionCreator;
