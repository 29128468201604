import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';

import { Button, CircularProgress, TableCell } from '@mui/material';

import { deleteProject } from '../../../../firebase/cloudFunctions';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export const TableRowDeleteIBO = ({ project }) => {
    const confirm = useConfirm();

    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteIbo = (project) => {
        confirm({ title: 'Please confirm', description: 'Are you sure you want to delete IBO ' + project.value + '?' })
            .then(() => {
                setIsLoading(true);
                deleteProject({
                    projectID: project.id
                }).then(() => {
                    setIsLoading(false);
                }).catch((e) => {
                    setIsLoading(false);
                })
            })
            .catch(() => {
                //set `error` state to true
                return

            });
    }

    return (
        <TableCell style={{ paddingRight: '40px' }} padding='normal'>
            {isLoading ? (
                <CircularProgress size={"20px"} />
            ) : (
                <Button
                    className="delbtn"
                    onClick={() => {
                        handleDeleteIbo(project);
                    }}
                >
                    <HighlightOffOutlinedIcon style={{ fontSize: "20px" }} />
                </Button>
            )}
        </TableCell>
    )
}