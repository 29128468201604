import { Backdrop, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormLabel, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react'
import { db } from '../../firebase';
import { gatherLastCompletedCheckpoint, gatherMeetingsStats } from '../../firebase/cloudFunctions';
import firebaseRefList from '../../firebase/firebaseRefList';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Timestamp, doc, onSnapshot } from 'firebase/firestore';
import { MeetingTable } from './components/meetingTable';
import { ErrorAlert } from '../../helper/errorAlert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function StatsMeeting() {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showAccumelated, setShowAccumelated] = useState(true);
    const [meetQuarterly, setMeetQuarterly] = useState({});
    const [meetQuarterlyAcc, setMeetQuarterlyAcc] = useState({});
    const [meetQuarterlyAccActive, setMeetQuarterlyAccActive] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [timeSynced, setTimeSynced] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        const getStatistics = async () => {
            const subscriber = onSnapshot(doc(db, 'Statistics/meetStats'), (dataSnap) => {
                if (dataSnap.exists() === true) {
                    //console.log("Data: ", dataSnap.data())
                    if (dataSnap.data().lastSync != null) {
                        let timeStamp = new Timestamp(dataSnap.data()?.lastSync?.seconds, dataSnap.data()?.lastSync?.nanoseconds);
                        //console.log("Time: ", timeStamp.toDate().toLocaleString('se-SV'))
                        setTimeSynced(timeStamp.toDate());
                    }

                    if (dataSnap.data()?.meetQuarterly != null) {
                        //console.log("Data: ", dataSnap.data()?.meetQuarterly)
                        setMeetQuarterly(dataSnap.data()?.meetQuarterly);
                    }

                    if (dataSnap.data()?.meetAccumulated != null) {
                        //console.log("Data: ", dataSnap.data()?.meetAccumulated)
                        setMeetQuarterlyAcc(dataSnap.data()?.meetAccumulated);
                    }

                    if (dataSnap.data()?.meetAccumulatedActive != null) {
                        //console.log("Data: ", dataSnap.data()?.meetAccumulatedActive)
                        setMeetQuarterlyAccActive(dataSnap.data()?.meetAccumulatedActive);
                    }

                    setIsLoading(false);
                }
            });

            firebaseRefList.push(subscriber);
        }

        getStatistics();
    }, []);

    const reloadData = () => {
        setIsLoading(true);

        const promises = [];

        try {
            promises.push(gatherLastCompletedCheckpoint({}));
            promises.push(gatherMeetingsStats({}));

            Promise.all(promises).then(() => {
                setIsLoading(false);
            });
        } catch (error) {
            setError(true);
            setIsLoading(false);
            setOpenSnackbar(true);
        }
    }

    const handleChangeView = () => {
        setShowAccumelated(!showAccumelated);
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Stack alignItems={'center'} justifyContent={"center"} spacing={2} style={{ marginBottom: '40px' }}>
                <Stack justifyContent={"center"} spacing={2}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                        <FormLabel>New per quarter</FormLabel>
                        <Switch checked={showAccumelated} onClick={handleChangeView} />
                        <FormLabel>Accumulated</FormLabel>
                    </Stack>

                    {showAccumelated ? (
                        <MeetingTable meetData={meetQuarterlyAcc} meetDataActive={meetQuarterlyAccActive} />
                    ) : (
                        <Fragment>
                            <MeetingTable meetData={meetQuarterly} />
                            <Box fontSize={'14px'} maxWidth={'600px'}>
                                <i>Note that New per quarter list the number of checkpoints that have been reached during the period,
                                    and can include multiple checkpoints or no checkpoints from a single IBO</i>
                            </Box>
                        </Fragment>

                    )}

                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} style={{ marginTop: '30px' }} spacing={1}>
                        <Button onClick={reloadData} size='small' disabled={isLoading} variant='outlined' style={{ width: '140px' }}>
                            Resync
                        </Button>

                        {timeSynced != null ? (
                            <Box fontSize={'14px'} color={'GrayText'}>
                                <i>Last Synced: {timeSynced.toLocaleString('se-SV')}</i>
                            </Box>
                        ) : ("")}

                    </Stack>

                </Stack>
            </Stack>

            <ErrorAlert isError={openSnackbar} setIsError={setOpenSnackbar} errorMsg={"Something went wrong!"} />
        </>
    );
}
