import { useState } from 'react';
import Popover from '@mui/material/Popover';
import IboTableStepperStatusIcon from './IboTableStepperStatusIcon.js'
import { StepperTabComponentStart } from './StepperTabs/StepperTabComponentStart';
import { TrackLabels } from '../../constants/labels/labels';
import { StepperTabComponentIncubator } from './StepperTabs/StepperTabComponentIncubator';
import { StepperTabComponentScale } from './StepperTabs/StepperTabComponentScale';
import { StepperTabComponentFirstMeet } from './StepperTabs/StepperTabComponentFirstMeet';
import { Box } from '@mui/material';

export default function StepperPopOver({projData}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const displayStepper = () => {
        switch (projData?.activeTrack) {
            case TrackLabels.start.key:
                return (<StepperTabComponentStart projData={projData} value={0} isPopup isHover onClickStep={null} />);
            case TrackLabels.incubator.key:
                return (<StepperTabComponentIncubator projData={projData} value={0} isPopup isHover onClickStep={null}/>)
            case TrackLabels.scale.key:
                return (<StepperTabComponentScale projData={projData} value={0} isPopup isHover onClickStep={null} />)
            default:
                if (projData?.currentStep === "First Meet" || projData?.currentStep === "PreMeet"){
                    return (<StepperTabComponentFirstMeet projData={projData} value={0} isPopup isHover onClickStep={null} />);
                }
                else{
                   return (<StepperTabComponentStart projData={projData} value={0} isPopup isHover onClickStep={null}/>); 
                }
                
        }
    }

    return (
        <div style={{ display: "flex", marginLeft: "10px", marginTop: "-18px", }}>
            <span aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{
                    cursor: "pointer"
                }}>
                    <IboTableStepperStatusIcon data={projData} />
            </span>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{paddingX: '10px'}}>
                    {displayStepper()}
                </Box>
                
            </Popover>
        </div>
    );
}
