import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import CommentBankRoundedIcon from '@mui/icons-material/CommentBankRounded';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import CommentLog from './CommentLog'
import { useGetInternalComments } from '../../hooks/commentData/useGetInternalComments';
import { BootstrapDialog, BootstrapDialogTitle } from '../../helper/bootstrapDialog';
import { Box, Divider, IconButton, MenuItem, Select, Stack, Switch, Tooltip, Typography } from '@mui/material';

export default function CommentDialogLog({ projID, projData }) {
    const [open, setOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState('ascending');
    const [checked, setChecked] = useState(true);
    const { comments } = useGetInternalComments(projID, null, null, "created")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        if (sortOrder === 'descending') {
            setChecked(true);
            setSortOrder('ascending')
        }
        else {
            setChecked(false);
            setSortOrder('descending')
        }
    }
    return (
        <div>
            <Tooltip title={'Internal Comments'} arrow>
            <IconButton size='small' onClick={handleClickOpen}>
                {comments.length > 0 ? (
                    <CommentIcon className="add_miro comment_icon_internal not_empty" fontSize="small" />
                ) : (
                    <CommentOutlinedIcon className="add_miro comment_icon_internal" fontSize="small" />
                )}
                </IconButton>
            </Tooltip>


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ marginTop: 8, marginBottom: 4, marginLeft: 15, }}>
                        <Typography sx={{ maxWidth: '600px' }} fontSize={'16px !important'}>Comment log <strong>(Internal)</strong> for IBO: {projData.value}</Typography>
                        <Box sx={{ paddingRight: '50px' }}>
                            <Stack direction={'row'} alignItems="center">
                                <span>
                                    Desc
                                </span>
                                <Switch checked={checked} onClick={handleChange} />
                                <span>
                                    Asc
                                </span>
                            </Stack>
                        </Box>
                    </Stack>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent dividers>
                    <CommentLog projID={projID} comments={comments} isExternal={false} sortOrder={sortOrder} />
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
