import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { db } from '../../firebase';
import { deleteTemplate } from '../../firebase/cloudFunctions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

//import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useConfirm } from 'material-ui-confirm';
import { ReactComponent as AssembledBricks } from '../../assets/images/krinova__AssembledBricks_line3_colour1.svg';
import firebaseRefList from '../../firebase/firebaseRefList'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';

//const deleteTemplate = firebase.functions().httpsCallable('deleteTemplate');

export default function ListOfTemplates() {
    const [loading, setLoading] = useState(true)
    const [templates, setTemplates] = useState([])
    const navigate = useNavigate()

    const confirmDelete = useConfirm();

    const handleDeleteTemplate = async (template) => {

        confirmDelete({ title: 'Please confirm', description: 'Are you sure you want to delete template ' + template.name + '?' })
            .then(() => {
                setLoading(true)
                deleteTemplate({
                    templateID: template.key,
                })
            })
            .then(() => {
                //set `isLoading` state to false, so the spinner is not rendered anymore
                setLoading(false);
            })
            .catch(() => {
                //set `error` state to true
                return

            });
    }



    useEffect(() => {

        const subscriber = () => {
            const ref = onSnapshot(query(collection(db, "QuestionTemplates"), orderBy('createdAt', 'desc')), (querySnapshot) => {
                const getTemplatesFromFirebase = [];
                querySnapshot.forEach((doc) => {
                    getTemplatesFromFirebase.push({
                        ...doc.data(), //spread operator
                        key: doc.id, // `id` given to us by Firebase 
                    })

                })
                setTemplates(getTemplatesFromFirebase)
                setLoading(false);
            });

            firebaseRefList.push(ref);
        }
        subscriber()
        // return cleanup function
        // return () => subscriber();
    }, [loading]); // empty dependencies array => useEffect only called once

    // call the cloud function
    const editTemplate = (template) => {
        //console.log(template.key + " - " + template.name)
        navigate("/template-manager", { state: { ID: template.key, groupName: template.name, groupCategory: template.category } })
    }

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
    }
    return (
        <>
            <div className="container">
                <h6>All groups</h6>
                <ul className="all_textfield_qustions">

                    {templates.length > 0 ? (
                        templates.map((template) => {

                            return (
                                <Card className="all_templates_card" variant="outlined" key={template.key} sx={{
                                    maxWidth: 600, marginBottom: '1rem', paddingBottom: '0rem', backgroundColor: "rgba(78, 172, 193, 0.3)",
                                }}>
                                    <CardContent>
                                        <AssembledBricks style={{ width: "24px", height: "fit-content", marginRight: "10px" }} />
                                        <button className="transparent_button" onClick={() => editTemplate(template)}><strong >{parse(template.name)}</strong> </button>

                                        <HighlightOffOutlinedIcon fontSize="small"
                                            style={{
                                                cursor: "pointer",
                                                marginTop: "3px"
                                            }} onClick={() => {
                                                handleDeleteTemplate(template)
                                            }} />
                                        <div className="group-category"> {parse(template.category)}</div>

                                    </CardContent>
                                </Card>
                            )

                        }
                        )
                    ) : (
                        <h3>No template added</h3>
                    )}
                </ul>
            </div>
        </>
    )
}

