import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList';
import { useGetUserNames } from '../userData/useGetUserNames';

export const useGetInternalComments = (projectID, location = null, questionKey = null, orderByVal = null, ascending = true) => {
    const [usernames] = useGetUserNames();

    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const order = () => {
        if (ascending === true){
            return 'asc'
        }
        else{
            return 'desc'
        }
    }

    function GetNames(userId) {
        let username;
        usernames.forEach((userName) => {
            if (userId === userName.id) {
                username = userName.value;
            }
        })
        return username
    }

    useEffect(() => {
        if (projectID == null || usernames == null || usernames.length === 0) return;

        let fsCollection = collection(db, "Projects/" + projectID + "/InternalNotes");
        let fsQuery;

        const getCommentsAssigned = (query) => {
            const ref = onSnapshot(query, (snapshot) => {
                let arr = [];
                snapshot.forEach((note) => {
                    let nameUser = GetNames(note.data().createdBy)
                    arr.push({ id: note.id, createdUserName: nameUser, ...note.data() })
                })
                setComments(arr)

            });

            firebaseRefList.push(ref);
            setIsLoading(false);
        }

        if (location != null && questionKey != null) {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("location", "==", location), where("assignedID", "==", questionKey), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("location", "==", location), where("assignedID", "==", questionKey))
            }

        }
        else if (location != null) {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, where("location", "==", location), orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection, where("location", "==", location))
            }
        }
        else {
            if (orderByVal != null) {
                fsQuery = query(fsCollection, orderBy(orderByVal, order()));
            }
            else {
                fsQuery = query(fsCollection)
            }
            fsQuery = query(fsCollection)
        }

        getCommentsAssigned(fsQuery);

    }, [usernames, projectID, location, orderByVal, ascending, questionKey])

    return { comments, isLoading };
};