import { useEffect, useState } from "react"
import Grading from "../Grading/Grading"
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Divider, FormControlLabel, Stack, Switch } from "@mui/material";

import { TrackLabels } from "../../constants/labels/labels";
import { BDInfoBox } from "../HomeIBO/components/BDInfoBox";
import { StepperTabComponentStart } from "../Stepper/StepperTabs/StepperTabComponentStart";
import { StepperTabComponentIncubator } from "../Stepper/StepperTabs/StepperTabComponentIncubator";
import { StepperTabComponentScale } from "../Stepper/StepperTabs/StepperTabComponentScale";
import { MatrixGrading } from "../MatrixGrading/MatrixGrading";


export const MatrixStart = () => {
    const [useNew, setUseNew] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { currentUser } = useAuth();
    const [projectData, setProjectData] = useState(Object)

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser != null) {
            if (currentUser.role === 2 || currentUser.role === 3) {
                if (location.state != null && location.state.projectData != null) {
                    setProjectData(location.state.projectData);
                    setIsLoading(false);
                }
                else {
                    navigate("/home-bd", { replace: true });
                }
            }
            else {
                navigate("/home", { replace: true });
            }
        }
    }, [projectData]);


    /**
     * 
     * @param {('start'|'incubator'|'scale')} track 
     */
    const handleOpenTrack = (track) => {
        navigate("/home#step1", { state: { projectData: projectData, track: track } });
    }

    /**
 * 
 * @param {String} href 
 */
    function onClickStep(href) {
        if (href.startsWith('#')) {
            navigate('/home' + href, { state: { projectData: projectData } })
        }
        else {
            navigate(href, { state: { projectData: projectData } })
        }
    }

    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }

    const displayStepper = () => {
        switch (projectData.activeTrack) {
            case TrackLabels.start.key:
                return (<StepperTabComponentStart projData={projectData} value={0} onClickStep={onClickStep} />);
            case TrackLabels.incubator.key:
                return (<StepperTabComponentIncubator projData={projectData} value={0} onClickStep={onClickStep} />)
            case TrackLabels.scale.key:
                return (<StepperTabComponentScale projData={projectData} value={0} onClickStep={onClickStep} />)
            default:
                return (<StepperTabComponentStart projData={projectData} value={0} onClickStep={onClickStep} />);
        }
    }


    return (
        <div style={{ width: '100%' }}>
            {displayStepper()}
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Box className='bd-info-box'>
                    <BDInfoBox projectData={projectData} />
                </Box>
            </Stack>

            <Divider sx={{ margin: "5px 0px", paddingTop: '40px' }}>Grading Matrix</Divider>

            <Box>
                
                <Box display={'flex'} justifyContent={'right'}>
                    <FormControlLabel disabled control={<Switch size="small" checked={useNew} onChange={() => setUseNew(!useNew)} />} label={<Box sx={{ fontSize: '0.8rem' }}>Matrix 2.0</Box>} />
                </Box>
                {useNew ? (
                    <MatrixGrading project={projectData} />
                ) : (
                    <Grading projectData={projectData} />
                )}
                
            </Box>

        </div >
    )
}