import { Card, CardContent } from "@mui/material"
import Explanation from "../../../Questions/Explanation"


export const TemplateEmbeddedLinkCard = ({ assignedQuestion }) => {
    return (
        <Card className="characters" key={assignedQuestion.key} sx={{ maxWidth: 530, marginBottom: '0rem', paddingBottom: '0rem' }}>
            <CardContent>
                {assignedQuestion.questionExplanation || assignedQuestion.explanation !== "" || null ? (
                    <Explanation key={assignedQuestion.key} data={assignedQuestion} />
                ) : ('')}
                <iframe width="460" height="215" src={assignedQuestion.text} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </CardContent>
        </Card>
    )
}