import { Fragment } from "react"
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

import { ReactComponent as SingleBrick } from '../../../assets/images/krinova__SingleBrick_line3_colour1.svg';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useGetCategories } from "../../../hooks/questionData/useGetCategories";

export const TemplateAvailableContentHeader = ({ columnId, sortList, sortListByType }) => {
    const { questionCategories } = useGetCategories();

    const handleChangeCategory = (event) => {
        sortList(event.target.value);
    };

    const handleChangeType = (event) => {
        sortListByType(event.target.value);
    };

    return (
        <Fragment>
            <div className="header-available-content"><SingleBrick style={{ width: "40px", height: "fit-content", marginRight: "10px" }} />
                <span><strong>Available content</strong></span>
            </div>
            <Box sx={{ width: 400 }}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <FormControl fullWidth size="small">
                            <InputLabel>
                                <FilterAltIcon fontSize="small" />Tag
                            </InputLabel>
                            <Select
                                label="Tag"
                                onChange={handleChangeCategory}
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                {questionCategories.map((category) => {
                                    return (
                                        <MenuItem key={columnId + "-" + category} value={category}>{category}</MenuItem>)
                                })}

                            </Select>

                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label"><FilterAltIcon />Type</InputLabel>
                            <Select
                                label="Type"
                                onChange={handleChangeType}
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                <MenuItem value={"Checked"}>Check box</MenuItem>
                                <MenuItem value={"EmbededLinkInput"}>Video</MenuItem>
                                <MenuItem value={"RadioButton"}>Radio</MenuItem>
                                <MenuItem value={"RichTextInput"}>Text (non-question)</MenuItem>
                                <MenuItem value={"Slider"}>Slider</MenuItem>
                                <MenuItem value={"TextInput"}>Question (text-answer)</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )
}