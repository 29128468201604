import { collection, onSnapshot } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList'

export const useGetUserNames = () => {
    const [usernames, setUserNames] = useState([]);

    useEffect(() => {
        const ref = onSnapshot(collection(db, "UsersPublic"), (querySnapshot) => {
            let arr = [];
            querySnapshot.forEach((doc) => {
                let fullUserName = doc.data().firstName + " " + doc.data().lastName
                arr.push({ id: doc.id, value: fullUserName, role: doc.data().role });
            });

            setUserNames(arr)
        });

        firebaseRefList.push(ref);

        return () => {setUserNames([])}
    }, []);
    
    return [usernames];
};