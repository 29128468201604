import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import {
    useNavigate
} from "react-router-dom";
import { db } from '../../firebase';
import { createTemplate } from '../../firebase/cloudFunctions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { doc, getDoc } from 'firebase/firestore';


//const createTemplate = functions.httpsCallable('createTemplate');


const AddNewTemplate = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);


    useEffect(() => {
        getDoc(doc(db, 'Category/group')).then((querySnapshot) => {
            setCategories(querySnapshot.data().category)
        });

    }, []);

    const [newContentGroup, setNewContentGroup] = useState({

        contentGroupName: {
            value: '',
            elementConfig: {
                required: true,
                id: 'standard-basic new-content-group',
                label: 'Title',
                multiline: false,
                rows: 2,
                variant: 'standard',
            },
            validation: {
                required: true,
                errorMessage: 'Please name your group',
            },
            valid: false,
            blur: false,
        },
    });

    //convert the `contactForm` object into an array
    const formElementsArray = [];
    for (let key in newContentGroup) {
        formElementsArray.push({
            id: key,
            ...newContentGroup[key],
        });
    }

    //map the array to return an array of JSX elements
    const formElements = formElementsArray.map((element) => {
        return (
            <Box key={element.id}>
                <TextField
                    {...element.elementConfig}
                    error={!element.valid && element.blur}

                    onChange={(event) => inputChangedHandler(event, element.id)}
                    onBlur={(event) => inputChangedHandler(event, element.id)}
                    value={element.value}
                ></TextField>
            </Box>
        );
    });

    //this function runs when an input changes or is blurred
    const inputChangedHandler = (event, inputIdentifier) => {
        //create a new object representing the input that was changed or blurred
        const updatedFormElement = {
            ...newContentGroup[inputIdentifier],
            value: event.target.value,
            valid: checkValidity(
                event.target.value,
                newContentGroup[inputIdentifier].validation
            ),
            blur: event.type === 'blur' ? true : false,
            touched: true,
        };

        //create a new object represeting the whole form object
        const updateFreeTextForm = {
            ...newContentGroup,
            [inputIdentifier]: updatedFormElement,
        };

        //the whole form is valid until it's not
        let formIsValid = true;
        for (let inputElementIdentifier in updateFreeTextForm) {
            formIsValid =
                updateFreeTextForm[inputElementIdentifier].valid && formIsValid;
        }

        //update `contactForm` state
        setNewContentGroup(updateFreeTextForm);

        //update `formIsValid` state
        setFormIsValid(formIsValid);
    };

    //this function is called from inside `inputChangedHandler()`, and checks the validity of an input field
    const checkValidity = (value, rules) => {
        //it's always true until there's one false in the way
        let isValid = true;
        if (rules.required) {
            //value.trim() gets rid of white spaces
            isValid = value.trim() !== '' && isValid;
        }

        return isValid;
    };


    const handleChange = (event) => {
        setCategory(event.target.value);
    };

    //console.log('category: ' + category);

    // call the cloud function
    const handleCreateTemplate = () => {

        setIsLoading(true);

        createTemplate({
            name: newContentGroup.contentGroupName.value,
            category: category,
            questions: null,

        }).then((data) => {
            //console.log('data: ' + data.data)

            navigate("/template-manager", { state: { ID: data.data, groupName: newContentGroup.contentGroupName.value, groupCategory: category } })

        })
            .catch((error) => {
                setIsLoading(false);
                console.log('failed: ' + error)
            });
    }


    return (
        <div className="new-template-column">
            <>
                <Box sx={{
                    textAlign: 'center',
                    marginTop: '40px'
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShow(prev => !prev)}
                    >Add new group</Button>

                    {show && <Box className="new-group">
                        {formElements}
                        <FormControl style={{ minWidth: 120, maxWidth: 350, marginTop: 30 }} size="small">
                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={category}
                                label="Category"
                                onChange={handleChange}
                            >
                                {categories.map((category) => {
                                    return (
                                        <MenuItem value={category}>{category}</MenuItem>)
                                })
                                }

                            </Select>

                        </FormControl>

                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                        ) : (
                            <>
                                <Button sx={{
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: 5,
                                }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreateTemplate}
                                    disabled={!formIsValid}>Create</Button>
                            </>
                        )}

                    </Box>}
                </Box>

            </>
        </div>
    )
}

export default AddNewTemplate
