import { useState, useRef, } from 'react';
import '../../assets/scss/app.scss'
import { createQuestion } from '../../firebase/cloudFunctions';
import { Alert, TextField, Grid, Button, Box, CircularProgress, Snackbar } from '@mui/material';
import JoditEditor from "jodit-react";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import './css/TextFieldCreator.css'
import InfoIcon from '@mui/icons-material/Info';


function LinkComponent(props) {
    //define `error` state and the function to change it. The value is false by default
    const [error, setError] = useState(false);

    //define `openSnackbar` state and the function to change it. The value is false by default
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);

    //define `formIsValid` state and the function to change it. The value is false by default
    const [formIsValid, setFormIsValid] = useState(false);

    const [show, setShow] = useState(false);
    const moreInfoQuestionEditor = useRef(null);

    const config2 = {
        readonly: false,
        placeholder: 'Compose your additional information here..',
        language: "en",
        showXPathInStatusbar: false,
        spellcheck: true,
        controls: {
            font: {
                list: {
                    'Montserrat, serif': 'Montserrat'
                }
            }
        }
    };

    const [moreInfoQuestion, setMoreInfoQuestion] = useState("");


    //define `contacForm` state and the function to change it
    //the whole form is represented by an object, with nested objects inside that represent the input fields
    const [freeTextForm, setFreeTextForm] = useState({

        link: {
            value: '',
            elementConfig: {
                required: true,
                id: 'standard-basic question-field',
                label: 'Link',
                variant: 'standard',
                className: 'input_textquestion',
                helperText: 'NOTE! It is the embedded-specific link to a YouTube video that needs to be entered. Replace the "watch?v="-part of your normal YouTube-link with "embed/".',
            },
            validation: {
                required: true,
                errorMessage: 'Please fill in a miro link',
            },
            valid: false,
            blur: false,
        },
    });

    //convert the `contactForm` object into an array
    const formElementsArray = [];
    for (let key in freeTextForm) {
        formElementsArray.push({
            id: key,
            ...freeTextForm[key],
        });
    }

    //map the array to return an array of JSX elements
    const formElements = formElementsArray.map((element) => {
        return (
            <Box key={element.id}>
                <TextField
                    {...element.elementConfig}
                    error={!element.valid && element.blur}

                    onChange={(event) => inputChangedHandler(event, element.id)}
                    onBlur={(event) => inputChangedHandler(event, element.id)}
                    value={element.value}
                ></TextField>
            </Box>
        );
    });

    //this function runs when an input changes or is blurred
    const inputChangedHandler = (event, inputIdentifier) => {
        //create a new object representing the input that was changed or blurred
        const updatedFormElement = {
            ...freeTextForm[inputIdentifier],
            value: event.target.value,
            valid: checkValidity(
                event.target.value,
                freeTextForm[inputIdentifier].validation
            ),
            blur: event.type === 'blur' ? true : false,
            touched: true,
        };

        //create a new object represeting the whole form object
        const updateFreeTextForm = {
            ...freeTextForm,
            [inputIdentifier]: updatedFormElement,
        };

        //the whole form is valid until it's not
        let formIsValid = true;
        for (let inputElementIdentifier in updateFreeTextForm) {
            formIsValid =
                updateFreeTextForm[inputElementIdentifier].valid && formIsValid;
        }

        //update `contactForm` state
        setFreeTextForm(updateFreeTextForm);

        //update `formIsValid` state
        setFormIsValid(formIsValid);
    };

    //this function is called from inside `inputChangedHandler()`, and checks the validity of an input field
    const checkValidity = (value, rules) => {
        //it's always true until there's one false in the way
        let isValid = true;
        if (rules.required) {
            //value.trim() gets rid of white spaces
            isValid = value.trim() !== '' && isValid;
        }

        return isValid;
    };

    //this function is called when the user closes the snackbar after getting an error (when the cloud function fails)
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };


    //this function is called when clicking the `Send` button
    const submitForm = async () => {
        //set `isLoading` state to true, so the spinner is rendered
        setIsLoading(true);

        let dataSnap;
        if (props.perk === true) {
            dataSnap = {
                type: 'EmbededLinkInput',
                text: freeTextForm.link.value,
                questionExplanation: moreInfoQuestion,
                options: null,
                questionIcon: null,
                questionShortDesc: props.shortDesc,
                labelPlacement: null,
                category: props.tag,
                perks: "Infinity",
            }
        }
        else {
            dataSnap = {
                type: 'EmbededLinkInput',
                text: freeTextForm.link.value,
                questionExplanation: moreInfoQuestion,
                options: null,
                questionIcon: null,
                questionShortDesc: props.shortDesc,
                labelPlacement: null,
                category: props.tag
            }
        }

        // call the cloud function. 
        createQuestion(dataSnap)
            //the code inside the `then()` block runs when the message was successfully sent from inside the cloud function
            .then((data) => {
                // console.log('data ' + data.data)

                //data.data

                //create a new `contactForm` object that looks like the original `contactForm` state
                let originalFreeTextForm = {};
                for (let key in freeTextForm) {
                    originalFreeTextForm[key] = {
                        ...freeTextForm[key],
                        value: '',
                        valid: false,
                        blur: false,
                    };
                }


                //reset `contactForm` state to its original value
                setFreeTextForm(originalFreeTextForm);

                //reset the whole form validity to false
                setFormIsValid(false);

                //set `isLoading` state to false, so the spinner is not rendered anymore
                setIsLoading(false);
                setMoreInfoQuestion("");

                //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                setOpenSnackbar(true);
            })
            //this code below runs when the message was NOT successfully sent from inside of the cloud function
            .catch(() => {

                //set `error` state to true
                setError(true);

                //set `isLoading` state to false, so the spinner is not rendered anymore
                setIsLoading(false);

                //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                setOpenSnackbar(true);
            });

    };

    // console.log('question ' + freeTextForm.question.value + ' description ' + freeTextForm.description.value)
    const resetFields = () => {
        setMoreInfoQuestion("");
        let originalFreeTextForm = {};
        for (let key in freeTextForm) {
            originalFreeTextForm[key] = {
                ...freeTextForm[key],
                value: '',
                valid: false,
                blur: false,
            };
        }


        //reset `contactForm` state to its original value
        setFreeTextForm(originalFreeTextForm);

    };

    //return what's rendered to the virtual DOM
    return (
        <Box mt="3rem" mb="3rem">
            <Grid container justifyContent="center">
                <Box>
                    <Grid container alignItems="center" direction="column">
                        <form>
                            <p align="center">Enter embedded link</p>
                            {formElements}
                            <div style={{ marginTop: 20 }}>
                                {!show ? (<><ControlPointIcon style={{
                                    fontSize: 17,
                                }}
                                    onClick={() => setShow(prev => !prev)}
                                />  <span style={{ verticalAlign: 'base-line' }}><strong>"More info"-element</strong></span><InfoIcon style={{
                                    color: "gray",
                                    fontSize: 17,
                                    marginLeft: 5,
                                }} /></>) : (<><DoDisturbOnOutlinedIcon style={{
                                    fontSize: 17,
                                }} onClick={() => setShow(prev => !prev)} /> <span style={{ verticalAlign: 'base-line' }}><strong>"More info"-element</strong></span><InfoIcon style={{
                                    color: "gray",
                                    fontSize: 16,
                                    marginLeft: 5,
                                }} /></>)}</div>

                            {show && <Box className="new-group">
                                <JoditEditor
                                    ref={moreInfoQuestionEditor}
                                    value={moreInfoQuestion}
                                    config={config2}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newInfoContent) => setMoreInfoQuestion(newInfoContent)}
                                    onChange={(newInfoContent) => { }}

                                />

                            </Box>}
                            <Grid container justifyContent="center">
                                <Box mt="2rem">
                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <div>
                                            <Button
                                                //size="medium"
                                                variant="contained"
                                                //disabled={!formIsValid}
                                                //color="primary"
                                                onClick={resetFields} style={{ marginRight: 20 }}>Reset</Button>
                                            <Button
                                                onClick={submitForm}
                                                disabled={!formIsValid}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    )}
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </Grid>
            {error ? (
                <Snackbar
                    open={openSnackbar}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={closeSnackbar} severity="error">
                        Something went wrong!
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Saved!!</Alert>
                </Snackbar>
            )}
        </Box>
    );
}

export default LinkComponent;
