import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import firebaseRefList from '../../firebase/firebaseRefList';

const useGetUserData = () => {
    const { currentUser } = useAuth();
    const [userData, setUserData] = useState(Object);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getUserData = async () => {
            if (currentUser != null) {
                const ref = onSnapshot(doc(db, "Users/" + currentUser.uid), (snapshot) => {
                    const data = snapshot.data()
                    setUserData(data);
                    setLoading(false);
                });

                firebaseRefList.push(ref);
                /*
                const userData = await getDoc(doc(db, "Users/" + currentUser.uid))
                if (userData.exists()) {
                    const data = userData.data()
                    setUserData(data)
                }*/
            }
            else{
               setLoading(false) 
            }
            
        }

        getUserData()

        return () => {setUserData({})}

    }, [currentUser]);

    return { userData, loading };
};

export default useGetUserData;