import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'
import { doc, getDoc } from 'firebase/firestore';


export const useGetUserProjectId = () => {
    const [projectIDs, setProjectIDs] = useState([]);
    const [loadingProjID, setLoadingProjID] = useState(true);
    const { currentUser } = useAuth();

    // console.log('current User', currentUser.uid)

    useEffect(() => {
        const getProjectId = async () => {
            setLoadingProjID(true);
            const doc1 = await getDoc(doc(db, 'UserProject/' + currentUser.uid));

            if (doc1.exists()) {
                const data = doc1.data()
                setProjectIDs(data.projects)
                // console.log("Document data: projects", data.projects);
            } else {
                // doc.data() will be undefined in this case
                //console.log("No such document!");
            }
            setLoadingProjID(false);
        }

        getProjectId()

    }, [currentUser.uid]);
    
    return {projectIDs, loadingProjID};
};