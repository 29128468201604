import CircularProgress from '@mui/material/CircularProgress'
import useGetUserData from '../../hooks/userData/useGetUserData';
import { useAuth } from '../../contexts/AuthContext'
import Box from '@mui/material/Box';
//import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Grid from '@mui/material/Grid';
//import Typography from '@mui/material/Typography';
import Grading from '../Grading/Grading';
import CommentLogExternalIBO from '../Comments/CommentLogExternalIBO';
import { IntroductionComponent } from './components/Introduction';
import { SettingsIBOComponent } from './components/Settings';
import { OtherTracks } from './components/OtherTracks';

const HomeIbo = ({ projectData, track, isPopup = false }) => {
    const { currentUser } = useAuth();
    const { loading } = useGetUserData();

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }

    return (
        <div>
            {currentUser.role >= 2 ? (
                <>
                    <div style={{ width: '100%' }}>
                        <div>
                            <Grading projectData={projectData} />
                        </div>
                    </div>
                </>
            ) : (
                <Box>
                    <Grid container spacing={3}>
                        <Grid item sm={12} md={12} lg={6}>
                            <IntroductionComponent track={track} />
                        </Grid>
                        <Grid item sm={12} md={12} lg={6} xl={6}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={6} lg={12} xl={12}>
                                    <SettingsIBOComponent project={projectData} />
                                </Grid>
                                <Grid item sm={12} md={6} lg={12} xl={12}>
                                    <CommentLogExternalIBO project={projectData} />
                                </Grid>
                                {isPopup === false ? (
                                    <Grid item sm={12} md={6} lg={12} xl={12}>
                                        <OtherTracks project={projectData} />
                                    </Grid>
                                ) : ("")}

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    )
}

export default HomeIbo
