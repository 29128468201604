import { Card, CardContent, Checkbox, FormControlLabel } from "@mui/material"
import parse from 'html-react-parser';
import { useAuth } from "../../../../contexts/AuthContext";


export const OptionsCard = ({ assignedQuestion, question, handleOptionChange }) => {
    const { currentUser } = useAuth();

    return (
        <Card className="characters" key={question.key} sx={{ maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem', marginLeft: '-15px' }}>
            <CardContent>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={assignedQuestion.answer || false}
                            onChange={handleOptionChange}
                            name={assignedQuestion.key}
                            //name="checked"
                            color="primary"
                            disabled={currentUser.role >= 2}
                        />
                    }
                    labelPlacement={question.labelPlacement}
                    label={parse(question.text)} />
            </CardContent>
        </Card>
    )
}