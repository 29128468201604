export const SIGN_UP_USERINFO_LABELS = {
    phone: "Phone number",
    region: "Region",
    county: "Municipality",
    country: "Country",
    city: "City",
    language: "Prefered language",
    dob: "Date of Birth",
    gender: "Gender",
    education: "Education (highest)",
    hkr: "Are you a student at or employed by Kristianstad University?",
    reference: "How did you hear of Krinova?",
    expectations: "What are your expectations on the meeting with Krinova?",

}

export const REFERENCE_LABELS = {
    advertising: 'Via advertising on social media',
    corporate: 'Via corporate events',
    recommendation: 'Via recommendation',
    university: 'Via the university',
    project: 'Via a project that Krivnova is involved in',
    other: 'Other',
}

export const QUESTION_INFO = {
    ideas: "1. What need does your business idea meet? How did you discover that need?",
    already: "2. Describe your service / product / idea?",
    unique: "3. In what way is your service / product / idea better than those on the market today?",
    motivate: "4. In what way does your project contribute to the sustainable development of society?",
    globalGoals: "4b. Which of the Global goals for sustainable development does your idea contribute to, if any?",
    customers: "5. Describe the target group for your product / service, who is your customer?",
    demand: "6. Have you tested your business idea?",
    competitors: "7. If you launched your product / service today, who would be your three biggest competitors?",
    workingInGroup: "8. Are you developing this idea alone or together with others?",
}

export const QUESTION_INFO_TEAM = {
    aloneORteam: "1. In what way does the teams's knowledge and experience contribute to the project?",
    competences: "2. Describe one or more future team members who could contribute to the development of the project?",
    timeANDresources: "3. How much time (in total for the whole team) can you spend on the project each week? For example: 10 hours a week.",
    challenge: "4. What are you working on right now?",
    nextStep: "5. What is the next step in the project?"
}

export const GLOBAL_GOALS_FIELDS = {
    poverty: "1 - No poverty",
    hunger: "2 - Zero hunger",
    goodHealth: "3 - Good health and well-being",
    education: "4 - Quality education",
    equality: "5 - Gender equality",
    sanitation: "6 - Clean water and sanitation",
    energy: "7 - Affordable and clean energy",
    economicGrowth: "8 - Decent work and economic growth",
    industry: "9 - Industry, innovation and infrastructure",
    inequalities: "10 - Reduced inequalities",
    cities: "11 - Sustainable cities and communities",
    consumption: "12 - Responsible consumption and production",
    climate: "13 - Climate action",
    lifeWater: "14 - Life below water",
    lifeLand: "15 - Life on land",
    peace: "16 - Peace, justice and strong institutions",
    partnerships: "17 - Partnerships for the goals",
}

export const COMPANY_CHOICES = {
    no: "No/Not yet",
    yes_new: "Yes, in a newly formed company based on the idea",
    yes_established: "Yes, within an established company",
}