import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useGetInternalComments } from '../../hooks/commentData/useGetInternalComments';
import { BootstrapDialog, BootstrapDialogTitle } from '../../helper/bootstrapDialog';
import { Box, Divider, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import CommentLog from './CommentLog';


export default function CommentDialogInternal({ projID, step, projData, questionKey, location }) {
    const [open, setOpen] = useState(false);
    //const [comments, setComments] = useState([])
    //const { currentUser } = useAuth();
    const { comments } = useGetInternalComments(projID, location, questionKey, "created")

    const [sortOrder, setSortOrder] = useState('ascending');
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        if (sortOrder === 'descending') {
            setChecked(true);
            setSortOrder('ascending')
        }
        else {
            setChecked(false);
            setSortOrder('descending')
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title={'Internal Comments'} arrow>
                <IconButton size='small' onClick={handleClickOpen}>
                    {comments.length > 0 ? (
                        <CommentOutlinedIcon className="add_miro comment_icon_internal not_empty" fontSize="small" />
                    ) : (
                        <CommentOutlinedIcon className="add_miro comment_icon_internal" fontSize="small" />
                    )}
                </IconButton>
            </Tooltip>



            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                minWidth='lg'
                maxWidth='xl'
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ marginTop: 8, marginBottom: 4, marginLeft: 15, }}>
                        <Typography sx={{ maxWidth: '600px' }} fontSize={'16px !important'}>Internal comments (question) for IBO {projData.value}:</Typography>
                        <Box sx={{ paddingRight: '50px' }}>
                            <Stack direction={'row'} alignItems="center">
                                <span>
                                    Desc
                                </span>
                                <Switch checked={checked} onClick={handleChange} />
                                <span>
                                    Asc
                                </span>
                            </Stack>
                        </Box>
                    </Stack>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent dividers>
                    <CommentLog projID={projID} isExternal={false} questionKey={questionKey} step={step} location={location} comments={comments} sortOrder={sortOrder} />
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
