import { Box, CircularProgress, Divider, IconButton, Stack, Typography } from "@mui/material"
import { useGetExternalFiles } from "../../hooks/fileData/useGetFiles"
import { Fragment } from "react"
import { canPreviewFile, getStorageBlob } from "../../helper/fileHelpers"

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LaunchIcon from '@mui/icons-material/Launch';



export const FilePublicFileList = ({ projData, step, location }) => {
    const { files, isLoading } = useGetExternalFiles(projData.id, location, null, "uploaded")

    const onDownload = async (file) => {
        const link = document.createElement("a");

        link.download = file.fileName;
        if (file.blob == null) {
            await getStorageBlob(file);
        }
        link.href = URL.createObjectURL(file.blob);
        link.click();
    };

    const onOpenLink = async (file) => {
        //console.log("File: ", file)
        if (file.blob == null) {
            await getStorageBlob(file);
        }

        const newWindow = window.open()
        newWindow.document.title = file.fileName;
        newWindow.document.write(`<iframe src="${URL.createObjectURL(file.blob)}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
    };

    return (
        <Box className="link-files-parent">
            <Box className="link-files" style={{ padding: '10px 5px', minHeight: '80px' }}>
                <Box style={{ fontSize: '14px', marginLeft: '13px' }}><strong>Resources/files</strong></Box>
                <Divider />
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '5px' }}>
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <Fragment>
                        {files.filter(file => file.isIBO !== true).map((file) => {
                            return (
                                <Fragment>
                                    <Stack direction={'row'} justifyContent={"space-between"} alignItems={"center"} sx={{ marginTop: '2px', marginBottom: '2px' }}>
                                        <Box className="file-name-public-box">
                                            {file.fileName}
                                        </Box>
                                        <Box>
                                            {canPreviewFile(file) === true ? (
                                                <IconButton onClick={() => { onOpenLink(file) }} size={'small'}>
                                                    <LaunchIcon
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </IconButton>
                                            ) : (
                                                <Box sx={{ minWidth: '33px' }}>

                                                </Box>
                                            )}


                                            <IconButton onClick={() => { onDownload(file) }} size={'small'}>
                                                <FileDownloadIcon
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>
                                    </Stack>
                                    <Divider sx={{borderColor: '#4eacc15c'}} />
                                </Fragment>
                            )
                        })}
                    </Fragment>
                )}
            </Box>
        </Box>

    )
}