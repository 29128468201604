import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"


const UserTerms = ({ open, handleClose, handleCloseAgree, handleCloseDisagree }) => {
    return (
        <Dialog
            open={open}
            onClose={handleCloseDisagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Terms"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Krinovas uppdrag är att bidra till fler och mer innovativa företag i regionen. På innovationsarenan finns ett team av erfarna business designer redo att erbjuda innovations- och utvecklingsstöd till start-ups och dig som vill starta företag men också till befintliga företag för tillväxt och acceleration.
                    <br></br><br></br>

                    Stödet innovationsarenan erbjuder är kostnadsfritt men kräver redovisning av vissa företagsuppgifter som enkel resultatuppföljning av Krinovas tjänster. (Detta innebär cirka en webbenkät per år).
                    <br></br><br></br>
                    De av företaget lämnade person- och företagsuppgifter som redovisas till Krinova kommer att hanteras enligt Lag (2018:558) om företagshemligheter samt Europaparlamentets och rådets förordning (EU) nr 2016/679, förkortat GDPR
                    Krinova AB skulle vilja att du lämnar vissa uppgifter om dig själv till vår verksamhet. Dina personuppgifter behandlas och sparas av oss för att vi ska kunna fullgöra vårt åtagande, ta kontakt eller ge den information som efterfrågas. De personuppgifter som kan komma att behandlas är främst sådan som du direkt eller indirekt lämnar till oss när du tar en kontakt med oss.
                    <br></br><br></br>
                    De uppgifter vi avser att samla in och behandla är namn, telefonnummer och e-post. Du avgör själv om du vill lämna några uppgifter till oss. Uppgifterna kommer endast att behandlas av oss, och i de fall vi är beroende av annan part för att genomföra leverans kommer de att kunna behandla dessa. Vi avser att behandla uppgifterna så länge du är kund eller önskar att ta del av vår information eller erbjudanden.
                    <br></br><br></br>
                    Du har enligt 26 § personuppgiftslagen (1998:204) rätt att gratis, en gång per kalenderår, efter skriftligt undertecknad ansökan ställd till oss, få besked om vilka personuppgifter om dig som vi behandlar och hur vi behandlar dessa. Du har också rätt att enligt 28 § personuppgiftslagen begära rättelse i fråga om personuppgifter som vi behandlar om dig. Du har också alltid rätt att skriftligen återkalla lämnade samtycken. Anser du att dina personuppgifter har behandlats felaktig har du möjlighet att lämna klagomål till tillsynsmyndigheten (Datainspektionen).
                    <br></br>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    Krinova's mission is to contribute to more and more innovative companies in the region. In the innovation arena, there is a team of experienced business designers ready to offer innovation and development support to start-ups and you who want to start a company but also to existing companies for growth and acceleration.
                    <br></br><br></br>
                    The support the innovation arena offers is free of charge but requires reporting of certain company information as a simple follow-up of results of Krinova's services. (This means about one web survey per year).
                    <br></br><br></br>
                    The personal and business information provided by the company that is reported to Krinova will be handled in accordance with the Act (2018: 558) on trade secrets and Regulation (EU) No 2016/679 of the European Parliament and of the Council, abbreviated GDPR.
                    <br></br><br></br>
                    Krinova AB would like you to provide certain information about yourself to our business. Your personal data is processed and stored by us so that we can fulfill our commitment, get in touch or provide the information requested. The personal data that may be processed is mainly such that you directly or indirectly provide to us when you contact us.
                    <br></br><br></br>
                    The information we intend to collect and process is name, telephone number and e-mail. You decide if you want to provide us with any information. The information will only be processed by us, and in cases where we are dependent on another party to carry out delivery, they will be able to process it. We intend to process the information as long as you are a customer or wish to receive our information or offers.
                    <br></br><br></br>
                    Pursuant to section 26 of the Personal Data Act (1998: 204), you have the right to be informed free of charge, once per calendar year, after a written application has been submitted to us, of the personal data about you that we process and how we process them. You also have the right to request correction in accordance with section 28 of the Personal Data Act in respect of personal data that we process about you. You also always have the right to revoke consent given in writing. If you believe that your personal data has been processed incorrectly, you have the opportunity to submit a complaint to the supervisory authority (Datainspektionen).
                    <br></br><br></br>


                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDisagree}>Disagree</Button>
                <Button onClick={handleCloseAgree} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserTerms;