import notFound from '../../assets/images/notFound2.png'
import Box from "@mui/material/Box/Box"
import { Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useEffect } from 'react'

const NotFound = () => {
    const analytics = getAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Not Found',
            page_path: window.location.pathname,
        });
    }, [])
    
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '50px' }}>
                <Box >
                    <p hidden>404 - Not Found</p>
                    <img alt='Not Found' src={notFound} height='100px' />
                </Box>
                <Box>
                    <h5>404 - Not Found</h5>
                </Box>
                <Box>
                    <h6>This page don't exists. Click <Link to={'/'} replace={true} className="open-modal-link"><strong><u>here</u></strong></Link> to go back to start.</h6>
                </Box>
            </Stack>

        </Box>
    )
}

export default NotFound