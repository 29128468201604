import { TableCell } from "@mui/material"


export const DeleteHeaderCell = (selectedTab, role) => {
    return (
        <>
            {selectedTab === "/admin" && role >= 3 ? (
                <TableCell>
                    Delete
                </TableCell>
            ) : ("")}
        </>
    );
}