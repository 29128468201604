import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";


export const useGetGradingTrackConfigurations = (projectID) => {
    const [loading, setLoading] = useState(true);
    const [trackConfigurations, setTrackConfigurations] = useState(Object);

    useEffect(() => {
        if (!projectID) return;

        const ref = onSnapshot(collection(db, "Projects/" + projectID + "/Ratings/ReadinessLevel/TrackConfigurations"), (snapshot) => {
            console.log("Hello?")
            let local = {};
            snapshot.docs.forEach((child) => {
                if (child.exists()){
                    let values = {};
                    if (child.data().startValues != null){
                        values['startValues'] = child.data().startValues;
                    }

                    if (child.data().targetValues != null){
                        values['targetValues'] = child.data().targetValues;
                    }

                    if (child.data().verifyMilestones != null){
                        values['verifyMilestones'] = child.data().verifyMilestones;
                    }

                    local[child.id] = values;
                }
            })
            setTrackConfigurations(local);

            setLoading(false);
        })

        firebaseRefList.push(ref);

    }, [projectID])


    return { loading, trackConfigurations };
}