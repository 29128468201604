import { Autocomplete, Box, Button, DialogActions, DialogContent, Divider, IconButton, InputAdornment, LinearProgress, Stack, TextField, Typography } from "@mui/material"

import { Fragment, useEffect, useState } from "react";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { setNextTimeStep } from "../../firebase/cloudFunctions";
import { BootstrapDialogTitle } from "../../helper/bootstrapDialog";
import { generateTimeslots } from "../../helper/timeslotGenerator";
import dayjs from "dayjs";


export const DatePickerBox = ({ projectData, stepdata, handleClose = null, isNew = false }) => {
    const [loading, setLoading] = useState(false);
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
    const [selectedTimeDate, setSelectedTimeDate] = useState("");

    useEffect(() => {
        if (stepdata?.nextDeadline != null) {
            const dateSplit = String(stepdata?.nextDeadline).split(' ');
            const curDate = new Date(Date.parse(dateSplit[0] + " " + dateSplit[dateSplit.length - 1]));
            setSelectedTime(dateSplit[dateSplit.length - 1]);
            setSelectedDate(dayjs(curDate));
            setSelectedTimeDate(curDate.toUTCString());
        }
    }, [stepdata])

    const handleTimeChange = (newValue) => {
        //console.log(new Date(selectedDate).toDateString() + " " + new Date(newValue.time).toTimeString());
        //console.log("Time: ", newValue)
        if (newValue != null) {
            // @ts-ignore
            setSelectedTime(newValue);
            setSelectedTimeDate(selectedDate.toDate().toDateString() + " " + newValue);
        }
        else {
            setSelectedTime(null)
            setSelectedTimeDate(selectedDate.toDate().toDateString());
        }
    };

    const handleDateChange = (newValue) => {
        //console.log(new Date(newValue).toDateString() + " " + new Date(selectedTime).toTimeString());
        if (newValue != null) {
            setSelectedDate(newValue)
            setSelectedTimeDate(new Date(newValue).toDateString() + " " + selectedTime);
        }
        else {
            setSelectedDate(null)
            setSelectedTimeDate(new Date().toDateString() + " " + selectedTime);
        }
    };

    const updateTime = () => {
        const newDate = new Date(Date.parse(selectedTimeDate));
        //console.log("FinalValue: ", newDate.toUTCString(), projectData?.id, stepdata?.key);

        setLoading(true);

        setNextTimeStep({
            time: newDate.toUTCString(),
            projectID: projectData.id,
            step: stepdata?.key,
        }).then(() => {
            if (handleClose != null) {
                handleClose();
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <Box>
            {handleClose != null ? (
                <Fragment>
                    <BootstrapDialogTitle onClose={handleClose}>
                        <h6 style={{ marginTop: 15, marginLeft: 15, maxWidth: '380px' }}>Update meeting time for {projectData?.value} - <i>{stepdata?.name}</i></h6>
                    </BootstrapDialogTitle>
                    <Divider />
                </Fragment>
            ) : (
                ""
            )}


            <DialogContent dividers={isNew === false}>
                {isNew === false ? (
                    <Fragment>
                        <Box sx={{ paddingBottom: '15px' }}>
                            {stepdata?.nextTime != null ? (
                                <Typography color={'GrayText'}>
                                    Current booked time: <i><strong>{stepdata?.nextDeadline}</strong></i>
                                </Typography>

                            ) : (
                                <Typography color={'GrayText'}>
                                    Current booked time: <i>No time set.</i>
                                </Typography>
                            )}
                        </Box>
                        <Divider />
                    </Fragment>

                ) : ("")}


                <Box sx={{ padding: '15px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Typography sx={{ paddingBottom: '15px', fontSize: '14px !important' }}>
                            Meeting time:
                        </Typography>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <DatePicker
                                label="Date"
                                value={selectedDate}
                                disablePast
                                format={'ddd YYYY-MM-DD'}
                                onChange={handleDateChange}
                                slotProps={{textField: {size: 'small', sx: {width: 200}}}}
                            />

                            <Autocomplete
                                freeSolo
                                size="small"
                                disableClearable
                                value={selectedTime}
                                onChange={(event, newValue) => handleTimeChange(newValue)}
                                options={generateTimeslots().map((option) => option.title)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Time"
                                        sx={{ width: 140 }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <InputAdornment position="end"><IconButton size="small"><AccessTimeRoundedIcon /></IconButton></InputAdornment>,
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Box>
            </DialogContent>
            {handleClose != null ? (
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={updateTime} disabled={loading}>
                        Set time
                    </Button>
                </DialogActions>
            ) : (
                <Fragment>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>
                        {loading ? (
                            <Box sx={{ width: '90px' }}>
                                <LinearProgress color='info' />
                            </Box>
                        ) : (
                            <Box sx={{ marginBottom: "4px" }}>

                            </Box>
                        )}
                    </div>
                    <Stack direction={'row'} justifyContent={'center'} sx={{ marginTop: '5px' }}>
                        <Button variant="contained" autoFocus onClick={updateTime} disabled={loading || selectedTime == null || selectedTime === '' || selectedDate == null || selectedDate === ''}>
                            Set time
                        </Button>
                    </Stack>
                </Fragment>
            )}

        </Box>
    )
}