import { useEffect } from "react"
import { StyledLinearProgressTrack, StyledLinearProgressWarning } from "../Progress/styledLinearProgress"
import { Tooltip } from "@mui/material"


export const TimeReportProgressBar = ({ projData, totalTime, projectSettings, width = '90px', marginRight = '10px', isIneligible = false }) => {

    const calcProgress = () => {
        if (totalTime == null || totalTime === 0) {
            return 0;
        }
        else {
            if (projData?.activeTrack != null && projectSettings?.timePool[projData?.activeTrack] != null) {
                return Math.round((Number(totalTime) / Number(projectSettings?.timePool[projData?.activeTrack])) * 100)
            }
            else {
                return 0;
            }
        }
    }

    return (
        <div style={{ width: width, marginRight: marginRight }}>
            <Tooltip arrow title={totalTime + "h of " + projectSettings?.timePool[projData?.activeTrack] + "h"}>
                {isIneligible ? (
                    <StyledLinearProgressWarning variant="determinate" value={calcProgress()} />
                ) : (
                    <StyledLinearProgressTrack variant="determinate" value={calcProgress()} />
                )}
            </Tooltip>
        </div>
    )
}