import { Card, CardContent, FormControl, FormControlLabel, FormGroup, Radio } from "@mui/material"
import parse from 'html-react-parser';
import { useAuth } from "../../../../contexts/AuthContext";
import { QuestionCommentBox } from "../QuestionCommentBox";


export const RadioButtonCard = ({ projectData, step, location, assignedQuestion, question, handleRadioBtnChange, answerRadio }) => {
    const { currentUser } = useAuth();

    return (
        <Card className="characters" variant="outlined" key={question.key} sx={{ maxWidth: 700, marginBottom: '1rem', paddingBottom: '0rem' }}>
            <CardContent>
                <span style={{ display: 'block', marginTop: 10 }}>{parse(question.text)}</span>
                {assignedQuestion.note != null ? (
                    <p className="note-style"><strong>{assignedQuestion.note}</strong></p>) : ("")}
                <br></br>

                {String(question.options).split(';').map(element => (
                    <div key={assignedQuestion.key + "-" + element}>
                        <FormControl component="fieldset">
                            <FormGroup row={false}>
                                <FormControlLabel
                                    checked={answerRadio[assignedQuestion.key] === element || false}
                                    aria-label="gender"
                                    value={element}
                                    name={assignedQuestion.key}
                                    control={<Radio />}
                                    disabled={currentUser.role >= 2}
                                    labelPlacement={question.labelPlacement}
                                    label={parse(element)}
                                    onChange={handleRadioBtnChange} />
                            </FormGroup>
                        </FormControl>
                    </div>
                ))}

                <QuestionCommentBox projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} />

            </CardContent>
        </Card>
    )
}