import { Fragment, useState } from 'react'
import '../../assets/scss/app.scss'
import { deleteBDUser } from '../../firebase/cloudFunctions';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Alert, Button, Checkbox, Modal, TableFooter, TablePagination } from '@mui/material';
import { useGetBdOwners } from '../../hooks/userData/useGetBdOwners';
//import Comments from './Comments'
import Box from '@mui/material/Box';
import { TableCell } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CreateBD from './CreateBD';
import EditBD from './EditBD';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useConfirm } from 'material-ui-confirm';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

//const deleteBDUser = firebase.app().functions("europe-west1").httpsCallable('deleteBDUser');

const TableBD = () => {
    const { currentUser } = useAuth();
    const bdowners = useGetBdOwners();
    const [error, setError] = useState(false);
    const [orderBy, setOrderBy] = useState("firstName");
    const [order, setOrder] = useState("desc");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [loadingDelete, setLoadingDelete] = useState('')
    const [editUser, setEditUser] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [bdFilter, setBdFilter] = useState('assignedMe');

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value) => {
        setBdFilter(value);
        setAnchorEl(null);
    };



    //define `openSnackbar` state and the function to change it. The value is false by default
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);

    /*
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            fontFamily: ['Montserrat', 'serif'].join(',')
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 1,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        head: {
            fontWeight: 'bold',
        },

    }));*/

    const tableStyle = {
        minWidth: 750,
    }

    const [openModal, setOpenModal] = useState(false);
    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => setOpenModal(false);

    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleModalEditOpen = (user) => {
        setEditUser(user);
        setOpenModalEdit(true);
    }
    const handleModalEditClose = () => setOpenModalEdit(false);

    function closeModal() {
        if (openModal) {
            setOpenModal(false);
        }
    }

    function closeModalEdit() {
        if (openModalEdit) {
            setOpenModalEdit(false);
        }
    }

    const confirmDelete = useConfirm();


    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    const createSortHandler = (property) => (event) => {
        //onRequestSort(event, property);
        const isAsc = orderBy === property && order === 'desc';
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'asc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteUser = async (user) => {
        confirmDelete({ title: 'Please confirm', description: 'Are you sure you want to delete user ' + user.firstName + ' ' + user.lastName + '(' + user.email + ')?\n This cannot be reverted!' })
            .then(() => {

                setLoadingDelete(user.id);
                const index = bdowners.indexOf(user);
                if (index > -1) {
                    bdowners.splice(index, 1);
                }

                deleteBDUser({
                    userID: user.id,
                }).then(() => {
                    setLoadingDelete('');
                }).catch((e) => {
                    setLoadingDelete('');
                });
            })
            .catch(() => {
                //set `error` state to true
                return

            });
    }

    function filterBDs(item) {
        if (bdFilter === "assignedMe") {
            if (item.BDId === currentUser.uid) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (bdFilter === "assigned") {
            if (item.BDId != null) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    function isBDSelectable(user) {
        if (user?.BDSelectable != null) {
            return user?.BDSelectable;
        }
        else {
            return true;
        }
    }


    function isIBOSelectable(user) {
        if (user?.IBOSelectable != null) {
            return user?.IBOSelectable;
        }
        else {
            return true;
        }
    }


    return (
        <Box sx={{ width: '80%', justifyContent: "center" }}>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <>
                    <Table style={tableStyle} aria-labelledby="bdTable" aria-label="BD Table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Delete</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === "firstName"}
                                        direction={orderBy === "firstName" ? order : 'desc'}
                                        onClick={createSortHandler("firstName")}
                                    >
                                        First Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === "lastName"}
                                        direction={orderBy === "lastName" ? order : 'desc'}
                                        onClick={createSortHandler("lastName")}
                                    >
                                        Last Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === "email"}
                                        direction={orderBy === "email" ? order : 'desc'}
                                        onClick={createSortHandler("email")}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    IBO-Selectable
                                </TableCell>
                                <TableCell>
                                    BD-Selectable
                                </TableCell>
                                <TableCell>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bdowners != null ? (
                                <Fragment>
                                    {bdowners.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => {
                                        return (
                                            <TableRow key={user.id}>
                                                <TableCell padding='normal' >
                                                    {loadingDelete !== user.id ? (
                                                        <Button
                                                            className="delbtn-bd"
                                                            onClick={() => handleDeleteUser(user)}
                                                        >
                                                            <HighlightOffOutlinedIcon style={{ fontSize: "20px", }} />
                                                        </Button>
                                                    ) : (
                                                        <div style={{ display: 'flex', justifyContent: 'left' }}><CircularProgress size={"20px"} /></div>
                                                    )}
                                                </TableCell>
                                                <TableCell padding='normal'>
                                                    {user.firstName}
                                                </TableCell>

                                                <TableCell padding='normal'>
                                                    {user.lastName}
                                                </TableCell>
                                                <TableCell padding='normal'>
                                                    {user.email}
                                                </TableCell>
                                                <TableCell padding='normal' align='center'>
                                                    {isIBOSelectable(user) ? (
                                                        <CheckCircleRoundedIcon htmlColor='#4eacc1' />
                                                    ) : ("")}
                                                </TableCell>
                                                <TableCell padding='normal' align='center'>
                                                    {isBDSelectable(user) ? (
                                                        <CheckCircleRoundedIcon htmlColor='#4eacc1' />
                                                    ) : ("")}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        className="edit-bd-table"
                                                        onClick={() => handleModalEditOpen(user)}
                                                    >
                                                        <EditIcon style={{ fontSize: "20px", }} />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </Fragment>
                            ) : ("")}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    labelRowsPerPage=""
                                    page={page}
                                    count={bdowners.length}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage} />
                            </TableRow>

                        </TableFooter>
                    </Table>

                    <div style={{ paddingTop: "20px" }}>
                        <Button
                            variant="contained"
                            onClick={handleModalOpen}
                        >
                            <ControlPointIcon fontSize="small" style={{ marginRight: "8px", }} />
                            Add Business Designer
                        </Button>
                    </div>

                    <Modal
                        open={openModal}
                        onClose={handleModalClose}
                    >
                        <CreateBD onClose={closeModal} />
                    </Modal>

                    <Modal
                        open={openModalEdit}
                        onClose={handleModalEditClose}
                    >
                        <EditBD user={editUser} onClose={closeModalEdit} />
                    </Modal>
                </>
            )
            }
            {/* {projects.map((project) => {
                return (
                    <Comments key={project.id} project={project} />
                )
            })} */}
            {
                error ? (
                    <Snackbar
                        open={openSnackbar}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={closeSnackbar} severity="error">
                            Something went wrong!
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="success">Done!</Alert>
                    </Snackbar>
                )
            }

        </Box >

    )
}


export default TableBD