import { useState, useEffect } from "react";
import { db } from '../../../firebase';
import { useGetAllQuestions } from "../../../hooks/questionData/useGetAllQuestions";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetUserNames } from "../../../hooks/userData/useGetUserNames";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";


const ExitSummaryBD = ({ projectID }) => {
    const questions = useGetAllQuestions();
    const [userNames] = useGetUserNames();
    const [internalComments, setInternalComments] = useState([]);
    const [externalComments, setExternalComments] = useState([]);

    const [update, setUpdate] = useState(false);
    const [allQuestions] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    function getNames(userId) {
        let username;
        userNames.forEach((userName) => {
            if (userId === userName.id) {
                username = userName.value;
                //console.log('User:' + username + '!')
            }
            //return username
        })
        return username
    }

    useEffect(() => {
        internalComments.forEach((comment) => {
            comment.createdByName = getNames(comment.createdBy);
        });

        externalComments.forEach((comment) => {
            comment.createdByName = getNames(comment.createdBy);
        })

        setUpdate(!update)
    }, [userNames, internalComments, externalComments]);

    useEffect(() => {
        setIsLoading(true);

        const func = async () => {
            const localInternal = [];
            const localExternal = [];
            internalComments.length = 0;
            externalComments.length = 0;

            await getDocs(query(collection(db, "Projects/" + projectID + "/ExternalNotes"), orderBy('created'))).then(async (documents) => {
                for (let index = 0; index < documents.docs.length; index++) {
                    const element = documents.docs[index];
                    const comment = {
                        id: element.id,
                        message: element.data().message,
                        location: element.data().location,
                        step: element.data().step,
                        created: element.data().created,
                        createdBy: element.data().createdBy,
                        createdByName: getNames(element.data().createdBy),
                    };

                    if (element.data().assignedID != null && element.data().step != null) {
                        await getDoc(doc(db, "Projects/" + projectID + "/Steps/" + element.data().step + "/AssignedQuestions/" + element.data().assignedID))
                            .then((data) => {
                                if (data.data() != null) {
                                    const question = questions.find(({ id }) => id === data.data().questionID);
                                    if (question != null) {
                                        comment["question"] = question;
                                    }
                                }
                            });
                    }

                    localExternal.push(comment)
                }

                setExternalComments(localExternal);
            });

            await getDocs(query(collection(db, "Projects/" + projectID + "/InternalNotes"), orderBy('created'))).then(async (documents) => {
                for (let index = 0; index < documents.docs.length; index++) {
                    const element = documents.docs[index];
                    const comment = {
                        id: element.id,
                        message: element.data().message,
                        location: element.data().location,
                        step: element.data().step,
                        created: element.data().created,
                        createdBy: element.data().createdBy,
                        createdByName: getNames(element.data().createdBy),
                    };

                    if (element.data().assignedID != null && element.data().step != null) {
                        await getDoc(doc(db, "Projects/" + projectID + "/Steps/" + element.data().step + "/AssignedQuestions/" + element.data().assignedID))
                            .then((data) => {
                                if (data.data() != null) {
                                    const question = questions.find(({ id }) => id === data.data().questionID);
                                    if (question != null) {
                                        comment["question"] = question;
                                    }
                                }
                            });
                    }

                    localInternal.push(comment)
                }

                setInternalComments(localInternal);
            });

            setIsLoading(false);
        }

        func();

        return () => {
            setInternalComments([]);
            setExternalComments([]);
        }

    }, [projectID, questions]);


    function ascendingComparator(a, b) {
        if (b["order"] > a["order"]) {
            return -1;
        }
        if (b["order"] < a["order"]) {
            return 1;
        }
        return 0;
    }

    function showCommentCard(comment) {
        let date;
        if (comment.created != null) {
            date = new Date(Number(comment.created.seconds) * 1000)
        }
        else {
            date = '';
        }

        return (
            <Card key={comment.id} variant="outlined" sx={{ padding: 2, width: '100%' }}>
                <span><strong>Comment: </strong></span><br />
                {comment.message}<br />
                {comment.createdByName != null && (
                    <><span><strong>By:</strong></span><br />
                        {comment.createdByName}<br /></>
                )}

                <span><strong>Location:</strong></span><br />
                {comment.location}<br />
                {comment.created != null ? (
                    <>
                        <span><strong>Created:</strong></span><br />
                        {date.toLocaleString('se-SV', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</>
                ) : (
                    ''
                )}

            </Card>
        );
    }

    return (
        <div>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
            ) : (
                <>

                    <h5>Internal Comments:</h5>
                    {internalComments.map((comment) => {
                        return showCommentCard(comment);
                    })}
                    <hr />
                    <h5>External Comments:</h5>
                    {externalComments.map((comment) => {
                        return showCommentCard(comment);
                    })}

                </>
            )
            }

        </div >
    )
};

export default ExitSummaryBD;