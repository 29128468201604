import './assets/scss/app.scss'
import { Container } from 'react-bootstrap'
import { Routes, Route } from 'react-router-dom'
import AuthProvider from './contexts/AuthContext'
import Navigation from './components/Navigation/Navigation'
import Landing from './components/Landing'
import Login from './components/Login'
import Signup from './components/Signup/Signup'
import AuthRoute from './components/Navigation/AuthRoute'
import ResetPassword from './components/UserInfo/ResetPassword'
import Confirmation from './components/UserInfo/Confirmation'
import QuestionManager from './components/Questions/QuestionManager'
import TemplateManager from './components/Templates/TemplateManager'
import HomeBD from './components/HomeBD/HomeBd'
import NotFound from './components/Pages/NotFound'
import AccountSettings from './components/UserInfo/AccountSettings'
import Home from './components/HomeIBO/Home'
import HomeAdmin from './components/HomeAdmin/HomeAdmin'
import { OnBoarding } from './components/Signup/OnBoarding'
import { OnBoardingInvited } from './components/Signup/OnBoardingInvited'
import { ErrorPage } from './components/Pages/ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'
import InactivePage from './components/Pages/InactivePage'
import { MatrixStart } from './components/Matrix/MatrixStart'
import { QuarterReportStep } from './components/QuarterReview/QuarterReviewStep'
import AwaitingConfirmPage from './components/Pages/AwaitingConfirmPage'

const App = () => {
    function ErrorFallback({ error }) {
        return (
            <div role="alert">
                <ErrorPage error={error} />
            </div >
        )
    }

    return (
        <>
            <AuthProvider>
                <Navigation />
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Container>
                        <Routes>
                            <Route path="/" element={<Landing />} />

                            <Route path="/login" element={<Login />} />

                            <Route path="/sign-up" element={<Signup />} />

                            <Route path="/invited" element={<OnBoardingInvited />} />

                            <Route path="/reset" element={<ResetPassword />} />

                            <Route path="/confirmation" element={<Confirmation />} />

                            <Route path="/home" element={
                                <AuthRoute>
                                    <Home />
                                </AuthRoute>
                            } />

                            <Route path="/admin" element={
                                <AuthRoute>
                                    <HomeAdmin />
                                </AuthRoute>
                            } />

                            <Route path="/question-manager" element={
                                <AuthRoute>
                                    <QuestionManager />
                                </AuthRoute>
                            } />

                            <Route path="/template-manager" element={
                                <AuthRoute>
                                    <TemplateManager />
                                </AuthRoute>
                            } />

                            <Route path="/set-up-account" element={
                                <AuthRoute>
                                    <OnBoarding />
                                </AuthRoute>
                            } />

                            <Route path="/account-settings" element={
                                <AuthRoute>
                                    <AccountSettings />
                                </AuthRoute>
                            } />

                            <Route path="/matrix" element={
                                <AuthRoute>
                                    <MatrixStart />
                                </AuthRoute>
                            } />

                            <Route path="/home-bd" element={
                                <AuthRoute>
                                    <HomeBD />
                                </AuthRoute>
                            } />

                            <Route path="/inactive" element={
                                <AuthRoute>
                                    <InactivePage />
                                </AuthRoute>
                            } />

                            <Route path="/awaiting-confirm" element={
                                <AuthRoute>
                                    <AwaitingConfirmPage />
                                </AuthRoute>
                            } />

                            <Route path="/quarter-review" element={
                                <AuthRoute>
                                    <QuarterReportStep />
                                </AuthRoute>
                            } />

                            <Route path="/supplementary-info" element={
                                <AuthRoute>
                                    <QuarterReportStep />
                                </AuthRoute>
                            } />

                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Container>
                </ErrorBoundary>
            </AuthProvider>
        </>
    )
}

export default App

