import { useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
//import TableIbo from './Table'
import '../../assets/scss/app.scss'
import TabMenu from './TabMenu';
import useGetUserData from '../../hooks/userData/useGetUserData'
import { Box, CircularProgress } from '@mui/material'

const HomeBD = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { loading } = useGetUserData();

    useEffect(() => {
        if (!loading) {
            if (currentUser != null) {
                //console.log("Role", currentUser.role)
                if (currentUser.role !== 2 && currentUser.role !== 3) {
                    navigate("/home", { replace: true });
                }
            }
            else {
                navigate("/login");
            }
        }

    }, [loading]);


    return (
        <>
            {!loading ? (
                <>
                    <Box className='jumbotron'>
                        <div className="text-center">
                            <h6>Welcome to the Business Designer dashboard!</h6>

                            <p className="jumbo-text">You are logged in as <strong>{currentUser.email}</strong></p>

                        </div>
                    </Box>
                    <>
                        <TabMenu />
                    </>
                </>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            )}


        </>
    )
}

export default HomeBD
