export const TeamsQuestions = {
    aloneORteam: {
        key: 'aloneORteam', 
        type: 'textField', 
        order: 1,
        orderLabel: '12.',
        text: "In what way does the teams's knowledge and experience contribute to the project?"
    },
    competences: {
        key: 'competences', 
        type: 'textField', 
        order: 2,
        orderLabel: '13.',
        text: "Describe one or more future team members who could contribute to the development of the project?"
    },
}