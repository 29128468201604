import { Box, DialogContent, Divider, Stack, Switch, Tooltip, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { BootstrapDialog, BootstrapDialogTitle } from "../../helper/bootstrapDialog"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useGetExternalFiles, useGetFiles, useGetInternalFiles } from "../../hooks/fileData/useGetFiles";
import { FileRows } from "./FileRow";

export const FileOverviewStepExternal = ({ projData, step, location }) => {
    const { files, isLoading } = useGetExternalFiles(projData.id, location, null, "uploaded")

    const [open, setOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState('ascending');
    const [checked, setChecked] = useState(true);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        setUpdate(!update);
    }, [files]);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (event) => {
        if (sortOrder === 'descending') {
            setChecked(true);
            setSortOrder('ascending')
        }
        else {
            setChecked(false);
            setSortOrder('descending')
        }
    }

    const filterList = (item) => {
        if (item.isIBO === true) {
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <Fragment>
            {files.filter(filterList).length > 0 ? (
                <Tooltip title="External Files" arrow>
                    <AttachFileIcon className="add_miro comment_icon_external not_empty"
                        style={{
                            cursor: "pointer",
                            //textlign: "center",
                            //marginLeft: "30px",
                        }}
                        onClick={handleClickOpen}
                    />
                </Tooltip>

            ) : (
                <Tooltip title="External Files" arrow>
                    <AttachFileIcon className="add_miro comment_icon_external"
                        style={{
                            cursor: "pointer",
                            //textlign: "center",
                            //marginLeft: "30px",
                        }}
                        onClick={handleClickOpen}
                    />
                </Tooltip>
            )}

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ marginTop: 8, marginBottom: 4, marginLeft: 15, }}>
                        <Typography sx={{ maxWidth: '600px' }} fontSize={'16px !important'}>External files, <strong>accessible by the IBO</strong> uploaded in {location} in IBO {projData.value}:</Typography>
                        <Box sx={{ paddingRight: '50px' }}>
                            <Stack direction={'row'} alignItems="center">
                                <span>
                                    Desc
                                </span>
                                <Switch checked={checked} onClick={handleChange} />
                                <span>
                                    Asc
                                </span>
                            </Stack>
                        </Box>
                    </Stack>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent dividers>
                    <Box>
                        <FileRows projID={projData.id} files={files} step={step} location={location} sortOrder={sortOrder} isExternal={true} />
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Fragment >
    )
}