import { useRef, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

const ResetPassword = () => {

    const emailRef = useRef()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { reset } = useAuth()
    const navigate = useNavigate()


    const handleReset = async (e) => {
        e.preventDefault()

        setError(null);

        try {
            // try to log in the user with the specified credentials
            setLoading(true)
            await reset(emailRef.current.value)
            navigate('/confirmation')
        } catch (e) {
            setError(e.message)
            setLoading(false)
        }
    }

    return (

        <>
            <div className="log-box mt-4">

                <h3>Forgot your password?</h3>

                {error && <Alert variant="danger">{error}</Alert>}

                <form onSubmit={handleReset}>

                    <div className="form-group">
                        <label></label>
                        <input type="email" className="form-control" ref={emailRef} placeholder="Enter email" />
                    </div>

                    <button disabled={loading} type="submit" className="btn btn-info btn-lg btn-block">Send</button>
                    <p className="forgot-password text-right">
                        Already registered? <Link to="/sign-up">Log In</Link>
                    </p>
                </form>

            </div>
        </>


    )
}

export default ResetPassword