import { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { db } from '../../firebase';
import { deleteQuestion } from '../../firebase/cloudFunctions';
import './css/ListAllQuestions.css'
import useDeleteQuestion from '../../hooks/questionData/useDeleteQuestion';
import { useConfirm } from 'material-ui-confirm';
import EditModal from './EditModal';
import firebaseRefList from '../../firebase/firebaseRefList'
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';

//const deleteQuestion = firebase.app().functions("europe-west1").httpsCallable('deleteQuestion');

/**
 * 
 * @param {object} param0 
 * @param {('All' | 'EmbededLinkInput' | 'Checked' | 'RadioButton' | 'Slider' | 'TextInput' | 'RichTextInput')} param0.type
 * @returns 
 */
export default function ListQuestions({ type }) {
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState('')
    const [deleteQuestionObj, setDeleteQuestion] = useState(null);

    const confirmDelete = useConfirm();
    useDeleteQuestion(deleteQuestionObj);

    const handleDeleteQuestion = async (question) => {
        //console.log(question)
        confirmDelete({ title: 'Please confirm', description: 'Are you sure you want to delete ' + question.shortDesc + '?\n Please note that if the content is currently part of a group, it will have to be manually un-grouped from within the Content manager for the deletion to take effect in these groups.\n All instances of ' + question.shortDesc + ' that are currently assigned to specific IBOs will not be effected by the deletion or un-grouping.' })
            .then(() => {
                setLoadingDelete(question);
                const index = questions.indexOf(question);
                if (index > -1) {
                    questions.splice(index, 1);
                }

                deleteQuestion({
                    questionID: question.key,
                }).then(() => {
                    setLoadingDelete('');
                }).catch((e) => {
                    console.log(e)
                    setLoadingDelete('');
                });
            })
            .catch(() => {
                //set `error` state to true
                return

            });
    }

    useEffect(() => {

        const subscriber = () => {
            const snap = onSnapshot(query(collection(db, 'Questions'), orderBy('createdAt', 'desc')), (querySnapshot) => {
                const getQuestionsFromFirebase = [];
                querySnapshot.forEach((doc) => {
                    if (doc.data().deleted !== true) {
                        getQuestionsFromFirebase.push({
                            ...doc.data(), //spread operator
                            key: doc.id, // `id` given to us by Firebase
                        });
                    }
                });

                setQuestions(getQuestionsFromFirebase)
                setLoading(false);
            })

            firebaseRefList.push(snap);
        }
        subscriber()
        // return cleanup function
        // return () => subscriber();
    }, [loading]); // empty dependencies array => useEffect only called once

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
    }

    const setHeaderText = () => {
        switch (type) {
            case 'Checked':
                return "All checkbox questions";
            case 'RadioButton':
                return "All radio-button questions";
            case 'Slider':
                return "All slider questions";
            case 'EmbededLinkInput':
                return "All video content";
            case 'RichTextInput':
                return "All Text (non-question)";
            case 'TextInput':
                return "All question (text answer) content";
            default:
                return "All questions";
        }
    }

    const setIcon = (questionType) => {
        switch (questionType) {
            case 'Checked':
                return <CheckBoxRoundedIcon style={{ marginRight: 10, color: "gray" }} />;
            case 'RadioButton':
                return <RadioButtonCheckedRoundedIcon style={{ marginRight: 10, color: "gray" }} />;
            case 'Slider':
                return <LinearScaleOutlinedIcon style={{ marginRight: 10, color: "gray" }} />;
            case 'EmbededLinkInput':
                return <OndemandVideoRoundedIcon style={{ marginRight: 10, color: "gray" }} />;
            case 'RichTextInput':
                return <SubjectRoundedIcon style={{ marginRight: 10, color: "gray" }} />;
            case 'TextInput':
                return <TextFieldsRoundedIcon style={{ marginRight: 10, color: "gray" }} />;
            default:
                return "";
        }
    }

    const filterFunc = (item) => {
        if (type === 'All'){
            return true;
        }
        else{
            return item.type === type;
        }
    }

    return (
        <>
            <div className="container">
                <h6>{setHeaderText()}</h6>
                <ul className="all_textfield_qustions">
                    {questions.filter(filterFunc).length > 0 ? (
                        questions.filter(filterFunc).map((question) => {
                            return (
                                <Card className="all_textfield_questions_card" variant="outlined" key={question.key} sx={{ maxWidth: 600, marginBottom: '1rem', paddingBottom: '0rem' }}>
                                    <CardContent>
                                        {setIcon(question.type)}

                                        <span>{question.shortDesc}</span>
                                        <EditModal short={question.shortDesc} text={question.text} moreInfo={question.explanation} questionId={question.key} type={question.type} tag={question.category} options={question.options || null} labelPlacement={question.labelPlacement || null} />

                                        {loadingDelete !== question ? (
                                            <HighlightOffOutlinedIcon fontSize="small"
                                                style={{
                                                    cursor: "pointer",
                                                    marginTop: "5px",
                                                }} onClick={() => {
                                                    handleDeleteQuestion(question)
                                                }} />
                                        ) : (<></>)}

                                    </CardContent>
                                </Card>
                            )
                        })
                    ) : (
                        <h3>No questions assigned</h3>
                    )}
                </ul>
            </div>
        </>
    );
}