import { useEffect, useState } from "react"
import { EXTRENAL_COMMENTS_TEXT as EXTRENAL_COMMENTS_TEXT_SWE} from "../../constants/lang/swe/comments";
import { EXTRENAL_COMMENTS_TEXT as EXTRENAL_COMMENTS_TEXT_ENG } from "../../constants/lang/eng/comments";
import useGetUserData from "../userData/useGetUserData";

export const useGetExternalCommentTexts = () => {
    const { userData, loading } = useGetUserData();
    
    const [sentences, setSentences] = useState({
        header: "",
        headerMeet: "",
        headerMeetIBO: "",
        hiddenToggle: "",
        errorMsgHide: "",
        errorMsgShow: "",
    });

    useEffect(() => {
        if (loading === false) {
            /* To be added later
            if (userData?.prefLang === 'swe') {
                setSentences(EXTRENAL_COMMENTS_TEXT_SWE)
            }
            else {
                setSentences(EXTRENAL_COMMENTS_TEXT_ENG)
            }*/
            setSentences(EXTRENAL_COMMENTS_TEXT_ENG)
        }
        else {
            setSentences(EXTRENAL_COMMENTS_TEXT_ENG)
        }
    }, [loading, userData]);

    return sentences;
}