import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";


export const useGetProjectSettingsData = () => {
    const [projectSettings, setProjectSettings] = useState(Object);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const ref = onSnapshot(doc(db, "SettingsValues/Projects"), (snapshot) => {

            if (snapshot.exists()) {
                setProjectSettings(snapshot.data());
            }

            setLoading(false);
        });

        firebaseRefList.push(ref);
    }, []);

    return { projectSettings, loading };
}