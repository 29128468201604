import { Card, CardContent, Divider, Box } from "@mui/material"

export const TemplateFileUploadCard = ({ assignedQuestion }) => {
    return (
        <Card className="characters" key={assignedQuestion.key} sx={{ maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem' }}>
            <CardContent>
                
                <div style={{ paddingTop: '10px', paddingLeft: '10px'}}>
                    <div><strong>{assignedQuestion.text}</strong></div>
                    <Divider sx={{marginBottom: '5px', width: '550px'}}/>
                    <Box sx={{border: 'dashed 1px grey', borderRadius: '5px', width: '550px', height: '80px', fontSize: '15px', textAlign: 'center', paddingTop: '25px'}} >
                        <strong>*Template*</strong> Upload file here <strong>*Template*</strong> 
                    </Box>
                </div>
            </CardContent>
        </Card>
    )
}