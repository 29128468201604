import { Box, Card, CardContent, Slider } from "@mui/material"
import parse from 'html-react-parser';
import { useAuth } from "../../../../contexts/AuthContext";
import { QuestionCommentBox } from "../QuestionCommentBox";


export const SliderCard = ({ projectData, step, location, assignedQuestion, question, sliderChange }) => {
    const { currentUser } = useAuth();

    function valuetext(valueSlide) {
        return `${valueSlide}`;
    }

    function createMarks(options) {
        let marks = [];

        String(options).split(';').forEach((option) => {
            marks.push({
                value: option,
                label: option,
            });
        })

        return marks;
    }

    return (
        <Card className="characters" variant="outlined" key={question.key} sx={{ maxWidth: 700, marginBottom: '1rem', paddingBottom: '0rem' }}>
            <CardContent>
                <span style={{ display: 'block', marginTop: 15 }}>{parse(question.text)}</span>
                {assignedQuestion.note != null ? (
                    <p className="note-style"><strong>{assignedQuestion.note}</strong></p>) : ("")}


                <Box sx={{ maxWidth: 600, marginTop: 2, marginLeft: 1, marginBottom: 2, marginRight: '22px' }}>
                    <Slider
                        defaultValue={assignedQuestion.answer || null}
                        // valueLabelFormat={valueLabelFormat(createMarks(assignedQuestion.options))}
                        getAriaValueText={valuetext}
                        step={null}
                        disabled={currentUser.role >= 2}
                        id={assignedQuestion.key}
                        valueLabelDisplay="off"
                        marks={createMarks(question.options)}
                        min={Number(createMarks(question.options)[0].value)}
                        max={Number(createMarks(question.options)[createMarks(question.options).length - 1].value)}
                        onChangeCommitted={(event, value) => sliderChange(assignedQuestion.key, value)} />
                </Box>

                <QuestionCommentBox projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} />
            </CardContent>
        </Card>
    )
}