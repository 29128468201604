import errorImg from '../../assets/images/error.png'
import Box from "@mui/material/Box/Box"
import { Card, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { getAnalytics, logEvent } from 'firebase/analytics'

export const ErrorPage = ({ error }) => {
    console.log("Error: ", error)
    const analytics = getAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: '500 Error',
            page_path: window.location.pathname,
        });

        logEvent(analytics, 'exception', {
            description: error.message,
            fatal: true,
        });
    }, [])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '50px' }}>
                <Box >
                    <p hidden>500 - Internal</p>
                    <img alt='Error' src={errorImg} height='100px' />
                </Box>
                <Box>
                    <h6>Something went wrong... Click <Link to={'/'} replace={true} className="open-modal-link"><strong><u>here</u></strong></Link> to go back to start.</h6>
                </Box>

                <Card>
                    <span>Message: {error.message}</span>
                </Card>
            </Stack>

        </Box>
    )
}