import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
//import ReactHtmlParser from 'react-html-parser';
import EditContent from './EditContent';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: 800,
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),

    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : ("")}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function EditContentModal({short, text, moreInfo, questionId, type, options = null, labelPlacement = null, tag}) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    //console.log('testtest' + props.text)


    return (
        <>
            <EditIcon className="edit_icon" fontSize="small"
                style={{
                    cursor: "pointer",
                    marginTop: "5px",
                }} onClick={handleClickOpen} />

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                minWidth='lg'
                maxWidth='xl'
                className="more-info-dialog-edit"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <h6><strong>Edit {short}</strong></h6>
                </BootstrapDialogTitle>
                <DialogContent>
                    <EditContent short={short} text={text} moreInfo={moreInfo} questionId={questionId} type={type} options={options} labelPlacement={labelPlacement} tag={tag} handleClose={handleClose} />
                </DialogContent>

            </BootstrapDialog>
        </>
    );
}
