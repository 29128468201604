export const generateTimeslots = () => {
    const timeList = [];
    for (let index = 0; index < 24; index++) {
        let hourVal = String(index).padStart(2, '0');

        timeList.push({ title: hourVal + ":00", time: hourVal + ":00" })
        timeList.push({ title: hourVal + ":15", time: hourVal + ":15" })
        timeList.push({ title: hourVal + ":30", time: hourVal + ":30" })
        timeList.push({ title: hourVal + ":45", time: hourVal + ":45" })
    }
    timeList.push({ title: "23:59", time: "23:59" })
    return timeList;
}