import { Fragment } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import CommentDialogExternal from "../../Comments/CommentDialogExternal";
import CommentDialogInternal from "../../Comments/CommentDialogInternal";


export const QuestionCommentBox = ({ projectData, step, location, assignedQuestion }) => {
    const { currentUser } = useAuth();
    
    return (
        <Fragment>
            {currentUser.role >= 2 ? (
                <div className="grid-container">
                    <div style={{ marginBottom: '5px', marginTop: '10px', display: 'flex', flexDirection: 'row' }}>
                        <CommentDialogExternal projID={projectData.id} projData={projectData} questionKey={assignedQuestion.key} step={step} location={location} />

                        <CommentDialogInternal projID={projectData.id} projData={projectData} questionKey={assignedQuestion.key} step={step} location={location} />
                    </div>

                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '5px' }}>
                    <CommentDialogExternal projID={projectData.id} projData={projectData} questionKey={assignedQuestion.key} step={step} location={location} />
                </div>
            )}
        </Fragment>
    )
}