import { useState } from "react"
import { Alert } from 'react-bootstrap'
import Box from '@mui/material/Box';
import { createBDUser } from '../../firebase/cloudFunctions';
import CircularProgress from "@mui/material/CircularProgress";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { Checkbox, Divider, FormControlLabel, Stack } from "@mui/material";

const CreateBD = ({ onClose }) => {
    const [emailRef, setEmailRef] = useState('');
    const [firstNameRef, setFirstNameRef] = useState('');
    const [lastNameRef, setLastNameRef] = useState('');

    const [displayIBO, setDisplayIBO] = useState(true);
    const [displayBD, setDisplayBD] = useState(true);

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const changeEmail = (event) => {
        setEmailRef(event.target.value);
    }

    const changeFirstName = (event) => {
        setFirstNameRef(event.target.value);
    }

    const changeLastName = (event) => {
        setLastNameRef(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setError("");
            setLoading(true);


            createBDUser({
                email: emailRef,
                firstName: firstNameRef,
                lastName: lastNameRef,
                IBOSelectable: displayIBO,
                BDSelectable: displayBD,
            }).then(() => {
                onClose.onClose();
            }).catch((error) => {
                setError("Failed to create user: " + error);
            }).finally(() => {
                setLoading(false);
            });

        } catch (e) {
            setError("Failed to create user: " + e);
            setLoading(false);
        }
    }

    function closeModalOnBtn() {
        onClose();
    }

    return (

        <Box sx={style}>
            <div>
                <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={closeModalOnBtn}>
                    <CloseRoundedIcon />
                </IconButton>
            </div>
            <div className="log-box" >
                <h3>Create BD</h3>
                {error && <Alert variant="danger">{error}</Alert>}

                <form onSubmit={handleSubmit}>

                    <div className="form-group">
                        <label>First Name:</label>
                        <input type="text" className="form-control" value={firstNameRef} onChange={changeFirstName} placeholder="Enter first name" required />
                    </div>

                    <div className="form-group">
                        <label>Last Name:</label>
                        <input type="text" className="form-control" value={lastNameRef} onChange={changeLastName} placeholder="Enter last name" required />
                    </div>

                    <div className="form-group">
                        <label>Email:</label>
                        <input type="email" className="form-control" value={emailRef} onChange={changeEmail} placeholder="Enter email" required />
                    </div>

                    <Divider sx={{ marginY: '10px' }} />

                    <Stack>
                        <FormControlLabel control={<Checkbox checked={displayIBO} onChange={() => setDisplayIBO(!displayIBO)} />} label="IBO-selectable" />
                        <FormControlLabel control={<Checkbox checked={displayBD} onChange={() => setDisplayBD(!displayBD)} />} label="BD-selectable" />
                    </Stack>
                    <br></br>

                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <button disabled={loading} type="submit" className="btn btn-info btn-lg btn-block">
                            Create account
                        </button>
                    )}
                </form>

            </div>
        </Box>

    )
}

export default CreateBD