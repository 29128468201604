import { MenuItem, Select, TableCell, Tooltip } from "@mui/material"
import { TrackLabels } from "../../../../constants/labels/labels"
import { useEffect, useState } from "react";
import { useConfirm } from "material-ui-confirm";
import { setActiveTrack } from "../../../../firebase/cloudFunctions";


export const TableRowTrack = ({ project }) => {
    const confirm = useConfirm();
    const [isLoading, setIsLoading] = useState(false);
    const [currentTrack, setCurrentTrack] = useState('');

    useEffect(() => {
        if (project != null && project.activeTrack != null && project.activeTrack !== "") {
            //console.log("Track: ", project.activeTrack)
            setCurrentTrack(project.activeTrack);
        }
        else {
            setCurrentTrack('-');
        }
    }, [project])


    const handleSetActiveTrack = (event) => {
        if (event.target.value != null && event.target.value !== "") {
            let descText = "";
            if (currentTrack != null && currentTrack !== "" && currentTrack !== "-") {
                descText = 'Do you want to change track for the IBO ' + project?.value + ' from ' + TrackLabels[currentTrack]?.label + ' to ' + TrackLabels[event.target.value]?.label + '?'
            }
            else {
                descText = 'Do you want to activate the IBO ' + project?.value + ' in ' + TrackLabels[event.target.value]?.label + '?'
            }

            confirm({ title: 'Please confirm', description: descText }).then(() => {
                setIsLoading(true);
                console.log(currentTrack, "=>", event.target.value)
                setCurrentTrack(event.target.value);

                setActiveTrack({
                    projectID: project.id,
                    newTrack: event.target.value,
                }).then(() => {
                    console.log("Saved!");
                }).catch((error) => {
                    console.error("Could not save data! Reason:", error);
                }).finally(() => {
                    setIsLoading(false);
                })

            })
                .catch(() => { setIsLoading(false); });
        }
    }

    return (
        <TableCell>
            <Select
                size='small'
                variant="standard"
                value={currentTrack}
                disabled={project.currentStep === 'PreMeet' || isLoading}
                sx={{ fontSize: '14px' }}
                onChange={handleSetActiveTrack}
            >
                {currentTrack == null || currentTrack === "" || currentTrack === "-" ? (
                    <MenuItem disabled value={"-"}><i> - </i> </MenuItem>
                ) : ("")}

                {currentTrack == null || currentTrack === "" || currentTrack === "-" ? (
                    <Tooltip title={currentTrack == null || currentTrack === "" || currentTrack === "-" ? 'Unlock Homework 1 to activate in START' : ''} arrow placement='left'>
                        <span>
                            <MenuItem disabled={currentTrack == null || currentTrack === "" || currentTrack === "-"} value={TrackLabels.start.key}>
                                {TrackLabels.start.label}
                            </MenuItem>
                        </span>
                    </Tooltip>
                ) : (
                    <MenuItem value={TrackLabels.start.key}>{TrackLabels.start.label}</MenuItem>
                )}

                <MenuItem disabled value={TrackLabels.incubator.key}>{TrackLabels.incubator.label}</MenuItem>
                <MenuItem disabled value={TrackLabels.scale.key}>{TrackLabels.scale.label}</MenuItem>
                <MenuItem disabled value={TrackLabels.exit.key}>{TrackLabels.exit.label}</MenuItem>
            </Select>
        </TableCell>
    )
}