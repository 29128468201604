import { IconButton, Stack, Tooltip } from "@mui/material";
import { TrackLabels } from "../../../constants/labels/labels";

import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { useState } from "react";

import { StartStepperContent } from "../../Stepper/Start/StartStepperComponent";
import { IncubatorStepperContent } from "../../Stepper/Incubator/IncubatorStepperContent";
import { ScaleStepperContent } from "../../Stepper/Scale/ScaleStepperContent";
import { StepperDialog } from "../../StepperDialog/stepperDialog";


export const OtherTracks = ({ project }) => {
    const [previewTrack, setPreviewTrack] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const handleOpenPreview = () => {
        setOpenPreview(true);
    }

    const handleClosePreview = () => {
        setOpenPreview(false);
    }


    console.log("Tracks: ", project.trackParticipation)

    const hasMultipleTracks = () => {
        if (project?.trackParticipation != null) {
            if (Object.keys(project?.trackParticipation).length === 1 && project?.trackParticipation[project.activeTrack] != null) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }

    const handleOpenTrack = (track) => {
        setPreviewTrack(track);
        handleOpenPreview();
    }

    const openTrack = () => {
        switch (previewTrack) {
            case TrackLabels.start.key:
                return (<StartStepperContent project={project} isPopup />);
            case TrackLabels.incubator.key:
                return (<IncubatorStepperContent project={project} isPopup />)
            case TrackLabels.scale.key:
                return (<ScaleStepperContent project={project} isPopup />)
            default:
                return (<StartStepperContent project={project} isPopup />);
        }
    }

    return (
        <>
            {hasMultipleTracks() === true ? (
                <div className="home-ibo-comments">
                    <div>
                        <span style={{ marginBottom: "0px, marginRight: 5px" }}>
                            <strong>Other tracks</strong>
                        </span>
                        <hr className="solid" style={{ marginTop: "3px", width: "100%" }}></hr>

                        <Stack spacing={1}>
                            {project.activeTrack === TrackLabels.start.key && project?.trackParticipation[project.activeTrack] == null ? (
                                <div>
                                    <Tooltip arrow title="Current track" placement="left">
                                        <strong>
                                            {TrackLabels.start.label} <i>(Pending)</i>
                                        </strong>
                                    </Tooltip>
                                </div>
                            ) : ("")}
                            {Object.keys(project?.trackParticipation).map((track) => {
                                if (TrackLabels[track] != null) {
                                    if (track === project?.activeTrack) {
                                        return (
                                            <div key={track}>
                                                <Tooltip arrow title="Current track" placement="left">
                                                    <strong>
                                                        {TrackLabels[track].label} <i>{project?.trackParticipation[track] === 0 ? "(Pending)" : project?.trackParticipation[track] === 2 ? "(Completed)" : ""}</i>
                                                    </strong>
                                                </Tooltip>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div key={track}>
                                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                    <div>
                                                        {TrackLabels[track].label} <i>{project?.trackParticipation[track] === 0 ? "(Pending)" : project?.trackParticipation[track] === 2 ? "(Completed)" : ""}</i>
                                                    </div>
                                                    <IconButton size="small" onClick={(e) => { handleOpenTrack(track) }}>
                                                        <OpenInBrowserIcon sx={{ height: '18px' }} />
                                                    </IconButton>
                                                </Stack>

                                            </div>
                                        )
                                    }
                                }
                            })}
                        </Stack>

                    </div>
                </div>
            ) : ("")}

            <StepperDialog open={openPreview} handleClose={handleClosePreview} track={previewTrack} project={project} />
        </>

    )
}