import { Button, Checkbox, DialogContent, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../helper/bootstrapDialog";
import PendingIcon from '@mui/icons-material/Pending';
import { assignBDToProj, confirmExitTrack, confirmTrackChangeAndAssignBD } from "../../../../firebase/cloudFunctions";

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { ExitReasonsLabels } from "../../../../constants/labels/labels";

export const PendingExitDialog = ({ projData }) => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('');
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChangeReason = (event) => {
        setReason(event.target.value);
    }

    const handleConfirmExit = () => {
        setLoading(true);

        confirmExitTrack({
            projectID: projData.id,
            reason: reason,
        }).then(() => {
            handleClose();
        }).catch((error) => {
            console.error('Error: ', error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <IconButton sx={{ height: '22px', width: '22px', marginRight: '-2px' }} size='small' onClick={handleClickOpen}>
                <DirectionsRunIcon htmlColor='#efb236' />
            </IconButton>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ padding: '10px 10px 0px 10px' }}><strong>Set Exit reason</strong></h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent>
                    <Stack>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                            <Select
                                value={reason}
                                label="Reason"
                                onChange={handleChangeReason}
                            >
                                <MenuItem sx={{height: '16px'}} value={''}></MenuItem>
                                {Object.keys(ExitReasonsLabels).map((key) => (
                                    <MenuItem key={key} value={key}>{ExitReasonsLabels[key]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack sx={{ paddingTop: '20px' }} alignItems={'center'} justifyContent={'space-between'}>
                            <Button onClick={handleConfirmExit} disabled={reason == null || reason === "" || loading === true} sx={{ width: '200px' }} variant="contained">
                                Confirm Exit
                            </Button>
                        </Stack>
                    </Stack>


                </DialogContent>
            </BootstrapDialog>
        </>
    );
}