import { TableCell } from "@mui/material"
import { format } from "date-fns";


export const TableRowNextDeadline = ({ project }) => {

    /**
     * 
     * @param {Date} deadline 
     * @returns {boolean}
     */
    function isLate(deadline) {
        const now = new Date(Date.now());
        if (deadline < now) {
            return true;
        }

        return false;
    }

    return (
        <>
            {project.activeTrack === 'exit' ? (
                <TableCell padding='none' style={{ textAlign: "center" }}><span style={{ justifyContent: 'right' }}> - </span></TableCell>
            ) : (
                <>
                    {project.nextDeadline != null ? (
                        <>
                            {isLate(new Date(project.nextDeadline['time'].seconds * 1000)) ? (
                                <TableCell style={{ textAlign: "center" }}><span style={{ color: '#ac2020', fontStyle: "italic", justifyContent: 'right' }}>{format(new Date(project.nextDeadline['time'].seconds * 1000), 'yyyy-MM-dd HH:mm')}</span></TableCell>
                            ) : (
                                <TableCell style={{ textAlign: "center" }}><span style={{ justifyContent: 'right' }}>{format(new Date(project.nextDeadline['time'].seconds * 1000), 'yyyy-MM-dd HH:mm')}</span></TableCell>
                            )}
                        </>
                    ) : (
                        <TableCell padding='none' style={{ textAlign: "center" }}><span style={{ justifyContent: 'right' }}>Not Set</span></TableCell>
                    )}
                </>
            )}
        </>
    )
}