import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
//import { useAuth } from '../contexts/AuthContext'
import { useGetInternalComments } from '../../hooks/commentData/useGetInternalComments';
import CommentLog from './CommentLog';
import { Box, Divider, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { BootstrapDialog, BootstrapDialogTitle } from '../../helper/bootstrapDialog';

export default function CommentDialogStepInternal({ projID, projData, step, location }) {
    const [open, setOpen] = useState(false);
    const { comments } = useGetInternalComments(projID, location, null, "created")

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [sortOrder, setSortOrder] = useState('ascending');
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        if (sortOrder === 'descending') {
            setChecked(true);
            setSortOrder('ascending')
        }
        else {
            setChecked(false);
            setSortOrder('descending')
        }
    }

    return (
        <div>
            <Tooltip title={'Internal Comments'} arrow>
            <IconButton size='small' onClick={handleClickOpen}>
                {comments.length > 0 ? (
                    <CommentOutlinedIcon
                        className="add_miro comment_icon_internal not_empty"
                        fontSize="medium"
                    />
                ) : (
                    <CommentOutlinedIcon
                        className="add_miro comment_icon_internal"
                        fontSize="medium"
                    />
                )}
                </IconButton>
            </Tooltip>
            


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ marginTop: 8, marginBottom: 4, marginLeft: 15, }}>
                        <Typography sx={{ maxWidth: '600px' }} fontSize={'16px !important'}>Internal comments for IBO {projData.value}:</Typography>
                        <Box sx={{ paddingRight: '50px' }}>
                            <Stack direction={'row'} alignItems="center">
                                <span>
                                    Desc
                                </span>
                                <Switch checked={checked} onClick={handleChange} />
                                <span>
                                    Asc
                                </span>
                            </Stack>
                        </Box>
                    </Stack>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent dividers>
                    <CommentLog projID={projID} isExternal={false} step={step} location={location} comments={comments} questionKey={"undefined"} sortOrder={sortOrder} />
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
