import { Box, DialogContent, Divider, Stack, Switch, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { BootstrapDialog, BootstrapDialogTitle } from "../../helper/bootstrapDialog"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useGetFiles } from "../../hooks/fileData/useGetFiles";
import { FileRows } from "./FileRow";

export const FileOverview = ({ projData }) => {
    const { files, isLoading } = useGetFiles(projData.id, null, null, "uploaded")

    const [open, setOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState('ascending');
    const [checked, setChecked] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (event) => {
        if (sortOrder === 'descending') {
            setChecked(true);
            setSortOrder('ascending')
        }
        else {
            setChecked(false);
            setSortOrder('descending')
        }
    }


    return (
        <Fragment>
            {files.length > 0 ? (
                <AttachFileIcon className="add_miro comment_icon_external not_empty" fontSize="small"
                    style={{
                        cursor: "pointer",
                        //textlign: "center",
                        //marginLeft: "30px",
                    }}
                    onClick={handleClickOpen}
                />
            ) : (
                <AttachFileIcon className="add_miro comment_icon_external" fontSize="small"
                    style={{
                        cursor: "pointer",
                        //textlign: "center",
                        //marginLeft: "30px",
                    }}
                    onClick={handleClickOpen}
                />
            )}

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ marginTop: 8, marginBottom: 4, marginLeft: 15, }}>
                        <Typography sx={{ maxWidth: '600px' }} fontSize={'16px !important'}>Files uploaded in IBO {projData.value}:</Typography>
                        <Box sx={{ paddingRight: '50px' }}>
                            <Stack direction={'row'} alignItems="center">
                                <span>
                                    Desc
                                </span>
                                <Switch checked={checked} onClick={handleChange} />
                                <span>
                                    Asc
                                </span>
                            </Stack>
                        </Box>
                    </Stack>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent dividers>
                    <Box>
                        <FileRows projID={projData.id} files={files} sortOrder={sortOrder} />
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Fragment >
    )
}