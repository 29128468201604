import { Fragment, useEffect, useState } from "react";
import { db } from '../../firebase';
import { editTemplate } from '../../firebase/cloudFunctions';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

import { useNavigate, useLocation } from 'react-router-dom'

//import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';

import { uuidv4 } from "@firebase/util";

import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';

import { ReactComponent as AssembledBricks } from '../../assets/images/krinova__AssembledBricks_line3_colour1.svg';
import { ReactComponent as SingleBrick } from '../../assets/images/krinova__SingleBrick_line3_colour1.svg';

import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

import { CircularProgress, Box, InputLabel, MenuItem, CardContent, TextField, FormControlLabel, Grid, Select, Checkbox, Radio, Slider, Button, FormControl, Card, FormGroup, Divider } from "@mui/material";
import Explanation from "../Questions/Explanation";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import EditModal from "../ContentCreation/EditModal";
import { useGetCategories } from "../../hooks/questionData/useGetCategories";
import { TemplatePreviewComponent } from "./components/TemplatePreviewComp";
import { TemplateAvailableContentHeader } from "./components/TemplateAvailableContentComp";
import { TemplateCurrentContentHeader } from "./components/TemplateCurentContentHeader";


const TemplateManager = () => {
    const [columns, setColumns] = useState({})
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [saving, setIsSaving] = useState(false);
    const location = useLocation()
    const { ID, groupName } = location.state
    const [allQuestions, setAllQuestions] = useState([])
    const [selectedQuestions, setSelectedQuestion] = useState([]);
    const [assignedQuestions, setAssignedQuestions] = useState([]);
    const navigate = useNavigate();
    const [groupTitle, setGroupTitle] = useState(groupName);
    const [selectedGroupCategory, setSelectedGroupCategory] = useState('');
    const [sortOption, setSortOption] = useState("All")
    const [sortOptionType, setSortOptionType] = useState("All")

    const { groupCategories, questionCategories } = useGetCategories();

    const handleSave = ({ value }) => {
        editTemplate({
            name: value,
            templateID: ID,
        })
    };
    //let selectedItems = []

    function handleNewSelectedGroupCategory(event) {
        setSelectedGroupCategory(event.target.value);
        const groupValue = event.target.value;

        editTemplate({
            category: groupValue,
            templateID: ID,
        })
    };

    useEffect(() => {
        const getData = async () => {
            await getDoc(doc(db, "QuestionTemplates/" + ID)).then((querySnapshot) => {
                setSelectedGroupCategory(querySnapshot.data().category)
            });

            const assignedQuestionsArray = [];
            const availableQuestions = [];
            const localAllQuestions = [];

            const assignedSnap = await getDocs(query(collection(db, "QuestionTemplates/" + ID + "/AssignedQuestions"), orderBy('order')));
            const questionSnap = await getDocs(query(collection(db, "Questions"), orderBy("shortDesc")));


            assignedSnap.forEach((assignedQuestion) => {
                let question = questionSnap.docs.find(question => assignedQuestion.data().questionID === question.id);
                if (question != null) {
                    assignedQuestionsArray.push({
                        key: question.id,
                        draggableId: uuidv4(),
                        ...question.data(),
                    });
                }
                else {
                    console.error("Could not find Question:", assignedQuestion.id)
                }
            })

            questionSnap.forEach((question) => {
                if (question.data().deleted !== true) {
                    const draggableId = uuidv4();
                    localAllQuestions.push({
                        key: question.id,
                        draggableId: draggableId,
                        ...question.data(),
                    })
                    if (question.data().perks === "Infinity" || assignedQuestionsArray.findIndex(usedQuestion => question.id === usedQuestion.key) === -1) {
                        availableQuestions.push({
                            key: question.id,
                            draggableId: draggableId,
                            ...question.data(),
                        });
                    }
                }

            })

            setAllQuestions(localAllQuestions);
            setAssignedQuestions(assignedQuestionsArray);
            setSelectedQuestion(assignedQuestionsArray);

            const columnsFromBackend = {
                questionColumn: {
                    name: "Content",
                    items: availableQuestions,
                },
                selectedColumn: {
                    name: "Group",
                    items: assignedQuestionsArray,
                },
            };
            setColumns(columnsFromBackend)


            setLoading(false);
        }

        getData();
    }, []);

    if (loading) {
        return <CircularProgress />
    }

    function fillList(items) {
        let selectedItems = [];
        setSelectedQuestion(items)
        items.forEach((item) => {
            selectedItems.push({ key: item.key })
        })
        console.log(selectedItems)
        return selectedItems
    }

    function addToList(item) {
        if (sortOption === "All" || sortOption === "") {
            if (sortOptionType === "All" || sortOptionType === "") {
                return true;
            }
            else {
                if (sortOptionType === item.type) {
                    return true;
                }
                else {
                    return false;
                }
            }

        }
        else {
            if (sortOptionType === "All" || sortOptionType === "") {
                if (sortOption === item.category) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                if (sortOption === item.category && sortOptionType === item.type) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
    }

    function sortList(localSortOption) {
        setSortOption(localSortOption);
        console.log("Sort:", localSortOption, sortOption, allQuestions)
        const availList = [];

        if (localSortOption === "All" || localSortOption === "") {
            if (sortOptionType === "All" || sortOptionType === "") {
                allQuestions.forEach(element => {
                    console.log("Compare", element.category, localSortOption)
                    if (!isInList(element, selectedQuestions) || element.perks === "Infinity") {
                        availList.push(element)
                    }
                });
            }
            else {
                allQuestions.forEach(element => {
                    console.log("Compare", element.category, localSortOption)
                    if (element.type === sortOptionType && (!isInList(element, selectedQuestions) || element.perks === "Infinity")) {
                        availList.push(element)
                    }
                });
            }

        }
        else {
            if (sortOptionType === "All" || sortOptionType === "") {
                allQuestions.forEach(element => {
                    console.log("Compare", element.category, localSortOption)
                    if (element.category === localSortOption && (!isInList(element, selectedQuestions) || element.perks === "Infinity")) {
                        availList.push(element)
                    }
                });
            }
            else {
                allQuestions.forEach(element => {
                    console.log("Compare", element.category, localSortOption)
                    if (element.category === localSortOption && element.type === sortOptionType && (!isInList(element, selectedQuestions) || element.perks === "Infinity")) {
                        availList.push(element)
                    }
                });
            }
        }

        console.log("Available: ", availList)

        const columnsFromBackend = {
            questionColumn: {
                name: "Content",
                items: availList
            },
            selectedColumn: {
                name: "Group",
                items: selectedQuestions
            },
        };

        setColumns(columnsFromBackend);
    }

    function isInList(item, list) {
        let found = false;
        list.forEach(element => {
            if (element.key === item.key) {
                found = true;
            }
        });

        return found;
    }

    function sortListByType(localSortType) {
        setSortOptionType(localSortType);
        console.log("Sort:", localSortType, sortOption)
        const availList = [];
        if (localSortType === "All" || localSortType === "") {
            if (sortOption === "All" || sortOption === "") {
                allQuestions.forEach(element => {
                    if (!isInList(element, selectedQuestions) || element.perks === "Infinity") {
                        availList.push(element)
                    }
                });
            }
            else {
                allQuestions.forEach(element => {
                    if (element.category === sortOption && (!isInList(element, selectedQuestions) || element.perks === "Infinity")) {
                        availList.push(element)
                    }
                });
            }
        }
        else {
            if (sortOption === "All" || sortOption === "") {
                allQuestions.forEach(element => {
                    if (element.type === localSortType && (!isInList(element, selectedQuestions) || element.perks === "Infinity")) {
                        availList.push(element)
                    }
                });
            }
            else {
                allQuestions.forEach(element => {
                    if (element.category === sortOption && element.type === localSortType && (!isInList(element, selectedQuestions) || element.perks === "Infinity")) {
                        availList.push(element)
                    }
                });
            }
        }


        const columnsFromBackend = {
            questionColumn: {
                name: "Content",
                items: availList
            },
            selectedColumn: {
                name: "Group",
                items: selectedQuestions
            },
        };

        setColumns(columnsFromBackend);
    }

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        const res = allQuestions.find((obj) => {
            //console.log("Comp: ", obj.draggableId, result.draggableId)
            return obj.draggableId === result.draggableId;
        });

        if (source.droppableId !== destination.droppableId) {
            //console.log("Source: ", source.droppableId)
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];

            if (res != null) {
                if (res.perks === "Infinity") {
                    if (destination.droppableId === "selectedColumn") {
                        const obj = sourceItems[source.index];
                        //console.log("Obj:", obj)
                        destItems.splice(destination.index, 0, { ...obj, draggableId: uuidv4() });

                        setColumns({
                            ...columns,
                            [source.droppableId]: {
                                ...sourceColumn,
                                items: sourceItems
                            },
                            [destination.droppableId]: {
                                ...destColumn,
                                items: destItems
                            }

                        });
                    }
                    else {
                        //console.log("Obj:", destination.index);

                        const [removed] = sourceItems.splice(source.index, 1);

                        setColumns({
                            ...columns,
                            [source.droppableId]: {
                                ...sourceColumn,
                                items: sourceItems
                            },
                            [destination.droppableId]: {
                                ...destColumn,
                                items: destItems
                            }

                        });
                    }
                }
                else {
                    //console.log("Obj:", source)
                    const [removed] = sourceItems.splice(source.index, 1);


                    if (destination.droppableId === "questionColumn") {
                        if (addToList(removed) && removed.deleted !== true) {
                            destItems.splice(destination.index, 0, removed);
                        }
                    }
                    else {
                        destItems.splice(destination.index, 0, removed);
                    }

                    setColumns({
                        ...columns,
                        [source.droppableId]: {
                            ...sourceColumn,
                            items: sourceItems
                        },
                        [destination.droppableId]: {
                            ...destColumn,
                            items: destItems
                        }
                    });
                }
            }
            else {
                const removed = sourceItems.splice(source.index, 1);
                //console.log("Removed (ELSE):", removed);
                setColumns({
                    ...columns,
                    [source.droppableId]: {
                        ...sourceColumn,
                        items: sourceItems
                    },
                    [destination.droppableId]: {
                        ...destColumn,
                        items: destItems
                    }

                });
            }


            //console.log(destItems)
            //console.log(destination.droppableId)
            //const selectedItems = [];
            //let saveColumn = destColumn;
            let saveItems = destItems;
            if (source.droppableId === "selectedColumn") {
                //saveColumn = sourceColumn;
                saveItems = sourceItems;
            }

            //const saveItems = [...saveColumn.items];

            fillList(saveItems)



            /*
            saveItems.forEach((item) => {
                selectedItems.push({ key: item.key })
            })
            console.log(selectedItems)*/

            saveTemplateData(ID, fillList(saveItems));

            /*
            setIsSaving(true);
            editTemplate({
                templateID: ID,
                questions: fillList(saveItems),
            })
            setIsSaving(false);*/

        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            })
        }
    };

    async function saveTemplateData(ID, questions) {
        setIsSaving(true);
        await editTemplate({
            templateID: ID,
            questions: questions,
        })
        setIsSaving(false);
    }

    //console.log('selected ' + columns.selectedColumn.items)
    function valuetext(value) {
        return `${value}`;
    }

    // function valueLabelFormat(value) {
    //     return marks.findIndex((mark) => mark.value === value) + 1;
    // }

    function createMarks(options) {
        let marks = [];

        String(options).split(';').forEach((option) => {
            marks.push({
                value: option,
                label: option,
            });
        })

        return marks;
    }

    const sliderChange = (event, value) => {

    }

    const handleChange = (event) => {
        sortList(event.target.value);
    };

    const handleChangeType = (event) => {
        sortListByType(event.target.value);
    };



    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", marginLeft: 20, marginTop: 10 }}>
                <Button size="medium" variant="contained" sx={{ mb: 5 }} onClick={() => navigate(-1)}>Go Back</Button>
                {saving ?
                    (
                        <div style={{ marginLeft: 10, justifyContent: 'center', padding: 6 }}>
                            <CircularProgress size={15} color="success" /> Saving...
                        </div>
                    ) : (
                        <></>
                    )}
            </div>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={14}>
                    <Grid item sm={12} md={7} xl={6}>
                        <div style={{ display: "flex", justifyContent: "left", }}>
                            <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)}>
                                {Object.entries(columns).map(([columnId, column], index) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                marginTop: "20px",
                                                marginRight: "20px",
                                            }}
                                            key={columnId}
                                        >
                                            {column.name === "Content" ? (
                                                <TemplateAvailableContentHeader
                                                    columnId={columnId}
                                                    sortList={sortList}
                                                    sortListByType={sortListByType}
                                                />
                                            ) : (
                                                <TemplateCurrentContentHeader
                                                    columnId={columnId}
                                                    groupTitle={groupTitle}
                                                    setGroupTitle={setGroupTitle}
                                                    selectedGroupCategory={selectedGroupCategory}
                                                    handleNewSelectedGroupCategory={handleNewSelectedGroupCategory}
                                                    handleSave={handleSave}
                                                />
                                            )}

                                            <div style={{ marginTop: 8 }}>
                                                <Droppable droppableId={String(columnId)} key={columnId}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                style={{
                                                                    background: snapshot.isDraggingOver
                                                                        ? "#f5f6f7"
                                                                        : "white",
                                                                    padding: 10,
                                                                    width: 400,
                                                                    minHeight: 300,
                                                                    height: "85vh",
                                                                    overflowY: "auto",
                                                                    overflowX: "hidden",
                                                                    borderRadius: 5,
                                                                    border: "1px solid #f2f3f5",
                                                                    marginBottom: 20,
                                                                    marginRight: 10,
                                                                }}
                                                            >
                                                                {column.items.map((item, index) => {
                                                                    return (
                                                                        <Draggable
                                                                            key={item.draggableId}
                                                                            draggableId={item.draggableId}
                                                                            index={index}
                                                                        >
                                                                            {(provided, snapshot, selectedColumn) => {
                                                                                let textColor = "black"
                                                                                if (item.deleted) {
                                                                                    textColor = "red"
                                                                                }

                                                                                return (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={{
                                                                                            userSelect: "none",
                                                                                            padding: 12,
                                                                                            borderRadius: 5,
                                                                                            margin: "0 0 8px 0",
                                                                                            minHeight: "50px",
                                                                                            border: "1px solid #f2f3f5",
                                                                                            backgroundColor: snapshot.isDragging
                                                                                                ? "#f2f3f5"
                                                                                                : "#white",
                                                                                            color: textColor,
                                                                                            ...provided.draggableProps.style
                                                                                        }}
                                                                                    >
                                                                                        {item.type === "RadioButton" ? (
                                                                                            <RadioButtonCheckedRoundedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : item.type === "miro" ? (
                                                                                            <LinkRoundedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : item.type === "fileUpload" ? (
                                                                                            <UploadFileRoundedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : item.type === "Checked" ? (
                                                                                            <CheckBoxRoundedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : item.type === "RichTextInput" ? (
                                                                                            <SubjectRoundedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : item.type === "Option" ? (
                                                                                            <CheckBoxRoundedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : item.type === "Slider" ? (
                                                                                            <LinearScaleOutlinedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : item.type === "TextInput" ? (
                                                                                            <TextFieldsRoundedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : item.type === "EmbededLinkInput" ? (
                                                                                            <OndemandVideoRoundedIcon style={{ marginRight: 10, color: "gray" }} />
                                                                                        ) : (
                                                                                            <span></span>
                                                                                        )}

                                                                                        {item.deleted ? (item.shortDesc + " (Removed)") : (item.shortDesc)}

                                                                                        {/*item.type === "miro" && (<LinkRoundedIcon style={{ marginLeft: 10, color: "green" }} />)*/}
                                                                                        {item.perks === "Infinity" && (<AllInclusiveIcon style={{ marginLeft: 10, color: "green" }} />)}

                                                                                        {selectedColumn.source.droppableId !== "selectedColumn" && item.perks !== "Infinity" &&
                                                                                            <EditModal short={item.shortDesc} text={item.text} moreInfo={item.explanation} questionId={item.key} type={item.type} tag={item.category} />
                                                                                        }
                                                                                        {/* <Button size="small">More</Button> */}
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        );
                                                    }}
                                                </Droppable>
                                            </div>
                                        </div>
                                    );
                                })}
                            </DragDropContext>
                        </div>
                    </Grid>

                    <Grid item sm={12} md={12} xl={6}>
                        <TemplatePreviewComponent assignedQuestions={assignedQuestions} columns={columns} />
                    </Grid>
                </Grid>
                <div className="save_questionaire" style={{ textAlign: "center" }}>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    ) : ('')}
                </div>
            </Box>
        </>
    );
}

export default TemplateManager;
