import { TableCell } from "@mui/material"


export const TableRowContactPerson = ({ project, iboNames }) => {

    return (
        <TableCell padding='normal'>
            {iboNames.filter(iboName => iboName.id === project?.IBOOwner[0]).map(filteredName => (
                <span key={filteredName}>
                    {filteredName.value}
                </span>
            ))}
        </TableCell>
    )
}