import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";


export const useGetFirstMeetInfo = (projectID) => {
    const [loading, setLoading] = useState(true);
    const [firstMeetData, setFirstMeetData] = useState({});

    useEffect(() => {
        if (!projectID) return;

        const ref = onSnapshot(doc(db, "Projects/" + projectID + "/Steps/meet1"), (snapshot) => {
            if (snapshot.exists()){
                setFirstMeetData({ id: snapshot.id, ...snapshot.data() })
            }
            else{
                setFirstMeetData({});
            }
            
            setLoading(false);
        })

        firebaseRefList.push(ref);
    }, [projectID])


    return { loading, firstMeetData };
}