import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase'

export const useGetOwnProjectEntryQuestions = () => {
    const { currentUser } = useAuth();
    const [questionList, setQuestionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getProject = async () => {
            if (currentUser != null) {
                const userProjects = await getDoc(doc(db, "UserProject/" + currentUser.uid));

                if (userProjects.exists()) {
                    const data = userProjects.data();

                    if (data.projects != null) {
                        const entryData = await getDocs(collection(db, "Projects/" + data.projects[0] + "/Steps/a/Questions"));


                        if (entryData.empty === false) {
                            const questions = [];
                            entryData.forEach((question) => {
                                questions.push({ id: question.id, answerData: question.data() })
                            });

                            setQuestionList(questions);
                        }
                    }
                }
            }

            setIsLoading(false)
        }
        getProject()
    }, [currentUser]);

    return { questionList, isLoading };
};

export const useGetProjectEntryQuestions = (projectID) => {
    const [questionList, setQuestionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getProject = async () => {
            if (projectID != null) {
                const entryData = await getDocs(collection(db, "Projects/" + projectID + "/Steps/a/Questions"));


                if (entryData.empty === false) {
                    const questions = [];
                    entryData.forEach((question) => {
                        questions.push({ id: question.id, answerData: question.data() })
                    });

                    setQuestionList(questions);
                }
            }

            setIsLoading(false)
        }
        getProject()
    }, [projectID]);

    return { questionList, isLoading };
};