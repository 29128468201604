import { collection, onSnapshot} from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList';

export const useGetCategories = () => {
    const [loading, setLoading] = useState(true);
    const [questionCategories, setQuestionCategories] = useState([]);
    const [groupCategories, setGroupCategories] = useState([]);

    useEffect(() => {
        const ref = onSnapshot(collection(db, "Category"), (snapshot) => {
            snapshot.docs.forEach((category) => {
                if (category.id === 'question') {
                    setQuestionCategories(category.data()?.category);
                }
                else if (category.id === 'group') {
                    setGroupCategories(category.data()?.category);
                }
            });

            setLoading(false);
        });

        firebaseRefList.push(ref);

    }, [])

    return { loading, questionCategories, groupCategories };
};