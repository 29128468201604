import { Box, Slider } from "@mui/material"
import parse from 'html-react-parser';

export const TemplateSliderCard = ({ assignedQuestion }) => {
    function valuetext(value) {
        return `${value}`;
    }

    function createMarks(options) {
        let marks = [];

        String(options).split(';').forEach((option) => {
            marks.push({
                value: option,
                label: option,
            });
        })

        return marks;
    }

    return (
        <div style={{ marginLeft: '20px' }}>
            <span style={{ display: 'block', marginTop: 10 }}>
                {parse(assignedQuestion.text)}
            </span>

            <Box sx={{ width: 400 }}>
                <Slider
                    aria-label="Restricted values"
                    getAriaValueText={valuetext}
                    step={null}
                    valueLabelDisplay="off"
                    marks={createMarks(assignedQuestion.options)}
                    min={Number(createMarks(assignedQuestion.options)[0].value)}
                    max={Number(createMarks(assignedQuestion.options)[createMarks(assignedQuestion.options).length - 1].value)}
                />
            </Box>
        </div>
    )
}