import { Card, CardContent } from "@mui/material"
import Explanation from "../../Explanation"
import parse from 'html-react-parser';


export const RichTextInputCard = ({ assignedQuestion, question }) => {

    return (
        <Card className="characters" key={question.key} sx={{ maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem', marginLeft: '-15px' }}>
            <CardContent className="rich-text-content">
                {question.explanation !== "" || null ? (<Explanation key={question.key} data={question} />) : ('')}
                {parse(question.text)}
                {assignedQuestion.note != null ? (
                    <p className="note-style"><strong>{assignedQuestion.note}</strong></p>) : ("")}
            </CardContent>
        </Card>
    )
}