import { useState } from "react"
import { Alert } from 'react-bootstrap'
import Box from '@mui/material/Box';
import { editBDUser } from '../../firebase/cloudFunctions';
import CircularProgress from "@mui/material/CircularProgress";
import { Checkbox, Divider, FormControlLabel, Stack } from "@mui/material";

const EditBD = ({ user, onClose }) => {
    const [emailRef, setEmailRef] = useState(user.email);
    const [firstNameRef, setFirstNameRef] = useState(user.firstName);
    const [lastNameRef, setLastNameRef] = useState(user.lastName);

    function isBDSelectable(user) {
        if (user?.BDSelectable != null) {
            return user?.BDSelectable;
        }
        else {
            return true;
        }
    }

    function isIBOSelectable(user) {
        if (user?.IBOSelectable != null) {
            return user?.IBOSelectable;
        }
        else {
            return true;
        }
    }

    const [displayIBO, setDisplayIBO] = useState(isIBOSelectable(user));
    const [displayBD, setDisplayBD] = useState(isBDSelectable(user));

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const changeEmail = (event) => {
        setEmailRef(event.target.value);
    }

    const changeFirstName = (event) => {
        setFirstNameRef(event.target.value);
    }

    const changeLastName = (event) => {
        setLastNameRef(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setError("");
            setLoading(true);

            //console.log("Send:", firstNameRef);
            //console.log("Send:", lastNameRef);

            editBDUser({
                userID: user.id,
                firstName: firstNameRef,
                lastName: lastNameRef,
                IBOSelectable: displayIBO,
                BDSelectable: displayBD,
            }).then(() => {
                onClose();
            }).catch((error) => {
                setError("Failed to update user. " + error);
            }).finally(() => {
                setLoading(false);
            });

        } catch (e) {
            setError("Failed to create user. " + e);
            setLoading(false);
        }
    }

    return (
        <Box sx={style}>
            <div className="log-box" >

                <h3>Edit BD</h3>
                {error && <Alert variant="danger">{error}</Alert>}

                <form onSubmit={handleSubmit}>

                    <div className="form-group">
                        <label>Email:</label>
                        <input type="email" className="form-control" value={emailRef} placeholder="Enter email" disabled />
                    </div>

                    <div className="form-group">
                        <label>First Name:</label>
                        <input type="text" className="form-control" value={firstNameRef} onChange={changeFirstName} placeholder="Enter first name" required />
                    </div>

                    <div className="form-group">
                        <label>Last Name:</label>
                        <input type="text" className="form-control" value={lastNameRef} onChange={changeLastName} placeholder="Enter last name" required />
                    </div>

                    <Divider sx={{ marginY: '10px' }} />

                    <Stack>
                        <FormControlLabel control={<Checkbox checked={displayIBO} onChange={() => setDisplayIBO(!displayIBO)} />} label="IBO-selectable" />
                        <FormControlLabel control={<Checkbox checked={displayBD} onChange={() => setDisplayBD(!displayBD)} />} label="BD-selectable" />
                    </Stack>
                    <br></br>

                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                    ) : (
                        <button disabled={loading} type="submit" className="btn btn-info btn-lg btn-block">Update account</button>
                    )}
                </form>

            </div>
        </Box>

    )
}

export default EditBD