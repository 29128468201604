import { Box, Divider, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, OutlinedInput, Stack, Tooltip } from "@mui/material";
import { useEffect, useState } from "react"
import { OwnershipCardRow } from "./OwnershipCardRow";
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const OwnershipCard = ({ value, handleChangeOwneship, isOwnershipInvalid, setOwnershipInvalid }) => {
    const [edited, setEdited] = useState(false);
    const [ownerList, setOwnerList] = useState([{
        ownerType: '',
        text: '',
        procent: 0,
    }]);

    useEffect(() => {
        if (value != null && value.length > 0){
            setOwnerList(value);
        }
    }, [value])

    useEffect(() => {
        if (calcTotal() !== 100){
            setOwnershipInvalid(true);
        }
        else {
            let emptyFields = false;
            for (let index = 0; index < ownerList.length; index++) {
                const element = ownerList[index];
                if (element.ownerType == null || element.ownerType === '' || element.text == null || element.text === ''){
                    emptyFields = true;
                    break;
                }
            }

            setOwnershipInvalid(emptyFields);
        }

        if (edited === true){
            handleChangeOwneship(ownerList);
        }
        
    }, [ownerList]);

    const handleChangeOwnerType = (index, value) => {
        let local = [...ownerList];

        local[index] = { ...local[index], ownerType: value };

        setOwnerList(local);
        setEdited(true);
    }

    const handleChangeText = (index, value) => {
        let local = [...ownerList];

        local[index] = { ...local[index], text: value };

        setOwnerList(local);
        setEdited(true);
    }

    const handleChangeProcent = (index, value) => {
        if (isNaN(Number(value)) === false) {
            let local = [...ownerList];

            if (Number(value) > 100) {
                local[index] = { ...local[index], procent: 100 };
            }
            else if (Number(value) < 0) {
                local[index] = { ...local[index], procent: 0 };
            }
            else {
                local[index] = { ...local[index], procent: Number(value) };
            }


            setOwnerList(local);
            setEdited(true);
        }
    }

    const addRow = () => {
        setOwnerList(prev => [...prev, {
            ownerType: '',
            text: '',
            procent: 0,
        }]);
        setEdited(true);
    }

    const deleteRow = (index) => {
        let list = [...ownerList];
        list.splice(index, 1);
        setOwnerList(list);
        setEdited(true);
    }


    const calcTotal = () => {
        let totalSum = 0;

        for (let index = 0; index < ownerList.length; index++) {
            const item = ownerList[index];

            totalSum += item.procent;
        }

        return totalSum;
    }

    return (
        <Grid item xs={12} sm={12}>
            <FormLabel sx={{ paddingBottom: '5px' }}>Company Ownership</FormLabel>

            <Stack sx={{ width: '100%' }}>
                {ownerList.map((item, index) => (
                    <OwnershipCardRow
                        key={index}
                        item={item}
                        index={index}
                        handleChangeOwnerType={handleChangeOwnerType}
                        handleChangeText={handleChangeText}
                        handleChangeProcent={handleChangeProcent}
                        deleteRow={() => deleteRow(index)}
                        isOwnershipInvalid={isOwnershipInvalid}
                    />
                ))}

                <Grid container spacing={1} sx={{ width: '100%', mb: '5px' }} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
                    <Grid item xs={4}>
                        <IconButton size="small" onClick={addRow}>
                            <AddCircleIcon htmlColor="#4eacc1" />
                        </IconButton>
                    </Grid>

                    <Grid item xs={4}>

                    </Grid>

                    <Grid item xs={3}>

                        <Stack>
                            <Box>
                                <Divider className="blue thick" />
                            </Box>
                            <Tooltip arrow title={calcTotal() !== 100 ? "Total procentage is not adding up to 100%" : ""}>
                                <span>
                                    <OutlinedInput
                                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                        size="small"
                                        value={calcTotal()}
                                        disabled
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: calcTotal() !== 100 ? "red" : "gray",
                                            },
                                        }}
                                    />
                                </span>
                            </Tooltip>


                        </Stack>

                    </Grid>

                    <Grid item xs={1}>
                        <div style={{ width: '25px' }}></div>
                    </Grid>

                </Grid>
            </Stack>
            <FormHelperText error={isOwnershipInvalid}>{isOwnershipInvalid ? "Add ownership to add up to 100% and fill in all fields" : ""}</FormHelperText>
        </Grid>
    )
}