import { Checkbox, DialogContent, Divider, FormControlLabel, IconButton, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../helper/bootstrapDialog";
import PendingIcon from '@mui/icons-material/Pending';
import CloseIcon from '@mui/icons-material/Close';
import { TrackLabels } from "../../constants/labels/labels";
import TimerIcon from '@mui/icons-material/Timer';
import { useGetTimeLogData } from "../../hooks/timelogData/useGetTimelogData";
import { TimeLogBase } from "./TimeLogBase";
import { TimeReportProgressBar } from "./TimeReportBar";
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import { useNavigate } from "react-router-dom";

export const TimeReportDialog = ({ projData, projectSettings, showBar = false }) => {
    const { timeLog, loading } = useGetTimeLogData(projData?.id, projData?.activeTrack)
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getTotalTimeDecimal = () => {
        let totalHours = 0;
        let totalMinutes = 0;

        for (let index = 0; index < timeLog.length; index++) {
            const timeRow = timeLog[index];

            if (timeRow?.amountOfTime != null) {
                let timeSplit = timeRow?.amountOfTime.split(':')

                if (timeSplit[0] != null && isNaN(Number(timeSplit[0])) === false) {
                    totalHours += Number(timeSplit[0]);
                }

                if (timeSplit[1] != null && isNaN(Number(timeSplit[1])) === false) {
                    totalMinutes += Number(timeSplit[1]);
                }
            }
        }


        let timeDec = totalMinutes / 60

        timeDec += totalHours;

        return timeDec;
    }

    const handleClickIsIneligible = () => {
        navigate('/home#step1', { state: { projectData: projData } })
    }

    return (
        <>
            <span style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                {showBar ? (
                    <TimeReportProgressBar projData={projData} totalTime={getTotalTimeDecimal()} projectSettings={projectSettings} isIneligible={projData.ineligible != null && projData.ineligible[projData.activeTrack] === true } />
                ) : ("")}
                {projData.ineligible != null && projData.ineligible[projData.activeTrack] === true ? (
                    <Tooltip arrow title={"No longer eligable for " + TrackLabels[projData.activeTrack].label + ", click to review"}>
                        <FlagRoundedIcon
                            htmlColor={"#da0000"}
                            sx={{
                                height: '22px',
                                cursor: "pointer",
                                marginRight: '-3px',
                                ':hover': {
                                    color: '#4eacc1'
                                }
                            }}
                            onClick={handleClickIsIneligible}
                        />
                    </Tooltip>

                ) : (
                    <TimerIcon
                        htmlColor={timeLog.length > 0 ? '#bf066d' : "#d1d1d1"}
                        sx={{
                            height: '22px',
                            cursor: "pointer",
                            marginRight: '-3px',
                            ':hover': {
                                color: '#4eacc1'
                            }
                        }}
                        onClick={handleClickOpen}
                    />
                )}

            </span>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ padding: '10px 10px 0px 10px' }}><strong>Timelog tool - {TrackLabels[projData.activeTrack]?.label}</strong></h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent>
                    <TimeLogBase projData={projData} timeLog={timeLog} projectSettings={projectSettings} totalTime={getTotalTimeDecimal()} loadingLogs={loading} />
                </DialogContent>
            </BootstrapDialog>
        </>
    );
}