import { useEffect } from 'react';
import { deleteQuestion } from '../../firebase/cloudFunctions';

const useDeleteQuestion = question => {
    useEffect(() => {
        if (!question) {
            return;
        }

        (async () => {
            await deleteQuestion({
                questionID: question.key,
            });
        })();

    }, [question]);

    return {}
}

export default useDeleteQuestion