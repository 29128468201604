    /**
     * 
     * @param {Date} deadline
     * @returns {boolean}
     */
    export const isLate = (deadline) => {
        const now = new Date(Date.now());
        if (deadline < now) {
            return true;
        }

        return false;
    }
