import { useEffect, useState } from "react"
import firebaseRefList from "../../firebase/firebaseRefList";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";


export const useGetStepListData = (project) => {
    const [loading, setLoading] = useState(true);
    const [stepList, setStepList] = useState(Object);

    useEffect(() => {
        if (!project) return;
        const ref = onSnapshot(collection(db, "Projects/" + project?.id + "/Steps"), (snapshot) => {
            let localList = {};

            snapshot.docs.forEach((doc) => {
                localList[doc.id] = doc.data();
            });

            setStepList(localList);
            setLoading(false);
        })

        firebaseRefList.push(ref);
    }, [project]);

    return { loading, stepList }
}