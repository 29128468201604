import { Fragment, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import { entryStepQuestions } from '../../firebase/cloudFunctions';
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { GLOBAL_GOALS_FIELDS } from "../../constants/lang/eng/fields";
import { ErrorAlert } from "../../helper/errorAlert";
import { BusinessIdeasQuestions, BusinessIdeasQuestionsEstablished, BusinessIdeasQuestionsNewComp } from "../../constants/ideasQuestions/businessIdeaQuestions";
import { FormHelperText, Radio, RadioGroup } from "@mui/material";

const UpdateIdeasV2 = ({ projectID, ideasData, projData, closeModal }) => {
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [answers, setAnswers] = useState(Object);
    const [update, setUpdate] = useState(false);

    const BusinessQuestions = useMemo(() => {
        if (projData?.existingComp === 'no' || projData?.existingComp === 'yes_new' || projData?.existingComp === "Yes, in a newly formed company based on the idea" || projData?.existingComp === 'No/Not yet') {
            return BusinessIdeasQuestionsNewComp;
        }
        else if (projData?.existingComp === 'yes_established' || projData?.existingComp === 'Yes, within an established company') {
            return BusinessIdeasQuestionsEstablished;
        }
        else {
            return {};
        }
    }, [projData]);

    useEffect(() => {
        let ideasKeys = Object.keys(BusinessQuestions);

        let localAnswers = {};
        for (let index = 0; index < ideasKeys.length; index++) {
            const ideaKey = ideasKeys[index];

            let ideaObject = ideasData.find(obj => obj.id === ideaKey);
            if (ideaKey === 'globalGoals') {
                const localGlobalGoals = {};

                if (ideaObject?.answerData?.answer != null && ideaObject?.answerData?.answer !== "") {
                    const split = String(ideaObject.answerData?.answer).split(';');

                    Object.keys(GLOBAL_GOALS_FIELDS).forEach((goal) => {
                        if (split.indexOf(GLOBAL_GOALS_FIELDS[goal]) === -1) {
                            localGlobalGoals[goal] = false;
                        }
                        else {
                            localGlobalGoals[goal] = true;
                        }
                    });
                }
                else {
                    Object.keys(GLOBAL_GOALS_FIELDS).forEach((goal) => {
                        localGlobalGoals[goal] = false;
                    });
                }

                localAnswers[ideaKey] = localGlobalGoals;
            }
            else {
                if (ideaObject?.answerData?.answer != null && ideaObject?.answerData?.answer !== "") {
                    localAnswers[ideaKey] = ideaObject?.answerData?.answer;
                }
                else {
                    localAnswers[ideaKey] = "";
                }
            }
        }

        setAnswers(localAnswers);
    }, [ideasData])

    const isValidUpdate = useMemo(() => {
        let ideasKeys = Object.keys(BusinessQuestions);

        let changedFields = false;
        for (let index = 0; index < ideasKeys.length; index++) {
            const ideaKey = ideasKeys[index];

            let ideaObject = ideasData.find(obj => obj.id === ideaKey);
            if (BusinessQuestions[ideaKey]?.required === true) {
                console.log("Data: ", ideaKey, ideaObject?.answerData, answers[ideaKey])
                if (ideaObject != null && ideaObject?.answerData != null && ideaObject?.answerData !== "" && ideaObject?.answerData?.answer != null && ideaObject?.answerData?.answer !== "") {
                    if (ideaObject?.answerData?.answer !== answers[ideaKey] && answers[ideaKey] != null && answers[ideaKey] !== "") {
                        console.log("Field changed")
                        changedFields = true;
                    }
                    else if (answers[ideaKey] == null || answers[ideaKey] === "") {
                        console.log("Return false 1")
                        return false;
                    }
                }
                else if (answers[ideaKey] == null || answers[ideaKey] === "") {
                    console.log("Return false 2")

                    return false;
                }
                else{
                    console.log("Modified: ", ideaKey, answers[ideaKey])
                    changedFields = true;
                }
            }
            else {
                console.log("Not required: ", )
                if (ideaObject?.answerData?.answer !== answers[ideaKey]) {
                    console.log("Field changed")
                    changedFields = true;
                }
            }
        }

        return changedFields;
    }, [ideasData, answers]);


    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    const handleChange = (event) => {
        let localAnswers = { ...answers };
        const value = event.target.value;

        switch (event.target.name) {
            case "globalGoals":
                const key = getKeyByValue(GLOBAL_GOALS_FIELDS, value);
                console.log("Answers: ", key, localAnswers[event.target.name]);
                localAnswers[event.target.name][key] = !localAnswers[event.target.name][key];

                /*
                localAnswers[event.target.name][key] = !localAnswers[event.target.name][key];
                let goalsString = "";
                Object.keys(localAnswers[event.target.name]).forEach((goal) => {
                    if (localAnswers[event.target.name][goal] === true) {
                        goalsString += GLOBAL_GOALS_FIELDS[goal] + ";"
                    }
                });

                goalsString = goalsString.slice(0, goalsString.length - 1);
                localAnswers[event.target.name] = goalsString;*/
                break;
            default:
                localAnswers[event.target.name] = value;
                break;
        }

        setAnswers(localAnswers);
        setUpdate(!update);
    }


    function collectData() {
        const dataToSend = [];

        let ideasKeys = Object.keys(BusinessQuestions);
        for (let index = 0; index < ideasKeys.length; index++) {
            const ideaKey = ideasKeys[index];

            if (answers[ideaKey] != null && answers[ideaKey] !== "") {
                if (ideaKey === 'globalGoals') {
                    let goalsString = "";
                    Object.keys(answers[ideaKey]).forEach((goal) => {
                        if (answers[ideaKey][goal] === true) {
                            goalsString += GLOBAL_GOALS_FIELDS[goal] + ";"
                        }
                    });

                    dataToSend.push({ title: ideaKey, answer: goalsString })
                }
                else {
                    dataToSend.push({ title: ideaKey, answer: answers[ideaKey] })
                }

            }
        }

        return dataToSend;
    }

    const handleSubmit = e => {
        e.preventDefault();

        setIsLoading(true);
        console.log(projectID);

        entryStepQuestions({
            projectID: projectID,
            entryQuestions: collectData(),
            updateData: true,
        }).then(() => {
            closeModal();
        }).catch((error) => {
            setErrorInfo(error);
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        })

    };

    return (
        <Box className="update-ideas">
            <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={closeModal}>
                <CloseRoundedIcon /></IconButton>
            <div style={{ marginBottom: '20px' }}>
                <span><strong>Update business idea:</strong></span>
            </div>
            <Fragment>
                <form onSubmit={handleSubmit}>
                    <Grid container gap={1} justifyContent="center">
                        {Object.keys(BusinessQuestions).map((ideaKey, index) => {
                            const textlabel = BusinessQuestions[ideaKey]?.orderLabel + " " + BusinessQuestions[ideaKey]?.text;
                            if ((BusinessQuestions[ideaKey]?.requiresKey != null && answers[BusinessQuestions[ideaKey]?.requiresKey] === BusinessQuestions[ideaKey]?.requiresVal) || BusinessQuestions[ideaKey]?.requiresKey == null) {
                                if (BusinessQuestions[ideaKey].type === 'multiCheckbox') {
                                    const options = BusinessQuestions[ideaKey]?.options;
                                    return (
                                        <Grid key={ideaKey} item xs={12} sm={12} md={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                            <FormControl sx={{ width: '97%', gap: '4px' }} required={BusinessQuestions[ideaKey]?.required} component="fieldset" error={(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required}>
                                                {index === 0 ? (<h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Business idea:</h6>) : ("")}
                                                <FormLabel sx={{ marginX: '5px' }}>{textlabel}</FormLabel>
                                                <FormGroup aria-label="position" id="reference">
                                                    {options.map((option) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={option?.key}
                                                                control={<Checkbox
                                                                    value={option?.value}
                                                                    checked={answers[ideaKey] != null && answers[ideaKey][option?.key] != null ? answers[ideaKey][option?.key] : false}
                                                                    onChange={handleChange}
                                                                    name={ideaKey}
                                                                    color="primary"
                                                                />}
                                                                label={option?.label}
                                                            />
                                                        )
                                                    })}
                                                </FormGroup>
                                                {(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? (
                                                    <FormHelperText>{(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? ("Answer is missing") : ("")}</FormHelperText>
                                                ) : (
                                                    <Box height={'24px'}>

                                                    </Box>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    )
                                }
                                else if (BusinessQuestions[ideaKey].type === 'radioGroup') {
                                    const options = BusinessQuestions[ideaKey]?.options;
                                    return (
                                        <Grid key={ideaKey} item xs={12} sm={12} md={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                            <FormControl sx={{ width: '97%', gap: '4px' }} required={BusinessQuestions[ideaKey]?.required} error={(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required}>
                                                <FormLabel sx={{ marginX: '5px' }} id="demo-controlled-radio-buttons-group">{textlabel}</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name={ideaKey}
                                                    sx={{ height: '120px' }}
                                                    value={answers[ideaKey] || null}
                                                    onChange={handleChange}
                                                >
                                                    {options.map((option) => (
                                                        <FormControlLabel
                                                            key={option?.value}
                                                            value={option?.value}
                                                            control={<Radio />}
                                                            label={option?.label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                                {(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? (
                                                    <FormHelperText>{(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? ("Answer is missing") : ("")}</FormHelperText>
                                                ) : (
                                                    <Box height={'24px'}>

                                                    </Box>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    );
                                }
                                else {
                                    return (
                                        <Grid key={ideaKey} item xs={12} sm={12} md={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                            <FormControl sx={{ width: '97%', gap: '4px' }} component="fieldset" required={BusinessQuestions[ideaKey]?.required} error={(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required}>
                                                <FormLabel sx={{ marginX: '5px' }}>{textlabel}</FormLabel>
                                                <TextField
                                                    label="Answer"
                                                    multiline
                                                    name={ideaKey}
                                                    rows={4}
                                                    onChange={handleChange}
                                                    value={answers[ideaKey]}
                                                />
                                                {(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? (
                                                    <FormHelperText>{(answers[ideaKey] == null || answers[ideaKey] === "") && BusinessQuestions[ideaKey]?.required ? ("Answer is missing") : ("")}</FormHelperText>
                                                ) : (
                                                    <Box height={'24px'}>

                                                    </Box>
                                                )}

                                            </FormControl>
                                        </Grid>
                                    );
                                }
                            }
                        })}
                    </Grid>


                    <div className="backConfirm" style={{ paddingTop: '10px' }}>
                        <LoadingButton
                            sx={{ width: '170px' }}
                            onClick={handleSubmit}
                            loading={isLoading}
                            loadingPosition="start"
                            variant="contained"
                            disabled={!isValidUpdate}
                        >
                            Update Info
                        </LoadingButton>
                    </div>
                </form>

            </Fragment>
            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Box>
    );
};

export default UpdateIdeasV2;