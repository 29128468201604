import { TableCell, TableHead, TableRow } from "@mui/material"
import { useAuth } from "../../../../contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { DeleteHeaderCell } from "./TableHeaderCells/DeleteHeaderCell";
import { IsActiveHeaderCell } from "./TableHeaderCells/IsActiveHeaderCell";
import { IBOHeaderCell } from "./TableHeaderCells/IBOHeaderCell";
import { ContactPersonHeaderCell } from "./TableHeaderCells/ContactPersonHeaderCell";
import { AssignedBDHeaderCell } from "./TableHeaderCells/AssignedBDHeaderCell";
import { TrackHeaderCell } from "./TableHeaderCells/TrackHeaderCell";
import { ProgressHeaderCell } from "./TableHeaderCells/ProgressHeaderCell";
import { NextDeadlineHeaderCell } from "./TableHeaderCells/NextDeadlineHeaderCell";


export const BDTableHeader = ({
    orderBy,
    orderByDir,
    handleSetOrderBy, 
    filterStatus, 
    setFilterStatus, 
    searchVal, 
    setSearchVal, 
    bdFilter, 
    setBDFilter,
    trackFilter,
    setTrackFilter,
    isAdmin,
}) => {
    const location = useLocation();
    const selectedTab = location.pathname;

    const { currentUser } = useAuth();

    return (
        <TableHead>
            <TableRow>
                {isAdmin ? (
                    <TableCell></TableCell>
                ) : ("")}
                
                <DeleteHeaderCell selectedTab={selectedTab} role={currentUser?.role} />

                <IsActiveHeaderCell filterStatus={filterStatus} setFilterStatus={setFilterStatus} />

                <IBOHeaderCell searchVal={searchVal} setSearchVal={setSearchVal} orderBy={orderBy} orderByDir={orderByDir} handleSetOrderBy={handleSetOrderBy} />

                <ContactPersonHeaderCell orderBy={orderBy} orderByDir={orderByDir} handleSetOrderBy={handleSetOrderBy} />

                <AssignedBDHeaderCell bdFilter={bdFilter} setBDFilter={setBDFilter} orderBy={orderBy} orderByDir={orderByDir} handleSetOrderBy={handleSetOrderBy} />

                <TrackHeaderCell trackFilter={trackFilter} setTrackFilter={setTrackFilter} orderBy={orderBy} orderByDir={orderByDir} handleSetOrderBy={handleSetOrderBy} />

                <ProgressHeaderCell orderBy={orderBy} orderByDir={orderByDir} handleSetOrderBy={handleSetOrderBy} />

                <NextDeadlineHeaderCell orderBy={orderBy} orderByDir={orderByDir} handleSetOrderBy={handleSetOrderBy} />

                <TableCell>Miro Link</TableCell>

                <TableCell>Comments</TableCell>
                
                <TableCell>Files</TableCell>
            </TableRow>
        </TableHead>
    )
}