import { TableCell, TableSortLabel } from "@mui/material";
import { OrderByLabels } from "../../../../../constants/labels/labels";


export const ProgressHeaderCell = ({ orderBy, orderByDir, handleSetOrderBy }) => {

    return (
        <TableCell style={{ textAlign: "center" }}>
            <TableSortLabel
                active={orderBy === OrderByLabels.Progress}
                direction={orderBy === OrderByLabels.Progress ? orderByDir : 'desc'}
                onClick={() => handleSetOrderBy(OrderByLabels.Progress)}
            >
                Progress
            </TableSortLabel>
        </TableCell>
    );
}