import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { db } from '../../../firebase';
// import { useConfirm } from 'material-ui-confirm';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import useGetUserData from '../../../hooks/userData/useGetUserData';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../../contexts/AuthContext'
import firebaseRefList from '../../../firebase/firebaseRefList'
import { setStepState } from '../../../firebase/cloudFunctions';
import { useConfirm } from 'material-ui-confirm';
import ExitSummaryBD from '../ExitStep/ExitSummaryBD';
import ExitSummaryIBO from '../ExitStep/ExitSummaryIBO';
import { onSnapshot, doc } from 'firebase/firestore';
import { format } from 'date-fns';


export const ExitStep = (props) => {
    const { currentUser } = useAuth();
    const { userData } = useGetUserData();
    //const confirm = useConfirm();
    const [stepBDStatus, setStepBDStatus] = useState("");
    const [stepIBOStatus, setStepIBOStatus] = useState("");
    //const [thisLoading, setThisLoading] = useState(false);
    //const setStepState = firebase.app().functions("europe-west1").httpsCallable('setStepState');
    //define `openSnackbar` state and the function to change it. The value is false by default
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    const [stepData, setStepData] = useState(Object);
    const [finished, setFinished] = useState(false);
    const [finishedIbo, setFinishedIbo] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const confirm = useConfirm();
    const [error, setError] = useState(false);


    useEffect(() => {

        const getStepData = () => {
            const ref = onSnapshot(doc(db, "Projects/" + props.projectData.id + "/Steps/" + props.step), (querySnapshot) => {
                if (querySnapshot.data() != null) {
                    let snap = {
                        ...querySnapshot.data(),
                        key: querySnapshot.id,
                    }

                    if (querySnapshot.data().nextTime != null) {
                        snap["nextDeadline"] = format(new Date(querySnapshot.data().nextTime.seconds * 1000), 'yyyy-MM-dd eeee HH:mm')
                    }

                    if (querySnapshot.data().BDState != null) {
                        setStepBDStatus(querySnapshot.data().BDState);
                    }

                    if (querySnapshot.data().BDState != null && querySnapshot.data().BDState === 'Passed') {
                        setFinished(true)
                    }

                    if (querySnapshot.data().IBOState != null) {
                        setStepIBOStatus(querySnapshot.data().IBOState);
                    }

                    if (querySnapshot.data().IBOState != null && querySnapshot.data().IBOState === 'Completed') {
                        setFinishedIbo(true)
                    }

                    setStepData(snap)
                    setIsLoading(false);
                }
            });

            firebaseRefList.push(ref);
        }

        // return cleanup function
        getStepData();
    }, [isLoading, props.projectData.id]);

    const handleChangeUnlock = (event) => {
        setFinished(event.target.unlocked);
        if (event.target.checked === true) {
            confirm({ title: 'Please confirm', description: 'Are you sure you want to set the status to finished?' })
                .then(() => {
                    setStatusLoading(true);
                    setStepState({
                        projectID: props.projectData.id,
                        stateType: 'BDState',
                        step: "f",
                        state: "Passed",

                    })
                        .then(() => {
                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        })
                        //this code below runs when the message was NOT successfully sent from inside of the cloud function
                        .catch(() => {
                            //set `error` state to true
                            setError(true);

                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        });
                })
                .catch(() => { setStatusLoading(false); });
        } else if (event.target.checked === false) {
            confirm({ title: 'Please confirm', description: 'Are you sure you want to set this step to not finished?' })
                .then(() => {
                    setStatusLoading(true);
                    setStepState({
                        projectID: props.projectData.id,
                        stateType: 'BDState',
                        step: "f",
                        state: "Unlocked",
                    })
                        .then(() => {
                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        })
                        //this code below runs when the message was NOT successfully sent from inside of the cloud function
                        .catch(() => {
                            //set `error` state to true
                            setError(true);

                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        });
                })
                .catch(() => { setStatusLoading(false); });
        }
    };

    const handleChangeUnlockIbo = (event) => {
        setFinished(event.target.unlocked);
        if (event.target.checked === true) {
            confirm({ title: 'Please confirm', description: 'Are you sure you want to set the status to finished?' })
                .then(() => {
                    setStatusLoading(true);
                    setStepState({
                        projectID: props.projectData.id,
                        stateType: 'IBOState',
                        step: "f",
                        state: "Completed",

                    })
                        .then(() => {
                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        })
                        //this code below runs when the message was NOT successfully sent from inside of the cloud function
                        .catch(() => {
                            //set `error` state to true
                            setError(true);

                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        });
                })
                .catch(() => { setStatusLoading(false); });
        } else if (event.target.checked === false) {
            confirm({ title: 'Please confirm', description: 'Are you sure you want to set this step to not finished?' })
                .then(() => {
                    setStatusLoading(true);
                    setStepState({
                        projectID: props.projectData.id,
                        stateType: 'IBOState',
                        step: "f",
                        state: "Not Completed",
                    })
                        .then(() => {
                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        })
                        //this code below runs when the message was NOT successfully sent from inside of the cloud function
                        .catch(() => {
                            //set `error` state to true
                            setError(true);

                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        });
                })
                .catch(() => { setStatusLoading(false); });
        }
    };


    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
    }

    // const location = useLocation()
    // const { projectData } = location.state
    return (
        <div>
            {currentUser.role >= 2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <h3>Summary</h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box>
                            <div style={{ marginTop: '10px' }}>
                                <ExitSummaryBD projectID={props.projectData.id} />
                            </div>
                        </Box>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <h5>Answers:</h5>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box>
                            <div style={{ marginTop: '10px' }}>
                                <ExitSummaryIBO projectID={props.projectData.id} />
                            </div>
                        </Box>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', }}>

                        <div style={{ marginTop: '20px' }}>
                            {statusLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', }}><CircularProgress /></div>
                            ) : (
                                <>

                                    <div style={{ display: 'flex', justifyContent: 'left', }}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            checked={finished}
                                            onChange={handleChangeUnlock}
                                            //disabled={checked !== true}
                                            //inputProps={{ 'aria-label': 'controlled' }}
                                            label="Finish"
                                        />

                                    </div>

                                </>)}
                        </div>
                    </div>
                </>
            ) : (


                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <h3>Summary</h3>
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <h5>Answers:</h5>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box>
                            <div style={{ marginTop: '10px' }}>
                                <ExitSummaryIBO projectID={props.projectData.id} />
                            </div>
                        </Box>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', }}>

                        <div style={{ marginTop: '20px' }}>
                            {statusLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', }}><CircularProgress /></div>
                            ) : (
                                <>

                                    <div style={{ display: 'flex', justifyContent: 'left', }}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            checked={finishedIbo}
                                            onChange={handleChangeUnlockIbo}
                                            //disabled={checked !== true}
                                            //inputProps={{ 'aria-label': 'controlled' }}
                                            label="Finish"
                                        />

                                    </div>

                                </>)}
                        </div>
                    </div>
                </>
            )}

            {
                error ? (
                    <Snackbar
                        open={openSnackbar}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={closeSnackbar} severity="error">
                            Something went wrong!
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="success">Done!!</Alert>
                    </Snackbar>
                )
            }
        </div>

    )
}