import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Grid from '@mui/material/Grid';
import { addMiroLink } from '../../../../firebase/cloudFunctions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { Alert, LinearProgress, Stack } from '@mui/material';
import { ErrorAlert } from '../../../../helper/errorAlert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: 800,
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function AddMiroLinkComponent({ projID, projData }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [formIsValid, setFormIsValid] = useState(true);
    const [miroLinkVal, setMiroLinkVal] = useState(projData?.miroLink || '');


    //this function is called when clicking the `Send` button
    const submitForm = async () => {
        //set `isLoading` state to true, so the spinner is rendered
        setIsLoading(true);

        // call the cloud function. 
        addMiroLink({
            projectID: projID,
            link: miroLinkVal
        }).then(() => {
            setMiroLinkVal('');
            setFormIsValid(false);
        }).catch((error) => {
            setErrorInfo(error);
            setErrorMsg('Something went wrong! Could not save link.');
            setError(true)
        }).finally(() => {
            setIsLoading(false);
        })

    };

    const handleInput = (event) => {
        let value = event.target.value;
        setMiroLinkVal(value);

        if (value != null && value !== "") {
            if (value.startsWith('https://miro.com/') === false) {
                setFormIsValid(false);
            }
            else {
                setFormIsValid(true);
            }
        }
        else {
            setFormIsValid(false);
        }
    }

    const openMiroLink = () => {
        window.open(projData.miroLink, '_blank');
    }

    return (
        <>
            {projData.miroLink != null && projData.miroLink !== "" ? (
                <CheckCircleOutlineOutlinedIcon className="check_icon" fontSize="small"
                    style={{
                        cursor: "pointer",
                        textAlign: "center",

                    }} onClick={handleClickOpen} />
            ) : (
                <ControlPointIcon className="add_miro" fontSize="small"
                    style={{
                        cursor: "pointer",
                        textAlign: "center",

                    }} onClick={handleClickOpen} />
            )}

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="more-info-dialog"
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    {projData.miroLink != null && projData.miroLink !== "" ? (
                        <h6><strong>Update Miro Link</strong></h6>
                    ) : (
                        <h6><strong>Add Miro Link</strong></h6>
                    )}

                </BootstrapDialogTitle>

                <DialogContent>
                    <form>
                        <TextField
                            required
                            fullWidth
                            value={miroLinkVal}
                            label='Link'
                            variant='standard'
                            className='input_textquestion'
                            onChange={handleInput}
                            error={formIsValid === false && miroLinkVal !== ""}
                            helperText={formIsValid === false && miroLinkVal !== "" ? ("Please fill in a valid Miro Link") : ("")}
                        />
                        <div style={{ color: 'gray', marginTop: '5px' }}>Example of correct format of link: <i>'https://miro.com/app/board/abc12_34abc=/'</i></div>
                        <Grid container justifyContent="center">
                            <Box mt="2rem">
                                {isLoading ? (
                                    <div>
                                        <CircularProgress size={29} />
                                    </div>
                                ) : (
                                    <Stack direction={'row'} spacing={1}>
                                        <Button
                                            onClick={submitForm}
                                            disabled={!formIsValid}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Save
                                        </Button>

                                        {projData.miroLink != null && projData.miroLink !== '' ? (
                                            <Button
                                                onClick={openMiroLink}
                                                disabled={!formIsValid}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Open <OpenInNewIcon fontSize='small' sx={{marginLeft: '5px'}} />
                                            </Button>
                                        ) : ("")}
                                    </Stack>
                                )}
                            </Box>
                        </Grid>
                    </form>
                </DialogContent>
            </BootstrapDialog>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    );
}
