import { collection, collectionGroup, getDocs, onSnapshot } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList'


export const useGetProjects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const getAllProjects = async () => {
            const companySnap = await getDocs(collectionGroup(db, 'CompanyInfo'));
            //console.log("Hello Res: ", companySnap.docs)

            const companyData = {};
            companySnap.forEach((child) => {
                companyData[child.ref.parent.parent.id] = child.data();
            });

            const ref = onSnapshot(collection(db, "Projects"), (querySnapshot) => {
                let arr = [];
                querySnapshot.forEach((doc) => {
                    if (companyData[doc.id] != null) {
                        let projName = doc.data().projectName;
                        if (companyData[doc.id]?.compName != null && companyData[doc.id]?.compName !== ""){
                            projName = companyData[doc.id]?.compName;
                        }

                        arr.push({
                            id: doc.id,
                            value: doc.data().projectName,
                            companyData: companyData[doc.id],
                            ...doc.data()
                        });
                    }
                    else {
                        arr.push({
                            id: doc.id,
                            value: doc.data().projectName,
                            ...doc.data()
                        });
                    }
                });
                setProjects(arr)
            });

            firebaseRefList.push(ref);
        }
        getAllProjects()
    }, []);

    return projects;
};