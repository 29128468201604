import { Box, Checkbox, Divider, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from "react";
import { Button } from "react-bootstrap";
import { addNewTimeLog } from "../../firebase/cloudFunctions";
import { AvailableMatrixTags, TimeEventType } from "../../constants/labels/labels";

export const AddNewTimeLogComponent = ({ projData }) => {
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTimeType, setSelectedTimeType] = useState('');

    const handleSetHours = (event) => {
        let value = Number(event.target.value);

        if (isNaN(value) === false && value >= 0) {
            if (value < 24) {
                setHours(Math.round(value));
            }
            else {
                setHours(23);
            }

        }
        else {
            setHours(0);
        }
    }

    const handleSetMinutes = (event) => {
        let value = Number(event.target.value);

        if (isNaN(value) === false && value >= 0) {
            if (value < 60) {
                setMinutes(Math.round(value));
            }
            else {
                if (hours < 23) {
                    setMinutes(0);
                    setHours(hours + 1);
                }
            }
        }
        else if (value < 0) {
            if (hours > 0) {
                setMinutes(45);
                setHours(hours - 1);
            }
            else {
                setMinutes(0);
            }
        }
        else {
            setMinutes(0);
        }
    }

    const handleChangeTrackNames = (event) => {
        const { target: { value } } = event;
        if (value?.includes('all')) {
            let trackList = [];
            if (value.length <= Object.keys(AvailableMatrixTags).length) {
                Object.keys(AvailableMatrixTags).forEach((track) => {
                    trackList.push(AvailableMatrixTags[track].label);
                });
            }

            setSelectedTags(trackList);
        }
        else {
            setSelectedTags(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    const handleSaveNewTimeLog = () => {
        if (projData?.id != null && projData.activeTrack != null) {
            let projID = projData?.id;
            let activeTrack = projData.activeTrack;
            let dateString = selectedDate.toDate().toISOString().split('T')[0]
            let time = hours + ":" + String(minutes).padStart(2, '0');
            let tagKeys = [];
            for (let index = 0; index < selectedTags.length; index++) {
                const tag = selectedTags[index];

                let tagData = AvailableMatrixTags.find((item) => item.label === tag);

                if (tagData != null) {
                    tagKeys.push(tagData.key);
                }
            }

            let dataSnap = {
                projectID: projID,
                track: activeTrack,
                date: dateString,
                amountOfTime: time,
                timelogType: selectedTimeType,
            };

            if (tagKeys.length > 0) {
                let tags = tagKeys.join(';')

                dataSnap['tags'] = tags;
            }

            console.log("SaveData: ", dataSnap)

            setLoading(true);
            addNewTimeLog(dataSnap).then((res) => {
                setSelectedDate(dayjs(new Date()));
                setHours(0);
                setMinutes(0);
                setSelectedTags([]);
                setSelectedTimeType('');
            }).catch((error) => {
                console.error("Could not save data! Reason: ", error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleSetTimeLogType = (event) => {
        const { target: { value } } = event;

        setSelectedTimeType(value);
    }

    return (
        <Box sx={{ padding: '15px 3px 0px 3px' }}>
            <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        disableFuture
                        slotProps={{ textField: { size: 'small' } }}
                        label="Date"
                        format="ddd YYYY-MM-DD"
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                    />

                </LocalizationProvider>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Box width={'80px'}>
                        Time spent:
                    </Box>
                    <TextField
                        sx={{ width: '105px' }}
                        type="number"
                        size="small"
                        label="Hours"
                        value={hours}
                        onChange={handleSetHours}
                    />
                    <TextField
                        sx={{ width: '105px' }}
                        type="number"
                        size="small"
                        label="Minutes"
                        value={minutes}
                        onChange={handleSetMinutes}
                        inputProps={{
                            step: 15,
                        }}
                    />
                </Stack>
                <Box sx={{ paddingTop: '15px' }}>
                    <FormControl >
                        <InputLabel>Time was used for:</InputLabel>
                        <Select
                            sx={{ width: '320px' }}
                            size="small"
                            label={'Time was used for:'}
                            value={selectedTimeType}
                            onChange={handleSetTimeLogType}
                        >
                            {Object.keys(TimeEventType).map((key) => {
                                const item = TimeEventType[key]
                                return (
                                    <MenuItem key={key} value={key}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box >
                    <FormControl >
                        <InputLabel>Worked with readiness (optional)</InputLabel>
                        <Select
                            label="Worked with readiness (optional)"
                            sx={{ width: '320px' }}
                            size="small"
                            multiple
                            value={selectedTags}
                            onChange={handleChangeTrackNames}
                            renderValue={(selected) => selectedTags.length === Object.keys(AvailableMatrixTags).length ? "All" : selected.join(', ')}
                        >
                            <MenuItem sx={{ height: '28px' }} value={"all"}>
                                <ListItemText primary={<strong>All</strong>} />
                                <Checkbox size="small" checked={selectedTags.length === Object.keys(AvailableMatrixTags).length} />
                            </MenuItem>
                            <Divider />
                            {Object.keys(AvailableMatrixTags).map((option) => {
                                return (
                                    <MenuItem sx={{ height: '28px' }} key={option} value={AvailableMatrixTags[option].label}>
                                        <ListItemText primary={AvailableMatrixTags[option].label} />
                                        <Checkbox size="small" checked={selectedTags.indexOf(AvailableMatrixTags[option].label) > -1} />
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ paddingBottom: '-5px', height: '2px' }}>
                    {loading ? (
                        <LinearProgress />
                    ) : ("")}
                </Box>
                <Button disabled={(hours === 0 && minutes === 0) || loading || selectedTimeType == null || selectedTimeType === ""} onClick={handleSaveNewTimeLog}>
                    Save timelog
                </Button>
            </Stack>

        </Box>
    )
}