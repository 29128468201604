import { useState, useEffect } from "react";
import { Divider, FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { TeamsQuestions } from "../../constants/ideasQuestions/teamsQuestions";


export const QuarterReviewTeamsinfo = ({ handleChange, values, setSaveTrigger, setInvalidData, confirmInfo }) => {
    const [update, setUpdate] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');


    const [isNextStepInvalid, setNextStepInvalid] = useState(false);
    const [isIdeasInvalid, setIdeasInvalid] = useState(false);
    const [isUniqueInvalid, setUniqueInvalid] = useState(false);
    const [isAlreadyInvalid, setAlreadyInvalid] = useState(false);
    const [isCompetitorsInvalid, setCompetitorsInvalid] = useState(false);
    const [isCustomersInvalid, setCustomersInvalid] = useState(false);
    const [isDemandInvalid, setDemandInvalid] = useState(false);
    const [isChallengeInvalid, setChallengeInvalid] = useState(false);
    const [isAloneORteamInvalid, setAloneORteamInvalid] = useState(false);
    const [isCompetencesInvalid, setCompetencesInvalid] = useState(false);
    const [isTimeANDresourcesInvalid, setTimeANDresourcesInvalid] = useState(false);
    const [isGeneralInvalid, setGeneralInvalid] = useState(false);

    useEffect(() => {
        if (confirmInfo === true){
            validateAllFields();
        }
    }, [confirmInfo]);
    
    useEffect(() => {
        if (isNextStepInvalid || isChallengeInvalid || isAloneORteamInvalid || isCompetencesInvalid || isTimeANDresourcesInvalid){
            setInvalidData(true);
        }
        else{
            setInvalidData(false);
        }
    }, [isNextStepInvalid, isChallengeInvalid, isAloneORteamInvalid, isCompetencesInvalid, isTimeANDresourcesInvalid])

    const validateInput = (type) => {
        switch (type) {
            case 'nextStep':
                if (values.nextStep != null && values.nextStep !== "") {
                    setNextStepInvalid(false);
                }
                else {
                    setNextStepInvalid(true);
                }
                break;
            case 'ideas':
                if (values.ideas != null && values.ideas !== "") {
                    setIdeasInvalid(false);
                }
                else {
                    setIdeasInvalid(true);
                }
                break;
            case "unique":
                if (values.unique != null && values.unique !== "") {
                    setUniqueInvalid(false);
                }
                else {
                    setUniqueInvalid(true);
                }
                break;
            case "already":
                if (values.already != null && values.already !== "") {
                    setAlreadyInvalid(false);
                }
                else {
                    setAlreadyInvalid(true);
                }
                break;
            case "competitors":
                if (values.competitors != null && values.competitors !== "") {
                    setCompetitorsInvalid(false);
                }
                else {
                    setCompetitorsInvalid(true);
                }
                break;
            case "customers":
                if (values.customers != null && values.customers !== "") {
                    setCustomersInvalid(false);
                }
                else {
                    setCustomersInvalid(true);
                }
                break;
            case "demand":
                if (values.demand != null && values.demand !== "") {
                    setDemandInvalid(false);
                }
                else {
                    setDemandInvalid(true);
                }
                break;
            case "challenge":
                if (values.challenge != null && values.challenge !== "") {
                    setChallengeInvalid(false);
                }
                else {
                    setChallengeInvalid(true);
                }
                break;
            case "aloneORteam":
                if (values.aloneORteam != null && values.aloneORteam !== "") {
                    setAloneORteamInvalid(false);
                }
                else {
                    setAloneORteamInvalid(true);
                }
                break;
            case "competences":
                if (values.competences != null && values.competences !== "") {
                    setCompetencesInvalid(false);
                }
                else {
                    setCompetencesInvalid(true);
                }
                break;
            case "timeANDresources":
                if (values.timeANDresources != null && values.timeANDresources !== "") {
                    setTimeANDresourcesInvalid(false);
                }
                else {
                    setTimeANDresourcesInvalid(true);
                }
                break;
            default:
                //console.log("Other: ", type)
                break;
        }
    }


    function collectData() {
        const dataToSend = [
            { title: "ideas", answer: values.ideas },
            { title: "unique", answer: values.unique },
            { title: "already", answer: values.already },
            { title: "competitors", answer: values.competitors },
            { title: "customers", answer: values.customers },
            { title: "demand", answer: values.demand },
            { title: "challenge", answer: values.challenge },
            { title: "aloneORteam", answer: values.aloneORteam },
            { title: "competences", answer: values.competences },
            { title: "timeANDresources", answer: values.timeANDresources },
            { title: "motivate", answer: values.motivate },
            { title: "globalGoals", answer: values.globalGoals },
            { title: "nextStep", answer: values.nextStep },
        ];

        return dataToSend;
    }

    function collectDataSaveProgress() {
        const dataToSend = [];

        if (values.ideas != null && values.ideas !== "") {
            dataToSend.push({ title: "ideas", answer: values.ideas })
        }
        if (values.unique != null && values.unique !== "") {
            dataToSend.push({ title: "unique", answer: values.unique })
        }
        if (values.already != null && values.already !== "") {
            dataToSend.push({ title: "already", answer: values.already })
        }
        if (values.competitors != null && values.competitors !== "") {
            dataToSend.push({ title: "competitors", answer: values.competitors })
        }
        if (values.customers != null && values.customers !== "") {
            dataToSend.push({ title: "customers", answer: values.customers })
        }
        if (values.demand != null && values.demand !== "") {
            dataToSend.push({ title: "demand", answer: values.demand })
        }
        if (values.challenge != null && values.challenge !== "") {
            dataToSend.push({ title: "challenge", answer: values.challenge })
        }
        if (values.aloneORteam != null && values.aloneORteam !== "") {
            dataToSend.push({ title: "aloneORteam", answer: values.aloneORteam })
        }
        if (values.competences != null && values.competences !== "") {
            dataToSend.push({ title: "competences", answer: values.competences })
        }
        if (values.timeANDresources != null && values.timeANDresources !== "") {
            dataToSend.push({ title: "timeANDresources", answer: values.timeANDresources })
        }
        if (values.motivate != null && values.motivate !== "") {
            dataToSend.push({ title: "motivate", answer: values.motivate })
        }
        if (values.globalGoals != null && values.globalGoals !== "") {
            dataToSend.push({ title: "globalGoals", answer: values.globalGoals })
        }
        if (values.nextStep != null && values.nextStep !== "") {
            dataToSend.push({ title: "nextStep", answer: values.nextStep })
        }

        return dataToSend;
    }

    const clearInputError = (type) => {
        switch (type) {
            case 'nextStep':
                setNextStepInvalid(false);
                break;
            case 'ideas':
                setIdeasInvalid(false);
                break;
            case "unique":
                setUniqueInvalid(false);
                break;
            case "already":
                setAlreadyInvalid(false);
                break;
            case "competitors":
                setCompetitorsInvalid(false);
                break;
            case "customers":
                setCustomersInvalid(false);
                break;
            case "demand":
                setDemandInvalid(false);
                break;
            case "challenge":
                setChallengeInvalid(false);
                break;
            case "aloneORteam":
                setAloneORteamInvalid(false);
                break;
            case "competences":
                setCompetencesInvalid(false);
                break;
            case "timeANDresources":
                setTimeANDresourcesInvalid(false);
                break;
            default:
                //console.log("Other: ", type)
                break;
        }
    }

    const isErrorField = (type) => {
        switch (type) {
            case 'aloneORteam':
                return isAloneORteamInvalid;
            case 'competences':
                return isCompetencesInvalid;
            case "timeANDresources":
                return isTimeANDresourcesInvalid;
            case "challenge":
                return isChallengeInvalid;
            case "nextStep":
                return isNextStepInvalid;
            default:
                return false;
        }
    }

    const validateAllFields = () => {
        let isError = false;

        if (values.nextStep != null && values.nextStep !== "") {
            setNextStepInvalid(false);
        }
        else {
            setNextStepInvalid(true);
            isError = true;
        }

        if (values.challenge != null && values.challenge !== "") {
            setChallengeInvalid(false);
        }
        else {
            setChallengeInvalid(true);
            isError = true;
        }

        if (values.aloneORteam != null && values.aloneORteam !== "") {
            setAloneORteamInvalid(false);
        }
        else {
            setAloneORteamInvalid(true);
            isError = true;
        }

        if (values.competences != null && values.competences !== "") {
            setCompetencesInvalid(false);
        }
        else {
            setCompetencesInvalid(true);
            isError = true;
        }

        if (values.timeANDresources != null && values.timeANDresources !== "") {
            setTimeANDresourcesInvalid(false);
        }
        else {
            setTimeANDresourcesInvalid(true);
            isError = true;
        }

        setGeneralInvalid(isError);
        return isError;
    }

    const handleChangeLocal = (e) => {
        handleChange(e);
        clearInputError(e.target.name);
        setGeneralInvalid(false);
        setSaveTrigger(e.target.value);
        setUpdate(!update);
    };

    return (
        <div style={{ padding: '10px' }}>
            <Grid container spacing={3} justifyContent="center" padding={3}>
                <Grid item xs={12}>
                    <Divider className="thick blue" />
                    <h6 style={{ fontWeight: 'bold', marginTop: '10px' }}> Team:</h6>
                </Grid>

                {Object.keys(TeamsQuestions).map((key) => (
                    <Grid key={key} item xs={12} sm={12} md={6}>
                        <FormControl sx={{ width: '100%' }} required>
                            <FormLabel sx={{ height: '55px' }}>{TeamsQuestions[key].text}</FormLabel>
                            <TextField
                                id="outlined-multiline-static"
                                label="Answer"
                                multiline
                                name={key}
                                rows={4}
                                onChange={handleChangeLocal}
                                value={values[key] || ""}
                                onBlur={() => { validateInput(key) }}
                                error={isErrorField(key)}
                                helperText={isErrorField(key) && "Answer is required"}
                            />
                        </FormControl>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};