import { Card, CardContent } from "@mui/material"
import Explanation from "../../Explanation"


export const EmbededLinkInputCard = ({ assignedQuestion, question }) => {
    return (
        <Card className="characters" key={question.key} sx={{
            maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem', marginLeft: '-15px'
        }}>
            <CardContent>
                {question.questionExplanation || question.explanation !== "" || null ? (
                    <Explanation key={question.key} data={question} />
                ) : ('')}

                <iframe className="responsive-iframe" src={question.text} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"></iframe>

                {assignedQuestion.note != null ? (
                    <p className="note-style"><strong>{assignedQuestion.note}</strong></p>
                ) : ("")}

            </CardContent>
        </Card>
    )
}