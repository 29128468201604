import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import { auth } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'
import { doc, getDoc } from 'firebase/firestore';


const useGetUserProject = () => {
    const [project, setProject] = useState({
        id: null,
        data: null,
        compData: null,
    });
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(true);

    // console.log('current User', currentUser.uid)

    useEffect(() => {
        const getProject = async () => {
            if (auth.currentUser != null) {
                const userProjects = await getDoc(doc(db, "UserProject/" + currentUser.uid));

                if (userProjects.exists()) {
                    const data = userProjects.data();

                    if (data.projects != null) {
                        const projData = await getDoc(doc(db, "Projects/" + data.projects[0]));
                        const compData = await getDoc(doc(db, "Projects/" + data.projects[0] + "/CompanyInfo/CompanyInfo"));

                        if (compData.exists()){
                            setProject({ id: projData.id, data: projData.data(), compData: compData.data() })
                        }
                        else{
                            setProject({ id: projData.id, data: projData.data(), compData: null })
                        }
                        
                    }
                } else {
                    // doc.data() will be undefined in this case
                    //console.log("No such document! ");
                }
            }

            setLoading(false)
        }
        getProject()

    }, [currentUser.uid]);
    return { project, loading };
};

export default useGetUserProject