import { useEffect, useState } from 'react'
import { CircularProgress, Stack, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAnalytics, logEvent } from 'firebase/analytics'
import useGetUserProject from '../../hooks/userData/useGetUserProject'
import { ProcessStepLabels } from '../../constants/labels/labels'
import { useAuth } from '../../contexts/AuthContext'

const AwaitingConfirmPage = () => {
    const { project, loading } = useGetUserProject();
    const { currentUser } = useAuth();
    const [pageLoading, setPageLoading] = useState(true);
    const analytics = getAnalytics();
    const navigate = useNavigate();

    useEffect(() => {
        if (loading === false) {
            console.log("Data. ", project.data)
            if (project.data.isActive != null && project.data.isActive !== true) {
                navigate("/inactive", { replace: true });
            }
            else if (project?.data?.currentStep !== ProcessStepLabels.PreMeet.key || project?.data?.alreadyBookedFM !== true) {
                navigate("/home", { replace: true });
            }

            setPageLoading(false);
        }
    }, [loading, project])

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Awaiting Confirm',
            page_path: window.location.pathname,
        });
    }, [])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '50px' }}>
                {pageLoading ? (
                    <Box>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {currentUser?.userInfo?.prefLang === 'swe' ? (
                            <>
                                <Box>
                                    <h5>Nästan klart</h5>
                                </Box>
                                <Box fontSize={'16px'} textAlign={'center'} maxWidth={'1060px'}>
                                    Tack för din ansökan till plattformen för Krinova Business Growth!
                                    Du kommer få tillgång till Mina sidor och verktygen på plattformen när din Business Designer har bekräftat er tidigare kontakt.
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box>
                                    <h5>Almost there</h5>
                                </Box>
                                <Box fontSize={'16px'} textAlign={'center'} maxWidth={'1060px'}>
                                    Thank you for your application to the Krinova Business Growth platform!
                                    You will get access to My pages and the tools on the platform once your Business Designer has confirmed your previous contact.
                                </Box>
                            </>
                        )}

                    </>
                )}

            </Stack>

        </Box>
    )
}

export default AwaitingConfirmPage;