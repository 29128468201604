import { Card, MenuItem, Select, Typography, Stack, FormControl, InputLabel, Box, Divider, ListItemText, Checkbox, LinearProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { updateDefaultTableSettings } from "../../firebase/cloudFunctions";
import { useAuth } from "../../contexts/AuthContext";
import { TrackLabels } from "../../constants/labels/labels";

export const PreferredTableSettingsCard = () => {
    const { currentUser } = useAuth();
    const [saving, setSaving] = useState(false);
    const [changed, setChanged] = useState(false);
    const [activeFilter, setActiveFilter] = useState('active');
    const [bdFiltering, setBDFiltering] = useState('assignedMe');
    const [selectedTrackNames, setSelectedTrackNames] = useState(['Unassigned', 'START', 'INCUBATOR', 'SCALE', 'EXIT']);
    const [orderBy, setOrderBy] = useState('nextDeadline');
    const [orderByDir, setOrderByDir] = useState('desc');

    useEffect(() => {
        console.log("User: ", currentUser?.userInfo)

        if (currentUser?.userInfo?.bdTableSettings != null) {
            const bdSettings = currentUser?.userInfo?.bdTableSettings;
            if (bdSettings.activeFilter != null) {
                setActiveFilter(bdSettings.activeFilter);
            }

            if (bdSettings.bdFilter != null) {
                setBDFiltering(bdSettings.bdFilter);
            }

            if (bdSettings.orderBy != null) {
                setOrderBy(bdSettings.orderBy);
            }

            if (bdSettings.orderByDir != null) {
                setOrderByDir(bdSettings.orderByDir);
            }

            if (bdSettings.selectedTracks != null) {
                let localSelectedTrackVals = bdSettings.selectedTracks.split(';');
                let localSelectedTrackNames = [];
                for (let index = 0; index < localSelectedTrackVals.length; index++) {
                    const trackVal = localSelectedTrackVals[index];

                    let trackOption = TrackLabels[trackVal].selectLabel;

                    if (trackOption != null) {
                        localSelectedTrackNames.push(trackOption);
                    }
                }
                setSelectedTrackNames(localSelectedTrackNames);
            }
        }
    }, [currentUser])

    const handleChangeActiveFilter = (event) => {
        setActiveFilter(event.target.value);
        setChanged(true);
    }

    const handleChangeBDFiltering = (event) => {
        setBDFiltering(event.target.value);
        setChanged(true);
    }

    const handleChangeOrderBy = (event) => {
        setOrderBy(event.target.value);
        setChanged(true);
    }

    const handleChangeOrderDir = (event) => {
        setOrderByDir(event.target.value);
        setChanged(true);
    }

    const handleChangeTrackNames = (event) => {
        const { target: { value } } = event;
        if (value?.includes('all')) {
            let trackList = [];
            if (value.length <= Object.keys(TrackLabels).length) {
                Object.keys(TrackLabels).forEach((track) => {
                    trackList.push(TrackLabels[track].selectLabel);
                });
            }

            setSelectedTrackNames(trackList);
        }
        else {
            setSelectedTrackNames(
                typeof value === 'string' ? value.split(',') : value,
            );
        }

        setChanged(true);
    };

    useEffect(() => {
        if (changed === true && saving === false) {
            setSaving(true);
            let selectedTracksFilter = [];
            for (let index = 0; index < selectedTrackNames.length; index++) {
                const trackName = selectedTrackNames[index];
                let trackVal = Object.keys(TrackLabels).find((key) => TrackLabels[key].selectLabel === trackName);

                if (trackVal != null) {
                    selectedTracksFilter.push(trackVal);
                }
            }

            let trackFilterString = selectedTracksFilter.join(';');
            console.log("Save: ", currentUser?.uid, activeFilter, bdFiltering, trackFilterString, orderBy, orderByDir);

            updateDefaultTableSettings({
                userID: currentUser?.uid,
                activeFilter: activeFilter,
                bdFilter: bdFiltering,
                selectedTracks: trackFilterString,
                orderBy: orderBy,
                orderByDir: orderByDir,
            }).then(() => {
                console.log("Saved!");
            }).catch((error) => {
                console.error('Could not save settings: ', error);
            }).finally(() => {
                setSaving(false);
            });
            setChanged(false);
        }
    }, [changed, saving, activeFilter, bdFiltering, selectedTrackNames, orderBy, orderByDir])


    return (
        <Card raised sx={{ padding: '10px', minWidth: 465, maxWidth: 640, }}>
            <h6 style={{ paddingLeft: 10 }}>Default table settings:</h6>
            <Box sx={{ padding: '10px' }}>
                <Stack spacing={0.5}>
                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                        <Box fontSize={'15px'}>
                            Show Active/Inactive:
                        </Box>

                        <FormControl>
                            <Select
                                size="small"
                                sx={{ width: '250px' }}
                                value={activeFilter}
                                onChange={handleChangeActiveFilter}
                            >
                                <MenuItem value={'active'}>Show active only</MenuItem>
                                <MenuItem value={'activePaused'}>Show active and paused only</MenuItem>
                                <MenuItem value={'inactive'}>Show inactive only</MenuItem>
                                <MenuItem value={'all'}>All projects</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Divider />
                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                        <Box fontSize={'15px'}>
                            BD filtering:
                        </Box>

                        <FormControl>
                            <Select
                                size="small"
                                sx={{ width: '250px' }}
                                value={bdFiltering}
                                onChange={handleChangeBDFiltering}
                            >
                                <MenuItem value={'assignedMe'}>Assigned to me</MenuItem>
                                <MenuItem value={'assignedMeAndUnassigned'}>Assigned to me + unassigned</MenuItem>
                                <MenuItem value={'assigned'}>All assigned</MenuItem>
                                <MenuItem value={'all'}>All</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Divider />
                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                        <Box fontSize={'15px'}>
                            Track filtering:
                        </Box>

                        <FormControl>
                            <Select
                                sx={{ width: '250px' }}
                                size="small"
                                multiple
                                value={selectedTrackNames}
                                onChange={handleChangeTrackNames}
                                renderValue={(selected) => selectedTrackNames.length === Object.keys(TrackLabels).length ? "All" : selected.join(', ')}
                            >
                                <MenuItem value={"all"}>
                                    <ListItemText primary={<strong>All</strong>} />
                                    <Checkbox size="small" checked={selectedTrackNames.length === Object.keys(TrackLabels).length} />
                                </MenuItem>
                                <Divider />
                                {Object.keys(TrackLabels).map((option) => {
                                    return (
                                        <MenuItem key={option} value={TrackLabels[option].selectLabel}>
                                            <ListItemText primary={TrackLabels[option].selectLabel} />
                                            <Checkbox size="small" checked={selectedTrackNames.indexOf(TrackLabels[option].selectLabel) > -1} />
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Divider />
                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                        <Box fontSize={'15px'}>
                            Order table by:
                        </Box>

                        <FormControl>
                            <Select
                                size="small"
                                sx={{ width: '200px' }}
                                value={orderBy}
                                onChange={handleChangeOrderBy}
                            >
                                <MenuItem value={'IBO'}>IBO</MenuItem>
                                <MenuItem value={'Contact'}>Contact Person</MenuItem>
                                <MenuItem value={'BD'}>BD</MenuItem>
                                <MenuItem value={'Track'}>Track</MenuItem>
                                <MenuItem value={'Progress'}>Progress</MenuItem>
                                <MenuItem value={'Next'}>Next deadline/Meeting</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Select
                                size="small"
                                sx={{ width: '95px' }}
                                value={orderByDir}
                                onChange={handleChangeOrderDir}
                            >
                                <MenuItem value={'asc'}>Asc.</MenuItem>
                                <MenuItem value={'desc'}>Desc.</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
            </Box>

            {saving ? (
                <LinearProgress sx={{ width: '98%' }} />
            ) : (
                <div style={{ height: '4px' }}></div>
            )}

        </Card>
    )
}