import { useState } from "react";
import { IconButton, Menu, MenuItem, TableCell } from "@mui/material"

import FilterListIcon from '@mui/icons-material/FilterList';

export const IsActiveHeaderCell = ({ filterStatus, setFilterStatus }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseStatus = (value) => {
        setAnchorEl(null);
        if (value != null) {
            setFilterStatus(value);
        }
    };

    return (
        <TableCell>
            <IconButton size='small' onClick={handleClick}>
                <FilterListIcon style={{ width: "18px", height: "fit-content" }} />
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => { handleCloseStatus(null) }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem style={{ color: filterStatus === "active" || filterStatus === "" ? "black" : "gray" }} value='active' onClick={() => handleCloseStatus("active")}>Only Active</MenuItem>
                <MenuItem style={{ color: filterStatus === "activePaused" || filterStatus === "" ? "black" : "gray" }} value='active' onClick={() => handleCloseStatus("activePaused")}>Active+paused</MenuItem>
                <MenuItem style={{ color: filterStatus === "inactive" || filterStatus === "" ? "black" : "gray" }} value='inactive' onClick={() => handleCloseStatus("inactive")}>Only Inactive</MenuItem>
                <MenuItem style={{ color: filterStatus === "all" || filterStatus === "" ? "black" : "gray" }} value='all' onClick={() => handleCloseStatus("all")}>All</MenuItem>
            </Menu>
        </TableCell>
    )
}