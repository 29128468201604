import { Divider, Card } from "@mui/material"
import { Fragment } from "react"

import { TemplateTextInputCard } from "./templateCards/templateTextInputCard";
import { TemplateEmbeddedLinkCard } from "./templateCards/templateEmbeddedLinkCard";
import { TemplateMiroCard } from "./templateCards/templateMiroCard";
import { TemplateFileUploadCard } from "./templateCards/templateFileUploadCard";
import { TemplateRichTextCard } from "./templateCards/templateRichTextCard";
import { TemplateOptionCard } from "./templateCards/templateOptionCard";
import { TemplateCheckedCard } from "./templateCards/templateCheckedCard";
import { TemplateRadioButtonCard } from "./templateCards/templateRadioButtonCard";
import { TemplateSliderCard } from "./templateCards/templateSliderCard";

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';


export const TemplatePreviewComponent = ({ assignedQuestions, columns }) => {
    return (
        <Fragment>
            <div className="header-preview-content" style={{ marginLeft: '30px' }}>
                <RemoveRedEyeOutlinedIcon sx={{ fontSize: "40px", marginRight: "10px" }} />
                <span>
                    <strong>Preview</strong>
                </span>
            </div>
            <div className="container" style={{ marginLeft: '30px', borderLeft: '2px solid black' }}>
                <Card
                    className="characters"
                    variant="outlined"
                    sx={{
                        marginBottom: '1rem',
                        paddingBottom: '0rem',
                        height: "91vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    <ul className="preview-list">
                        {assignedQuestions != null && columns.selectedColumn != null ? (
                            columns.selectedColumn.items.map((assignedQuestion) => {
                                if (assignedQuestion.type === "TextInput") {
                                    return (
                                        <Fragment>
                                            <TemplateTextInputCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>
                                    )
                                }
                                else if (assignedQuestion.type === "EmbededLinkInput") {
                                    return (
                                        <Fragment>
                                            <TemplateEmbeddedLinkCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>
                                    )
                                }
                                else if (assignedQuestion.type === "miro") {
                                    return (
                                        <Fragment>
                                            <TemplateMiroCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>
                                    )
                                }
                                else if (assignedQuestion.type === "fileUpload") {
                                    return (
                                        <Fragment>
                                            <TemplateFileUploadCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>
                                    )
                                }
                                else if (assignedQuestion.type === "RichTextInput") {
                                    return (
                                        <Fragment>
                                            <TemplateRichTextCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>
                                    )
                                } else if (assignedQuestion.type === "Option") {
                                    return (
                                        <Fragment>
                                            <TemplateOptionCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>
                                    )
                                }
                                else if (assignedQuestion.type === "Checked") {
                                    return (
                                        <Fragment>
                                            <TemplateCheckedCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>

                                    )
                                }
                                else if (assignedQuestion.type === "RadioButton") {
                                    return (
                                        <Fragment>
                                            <TemplateRadioButtonCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>

                                    )
                                }
                                else if (assignedQuestion.type === "Slider") {
                                    return (
                                        <Fragment>
                                            <TemplateSliderCard assignedQuestion={assignedQuestion} />
                                            <Divider />
                                        </Fragment>
                                    )
                                }
                                else {
                                    return (
                                        <span>No questions assigned.</span>
                                    )
                                }
                            })
                        ) : (
                            <h6>Drag over some content to preview it here</h6>
                        )}
                    </ul>
                </Card>
            </div>
        </Fragment>
    )
}