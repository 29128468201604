import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextFieldCreator from './TextFieldCreator'
import LinkComponent from './LinkComponent'
import TextFormater from './TextFormater'
import OptionCreator from './OptionCreator';
import ListQuestions from './ListQuestions';



export default function TabMenuQuestionCreator({shortDesc, tag, perk}) {
    const [value, setValue] = useState('-1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ marginBottom: '20px', marginTop: '30px' }}>
                <p>4. Choose type of content to create</p>
            </Box>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="tab nav content creator">
                            <Tab hidden value="-1"/>
                            <Tab style={{ fontSize: "0.8rem" }} label="Text (non-question)" value="1" />
                            <Tab style={{ fontSize: "0.8rem" }} label="Video" value="2" />
                            <Tab style={{ fontSize: "0.8rem" }} label="Question (text-answer)" value="3" />
                            <Tab style={{ fontSize: "0.8rem" }} label="Question (choice-answer)" value="4" />
                        </TabList>
                    </Box>
                    {/* 
                    <TabPanel value="-1">
                        <ListQuestions type='All' />
                    </TabPanel>
                    */}
                    <TabPanel value="1">
                        <TextFormater shortDesc={shortDesc} tag={tag} perk={perk} />
                        <ListQuestions type='RichTextInput' />
                    </TabPanel>
                    <TabPanel value="2">
                        <LinkComponent shortDesc={shortDesc} tag={tag} perk={perk} />
                        <ListQuestions type='EmbededLinkInput' />
                    </TabPanel>

                    <TabPanel value="3">
                        <TextFieldCreator shortDesc={shortDesc} tag={tag} perk={perk} />
                        <ListQuestions type='TextInput' />
                    </TabPanel>
                    <TabPanel value="4">
                        <OptionCreator shortDesc={shortDesc} tag={tag} perk={perk} />
                        <ListQuestions type='Checked' />
                        <ListQuestions type='RadioButton' />
                        <ListQuestions type='Slider' />
                    </TabPanel>


                </TabContext>
            </Box>
        </>
    );
}
