import { Box, Divider, Skeleton, Stack } from "@mui/material"
import { AddNewTimeLogComponent } from "./AddNewTimeLog"
import { useGetTimeLogData } from "../../hooks/timelogData/useGetTimelogData"
import { useEffect, useState } from "react";
import { TimeLogRow } from "./TimeLogRow";
import { TimeReportProgressBar } from "./TimeReportBar";


export const TimeLogBase = ({ projData, timeLog, loadingLogs, totalTime, projectSettings }) => {
    const [hoverID, setHoverID] = useState('');

    const handleOnHover = (itemID) => {
        setHoverID(itemID)
    }

    const handleOnLeave = () => {
        setHoverID('')
    }

    const getTotalTime = () => {
        let totalHours = 0;
        let totalMinutes = 0;

        for (let index = 0; index < timeLog.length; index++) {
            const timeRow = timeLog[index];

            if (timeRow?.amountOfTime != null) {
                let timeSplit = timeRow?.amountOfTime.split(':')

                if (timeSplit[0] != null && isNaN(Number(timeSplit[0])) === false) {
                    totalHours += Number(timeSplit[0]);
                }

                if (timeSplit[1] != null && isNaN(Number(timeSplit[1])) === false) {
                    totalMinutes += Number(timeSplit[1]);
                }
            }
        }


        let timeDec = totalMinutes / 60
        var n = new Date(0, 0);
        n.setMinutes(timeDec * 60);

        let extraHours = n.getHours();
        let extraMinutes = n.getMinutes()

        totalHours += extraHours;

        return totalHours + "h " + String(extraMinutes).padStart(2, '0') + "min";
    }

    return (
        <Stack>
            <Box>
                {loadingLogs ? (
                    <Skeleton height={40} />
                ) : (
                    <>
                        <Box sx={{marginBottom: '10px'}}>
                            <TimeReportProgressBar projData={projData} totalTime={totalTime} projectSettings={projectSettings} width="330px" marginRight="0px" />
                        </Box>
                        

                        <Divider className="blue"><strong>Log</strong></Divider>
                        {timeLog.length > 0 ? (
                            <>
                                {timeLog.map((log) => {
                                    return (
                                        <div key={log.id}>
                                            <TimeLogRow logData={log} hoverID={hoverID} onHover={handleOnHover} onLeave={handleOnLeave} />
                                            <Divider className="blue" />
                                        </div>
                                    )
                                })}
                            </>
                        ) : (
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                                    <i>No logs registered</i>
                                </Box>

                                <Divider className="blue" />
                            </Box>
                        )}


                        <Stack sx={{ marginTop: '5px' }} direction={'row'} justifyContent={'flex-end'}>
                            <strong>Used: {getTotalTime()}</strong>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'flex-end'}>
                            <strong>Total: {projectSettings?.timePool[projData?.activeTrack]}h 00min</strong>
                        </Stack>
                    </>
                )}
            </Box>
            <Divider sx={{ paddingTop: '10px' }} className="blue"><strong>Add new timelog</strong></Divider>
            <AddNewTimeLogComponent projData={projData} />
        </Stack>
    )
}