import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import format from 'date-fns/format'
import { useGetUserNames } from '../userData/useGetUserNames';
import firebaseRefList from '../../firebase/firebaseRefList'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

export const useGetAllAvailableTimesAll = () => {
    const [alltimes, setAlltimes] = useState([]);
    const [usernames] = useGetUserNames();

    useEffect(() => {
        const getAllAvailableTimes = async () => {

            function GetNames(userId) {
                let username;
                usernames.forEach((userName) => {
                    if (userId === userName.id) {
                        username = userName.value;
                        //console.log('User:' + username + '!')
                    }
                    //return username
                })
                return username
            }

            let timeLimit = new Date(Date.now());
            //timeLimit.setHours(timeLimit.getHours() + 48);

            const timeQuery = query(collection(db, 'AvailableTimes'), where("status", "==", 0), where("startTime", ">", timeLimit), orderBy('startTime'))
            const ref = onSnapshot(timeQuery, (querySnapshot) => {
                let arr = [];
                querySnapshot.forEach((doc) => {
                    let nameUser = GetNames(doc.data().ownerID)
                    arr.push({ id: doc.id, startTime: format(new Date(doc.data().startTime.seconds * 1000), 'yyyy-MM-dd eeee HH:mm'), endTime: format(new Date(doc.data().endTime.seconds * 1000), 'HH:mm'), owner: nameUser })
                });
                setAlltimes(arr)
            });

            firebaseRefList.push(ref);

        }
        getAllAvailableTimes()
    }, [usernames]);

    return [alltimes]
};

export const useGetAllAvailableTimes = () => {
    const [alltimes, setAlltimes] = useState([]);
    const [usernames] = useGetUserNames();

    useEffect(() => {
        const getAllAvailableTimes = async () => {

            function GetNames(userId) {
                let username;
                usernames.forEach((userName) => {
                    if (userId === userName.id) {
                        username = userName.value;
                        //console.log('User:' + username + '!')
                    }
                    //return username
                })
                return username
            }

            let timeLimit = new Date(Date.now());
            timeLimit.setHours(timeLimit.getHours() + 48);

            const timeQuery = query(collection(db, 'AvailableTimes'), where("status", "==", 0), where("startTime", ">", timeLimit), orderBy('startTime'));
            const ref = onSnapshot(timeQuery, (querySnapshot) => {
                let arr = [];
                querySnapshot.forEach((doc) => {
                    let nameUser = GetNames(doc.data().ownerID)
                    arr.push({ id: doc.id, startTime: format(new Date(doc.data().startTime.seconds * 1000), 'yyyy-MM-dd eeee HH:mm'), endTime: format(new Date(doc.data().endTime.seconds * 1000), 'HH:mm'), owner: nameUser })
                });
                setAlltimes(arr)
            });

            firebaseRefList.push(ref);
        }
        
        getAllAvailableTimes();
    }, [usernames]);

    return [alltimes]
};