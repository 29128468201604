import { Box, Card, TextField } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useState } from "react";
import useGetUserData from "../../hooks/userData/useGetUserData";
import { useAuth } from "../../contexts/AuthContext";

export const ChangePasswordCard = () => {
    const { changePassword } = useAuth();
    const [passLoading, setPassLoading] = useState(false);
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [newAgainPass, setNewAgainPass] = useState('');
    const [errorText, setErrorText] = useState('');
    const [errorPassAgain, setErrorPassAgain] = useState(false);
    const [errorPass, setErrorPass] = useState(false);
    const [errorOldPass, setErrorOldPass] = useState(false);

    const [errorResponse, setErrorResponse] = useState('');
    const [saved, setSaved] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setPassLoading(true)

        let isOk = true;
        if (oldPass === '') {
            isOk = false;
            setErrorOldPass(true);
        }
        else {
            setErrorOldPass(false);
        }

        if (newPass === '') {
            isOk = false;
            setErrorPass(true);
        }
        else {
            setErrorPass(false);
        }

        if (newAgainPass === '') {
            isOk = false;
            setErrorPassAgain(true);
        }
        else {
            setErrorPassAgain(false);
        }

        if (isOk) {
            const response = await changePassword(oldPass, newPass);
            if (response != null) {
                setErrorResponse(response.message);
                setSaved(false);
            }
            else {
                setErrorResponse('');
                setSaved(true);
            }
        }

        setPassLoading(false)
    }


    const setOldPassChange = (event) => {
        setOldPass(event.target.value)

        if (event.target.value === '') {
            setErrorOldPass(true);
        }
        else {
            setErrorOldPass(false);
        }
    }

    const setNewPassChange = (event) => {
        setNewPass(event.target.value)

        if (event.target.value === '') {
            setErrorPass(true);
        }
        else {
            setErrorPass(false);
        }
    }

    const setNewPassAgainChange = (event) => {
        setNewAgainPass(event.target.value)

        if (event.target.value !== newPass) {
            setErrorPassAgain(true);
            setErrorText("Passwords does not match!")

        }
        else {
            setErrorPassAgain(false);
            setErrorText("");
        }
    }


    return (
        <Card raised sx={{ padding: '10px', minWidth: 465, maxWidth: 440, }}>
            <h6 style={{ paddingLeft: 10 }}>Change Password:</h6>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="form-group" style={{ paddingLeft: 10, paddingTop: 5, width: "70%" }}>
                    <TextField
                        size="small"
                        required
                        type="password"
                        id="outlined-required"
                        label="Enter current password:"
                        value={oldPass}
                        error={errorOldPass}
                        onChange={setOldPassChange}
                    />
                </div>

                <div className="form-group" style={{ paddingLeft: 10, width: "70%" }}>
                    <TextField
                        size="small"
                        required
                        type="password"
                        id="outlined-required"
                        label="Enter new password:"
                        value={newPass}
                        error={errorPass}
                        onChange={setNewPassChange}
                    />
                </div>

                <div className="form-group" style={{ paddingLeft: 10, width: "70%" }}>
                    <TextField
                        size="small"
                        required
                        type="password"
                        id="outlined-required"
                        label="Enter new password again:"
                        value={newAgainPass}
                        onChange={setNewPassAgainChange}
                        error={errorPassAgain}
                        helperText={errorText}
                    />
                </div>

                <div className="form-group" style={{ paddingLeft: 10, marginTop: '4px' }}>
                    <LoadingButton
                        loading={passLoading}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Update Password
                    </LoadingButton>
                </div>

                {errorResponse !== '' ? (
                    <div className="form-group" style={{ paddingLeft: 10, color: 'red' }}>
                        Could not change password! {errorResponse}
                    </div>
                ) : (
                    <>
                        {saved ? (<div className="form-group" style={{ paddingLeft: 10, color: 'green' }}>
                            Saved!
                        </div>) : (<></>)}
                    </>)}
            </Box>
        </Card>
    );
}