import { Box, CircularProgress, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGetUserData from "../../hooks/userData/useGetUserData";
import SignupInvited from "./Steps/InvitedSignup";
import ConfirmUserInfo from "./Steps/ConfirmUserInfo";
import UserInfo from "./Steps/UserInfo";
import { useAuth } from "../../contexts/AuthContext";
import { doc, getDocFromServer } from "firebase/firestore";
import { db } from "../../firebase";
import { addUseToProj } from "../../firebase/cloudFunctions";
import { ErrorAlert } from "../../helper/errorAlert";


export const OnBoardingInvited = () => {
    const labels = ["Create Account", "User Info", "Confirm"];
    const [update, setUpdate] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [creatingUser, setCreatingUser] = useState(false);
    const [userValues, setUserValues] = useState({
        language: "English",
        phone: "",
        gender: "",
        reference: "",
        date: "",
        education: "",
        hkrConnection: "",
        termsChecked: false,
        referenceList: {},
    });

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const { userData, loading } = useGetUserData();

    const [projectID, setProjectID] = useState("");
    const [linkUsed, setLinkedUsed] = useState(false);
    const [linkValid, setLinkedValid] = useState(true);
    const [projectName, setProjectName] = useState("");
    const { currentUser } = useAuth();
    const location = useLocation()
    const navigate = useNavigate()

    const selectedToken = location.search.slice(1).split('=');
    const selectedTab = location.hash;

    const token = {}

    if (selectedToken[0] === "t") {
        token["token"] = selectedToken[1];
    }

    useEffect(() => {
        if (currentUser == null) {
            if (token["token"] != null) {
                setLoadingPage(true);
                const getInviteInfo = () => {
                    getDocFromServer(doc(db, "Invitations/" + token["token"])).then((querySnapshot) => {
                        if (querySnapshot.data() != null) {
                            if (querySnapshot.data().used === 0) {
                                setProjectName(querySnapshot.data().projName);
                                setProjectID(querySnapshot.data().projID);
                            }
                            else {
                                console.log("Link is already used!");
                                setLinkedUsed(true);
                            }
                        }
                        else {
                            console.log("Link does not exists!");
                            setLinkedValid(false);
                            //navigate("/", { replace: true });
                        }

                        finishLoading();
                    })
                }

                getInviteInfo();
            }
            else {
                console.log("Not an invitation-link!");
                navigate("/", { replace: true });
            }
        }
        else {
            if (loading === false) {
                if (userData != null && Object.keys(userData).length > 0 && currentUser?.userInfo != null) {
                    loadUserData(userData);
                    setStepFromDB(currentUser?.userInfo.dataSet);
                }
            }

        }
    }, [loading]);

    useEffect(() => {
        setUpdate(!update);
    }, [userValues])


    const finishLoading = () => {
        setTimeout(() => {
            setLoadingPage(false);
        }, 500);
    }

    const handleCreate = async () => {
        await addUserToProject();
    }

    const addUserToProject = async () => {
        //set `isLoading` state to true, so the spinner is rendered
        setCreatingUser(true);

        // call the cloud function. 
        await addUseToProj({
            projectID: projectID,
            link: token["token"],
        }).then(() => {
            navigate('/invited#user')
        }).catch((error) => {
            setErrorInfo(error)
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setCreatingUser(false);
        })
    };

    const checkTerms = (checked) => {
        const localForms = userValues;
        localForms['termsChecked'] = checked;
        setUserValues(localForms);
    }

    const loadUserData = (userData) => {
        let dbValues = {
            language: "English",
            phone: "",
            gender: "",
            reference: "",
            date: "",
            education: "",
            hkrConnection: "",
            termsChecked: false,
            referenceList: {},
        };

        // @ts-ignore
        if (userData.phoneNr != null) {
            // @ts-ignore
            dbValues['phone'] = userData.phoneNr;
        }

        // @ts-ignore
        if (userData.city != null) {
            // @ts-ignore
            dbValues['city'] = userData.city;
        }

        // @ts-ignore
        if (userData.gender != null) {
            // @ts-ignore
            dbValues['gender'] = userData.gender;
        }

        // @ts-ignore
        if (userData.prefLang != null) {
            // @ts-ignore
            if (userData.prefLang === 'eng') {
                dbValues['language'] = "English";
            }
            // @ts-ignore
            else if (userData.prefLang === 'swe') {
                dbValues['language'] = "Svenska";
            }
        }

        // @ts-ignore
        if (userData.dateOfBirth != null) {
            // @ts-ignore
            dbValues['date'] = userData.dateOfBirth;
        }

        // @ts-ignore
        if (userData.education != null) {
            // @ts-ignore
            dbValues['education'] = userData.education;
        }

        // @ts-ignore
        if (userData.hkrConnection != null) {
            // @ts-ignore
            dbValues['hkrConnection'] = userData.hkrConnection;
        }

        // @ts-ignore
        if (userData.reference != null) {
            // @ts-ignore
            dbValues['reference'] = userData.reference;

            let refList = {};
            // @ts-ignore
            String(userData.reference).split(';').forEach((value) => {
                refList[value] = true;
            });

            dbValues['referenceList'] = refList;
        }

        // @ts-ignore
        if (userData.expectation != null) {
            // @ts-ignore
            dbValues['expectations'] = userData.expectation;
        }

        if (userData.acceptTerms != null) {
            dbValues['termsChecked'] = userData.acceptTerms;
        }
        setUserValues(dbValues);
    }



    const setStepFromDB = (step) => {
        if (selectedTab === "" && step != null && step !== "") {
            if (step === 0) {
                navigate('/invited#user')
                finishLoading();
            }
            else if (step === 1) {
                navigate('/invited#user')
                navigate('/invited#confirm')
                finishLoading();
            }
            else if (step === 3) {
                navigate("/home")
            }
        }
        else {
            setLoadingPage(false);
        }
    }

    const handleChangeUser = (e) => {
        const { name, value } = e.target;
        //console.log("Here!", name, value)
        const localForms = userValues;
        // Set values
        if (name === "reference") {
            if (localForms.referenceList[value] != null) {
                localForms.referenceList[value] = !localForms.referenceList[value];
            }
            else {
                localForms.referenceList[value] = true;
            }

            let options = [];
            Object.keys(localForms.referenceList).forEach((key) => {
                if (localForms.referenceList[key] === true) {
                    options.push(key);
                }
            })
            localForms.reference = options.join(';');

        }
        else {
            localForms[name] = value;
        }

        setUserValues(localForms);
    };

    //Reading the initial value from the hash-value
    function getHashValueInit(hashVal) {
        switch (hashVal) {
            case '#user':
                return 1;
            case '#confirm':
                return 2;
            default:
                return 0;
        }
    }

    const [activeStep, setActiveStep] = useState(getHashValueInit(selectedTab));

    useEffect(() => {
        if (selectedTab != null) {
            getHashValue(selectedTab)
        }
    }, [selectedTab])

    function getHashValue(hashVal) {
        switch (hashVal) {
            case '#user':
                if (activeStep !== 1) {
                    setActiveStep(1);
                }
                break;
            case '#confirm':
                if (activeStep !== 2) {
                    setActiveStep(2);
                }
                break;
            default:
                if (activeStep !== 0) {
                    setActiveStep(0);
                }
                break;
        }
    }

    const handleSteps = (step) => {
        if (loadingPage === false) {
            switch (step) {
                case 0:
                    return (
                        <div>
                            <SignupInvited handleNext={handleCreate} isLoadingCreateUser={creatingUser} />
                        </div>
                    );
                case 1:
                    return (
                        <div>
                            <UserInfo handleChange={handleChangeUser} values={userValues} updateVals={update} invite={true} />
                        </div>
                    );
                case 2:
                    return (
                        <div>
                            <ConfirmUserInfo values={userValues} checkTerms={checkTerms} invite={true} />
                        </div>
                    );
                default:
                    break;
            }
        }

    };

    return (
        <>
            {loadingPage === true ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <>
                    {linkValid === true ? (
                        <>
                            {linkUsed === false ? (
                                <>
                                    <Box style={{ margin: "30px 0 50px" }}>
                                        <Typography variant="h4" align="center">
                                            Set up account
                                        </Typography>
                                        {projectName ? (
                                            <Typography
                                                variant="subtitle2"
                                                align="center"
                                                style={{ margin: "10px 0" }}>
                                                You have been invited as an collaberator in {projectName}<br /> We need some information about you!
                                            </Typography>) : (
                                            <Typography
                                                variant="subtitle2"
                                                align="center"
                                                style={{ margin: "10px 0" }}>
                                                We need some information about you!
                                            </Typography>)}
                                    </Box>
                                    <Stepper
                                        activeStep={activeStep}
                                        style={{ margin: "30px 0 15px" }}
                                        alternativeLabel
                                    >
                                        {labels.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    {handleSteps(activeStep)}


                                </>
                            ) : (
                                <span>
                                    Link has already been used!
                                </span>
                            )}
                        </>
                    ) : (
                        <span>
                            Link is not valid!
                        </span>
                    )}

                    <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
                </>
            )}
        </>
    );
};
