import { Box, Card, CardContent, TextField } from "@mui/material"
import Explanation from "../../Explanation"
import parse from 'html-react-parser';
import { useAuth } from "../../../../contexts/AuthContext";
import { QuestionCommentBox } from "../QuestionCommentBox";


export const TextInputCard = ({ projectData, step, location, assignedQuestion, question, handleChange }) => {
    const { currentUser } = useAuth();

    return (
        <Card className="characters" variant="outlined" key={question.key} sx={{ maxWidth: 700, marginBottom: '1rem', paddingBottom: '0rem' }}>
            <CardContent>
                {parse(question.text)} {assignedQuestion.note != null ? (
                    <p className="note-style"><strong>{assignedQuestion.note}</strong></p>) : ("")
                }
                {question.questionExplanation || question.explanation !== "" ? (
                    <Explanation key={question.key} data={question} />
                ) : ('')}

                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { mb: '1rem', mt: '0.5rem', width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="question_form_textfield"
                >
                    <TextField id="standard-multiline-static"
                        label="Answer"
                        onChange={handleChange}
                        name={assignedQuestion.key}
                        multiline
                        rows={4}
                        disabled={currentUser.role >= 2}
                        defaultValue={assignedQuestion.answer || null}
                        variant="standard"
                    />

                </Box>

                <QuestionCommentBox projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} />

            </CardContent>
        </Card>
    )
}