import { useEffect, useState } from "react"
import { INTRODUCTION_PARAGRAPHS_START as INTRODUCTIONTEXT_START_SWE } from "../../constants/lang/swe/startPageIBO";
import { INTRODUCTION_PARAGRAPHS_INCUBATOR as INTRODUCTIONTEXT_INCUBATOR_SWE } from "../../constants/lang/swe/startPageIBO";
import { INTRODUCTION_PARAGRAPHS_SCALE as INTRODUCTIONTEXT_SCALE_SWE } from "../../constants/lang/swe/startPageIBO";

import { INTRODUCTION_PARAGRAPHS_START as INTRODUCTIONTEXT_START_ENG } from "../../constants/lang/eng/startPageIBO";
import { INTRODUCTION_PARAGRAPHS_INCUBATOR as INTRODUCTIONTEXT_INCUBATOR_ENG } from "../../constants/lang/eng/startPageIBO";
import { INTRODUCTION_PARAGRAPHS_SCALE as INTRODUCTIONTEXT_SCALE_ENG } from "../../constants/lang/eng/startPageIBO";
import useGetUserData from "../userData/useGetUserData";

/**
 *  @param {('start'|'incubator'|'scale')} track
 *  @param {function():void} handleOpenInfo 
 *  @param {function():void} handleOpenVideo 
 */
export const useGetIntroParagraphs = (track, handleOpenInfo, handleOpenVideo) => {
    const { userData, loading } = useGetUserData();
    const [paragraphs, setParagraphs] = useState({});

    useEffect(() => {
        let sweComponent;
        let engComponent;

        switch (track) {
            case 'start':
                sweComponent = INTRODUCTIONTEXT_START_SWE(handleOpenInfo, handleOpenVideo);
                engComponent = INTRODUCTIONTEXT_START_ENG(handleOpenInfo, handleOpenVideo);
                break;
            case 'incubator':
                sweComponent = INTRODUCTIONTEXT_INCUBATOR_SWE(handleOpenInfo, handleOpenVideo);
                engComponent = INTRODUCTIONTEXT_INCUBATOR_ENG(handleOpenInfo, handleOpenVideo);
                break;
            case 'scale':
                sweComponent = INTRODUCTIONTEXT_SCALE_SWE(handleOpenInfo, handleOpenVideo);
                engComponent = INTRODUCTIONTEXT_SCALE_ENG(handleOpenInfo, handleOpenVideo);
                break;
            default:
                sweComponent = INTRODUCTIONTEXT_START_SWE(handleOpenInfo, handleOpenVideo);
                engComponent = INTRODUCTIONTEXT_START_ENG(handleOpenInfo, handleOpenVideo);
                break;
        }

        if (loading === false) {
            // @ts-ignore
            if (userData?.prefLang === 'swe') {
                setParagraphs(sweComponent)
            }
            else {
                setParagraphs(engComponent)
            }
        }
        else {
            setParagraphs(engComponent)
        }

    }, [loading, userData, track]);

    return paragraphs;
}