import { Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Checkbox, Stack, FormLabel } from "@mui/material";
import { Fragment, useEffect, useState } from "react"


export const MeetingTable = ({ meetData, meetDataActive = null }) => {
    const [update, setUpdate] = useState(false);

    const getCurrentQuarter = () => {
        let curDate = new Date();
        //console.log("Date", curDate.getFullYear(), curDate.getMonth() + 1)
        let quarter = String(curDate.getFullYear());

        switch (curDate.getMonth() + 1) {
            case 1: case 2: case 3:
                quarter += '-Q1';
                break;
            case 4: case 5: case 6:
                quarter += '-Q2';
                break;
            case 7: case 8: case 9:
                quarter += '-Q3';
                break;
            case 10: case 11: case 12:
                quarter += '-Q4';
                break;
            default:
                break;
        }

        return quarter;
    }

    const setInitCurrentData = () => {
        if (meetDataActive == null){
            return meetData;
        }
        else if (activeOnly === true){
            return meetDataActive;
        }
        else{
            return meetData;
        }
    }

    const [curQuarter] = useState(getCurrentQuarter())
    const [activeOnly, setActiveOnly] = useState(true);
    const [currentData, setCurrentData] = useState(setInitCurrentData)

    const handleSetActive = () => {
        setActiveOnly(!activeOnly);
    }

    useEffect(() => {
        if (meetDataActive == null){
            setCurrentData(meetData);
        }
        else if (activeOnly === true){
            setCurrentData(meetDataActive);
        }
        else{
            setCurrentData(meetData);
        }
    }, [activeOnly])

    useEffect(() => {
        if (meetDataActive == null){
            setCurrentData(meetData);
        }
        else if (activeOnly === true){
            setCurrentData(meetDataActive);
        }
        else{
            setCurrentData(meetData);
        }

        setUpdate(!update);
    }, [meetData, meetDataActive])

    const countTotal = (row) => {
        let total = 0;
        Object.keys(row).forEach((key) => {
            total += Number(row[key]);
        });

        return total;
    }

    return (
        <Fragment>
            <Table
                sx={{ justifyContent: 'center', width: '900px' }}
                aria-labelledby="statsTable"
                aria-label="Stats Table">
                <TableHead>
                    <TableRow>
                        <TableCell key={"quarterHeader"}>Quarter</TableCell>
                        <TableCell key={"createdHeader"}>Signed up</TableCell>
                        <TableCell key={"fmHeader"}>First Meet</TableCell>
                        <TableCell key={"c1Header"}>Checkpoint 1</TableCell>
                        <TableCell key={"c2Header"}>Checkpoint 2</TableCell>
                        <TableCell key={"c3Header"}>Checkpoint 3</TableCell>
                        <TableCell key={"c4Header"}>Checkpoint 4</TableCell>
                        <TableCell key={"totalHeader"}>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(currentData).sort((a, b) => String(b).localeCompare(a)).map((key, index) => {
                        if (key === curQuarter) {
                            return (
                                <TableRow style={{ backgroundColor: '#17a2b830' }} key={key + "row"}>
                                    <TableCell key={"quarterField-" + key + "-" + index}>
                                        <i>{key}*</i>
                                    </TableCell>
                                    <TableCell key={"createdData-" + key + "-" + index}>
                                        <i>{currentData[key]['created'] || '0'}</i>
                                    </TableCell>
                                    <TableCell key={"fmData-" + key + "-" + index}>
                                        <i>{currentData[key]['meet1'] || '0'}</i>
                                    </TableCell>
                                    <TableCell key={"c1Data-" + key + "-" + index}>
                                        <i>{currentData[key]['meet2'] || '0'}</i>
                                    </TableCell>
                                    <TableCell key={"c2Data-" + key + "-" + index}>
                                        <i>{currentData[key]['meet3'] || '0'}</i>
                                    </TableCell>
                                    <TableCell key={"c3Data-" + key + "-" + index}>
                                        <i>{currentData[key]['meet4'] || '0'}</i>
                                    </TableCell>
                                    <TableCell key={"c4Data-" + key + "-" + index}>
                                        <i>{currentData[key]['meet5'] || '0'}</i>
                                    </TableCell>
                                    <TableCell key={"totalData-" + key + "-" + index}>
                                        <i>{countTotal(currentData[key])}</i>
                                    </TableCell>
                                </TableRow>
                            );
                        }
                        else {
                            return (
                                <TableRow key={key + "row"}>
                                    <TableCell key={"quarterField-" + key + "-" + index}>
                                        {key}
                                    </TableCell>
                                    <TableCell key={"createdData-" + key + "-" + index}>
                                        <i>{currentData[key]['created'] || '0'}</i>
                                    </TableCell>
                                    <TableCell key={"fmData-" + key + "-" + index}>
                                        {currentData[key]['meet1'] || '0'}
                                    </TableCell>
                                    <TableCell key={"c1Data-" + key + "-" + index}>
                                        {currentData[key]['meet2'] || '0'}
                                    </TableCell>
                                    <TableCell key={"c2Data-" + key + "-" + index}>
                                        {currentData[key]['meet3'] || '0'}
                                    </TableCell>
                                    <TableCell key={"c3Data-" + key + "-" + index}>
                                        {currentData[key]['meet4'] || '0'}
                                    </TableCell>
                                    <TableCell key={"c4Data-" + key + "-" + index}>
                                        {currentData[key]['meet5'] || '0'}
                                    </TableCell>
                                    <TableCell key={"totalData-" + key + "-" + index}>
                                        {countTotal(currentData[key])}
                                    </TableCell>
                                </TableRow>
                            );
                        }

                    })}
                </TableBody>
            </Table>
            <Stack direction={'row'} justifyContent={"space-between"} alignItems={"flex-start"} style={{ marginTop: '5px', height: '20px' }}>
                <div style={{ marginLeft: '10px' }}>
                    <i>* Current quarter</i>
                </div>
                {meetDataActive != null ? (
                    <div>
                        <Checkbox size="small" checked={activeOnly} onClick={handleSetActive} />
                        <FormLabel><i>Active Only</i></FormLabel>
                    </div>
                    
                ) : ("")}
            </Stack>
        </Fragment>
    )
}