import { useEffect, useState } from "react"
import { GENERAL_PHRASES as GENERAL_PHRASES_ENG } from "../../constants/lang/eng/generalPhrases";
import { GENERAL_PHRASES as GENERAL_PHRASES_SWE } from "../../constants/lang/swe/generalPhrases";
import useGetUserData from "../userData/useGetUserData";

export const useGetGeneralPhrases = () => {
    const { userData, loading } = useGetUserData();
    
    const [sentences, setSentences] = useState({
        notSet: "",
        notProvided: "",
    });

    useEffect(() => {
        if (loading === false) {
            /* To be added later
            // @ts-ignore
            if (userData?.prefLang === 'swe') {
                setSentences(GENERAL_PHRASES_SWE)
            }
            else {
                setSentences(GENERAL_PHRASES_ENG)
            }*/
            setSentences(GENERAL_PHRASES_ENG)
        }
        else {
            setSentences(GENERAL_PHRASES_ENG)
        }
    }, [loading, userData]);

    return sentences;
}