import { ErrorAlert } from "./errorAlert"
import { InfoAlert } from "./infoAlert"


export const AlertBox = ({ open, setOpen, isError, successMsg, errorMsg, errorInfo = null }) => {
    if (isError === true) {
        return <ErrorAlert isError={open} setIsError={setOpen} errorMsg={errorMsg} errorInfo={errorInfo} />
    }
    else {
        return <InfoAlert open={open} setIsOpen={setOpen} message={successMsg} />
    }
}