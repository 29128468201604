import { ReactComponent as EntryGray } from '../../assets/images/stepper/Entry_gray.svg';
import { ReactComponent as EntryGrayGreenOutline } from '../../assets/images/stepper/Entry_grayGreenOutline.svg';
//import { ReactComponent as EntryOrangeGreenOutline } from '../assets/images/stepper/Entry_orangeGreenOutline.svg';
import { ReactComponent as EntrySolidGreen } from '../../assets/images/stepper/Entry_solidGreen.svg';
import { ReactComponent as BallGray } from '../../assets/images/stepper/Ball_gray.svg';
import { ReactComponent as BallOrangeGreenOutline } from '../../assets/images/stepper/Ball_orangeGreenOutline.svg';
import { ReactComponent as BallSolidGreen } from '../../assets/images/stepper/Ball_solidGreen.svg';
import { ReactComponent as BallGrayGreenOutline } from '../../assets/images/stepper/Ball_grayGreenOutline.svg';
import { ReactComponent as LinkGrayGreenOutline } from '../../assets/images/stepper/Link_grayGreenOutline.svg';
import { ReactComponent as LinkOrangeGreenOutline } from '../../assets/images/stepper/Link_orangeGreenOutline.svg';
import { ReactComponent as LinkSolidGreen } from '../../assets/images/stepper/Link_solidGreen.svg';
import { ReactComponent as LinkGray } from '../../assets/images/stepper/Link_gray.svg';
import { ReactComponent as ExitGray } from '../../assets/images/stepper/Exit_gray.svg';
import { ReactComponent as ExitGrayGreenOutline } from '../../assets/images/stepper/Exit_grayGreenOutline.svg';
import { ReactComponent as ExitOrangeGreenOutline } from '../../assets/images/stepper/Exit_orangeGreenOutline.svg';
import { ReactComponent as ExitSolidGreen } from '../../assets/images/stepper/Exit_solidGreen.svg';
import { Tab } from '@mui/material';

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/**
 * 
 * @param {Object} step 
 * @param {String} type 
 * @param {Number} order 
 * @param {Object} currentUser 
 * @param {(href: string) => void} onClickStep 
 * @returns 
 */
export function setStepVisuals(step, type, order, currentUser, onClickStep, isHover = false) {
    let currentTime = new Date(Date.now());
    let stepLabel = null;
    let disabled = false;

    let classname = 'home-stepper-graphic';

    if (isHover){
        classname = 'stepper-graphics';
    }

    if (step == null) {
        switch (type) {
            case "Meeting":
                stepLabel = <BallGray className={classname} />;
                disabled = true;
                break;
            case "Link":
                stepLabel = <LinkGray className={classname} />;
                disabled = true;
                break;
            case "Init":
                stepLabel = <EntryGray className={classname} />;
                disabled = true;
                break;
            case "Exit":
                stepLabel = <ExitGray className={classname} />;
                disabled = true;
                break;
            default:
                return;
        }
    } else if (step.type === "Meeting") {
        let late = false;
        // eslint-disable-next-line eqeqeq
        if (step.nextTime != undefined) {
            let deadline = new Date(step.nextTime.seconds * 1000);

            if (currentTime.getTime() > deadline.getTime()) {
                late = true;
            }
            //console.log("Time: " + deadline.toString() + ", Now: " + currentTime.toString() + "Late: " + late);
        }

        switch (step.meetState) {
            case "Not Booked":
                stepLabel = <BallGray className={classname} />;
                if (currentUser.role < 2) {
                    disabled = true;
                }
                break;
            case "Booked":
                if (late) {
                    stepLabel = <BallOrangeGreenOutline className={classname} />;
                }
                else {
                    stepLabel = <BallGrayGreenOutline className={classname} />;
                }
                break;
            case "Completed":
                stepLabel = <BallSolidGreen className={classname} />;
                break;
            default:
                stepLabel = <BallGray className={classname} />;
                disabled = true;
                break;
        }
    } else if (step.type === "Init") {
        switch (step.BDState) {
            case "Locked":
                stepLabel = <EntryGray className={classname} />;
                disabled = true;
                break;
            case "Unlocked":
                if (step.IBOState === "Not Completed") {
                    stepLabel = <EntryGrayGreenOutline className={classname} />;
                } else if (step.IBOState === "Completed") {
                    stepLabel = <EntrySolidGreen className={classname} />;
                }
                break;
            case "Passed":
                stepLabel = <EntrySolidGreen className={classname} />;
                break;
            default:
                stepLabel = <EntryGray className={classname} />;
                disabled = true;
                break;
        }
    } else if (step.type === "Link") {
        let late = false;
        // eslint-disable-next-line eqeqeq
        if (step.nextTime != undefined) {
            let deadline = new Date(step.nextTime.seconds * 1000);

            if (currentTime.getTime() > deadline.getTime()) {
                late = true;
            }
            //console.log("Time: " + deadline.toString() + ", Now: " + currentTime.toString() + "Late: " + late);
        }

        switch (step.BDState) {
            case "Locked":
                stepLabel = <LinkGray className={classname} />;
                disabled = true;
                break;
            case "Unlocked":
                if (step.IBOState === "Not Completed") {
                    if (late) {
                        stepLabel = <LinkOrangeGreenOutline className={classname} />;
                    }
                    else {
                        stepLabel = <LinkGrayGreenOutline className={classname} />;
                    }
                    break;
                } else if (step.IBOState === "Completed") {
                    stepLabel = <LinkOrangeGreenOutline className={classname} />;
                    break;
                }
                break;
            case "Passed":
                stepLabel = <LinkSolidGreen className={classname} />;
                break;
            default:
                stepLabel = <LinkGray className={classname} />;
                disabled = true;
                break;
        }
    } else if (step.type === "Exit") {
        switch (step.BDState) {
            case "Locked":
                stepLabel = <ExitGray className={classname} />;
                disabled = true;
                break;
            case "Unlocked":
                if (step.IBOState === "Not Completed") {
                    stepLabel = <ExitGrayGreenOutline className={classname} />;
                } else if (step.IBOState === "Completed") {
                    stepLabel = <ExitOrangeGreenOutline className={classname} />;
                }
                break;
            case "Passed":
                stepLabel = <ExitSolidGreen className={classname} />;
                break;
            default:
                stepLabel = <ExitGray className={classname} />;
                disabled = true;
                break;
        }
    }

    return <Tab className="stepper-tab" label={stepLabel} value={order} onClick={() => onClickStep("#step" + order)} disabled={disabled} />
}