export const USERINFO_HEADERS = {
    userInfo: "User info",
    deactivated: "Deactivated account",
    email: "Email",
    gender: "Gender",
    dob: "Date of birth",
    city: "City",
    phone: "Phone",
    education: "Education",
    hkrConn: "Connection to Kristianstad University",
    language: "Prefered language",
    expectation: "Expectation",
    reference: "Reference",
}