export const GRADING_COLUMNS_TAGS = {
    CRTag: "CR",
    TeamRTag: "TeamR",
    BRTag: "BR",
    SRTag: "SR",
    FRTag: "FR",
    TechRTag: "TechR",
    IPRR: "IPRR",
}

export const GRADING_TABLE_HEADERS = {
    Level:  "LEVEL",
    CR:     "CUSTOMER READINESS",
    TeamR:  "TEAM READINESS",
    BR:     "BUSINESS READINESS",
    SR:     "SUSTAINABILITY READINESS",
    FR:     "FUNDING READINESS",
    TechR:  "TECHNOLOGY READINESS",
    IPRR:   "IPR READINESS",
}

const blueBG = "blue-bg";
const greenBG = "green-bg";
const peachBG = "peach-bg";
const greyBG = "grey-bg";
const whiteBG = "";

export const GRADING_CELL_BACKGROUND = {
    CR:     {1: blueBG, 2: blueBG,  3: blueBG,  4: greenBG, 5: greenBG, 6: greenBG, 7: peachBG, 8: peachBG, 9: greyBG},
    TeamR:  {1: blueBG, 2: blueBG,  3: blueBG,  4: greenBG, 5: greenBG, 6: peachBG, 7: peachBG, 8: peachBG, 9: greyBG},
    BR:     {1: blueBG, 2: blueBG,  3: greenBG, 4: greenBG, 5: greenBG, 6: peachBG, 7: peachBG, 8: greyBG,  9: greyBG},
    SR:     {1: blueBG, 2: blueBG,  3: greenBG, 4: greenBG, 5: greenBG, 6: peachBG, 7: peachBG, 8: greyBG,  9: greyBG},
    FR:     {1: blueBG, 2: blueBG,  3: greenBG, 4: whiteBG, 5: whiteBG, 6: whiteBG, 7: whiteBG, 8: whiteBG, 9: whiteBG},
    TechR:  {1: blueBG, 2: blueBG,  3: greenBG, 4: whiteBG, 5: whiteBG, 6: whiteBG, 7: whiteBG, 8: whiteBG, 9: whiteBG},
    IPRR:   {1: blueBG, 2: greenBG, 3: whiteBG, 4: whiteBG, 5: whiteBG, 6: whiteBG, 7: whiteBG, 8: whiteBG, 9: whiteBG},
}

export const GRADING_CELL_LEVEL_COLOR = {
    1: "red", 
    2: "red",  
    3: "red",  
    4: "orange", 
    5: "yellow", 
    6: "lightgreen", 
    7: "green", 
    8: "green", 
    9: "green",
}

export const DEFAULT_MILESTONE_TEXT = "Example milestone text";