import { Checkbox, FormControlLabel, FormControl, FormGroup } from "@mui/material"
import parse from 'html-react-parser';

export const TemplateCheckedCard = ({ assignedQuestion }) => {
    return (
        <div style={{ marginLeft: '20px' }}>
        <span style={{ display: 'block', marginTop: 10 }}>
            {parse(assignedQuestion.text)}
        </span>

        {String(assignedQuestion.options).split(';').map(element => (
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        checked={assignedQuestion.answer || false}
                        name={assignedQuestion.key}
                        //name="checked"
                        color="primary"
                        control={<Checkbox />}
                        label={parse(element)}
                        labelPlacement={assignedQuestion.labelPlacement}
                    />
                </FormGroup>
            </FormControl>
        ))}
    </div>
    )
}