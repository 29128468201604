export const EmailLabels = {
    deadline: "deadline",
    notificationsHeader: "notificationsHeader",
    notificationsLabel: "notificationsLabel",
    unlocked: "unlocked",
    oneWeek: "oneWeek",
    oneDay: "oneDay",
    reviewed: "reviewed",
    meetingApproved: "meetingApproved",
}

export const TrackLabels = {
    unassigned: { key: "unassigned", label: "-", selectLabel: "Unassigned", index: 0 },
    start: { key: "start", label: "START", selectLabel: "START", index: 1 },
    incubator: { key: "incubator", label: "INCUBATOR", selectLabel: "INCUBATOR", index: 2 },
    scale: { key: "scale", label: "SCALE", selectLabel: "SCALE", index: 3 },
    exit: { key: "exit", label: "EXIT", selectLabel: "EXIT", index: 4 },
}

export const OrderByLabels = {
    BD: "BD",
    Contact: "Contact",
    IBO: "IBO",
    Next: "Next",
    Progress: "Progress",
    Track: "Track",
}

export const ProcessStepLabels = {
    'PreMeet': { key: "PreMeet", label: "Sign-up", stepGraphic: false },
    'First Meet': { key: "First Meet", label: "First meet", stepGraphic: true },
}

export const TimeEventType = {
    meetingCompany: { key: 'meetingCompany', label: 'Meeting with company' },
    meetingExternal: { key: 'meetingExternal', label: 'Meeting with external partner' },
    admin: { key: 'admin', label: 'Admin/research' },
    event: { key: 'event', label: 'Event' },
    other: { key: 'other', label: 'Other' },
}

export const AvailableMatrixTags = [
    { key: 'CR', label: 'Customer', fullLabel: 'Customer Readiness' },
    { key: 'TR', label: 'Team', fullLabel: 'Team Readiness' },
    { key: 'BR', label: 'Business', fullLabel: 'Business Readiness' },
    { key: 'SR', label: 'Sustainability', fullLabel: 'Sustainability Readiness' },
    { key: 'FR', label: 'Funding', fullLabel: 'Funding Readiness' },
    { key: 'TechR', label: 'Technology', fullLabel: 'Technology Readiness' },
    { key: 'IPR', label: 'IPR', fullLabel: 'IPR Readiness' },
]

export const ProcessStepIndex = {
    'PreMeet': 0,
    'First Meet': 1,
    'Homework 1': 2,
    'Checkpoint 1': 3,
    'Homework 2': 4,
    'Checkpoint 2': 5,
    'Homework 3': 6,
    'Checkpoint 3': 7,
    'Homework 4': 8,
    'Checkpoint 4': 9,
    'Exit': 10,
}

export const ExitReasonsLabels = {
    start: "Completed START",
    incubator: "Completed INCUBATOR",
    scale: "Completed SCALE",
}

export const InactiveReasonsLabels = {
    blocked: "Blocked/not applicable",
    paused: "Paused due to inactivity",
    aborted: "Aborted on IBOs request",
    exit: "Has exited",
    transfered: "Transfered to other actor in innovation development",
}

export const CompanyChoices = {
    no: "No/Not yet",
    yes_new: "Yes, in a newly formed company based on the idea",
    yes_established: "Yes, within an established company",
}

export const OwnerTypes = {
    privateFounder: "Founder (private)",
    compFounder: "Founder (through company)",
    investor: "Investor",
    privateOther: "Other (private)",
    compOther: "Other (company)",
}

export const WorkInGroupAnswers = {
    alone: "Alone",
    group: "Group",
}