import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListOfTemplates from './ListOfTemplates';
import AddNewTemplate from './AddNewTemplate';


const TemplateEditor = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <ListOfTemplates />
                </Grid>
                <Grid item md={6}>
                    <AddNewTemplate />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TemplateEditor
