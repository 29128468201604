import { Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, TextField } from "@mui/material"
import parse from 'html-react-parser';
import { useAuth } from "../../../../contexts/AuthContext";
import { QuestionCommentBox } from "../QuestionCommentBox";


export const CheckedCard = ({ projectData, step, location, assignedQuestion, question, splitCheckedOptions, handleSingleCheckedOptionChange, handleCheckedOptionChange }) => {
    const { currentUser } = useAuth();

    if (question.perks == null || question.perks === "") {
        return (
            <Card className="characters" key={question.key} sx={{ maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem', marginLeft: '-15px' }}>
                <CardContent>
                    <span style={{ display: 'block', marginTop: 10 }}>{parse(question.text)}</span>
                    {assignedQuestion.note != null ? (
                        <p className="note-style"><strong>{assignedQuestion.note}</strong></p>) : ("")}

                    {splitCheckedOptions(assignedQuestion, question).map((element, index) => (
                        <div key={assignedQuestion.key + "-" + index}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row={false}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={assignedQuestion.key}
                                                checked={element.answer}
                                                onChange={handleCheckedOptionChange}
                                                name={element.option}
                                                //name="checked"
                                                color="primary"
                                                disabled={currentUser.role >= 2}
                                            />
                                        }
                                        label={parse(element.option)}
                                        labelPlacement={question.labelPlacement}
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    ))}

                    <QuestionCommentBox projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} />
                </CardContent>
            </Card>
        )
    }
    else if (question.perks === "Infinity") {
        return (
            <Card className="characters" key={question.key} sx={{ maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem', marginLeft: '-15px' }}>
                <CardContent>
                    <span style={{ display: 'block', marginTop: 10 }}>{parse(question.text)}</span>
                    {assignedQuestion.note != null ? (
                        <p className="note-style"><strong>{assignedQuestion.note}</strong></p>) : ("")}

                    {splitCheckedOptions(assignedQuestion, question).map(element => (
                        <div key={assignedQuestion.key + "-" + element}>
                            <FormControl component="fieldset">
                                <FormGroup row={false}>
                                    {element.option === "" ? (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value={assignedQuestion.key}
                                                    checked={element.answer}
                                                    onChange={handleSingleCheckedOptionChange}
                                                    name={element.option}
                                                    //name="checked"
                                                    color="primary"
                                                    disabled={currentUser.role >= 2}
                                                />
                                            }
                                            label={parse(element.option)}
                                            labelPlacement={question.labelPlacement}
                                        />
                                    ) : (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value={assignedQuestion.key}
                                                    checked={element.answer}
                                                    onChange={handleCheckedOptionChange}
                                                    name={element.option}
                                                    //name="checked"
                                                    color="primary"
                                                    disabled={currentUser.role >= 2}
                                                />
                                            }
                                            label={parse(element.option)}
                                            labelPlacement={question.labelPlacement}
                                        />
                                    )}

                                </FormGroup>
                            </FormControl>
                        </div>
                    ))}
                </CardContent>
            </Card>
        )
    }
    else {
        return (
            <Box></Box>
        )
    }
}