import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { db } from '../../firebase'
import { useAuth } from "../../contexts/AuthContext"
import firebaseRefList from '../../firebase/firebaseRefList'

import { ReactComponent as GrayProgress } from '../../assets/images/progress/grayProgress.svg';
import { ReactComponent as GreenGrayProgress } from '../../assets/images/progress/greenGrayProgress.svg';
import { ReactComponent as GreenOrangeProgress } from '../../assets/images/progress/greenOrangeProgress.svg';
import { ReactComponent as GreenProgress } from '../../assets/images/progress/greenProgress.svg';
import { doc, onSnapshot } from 'firebase/firestore';

export default function StepperGraphicsIcons(props) {
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth()

    const stepNames = {}
    stepNames["PreMeet"] = 'a';
    stepNames["First Meet"] = 'meet1';
    stepNames["Homework 1"] = 'b';
    stepNames["Checkpoint 1"] = 'meet2';
    stepNames["Homework 2"] = 'c';
    stepNames["Checkpoint 2"] = 'meet3';
    stepNames["Homework 3"] = 'd';
    stepNames["Checkpoint 3"] = 'meet4';
    stepNames["Homework 4"] = 'e';
    stepNames["Checkpoint 4"] = 'meet5';
    stepNames["Exit"] = 'f';

    const [stepInfo, setStepInfo] = useState({});

    useEffect(() => {
        if (props.data == null || props.data.currentStep == null) return;
        const ref = onSnapshot(doc(db, "Projects/" + props.data.id + "/Steps/" + stepNames[props.data.currentStep]), (snapshot) => {
            const localData = {};
            if (snapshot.exists) {
                localData["meetState"] = snapshot.data().meetState;
                localData["BDState"] = snapshot.data().BDState;
                localData["IBOState"] = snapshot.data().IBOState;
                localData["type"] = snapshot.data().type;
                localData["nextTime"] = snapshot.data().nextTime;

                setStepInfo(localData);
            }
            setLoading(false);

        })

        firebaseRefList.push(ref);

    }, [loading, props.data])

    function setStepVisuals() {
        let currentTime = new Date(Date.now());
        let stepLabel = null;
        let disabled = true;

        if (stepInfo === null) {
            stepLabel = <GrayProgress className="ibo-table-stepper-icon" />;
            disabled = true;
        } else if (stepInfo["type"] === "Meeting") {
            let late = false;
            // eslint-disable-next-line eqeqeq
            if (stepInfo["nextTime"] != undefined) {
                let deadline = new Date(stepInfo["nextTime"].seconds * 1000);

                if (currentTime.getTime() > deadline.getTime()) {
                    late = true;
                }
                //console.log("Time: " + deadline.toString() + ", Now: " + currentTime.toString() + "Late: " + late);
            }

            switch (stepInfo["meetState"]) {
                case "Not Booked":
                    stepLabel = <GrayProgress className="ibo-table-stepper-icon" />;
                    if (currentUser.role < 2) {
                        disabled = true;
                    }
                    break;
                case "Booked":
                    if (late) {
                        stepLabel = <GreenOrangeProgress className="ibo-table-stepper-icon" />;
                        disabled = true;
                    }
                    else {
                        stepLabel = <GreenGrayProgress className="ibo-table-stepper-icon" />;
                        disabled = true;
                    }
                    break;
                case "Completed":
                    stepLabel = <GreenProgress className="ibo-table-stepper-icon" />;
                    disabled = true;
                    break;
                default:
                    stepLabel = <GrayProgress className="ibo-table-stepper-icon" />;
                    disabled = true;

                    break;
            }
        } else if (stepInfo["type"] === "Link" || stepInfo["type"] === "Exit") {
            switch (stepInfo["BDState"]) {
                case "Locked":
                    stepLabel = <GrayProgress className="ibo-table-stepper-icon" />;
                    disabled = true;

                    break;
                case "Unlocked":
                    if (stepInfo["IBOState"] === "Not Completed") {
                        stepLabel = <GreenGrayProgress className="ibo-table-stepper-icon" />;
                    } else if (stepInfo["IBOState"] === "Completed") {
                        stepLabel = <GreenOrangeProgress className="ibo-table-stepper-icon" />;
                        disabled = true;
                    }
                    break;
                case "Passed":
                    stepLabel = <GreenProgress className="ibo-table-stepper-icon" />;
                    disabled = true;
                    break;
                default:
                    stepLabel = <GrayProgress className="ibo-table-stepper-icon" />;
                    disabled = true;
                    break;
            }
        }
        else if (stepInfo["type"] === "Init") {
            switch (stepInfo["BDState"]) {
                case "Locked":
                    stepLabel = <GrayProgress className="ibo-table-stepper-icon" />;
                    disabled = true;

                    break;
                case "Unlocked":
                    if (stepInfo["IBOState"] === "Not Completed") {
                        stepLabel = <GreenGrayProgress className="ibo-table-stepper-icon" />;
                    } else if (stepInfo["IBOState"] === "Completed") {
                        stepLabel = <GreenProgress className="ibo-table-stepper-icon" />;
                        disabled = true;
                    }
                    break;
                case "Passed":
                    stepLabel = <GreenProgress className="ibo-table-stepper-icon" />;
                    disabled = true;
                    break;
                default:
                    stepLabel = <GrayProgress className="ibo-table-stepper-icon" />;
                    disabled = true;
                    break;
            }
        }

        return stepLabel;
    }


    return (
        <Box style={{ marginTop: "17px", paddingLeft: "10px", marginLeft: "-10px" }}>
            {setStepVisuals()}
        </Box>
    );
}
