import { Box, Checkbox, DialogContent, Divider, Button, FormControl, InputLabel, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, IconButton, FormControlLabel, Switch, Tooltip } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../../helper/bootstrapDialog"
import { DEFAULT_MILESTONE_TEXT, GRADING_TABLE_HEADERS } from "../../../constants/grading"
import { GRADING_CELL_INFOTEXT } from "../../../constants/lang/eng/grading"
import { useEffect, useState } from "react"

import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CommentDialogGradingCellInternal from "../../Comments/CommentDialogGradingCellInternal"

/**
 * 
 * @param {object} param0 
 * @param {boolean} param0.open
 * @param {() => void} param0.handleClose
 * @param {import("../../../types/Grading").GradingCell} param0.cell
 * @param {import("../../../types/Project").IBOProject} param0.project
 * @param {object} param0.startLevel
 * @param {object} param0.targetLevel
 * @param {object} param0.currentLevel
 * @param {(level: any, tag: any, state: ('start' | 'goal' | 'current')) => void} param0.setCellState
 * @param {boolean} param0.update
 * @param {boolean} param0.hasStartVal
 * @param {boolean} param0.hasTargetVal
 * @param {boolean} param0.hasVerfifiedMilestones
 * @param {boolean} param0.verifyMilestones
 * @param {(tag: string, level: number, milestones: any) => void} param0.handleSaveMilestoneData
 * @param {() => void} param0.handleNextyVerifyMilestones
 * @param {(tag: string, level: number) => void} param0.handleMarkCellAsComplete
 * @param {boolean} param0.finalCell
 */
export const EditCellDialog = ({
    open,
    handleClose,
    cell,
    startLevel,
    targetLevel,
    project,
    currentLevel,
    setCellState,
    update,
    hasStartVal,
    hasTargetVal,
    hasVerfifiedMilestones,
    verifyMilestones,
    handleSaveMilestoneData,
    handleNextyVerifyMilestones,
    handleMarkCellAsComplete,
    finalCell,
}) => {
    const [toggleVal, setToggleVal] = useState('');
    const [canBeEdited, setCanBeEdited] = useState(false);
    const [edit, setEdit] = useState(true);
    const [isEdited, setIsEdited] = useState(false);
    const [updateLocal, setUpdateLocal] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [milestones, setMilestones] = useState([
        { text: DEFAULT_MILESTONE_TEXT, checked: false }
    ]);


    useEffect(() => {
        if (hasVerfifiedMilestones === true || hasStartVal === false || hasTargetVal === false) {
            setEdit(false);
        }
        else {
            setEdit(true);
        }
    }, [hasVerfifiedMilestones, hasStartVal, hasTargetVal]);


    useEffect(() => {
        if (cell?.levelData != null) {
            if (cell?.levelData?.Milestones != null) {
                let localList = [];

                for (let index = 0; index < cell?.levelData?.Milestones.length; index++) {
                    const value = cell?.levelData?.Milestones[index];
                    let checked = false;

                    if (cell?.levelData?.MilestoneCompletion != null && cell?.levelData?.MilestoneCompletion[index]) {
                        checked = cell?.levelData?.MilestoneCompletion[index];
                    }

                    localList.push({ text: value, checked: checked })
                }

                setMilestones(localList);
                setUpdateLocal(!updateLocal);
            }
            else {
                setMilestones([{ text: DEFAULT_MILESTONE_TEXT, checked: false }]);
                setUpdateLocal(!updateLocal);
            }

            if (cell?.levelData?.LevelCompletion != null) {
                setIsCompleted(true);
            }
            else {
                setIsCompleted(false);
            }
        }
        else {
            setMilestones([{ text: DEFAULT_MILESTONE_TEXT, checked: false }]);
            setUpdateLocal(!updateLocal);
            setIsCompleted(false);
        }

        if (cell.level != null && cell.tag != null) {
            console.log("Edit: ", isOutOfRange(cell.tag));
            if (hasStartVal === false || hasTargetVal === false){
                setCanBeEdited(false);
            }
            else{
                setCanBeEdited(!isOutOfRange(cell.tag));
            }
        }

        setIsEdited(false);
    }, [cell])

    useEffect(() => {
        console.log("Value: ", startLevel, targetLevel)
        if (startLevel != null && startLevel[cell.tag] != null && startLevel[cell.tag] === cell.level) {
            setToggleVal('start')
        } else if (targetLevel != null && targetLevel[cell.tag] != null && targetLevel[cell.tag] === cell.level) {
            setToggleVal('goal');
        } else {
            setToggleVal('');
        }
    }, [startLevel, targetLevel, cell, update])

    const getInfoText = () => {
        let text = "";
        if (cell.tag != null && GRADING_CELL_INFOTEXT[cell.tag] != null) {
            if (GRADING_CELL_INFOTEXT[cell.tag][cell.level] != null) {
                text = GRADING_CELL_INFOTEXT[cell.tag][cell.level];
            }
        }

        return text;
    }

    const isOutOfRange = (tag) => {
        if ((startLevel[tag] != null && startLevel[tag] > Number(cell.level)) || (targetLevel[tag] != null && targetLevel[tag] < Number(cell.level))) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleChange = (event, value) => {
        if (value == null) {
            // @ts-ignore
            setCellState(cell.level, cell.tag, toggleVal);
        }
        else {
            setCellState(cell.level, cell.tag, value);
        }
    }

    const handleChangeText = (index, value) => {
        let local = milestones;

        if (local[index] != null) {
            local[index].text = value;
        }
        setIsEdited(true);
        setMilestones(local);
        setUpdateLocal(!updateLocal);
    }

    const handleCheckMilestone = (index) => {
        let local = milestones;
        if (local[index] != null) {
            if (local[index].checked != null) {
                local[index].checked = !local[index].checked;
            }
            else {
                local[index].checked = true;
            }
        }
        setIsEdited(true);
        setMilestones(local);
        setUpdateLocal(!updateLocal);
    }

    const addMilestoneRow = () => {
        let local = milestones;

        local.push({
            text: DEFAULT_MILESTONE_TEXT,
            checked: false
        });

        setIsEdited(true);
        setMilestones(local);
        setUpdateLocal(!updateLocal);
    }

    const removeMilestoneRow = (index) => {
        let local = milestones;

        local.splice(index, 1);
        setIsEdited(true);
        setMilestones(local);
        setUpdateLocal(!updateLocal);
    }

    const handleClickNext = () => {
        if (cell != null) {
            handleSaveMilestoneData(cell.tag, cell.level, milestones);
        }

        handleNextyVerifyMilestones();
    }

    const handleClickSave = () => {
        if (cell != null) {
            handleSaveMilestoneData(cell.tag, cell.level, milestones);
        }
        handleClose();
    }

    const allChecked = () => {
        let completed = true;
        for (let index = 0; index < milestones.length; index++) {
            const element = milestones[index];

            if (element.checked === false) {
                completed = false;
                break;
            }
        }

        return completed;
    }

    const handleClickComplete = () => {
        if (cell?.tag != null && cell?.level != null) {
            handleSaveMilestoneData(cell.tag, cell.level, milestones);
            handleMarkCellAsComplete(cell.tag, cell.level);
            handleClose();
        }
    }

    const getCompletedText = () => {
        let text = "";

        let timestamp = new Date();
        let track = "";

        if (cell?.levelData?.LevelCompletion != null) {
            if (cell?.levelData?.LevelCompletion.CompletionTime != null) {
                timestamp = cell?.levelData?.LevelCompletion.CompletionTime.toDate();
            }
            if (cell?.levelData?.LevelCompletion.InTrack != null) {
                track = cell?.levelData?.LevelCompletion.InTrack;
            }

            text = "Completed in " + track + " at " + timestamp.toLocaleString('se-sv');
        }

        return text;
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <Box width={'500px'}>
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ padding: '10px 10px 0px 10px' }}><strong>{GRADING_TABLE_HEADERS[cell.tag]} - Level: {cell.level}</strong></h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent>
                    <Stack spacing={1}>
                        <Typography sx={{ width: '95%' }}>
                            {getInfoText()}
                        </Typography>
                        {cell.tag != null && cell.level != null ? (
                            <Box position={'absolute'} right={8} top={33}>
                                <CommentDialogGradingCellInternal projData={project} tag={cell.tag} level={cell.level} />
                            </Box>

                        ) : ("")}

                        <Divider />
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography>
                                Milestones
                            </Typography>
                            {canBeEdited ? (
                                <FormControlLabel control={<Switch size="small" disabled={isCompleted} checked={edit} onChange={() => setEdit(!edit)} />} label={<Box fontSize={'14px'}>Edit</Box>} />
                            ) : ("")}

                        </Stack>

                        {edit ? (
                            <>
                                {milestones.map((item, index) => {
                                    return (
                                        <Stack key={index} direction={'row'} alignItems={'center'}>
                                            <Checkbox checked={item.checked} disabled />

                                            <TextField
                                                sx={{ width: '380px' }}
                                                size="small"
                                                value={item.text}
                                                onChange={(e) => handleChangeText(index, e.target.value)}
                                            />

                                            {index > 0 ? (
                                                <IconButton onClick={() => removeMilestoneRow(index)}>
                                                    <HighlightOffRoundedIcon />
                                                </IconButton>
                                            ) : ("")}
                                        </Stack>
                                    );

                                })}

                                <Stack sx={{ paddingLeft: '5px' }} direction={'row'} alignItems={'center'}>
                                    <Button onClick={addMilestoneRow}>
                                        <Stack direction={'row'} spacing={1}>
                                            <ControlPointRoundedIcon />
                                            <Box>
                                                Add Milestone
                                            </Box>
                                        </Stack>

                                    </Button>
                                </Stack>
                            </>
                        ) : (
                            <>
                                {milestones.map((item, index) => {
                                    return (
                                        <Stack key={index} direction={'row'} alignItems={'center'}>
                                            <Checkbox disabled={isCompleted || canBeEdited === false} checked={item.checked} onChange={() => handleCheckMilestone(index)} />

                                            <Box fontSize={'16px'}>
                                                {item.text}
                                            </Box>
                                        </Stack>
                                    );
                                })}

                                <Box display={'flex'} justifyContent={'center'} width={'100%'} paddingTop={'10px'} paddingBottom={'20px'}>
                                    {allChecked() ? (
                                        <>
                                            {isCompleted ? (
                                                <Box>
                                                    <Tooltip arrow title={getCompletedText()} placement="right">
                                                        <Stack direction={'row'} alignItems={'center'} spacing={1} width={'140px'}>
                                                            <CheckRoundedIcon htmlColor="#00ad03" />
                                                            <Box fontSize={'1.1rem'} color={'#00ad03'}>
                                                                Completed
                                                            </Box>
                                                        </Stack>
                                                    </Tooltip>

                                                </Box>
                                            ) : (
                                                <Button onClick={handleClickComplete} size="small" variant="contained">
                                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                        <CheckRoundedIcon />
                                                        <Box>
                                                            Mark as completed
                                                        </Box>
                                                    </Stack>
                                                </Button>
                                            )}
                                        </>
                                    ) : ("")}
                                </Box>
                            </>
                        )}
                    </Stack>


                    <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                        <Button size="small" onClick={handleClose} variant="outlined">
                            {isEdited ? (
                                "Close without saving"
                            ) : (
                                "Close"
                            )}
                        </Button>
                        {verifyMilestones ? (
                            <Button size="small" onClick={handleClickNext} variant="outlined">
                                {finalCell ? (
                                    "Complete"
                                ) : (
                                    "Next"
                                )}
                            </Button>
                        ) : (
                            <>
                                {isEdited ? (
                                    <Button size="small" onClick={handleClickSave} variant="outlined">
                                        Save
                                    </Button>
                                ) : ("")}
                            </>

                        )}

                    </Stack>
                </DialogContent>
            </Box>

        </BootstrapDialog>
    )
}