import { useRef, useState } from "react"
import { useAuth } from "../../../contexts/AuthContext"
import { useNavigate } from "react-router-dom"
import { Alert, Button } from 'react-bootstrap'
import { Box, FormHelperText, IconButton, InputAdornment, LinearProgress, OutlinedInput, Stack, TextField } from "@mui/material"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"

const SignupInvited = ({ handleNext, isLoadingCreateUser }) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const [emailVal, setEmailVal] = useState('');
    const [passVal, setPassVal] = useState('');
    const [pass2Val, setPass2Val] = useState('');
    const [firstNameVal, setFirstNameVal] = useState('');
    const [lastNameVal, setLastNameVal] = useState('');

    const { signup } = useAuth()
    const [error, setError] = useState("")
    // @ts-ignore
    const [matching, setMatching] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordAgain, setShowPasswordAgain] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleClickShowPasswordAgain = () => {
        setShowPasswordAgain(!showPasswordAgain)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const checkPassword = () => {
        if (passVal !== pass2Val && pass2Val !== "") {
            setMatching(false);
        }
        else {
            setMatching(true);
        }
    }

    const checkEmail = () => {
        if (emailVal !== "") {
            setEmailValid(emailRegex.test(emailVal));
        }
        else {
            setEmailValid(true);
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setError("");
            setLoading(true);
            if (emailVal !== "" && passVal !== "" && firstNameVal !== "" && lastNameVal !== "") {
                if (emailRegex.test(emailVal) === true) {
                    if (passVal === pass2Val) {
                        await signup(emailVal, passVal, firstNameVal, lastNameVal, true).then(async () => {
                            await handleNext();
                        })
                    }
                    else {
                        throw new Error("Password does not match!");
                    }
                }
                else {
                    throw new Error("Please enter a valid email!");
                }
            }
            else {
                throw new Error("Please fill in all of the fields!");
            }

        } catch (e) {
            setError(e.message);
            setLoading(false)
        }
    }

    return (

        <div className="log-box">

            <h3>Sign Up</h3>
            {error && <Alert variant="danger">{error}</Alert>}

            <form onSubmit={handleSubmit}>

                <Stack className="form-group">
                    <div>
                        <label>Email</label>
                    </div>

                    <TextField
                        sx={{ width: "100%" }}
                        type="email"
                        value={emailVal}
                        onChange={(e) => { setEmailVal(e.target.value) }}
                        onBlur={checkEmail}
                        error={emailValid === false}
                        size="small"
                        placeholder="Enter email"
                        required
                    />

                    <FormHelperText error={emailValid === false}>{emailValid === false ? ("Please enter a valid email!") : ("")}</FormHelperText>
                </Stack>

                <Stack className="form-group">
                    <div>
                        <label>Password:</label>
                    </div>

                    <OutlinedInput
                        sx={{ width: "100%" }}
                        type={showPassword ? "text" : "password"}
                        value={passVal}
                        onChange={(e) => { setPassVal(e.target.value) }}
                        size="small"
                        onBlur={checkPassword}
                        error={matching === false}
                        placeholder="Enter password"
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Stack>


                <Stack className="form-group">
                    <div>
                        <label>Enter password again:</label>
                    </div>

                    <OutlinedInput
                        sx={{ width: "100%" }}
                        type={showPasswordAgain ? "text" : "password"}
                        value={pass2Val}
                        onChange={(e) => { setPass2Val(e.target.value) }}
                        size="small"
                        placeholder="Enter password again"
                        onBlur={checkPassword}
                        error={matching === false}
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordAgain}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPasswordAgain ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText error={matching === false}>{matching === false ? ("Password does not match!") : ("")}</FormHelperText>
                </Stack>

                <Stack className="form-group">
                    <div>
                        <label>First Name:</label>
                    </div>

                    <TextField
                        sx={{ width: "100%" }}
                        type="text"
                        value={firstNameVal}
                        onChange={(e) => { setFirstNameVal(e.target.value) }}
                        size="small"
                        required
                        placeholder="Enter first name"
                    />
                </Stack>

                <Stack className="form-group">
                    <div>
                        <label>Last Name:</label>
                    </div>

                    <TextField
                        sx={{ width: "100%" }}
                        type="text"
                        value={lastNameVal}
                        onChange={(e) => { setLastNameVal(e.target.value) }}
                        size="small"
                        required
                        placeholder="Enter last name"
                    />
                </Stack>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {loading ? (
                        <Box sx={{ width: '100%', marginBottom: "3px" }}>
                            <LinearProgress color="primary" />
                        </Box>
                    ) : (
                        <Box sx={{ marginBottom: "7px" }}>

                        </Box>
                    )}

                </div>
                <Button disabled={loading} type="submit" className="btn btn-info btn-md btn-block">Create account</Button>
            </form>
        </div>

    )
}

export default SignupInvited