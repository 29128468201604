import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import { useAuth } from '../../contexts/AuthContext'
import { useGetExternalComments } from '../../hooks/commentData/useGetExternalComments';
import { BootstrapDialog, BootstrapDialogTitle } from '../../helper/bootstrapDialog';
import CommentLog from './CommentLog';
import { Box, Divider, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material';

export default function CommentDialogEntryStep({ projID, location, projData, step }) {
    const [open, setOpen] = useState(false);
    const { comments } = useGetExternalComments(projID, location, "undefined", "created")

    const { currentUser } = useAuth();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [sortOrder, setSortOrder] = useState('ascending');
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        if (sortOrder === 'descending') {
            setChecked(true);
            setSortOrder('ascending')
        }
        else {
            setChecked(false);
            setSortOrder('descending')
        }
    }

    return (
        <div>
            {/* <Button className="commentbnt" onClick={handleClickOpen}>
                <CommentOutlinedIcon />
            </Button> */}

            {currentUser.role >= 2 && comments.length === 0 &&
                <Tooltip title={'External Comments'} arrow>
                    <IconButton size='small' onClick={handleClickOpen}>
                        <RecordVoiceOverRoundedIcon
                            className="comment_icon_external"
                            fontSize="medium"
                        />
                    </IconButton>
                </Tooltip>
            }


            {comments != null && comments.length > 0 &&
                <IconButton onClick={handleClickOpen}>
                    <RecordVoiceOverRoundedIcon
                        className="add_miro comment_icon_external not_empty"
                        fontSize="medium"
                    />
                </IconButton>

            }

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ marginTop: 8, marginBottom: 4, marginLeft: 15, }}>
                        <Typography sx={{ maxWidth: '600px' }} fontSize={'16px !important'}>External comments for IBO {projData.value}:</Typography>
                        <Box sx={{ paddingRight: '50px' }}>
                            <Stack direction={'row'} alignItems="center">
                                <span>
                                    Desc
                                </span>
                                <Switch checked={checked} onClick={handleChange} />
                                <span>
                                    Asc
                                </span>
                            </Stack>
                        </Box>
                    </Stack>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent dividers>
                    <CommentLog projID={projID} isExternal={false} step={step} location={location} comments={comments} questionKey={"undefined"} sortOrder={sortOrder} />
                </DialogContent>

            </BootstrapDialog>
        </div>
    );
}
