import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
//import ControlPointIcon from '@mui/icons-material/ControlPoint';
//import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    return (
        <>
            <InfoIcon className="explanation_icon" fontSize="small"
                style={{
                    cursor: "pointer",
                    color: "gray",
                    marginLeft: "20px",
                    marginTop: "3px",
                }} onClick={handleClickOpen} />

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="more-info-dialog"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                </BootstrapDialogTitle>
                <DialogContent style={{ marginTop: "30px", maxWidth: "800px" }}>

                    {props.data.explanation != null ? (
                        parse(props.data.explanation) || 'No further information or explanation available') : (parse(props.data.explanation || 'No further information or explanation available'))}

                </DialogContent>

            </BootstrapDialog>
        </>
    );
}
