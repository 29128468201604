import { COUNTRY_LIST, COUNTY_LIST, REGION_LIST } from "../lang/general/regions";

export const contactingChoices = {
    newIdea: { key: "newIdea", label: "I have an innovative idea I want to explore" },
    business: { key: "business", label: "I have an established business that I wish to scale up" },
}

export const companyChoices = {
    no: { key: "no", label: "No/Not yet" },
    yes: { key: "yes", label: "Yes" },
}

export const ProjectStartQuestions = {
    contactingKrinova: {
        key: 'contactingKrinova',
        type: 'radioGroup',
        order: 0,
        required: true,
        text: "Why are you contacting us at Krinova?",
        options: [
            { value: contactingChoices.newIdea.key, label: contactingChoices.newIdea.label },
            { value: contactingChoices.business.key, label: contactingChoices.business.label },
        ],
    },
    existingCompNewIdea: {
        key: 'existingCompNewIdea',
        type: 'radioGroup',
        order: 1,
        required: true,
        text: "Do you have a registered company based around this idea?",
        options: [
            { value: companyChoices.no.key, label: companyChoices.no.label },
            { value: companyChoices.yes.key, label: companyChoices.yes.label },
        ],
    }
}

export const ProjectInfoQuestions = {
    projName: {
        key: 'projName',
        type: 'textField',
        required: true,
        order: 0,
        text: "Project name",
    },
    website: {
        key: 'website',
        type: 'textField',
        required: false,
        order: 1,
        text: "Website (if applicable)",
        placeHolder: "i.e: www.youradress.com"
    },
}

export const CompanyQuestions = {
    compForm: {
        key: 'compForm',
        type: 'radioGroup',
        required: true,
        order: 2,
        text: "Company form",
        options: [
            { value: "Enskild firma (or equivalent)", label: "Enskild firma (or equivalent)" },
            { value: "Handelsbolag/Kommanditbolag (or equivalent)", label: "Handelsbolag/Kommanditbolag (or equivalent)" },
            { value: "Aktiebolag (or equivalent)", label: "Aktiebolag (or equivalent)" },
        ],
    },
    compName: {
        key: 'compName',
        type: 'textField',
        required: true,
        order: 3,
        text: "Company Name",
        placeHolder: "Enter your company name"
    },
    website: {
        key: 'website',
        type: 'textField',
        required: false,
        order: 1,
        text: "Website (if applicable)",
        placeHolder: "i.e: www.youradress.com"
    },
    orgNum: {
        key: 'orgNum',
        type: 'textField',
        order: 4,
        required: true,
        text: "Organization number",
        placeHolder: "Enter number"
    },
    region: {
        key: 'region',
        keyScania: "county",
        keyOutsideSweden: "country",
        required: true,
        type: 'autoCompleteRegion',
        order: 5,
        text: "Region (headquarter if multiple)",
        textScania: "Municipality",
        textOutsideSweden: "Country",
        options: REGION_LIST,
        optionsScania: COUNTY_LIST,
        optionsOutsideSweden: COUNTRY_LIST,
    },
    founded: {
        key: 'founded',
        type: 'datePickerYear',
        required: true,
        order: 6,
        text: "Year founded",
    },
    compRole: {
        key: 'compRole',
        type: 'textField',
        required: true,
        order: 7,
        text: "Your role in the company",
        placeHolder: "Answer"
    },
    revenue: {
        key: 'revenue',
        type: 'radioGroup',
        required: true,
        order: 9,
        text: "Revenue (latest full fiscal year)",
        options: [
            { value: "< 500k SEK", label: "< 500k SEK" },
            { value: "501k - 2m SEK", label: "501k - 2m SEK" },
            { value: "2-10m SEK", label: "2-10m SEK" },
            { value: "> 10m SEK", label: "> 10m SEK" },
        ],
    },
    numEmployees: {
        key: 'numEmployees',
        type: 'radioGroup',
        required: true,
        order: 10,
        text: "Number of employees",
        options: [
            { value: "0", label: "0" },
            { value: "1-4", label: "1-4" },
            { value: "5-10", label: "5-10" },
            { value: "> 10", label: "> 10" },
        ],
    },
}