import React, { useState, useEffect } from 'react'
import { db } from '../../firebase';
import firebaseRefList from '../../firebase/firebaseRefList'
import moment from 'moment';
import MuiAlert from '@mui/material/Alert';
import { Box, Checkbox, Select, Table, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Stack, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { Fragment } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, PieChart, Pie, Cell, LineChart, Line } from 'recharts';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function StatsGender() {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [category, setCategory] = useState('');

    const eduFields = {
        elementary: "Elementary School",
        secondary: "Secondary / High School",
        postSub3: "Post secondary education < 3 years",
        post3to5: "Post secondary education 3-5 years",
        postabove5: "Post secondary education > 5 years",
        phd: "PhD",
    }

    const eduFieldsShort = {
        elementary: "Elem.School",
        secondary: "Sec/High School",
        postSub3: "Post sec. < 3 years",
        post3to5: "Post sec. 3-5 years",
        postabove5: "Post sec. > 5 years",
        phd: "PhD",
    }

    const genderFields = {
        male: "Male",
        female: "Female",
        other: "Other"
    }

    const [genderData, setGenderData] = useState({
        Male: 0,
        Female: 0,
        Other: 0
    });
    const [eduData, setEduData] = useState({
        elementary: 0,
        secondary: 0,
        postSub3: 0,
        post3to5: 0,
        postabove5: 0,
        phd: 0,
    });
    const [cityData, setCityData] = useState({});
    const [ageData, setAgeData] = useState({});
    const [hkrData, setHkrData] = useState({});
    const [refData, setRefData] = useState({});

    const [combDataEdu, setCombDataEdu] = useState({});
    const [combDataCity, setCombDataCity] = useState({});
    const [combDataAge, setCombDataAge] = useState({});
    const [combDataHkr, setCombDataHkr] = useState({});
    const [combDataRef, setCombDataRef] = useState({});

    const [selectedDateFrom, setSelectedDateFrom] = useState('');
    const [selectedDateTo, setSelectedDateTo] = useState('');


    const [selection, setSelection] = useState({
        gender: false,
        education: false,
    });

    const handleChange = (event) => {
        setSelection({
            ...selection,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleDateChangeFrom = (newValue) => {
        if (newValue != null) {
            setSelectedDateFrom(newValue);

        } else {
            setSelectedDateFrom(null)
        }
    };

    const handleDateChangeTo = (newValue) => {
        if (newValue != null) {
            setSelectedDateTo(newValue);

        } else {
            setSelectedDateTo(null)
        }
    };

    const { gender, education } = selection;

    useEffect(() => {
        setIsLoading(true);
        const getStatistics = async () => {
            const localGender = {
                Male: 0,
                Female: 0,
                Other: 0,
            };
            const localEdu = {
                elementary: 0,
                secondary: 0,
                postSub3: 0,
                post3to5: 0,
                postabove5: 0,
                phd: 0,
            }
            const localCombEdu = {
                Male: {
                    elementary: 0,
                    secondary: 0,
                    postSub3: 0,
                    post3to5: 0,
                    postabove5: 0,
                    phd: 0,
                },
                Female: {
                    elementary: 0,
                    secondary: 0,
                    postSub3: 0,
                    post3to5: 0,
                    postabove5: 0,
                    phd: 0,
                },
                Other: {
                    elementary: 0,
                    secondary: 0,
                    postSub3: 0,
                    post3to5: 0,
                    postabove5: 0,
                    phd: 0,
                },
            }
            console.log("Date: ", selectedDateTo, selectedDateFrom)
            if ((selectedDateFrom == null || selectedDateFrom === "") && (selectedDateTo == null || selectedDateTo === "")) {
                const subscriber = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1)), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if ((selectedDateFrom == null || selectedDateFrom === "") && selectedDateTo != null && selectedDateTo !== "") {
                // @ts-ignore
                const subscriber = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1), where("created", "<=", selectedDateTo['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if (selectedDateFrom != null && selectedDateFrom !== "" && (selectedDateTo == null || selectedDateTo === "")) {
                // @ts-ignore
                const subscriber = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1), where("created", ">=", selectedDateFrom['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }
            else if (selectedDateFrom != null && selectedDateFrom !== "" && selectedDateTo != null && selectedDateTo !== "") {
                // @ts-ignore
                const subscriber = onSnapshot(query(collection(db, 'Users'), where('role', '==', 1), where("created", "<=", selectedDateTo['$d']), where("created", ">=", selectedDateFrom['$d'])), querySnapshot => {
                    saveData(querySnapshot);
                });

                firebaseRefList.push(subscriber);
            }

        }

        getStatistics();
        setIsLoading(false);

    }, [selectedDateFrom, selectedDateTo]);


    function disabledDate(current) {
        if (selectedDateFrom != null) {
            return (current >= selectedDateTo || current >= moment().endOf('day'));
        }
        else {
            return current >= moment().endOf('day');
        }
    }

    function disabledDateTo(current) {
        if (selectedDateFrom != null) {
            return (current <= selectedDateFrom || current >= moment().endOf('day'));
        }
        else {
            return current >= moment().endOf('day');
        }

    }

    const saveData = (querySnapshot) => {
        const localGender = {
            Male: 0,
            Female: 0,
            Other: 0,
        };
        const localEdu = {
            elementary: 0,
            secondary: 0,
            postSub3: 0,
            post3to5: 0,
            postabove5: 0,
            phd: 0,
        }
        const localCity = {};
        const localAge = {};
        const localConn = {};
        const localRef = {};

        const localCombEdu = {
            Male: {
                elementary: 0,
                secondary: 0,
                postSub3: 0,
                post3to5: 0,
                postabove5: 0,
                phd: 0,
            },
            Female: {
                elementary: 0,
                secondary: 0,
                postSub3: 0,
                post3to5: 0,
                postabove5: 0,
                phd: 0,
            },
            Other: {
                elementary: 0,
                secondary: 0,
                postSub3: 0,
                post3to5: 0,
                postabove5: 0,
                phd: 0,
            },
        }

        const localCombCity = {
            Male: {},
            Female: {},
            Other: {},
        }

        const localCombAge = {
            Male: {},
            Female: {},
            Other: {},
        }

        const localCombHkr = {
            Male: {},
            Female: {},
            Other: {},
        }

        const localCombRef = {
            Male: {},
            Female: {},
            Other: {},
        }

        querySnapshot.forEach((doc) => {
            let gender;
            let eduLevel;
            let age;
            let city;
            let hkr;
            let ref;

            if (doc.data().gender != null) {
                gender = doc.data().gender;
                if (localGender[gender] == null) {
                    localGender[gender] = 1;
                }
                else {
                    localGender[gender] = localGender[gender] + 1;
                }
            }

            if (doc.data().education != null) {
                const index = Object.values(eduFields).indexOf(doc.data().education);
                if (index !== -1) {
                    eduLevel = Object.keys(eduFields).at(index);
                    if (localEdu[eduLevel] == null) {
                        localEdu[eduLevel] = 1;
                    }
                    else {
                        localEdu[eduLevel] = localEdu[eduLevel] + 1;
                    }
                }
            }

            if (doc.data().city != null) {
                //console.log("City", doc.data().city)
                city = String(doc.data().city).toLocaleLowerCase().trim();
                if (localCity[city] != null) {
                    localCity[city] += 1;
                }
                else {
                    localCity[city] = 1;
                }
            }

            if (doc.data().hkrConnection != null) {
                hkr = doc.data().hkrConnection;
                if (localConn[hkr] != null) {
                    localConn[hkr] += 1;
                }
                else {
                    localConn[hkr] = 1;
                }
            }

            if (doc.data().reference != null) {
                const refList = String(doc.data().reference).split(';');
                ref = refList;

                refList.forEach((obj) => {
                    if (localRef[obj] != null) {
                        localRef[obj] += 1;
                    }
                    else {
                        localRef[obj] = 1;
                    }
                });

            }

            if (doc.data().dateOfBirth != null) {
                let dob = String(doc.data().dateOfBirth).split('-')[0];
                let current = new Date(Date.now()).getFullYear();


                age = Number(current) - Number(dob);
                //console.log("Age: ", age);
                if (age >= 0) {
                    if (localAge[age] != null) {
                        localAge[age] += 1;
                    }
                    else {
                        localAge[age] = 1;
                    }
                }
            }

            if (gender != null) {
                if (eduLevel != null) {
                    if (localCombEdu[gender] != null) {
                        if (localCombEdu[gender][eduLevel] != null) {
                            localCombEdu[gender][eduLevel] = localCombEdu[gender][eduLevel] + 1;
                        }
                        else {
                            localCombEdu[gender][eduLevel] = 1;
                        }
                    }
                    else {
                        localCombEdu[gender] = {
                            eduLevel: 1,
                        };
                    }
                }

                if (age != null) {
                    if (localCombAge['Male'][age] == null) {
                        localCombAge['Male'][age] = 0;
                    }

                    if (localCombAge[gender] != null) {
                        if (localCombAge[gender][age] != null) {
                            localCombAge[gender][age] = localCombAge[gender][age] + 1;
                        }
                        else {
                            localCombAge[gender][age] = 1;
                        }
                    }
                    else {
                        localCombAge[gender] = {
                            age: 1,
                        };
                    }
                }

                if (city != null) {
                    if (localCombCity['Male'][city] == null) {
                        localCombCity['Male'][city] = 0;
                    }

                    if (localCombCity[gender] != null) {
                        if (localCombCity[gender][city] != null) {
                            localCombCity[gender][city] = localCombCity[gender][city] + 1;
                        }
                        else {
                            localCombCity[gender][city] = 1;
                        }
                    }
                    else {
                        localCombCity[gender] = {
                            city: 1,
                        };
                    }
                }

                if (hkr != null) {
                    if (localCombHkr['Male'][hkr] == null) {
                        localCombHkr['Male'][hkr] = 0;
                    }

                    if (localCombHkr[gender] != null) {
                        if (localCombHkr[gender][hkr] != null) {
                            localCombHkr[gender][hkr] = localCombHkr[gender][hkr] + 1;
                        }
                        else {
                            localCombHkr[gender][hkr] = 1;
                        }
                    }
                    else {
                        localCombHkr[gender] = {
                            hkr: 1,
                        };
                    }
                }

                if (ref != null) {
                    if (localCombRef[gender] != null) {
                        ref.forEach((obj) => {
                            if (localCombRef['Male'][obj] == null) {
                                localCombRef['Male'][obj] = 0;
                            }

                            if (localCombRef[gender][obj] != null) {
                                localCombRef[gender][obj] = localCombRef[gender][obj] + 1;
                            }
                            else {
                                localCombRef[gender][obj] = 1;
                            }
                        })
                    }
                    else {
                        localCombRef[gender] = {};
                        ref.forEach((obj) => {
                            if (localCombRef['Male'] == null || localCombRef['Male'][obj] == null) {
                                localCombRef['Male'][obj] = 0;
                            }

                            if (localCombRef[gender][obj] != null) {
                                localCombRef[gender][obj] = localCombRef[gender][obj] + 1;
                            }
                            else {
                                localCombRef[gender][obj] = 1;
                            }
                        });
                    }
                }
            }
        });

        setAgeData(localAge);
        setCityData(localCity);
        setHkrData(localConn);
        setRefData(localRef);
        setGenderData(localGender);
        setEduData(localEdu);

        setCombDataEdu(localCombEdu);
        setCombDataAge(localCombAge);
        setCombDataCity(localCombCity);
        setCombDataHkr(localCombHkr);
        setCombDataRef(localCombRef);
    }

    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    const createData = (type, genderSplit = false) => {
        let data = []
        switch (type) {
            case 'gender':
                Object.keys(genderData).forEach((key) => {
                    data.push({ name: key, count: genderData[key] });
                })
                break;
            case 'edu': {
                if (genderSplit === true) {
                    Object.keys(combDataEdu['Male']).forEach((eduLevel) => {
                        data.push({ name: eduFieldsShort[eduLevel], male: combDataEdu['Male'][eduLevel], female: combDataEdu['Female'][eduLevel], other: combDataEdu['Other'][eduLevel] });
                    })
                }
                else {
                    Object.keys(eduData).forEach((key) => {
                        data.push({ name: eduFieldsShort[key], count: eduData[key] });
                    })
                }

                break;
            }
            case 'city': {
                if (genderSplit === true) {
                    Object.keys(combDataCity['Male']).forEach((city) => {
                        data.push({ name: city, male: combDataCity['Male'][city], female: combDataCity['Female'][city], other: combDataCity['Other'][city] });
                    })
                }
                else {
                    Object.keys(cityData).forEach((key) => {
                        data.push({ name: key, count: cityData[key] });
                    })
                }
                break;
            }
            case 'age': {
                if (genderSplit === true) {
                    for (let index = 0; index <= 100; index++) {
                        data.push({ age: index, male: combDataAge['Male'][index] || 0, female: combDataAge['Female'][index] || 0, other: combDataAge['Other'][index] || 0 });
                    }
                }
                else {
                    for (let index = 0; index <= 100; index++) {
                        if (ageData[index] != null) {
                            data.push({ age: index, count: ageData[index] });
                        }
                        else {
                            data.push({ age: index, count: 0 });
                        }

                    }
                }

                break;
            }
            case 'hkr': {
                if (genderSplit === true) {
                    Object.keys(combDataHkr['Male']).forEach((hkr) => {
                        data.push({ name: hkr, male: combDataHkr['Male'][hkr], female: combDataHkr['Female'][hkr], other: combDataHkr['Other'][hkr] });
                    });
                }
                else {
                    Object.keys(hkrData).forEach((key) => {
                        data.push({ name: key, count: hkrData[key] });
                    });
                }

                break;
            }
            case 'ref': {
                if (genderSplit === true) {
                    Object.keys(combDataRef['Male']).forEach((ref) => {
                        data.push({ name: ref, male: combDataRef['Male'][ref], female: combDataRef['Female'][ref], other: combDataRef['Other'][ref] });
                    })
                }
                else {
                    Object.keys(refData).forEach((key) => {
                        data.push({ name: key, count: refData[key] });
                    })
                }

                break;
            }
            default:
                break;
        }

        return data;
    }

    function createRow(eduField) {
        const data = [];
        data.push(eduFields[eduField]);

        Object.keys(combDataEdu).forEach((gender) => {
            if (combDataEdu[gender] != null && combDataEdu[gender][eduField] != null) {
                data.push(combDataEdu[gender][eduField]);
            }
            else {
                data.push(0);
            }
        });

        return data;
    }


    const renderTable = () => {
        if (gender === true) {
            switch (category) {
                case '':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack spacing={3}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={createData('gender')}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#007bff" />
                                    </BarChart>

                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                {Object.keys(genderData).map((gender) => {
                                                    return (
                                                        <TableCell key={gender + "-header"}>
                                                            {gender}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {Object.keys(genderData).map((gender, index) => {
                                                    return (
                                                        <TableCell key={gender + "-" + index}>
                                                            {genderData[gender]}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Stack>
                            )}
                        </>
                    );
                case 'gen':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack spacing={3}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={createData('gender')}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#007bff" />
                                    </BarChart>

                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                {Object.keys(genderData).map((gender) => {
                                                    return (
                                                        <TableCell key={gender + "-header"}>
                                                            {gender}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {Object.keys(genderData).map((gender, index) => {
                                                    return (
                                                        <TableCell key={gender + "-" + index}>
                                                            {genderData[gender]}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Stack>
                            )}
                        </>
                    );
                case 'edu':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack spacing={5}>
                                    <BarChart
                                        width={700}
                                        height={400}
                                        data={createData('edu', true)}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="male" fill="#28a745" />
                                        <Bar dataKey="female" fill="#fd7e14" />
                                        <Bar dataKey="other" fill="#6610f2" />
                                    </BarChart>

                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Education Level
                                                </TableCell>
                                                {Object.keys(genderFields).map((gender) => {
                                                    return (
                                                        <TableCell key={gender}>
                                                            {genderFields[gender]}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(eduFields).map((field) => {
                                                return (
                                                    <TableRow key={field}>
                                                        {createRow(field).map((value, index) => {
                                                            return (
                                                                <TableCell key={field + "-" + value + "-" + index}>
                                                                    {value}
                                                                </TableCell>);
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Stack>
                            )}
                        </>
                    );
                case 'city':
                    return (
                        <Table
                            sx={{ justifyContent: 'center' }}
                            aria-labelledby="statsTable"
                            aria-label="Stats Table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        City
                                    </TableCell>
                                    {Object.keys(genderFields).map((gender) => {
                                        return (
                                            <TableCell key={gender}>
                                                {genderFields[gender]}
                                            </TableCell>);
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {createData('city', true).sort((a, b) => (Number(b.male || 0) + Number(b.female || 0) + Number(b.other || 0)) - (Number(a.male || 0) + Number(a.female || 0) + Number(a.other || 0))).map((row) => {
                                    return (
                                        <TableRow key={row.name}>
                                            <TableCell key={row.name + '- title'}>
                                                {capitalizeWord(row.name)}
                                            </TableCell>
                                            {Object.keys(combDataCity).map((gender) => {
                                                return (
                                                    <TableCell key={row.name + '-' + gender}>
                                                        {row[gender.toLocaleLowerCase()] || 0}
                                                    </TableCell>);
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    );
                case 'age':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack spacing={5}>
                                    <LineChart
                                        width={900}
                                        height={400}
                                        data={createData('age', true)}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="age" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="male" stroke="#28a745" />
                                        <Line type="monotone" dataKey="female" stroke="#fd7e14" />
                                        <Line type="monotone" dataKey="other" stroke="#6610f2" />
                                    </LineChart>
                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={"ageHeader"}>Age</TableCell>

                                                {Object.keys(genderFields).map((gender) => {
                                                    return (
                                                        <TableCell key={gender}>
                                                            {genderFields[gender]}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {createData('age', true).sort((a, b) => Number(a.age) - Number(b.age)).map((row, index) => {
                                                //console.log("Row: ", row.male, row.female, row.other)
                                                if (row.male !== 0 || row.female !== 0 || row.other !== 0) {
                                                    return (
                                                        <TableRow key={row.age}>
                                                            <TableCell key={row.age + '- title'}>
                                                                {row.age}
                                                            </TableCell>
                                                            {Object.keys(genderFields).map((gender) => {
                                                                return (
                                                                    <TableCell key={row.name + '-' + gender}>
                                                                        {row[gender.toLocaleLowerCase()] || 0}
                                                                    </TableCell>);
                                                            })}
                                                        </TableRow>
                                                    );
                                                }

                                            })}
                                        </TableBody>
                                    </Table>
                                </Stack>

                            )}
                        </>
                    );
                case 'hkr':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (

                                <Stack spacing={5}>
                                    <BarChart
                                        width={700}
                                        height={400}
                                        data={createData('hkr', true)}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="male" fill="#28a745" />
                                        <Bar dataKey="female" fill="#fd7e14" />
                                        <Bar dataKey="other" fill="#6610f2" />
                                    </BarChart>

                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={"hkrHeader"}>Connection to HKR</TableCell>

                                                {Object.keys(genderFields).map((gender) => {
                                                    return (
                                                        <TableCell key={gender}>
                                                            {genderFields[gender]}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {createData('hkr', true).map((row, index) => {
                                                return (
                                                    <TableRow key={row.name}>
                                                        <TableCell key={row.name + '- title'}>
                                                            {row.name}
                                                        </TableCell>
                                                        {Object.keys(genderFields).map((gender) => {
                                                            return (
                                                                <TableCell key={row.name + '-' + gender}>
                                                                    {row[gender.toLocaleLowerCase()] || 0}
                                                                </TableCell>);
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Stack>
                            )}
                        </>
                    );
                case 'ref':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (

                                <Stack spacing={5}>
                                    <BarChart
                                        width={700}
                                        height={400}
                                        data={createData('ref', true)}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />#ffc107
                                        <Bar dataKey="male" fill="#28a745" />
                                        <Bar dataKey="female" fill="#fd7e14" />
                                        <Bar dataKey="other" fill="#6610f2" />
                                    </BarChart>

                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={"hkrHeader"}>Reference</TableCell>

                                                {Object.keys(genderFields).map((gender) => {
                                                    return (
                                                        <TableCell key={gender}>
                                                            {genderFields[gender]}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {createData('ref', true).map((row, index) => {
                                                return (
                                                    <TableRow key={row.name}>
                                                        <TableCell key={row.name + '- title'}>
                                                            {row.name}
                                                        </TableCell>
                                                        {Object.keys(genderFields).map((gender) => {
                                                            return (
                                                                <TableCell key={row.name + '-' + gender}>
                                                                    {row[gender.toLocaleLowerCase()] || 0}
                                                                </TableCell>);
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Stack>
                            )}
                        </>
                    );
                default:
                    break;
            }
        }
        else {
            switch (category) {
                case '':

                    break;
                case 'gen':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack spacing={5}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={createData('gender')}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#007bff" />
                                    </BarChart>

                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                {Object.keys(genderData).map((gender) => {
                                                    return (
                                                        <TableCell key={gender + "-header"}>
                                                            {gender}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {Object.keys(genderData).map((gender, index) => {
                                                    return (
                                                        <TableCell key={gender + "-" + index}>
                                                            {genderData[gender]}
                                                        </TableCell>);
                                                })}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Stack>
                            )}
                        </>
                    );
                case 'edu':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack spacing={5}>

                                    <BarChart
                                        width={700}
                                        height={400}
                                        data={createData('edu')}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#007bff" />
                                    </BarChart>

                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={"eduHeader"}>Education Level</TableCell>
                                                <TableCell key={"userHeader"}>Number of users</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(eduData).map((eduLevel, index) => {
                                                return (
                                                    <TableRow key={eduLevel + "row"}>
                                                        <TableCell key={"eduField-" + eduLevel + "-" + index}>
                                                            {eduFields[eduLevel]}
                                                        </TableCell>
                                                        <TableCell key={"eduData-" + eduLevel + "-" + index}>
                                                            {eduData[eduLevel]}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>

                                </Stack>
                            )}
                        </>
                    );
                case 'city':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Table
                                    sx={{ justifyContent: 'center' }}
                                    aria-labelledby="statsTable"
                                    aria-label="Stats Table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell key={"cityHeader"}>City</TableCell>
                                            <TableCell key={"userHeader"}>Number of users</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(cityData).sort((a, b) => cityData[b] - cityData[a]).map((city, index) => {
                                            return (
                                                <TableRow key={city + "row"}>
                                                    <TableCell key={"cityField-" + city + "-" + index}>
                                                        {capitalizeWord(city)}
                                                    </TableCell>
                                                    <TableCell key={"cityData-" + city + "-" + index}>
                                                        {cityData[city]}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            )}
                        </>
                    );
                case 'age':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack>
                                    <LineChart
                                        width={900}
                                        height={400}
                                        data={createData('age')}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="age" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="count" stroke="#007bff" />
                                    </LineChart>
                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={"ageHeader"}>Age</TableCell>
                                                <TableCell key={"userHeader"}>Number of users</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(ageData).sort((a, b) => Number(a) - Number(b)).map((age, index) => {
                                                return (
                                                    <TableRow key={age + "row"}>
                                                        <TableCell key={"ageField-" + age + "-" + index}>
                                                            {age}
                                                        </TableCell>
                                                        <TableCell key={"ageData-" + age + "-" + index}>
                                                            {ageData[age]}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Stack>

                            )}
                        </>
                    );
                case 'hkr':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack spacing={5}>
                                    <BarChart
                                        width={700}
                                        height={400}
                                        data={createData('hkr')}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#007bff" />
                                    </BarChart>
                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={"hkrHeader"}>Connection to HKR</TableCell>
                                                <TableCell key={"userHeader"}>Number of users</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(hkrData).map((hkr, index) => {
                                                return (
                                                    <TableRow key={hkr + "row"}>
                                                        <TableCell key={"hkrField-" + hkr + "-" + index}>
                                                            {hkr}
                                                        </TableCell>
                                                        <TableCell key={"hkrData-" + hkr + "-" + index}>
                                                            {hkrData[hkr]}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Stack>

                            )}
                        </>
                    );
                case 'ref':
                    return (
                        <>
                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <Stack spacing={5}>
                                    <BarChart
                                        width={700}
                                        height={400}
                                        data={createData('ref')}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#007bff" />
                                    </BarChart>
                                    <Table
                                        sx={{ justifyContent: 'center' }}
                                        aria-labelledby="statsTable"
                                        aria-label="Stats Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={"refHeader"}>Reference</TableCell>
                                                <TableCell key={"userHeader"}>Number of users</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(refData).map((ref, index) => {
                                                return (
                                                    <TableRow key={ref + "row"}>
                                                        <TableCell key={"refField-" + ref + "-" + index}>
                                                            {ref}
                                                        </TableCell>
                                                        <TableCell key={"refData-" + ref + "-" + index}>
                                                            {refData[ref]}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Stack>

                            )}
                        </>
                    );
                default:
                    break;
            }
        }
    }

    const capitalizeWord = (word) => {
        //console.log("Word", word);
        let newWord = String(word).charAt(0).toUpperCase() + String(word).slice(1);
        //console.log("New: ", newWord);
        return newWord;
    }

    return (
        <div>
            <Stack justifyContent={"center"} style={{ marginBottom: '40px' }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent={'left'}>
                    <Grid item xs={2} sm={4} md={4}>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'left' }}>
                                Sort data by:
                            </div>
                            <FormGroup>
                                <FormControl fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        style={{ width: "220px" }}
                                        size={'small'}
                                        value={category}
                                        label="Category"
                                        onChange={handleChangeCategory}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        <MenuItem value={'gen'}>Gender</MenuItem>
                                        <MenuItem value={'edu'}>Education Level</MenuItem>
                                        <MenuItem value={'city'}>City</MenuItem>
                                        <MenuItem value={'age'}>Age</MenuItem>
                                        <MenuItem value={'hkr'}>Connection to HKR</MenuItem>
                                        <MenuItem value={'ref'}>Reference</MenuItem>
                                    </Select>
                                </FormControl>

                                {category !== 'gen' &&
                                    <FormControlLabel
                                        style={{ paddingTop: "10px" }}
                                        control={
                                            <Checkbox checked={gender} onChange={handleChange} name="gender" />
                                        }
                                        label="Gender"
                                    />}


                                {/* 
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={education} onChange={handleChange} name="education" />
                                    }
                                    label="Education"
                                />*/}
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={4} md={4}>
                        <div style={{ marginTop: '35px', marginBottom: '10px', display: 'flex', justifyContent: 'left' }}>
                            Timespan:
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack justifyContent={"center"}  >

                                <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'left' }}>
                                    From:
                                </div>
                                <DatePicker
                                    label=""
                                    value={selectedDateFrom}
                                    disableFuture
                                    format={'ddd YYYY-MM-DD'}
                                    onChange={handleDateChangeFrom}
                                    slotProps={{textField: {size: 'small', sx: {width: 220 }, error: false}}}
                                />
                                <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', justifyContent: 'left' }}>
                                    To:
                                </div>
                                <DatePicker
                                    label=""
                                    value={selectedDateTo}
                                    disableFuture
                                    format={'ddd YYYY-MM-DD'}
                                    onChange={handleDateChangeTo}
                                    slotProps={{textField: {size: 'small', sx: {width: 220 }, error: false}}}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Stack>

            <hr />
            <Box sx={{ marginTop: '40px', width: '60%', justifyContent: "center" }}>
                {renderTable()}
            </Box>
        </div>
    );
}
