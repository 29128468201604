import { useEffect, useState } from "react"
import { USERINFO_HEADERS as USERINFO_HEADERS_ENG } from "../../constants/lang/eng/userInfo";
import { USERINFO_HEADERS as USERINFO_HEADERS_SWE } from "../../constants/lang/swe/userInfo";
import useGetUserData from "../userData/useGetUserData";

export const useGetUserInfoTexts = () => {
    const { userData, loading } = useGetUserData();
    
    const [sentences, setSentences] = useState({
        userInfo: "",
        deactivated: "",
        email: "",
        gender: "",
        dob: "",
        city: "",
        phone: "",
        education: "",
        hkrConn: "",
        language: "",
        expectation: "",
        reference: "",
    });

    useEffect(() => {
        if (loading === false) {
            /*
            // @ts-ignore
            if (userData?.prefLang === 'swe') {
                //setSentences(USERINFO_HEADERS_SWE)
                setSentences(USERINFO_HEADERS_ENG)
            }
            else {
                setSentences(USERINFO_HEADERS_ENG)
            }*/
            setSentences(USERINFO_HEADERS_ENG)
        }
        else {
            setSentences(USERINFO_HEADERS_ENG)
        }
    }, [loading, userData]);

    return sentences;
}