import { Autocomplete, Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, LinearProgress, Radio, RadioGroup, TextField } from "@mui/material"
import { useEffect, useMemo, useState } from "react"

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { COUNTRY_LIST, COUNTY_LIST, REGION_LIST } from "../../constants/lang/general/regions"
import { ErrorAlert } from "../../helper/errorAlert"
import { updateCompInfo } from "../../firebase/cloudFunctions"

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { companyChoices, CompanyQuestions, contactingChoices, ProjectInfoQuestions, ProjectStartQuestions } from "../../constants/companyQuestions/companyQuestions"



export const UpdateCompInfo_v2 = ({ projectData, compInfo, closeModal }) => {
    const setInitYearFounded = () => {
        if (compInfo.founded != null && compInfo.founded !== "") {
            let localYear = new Date(Date.parse(compInfo.founded));
            return localYear;
        }
        else {
            return null;
        }
    }

    //console.log("PROJ", projectData, compInfo)
    const [answers, setAnswers] = useState({ ...compInfo })

    const [year, setYear] = useState(setInitYearFounded());
    const [creatingProj, setCreatingProj] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [saveTrigger, setSaveTrigger] = useState('');
    const [autoSave, setAutoSave] = useState('');

    const [queueSubmit, setQueueSubmit] = useState(false);
    const [queueSave, setQueueSave] = useState(false);

    const [isGeneralInvalid, setGeneralInvalid] = useState(false);

    const [contactingKrinova, setContactKrinova] = useState('');
    const [existingCompany, setExistingCompany] = useState('');

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    useEffect(() => {
        if (projectData?.existingComp != null && projectData?.existingComp !== "") {
            handleChange({ target: { name: "existingComp", value: projectData?.existingComp } });
            //console.log("Here?", projectData?.existingComp, contactingKrinova, contactingKrinova == null || contactingKrinova === "", projectData?.existingComp === 'no' && projectData?.existingComp === 'yes_new',)
            if (contactingKrinova == null || contactingKrinova === "") {
                if (projectData?.existingComp === 'no' || projectData?.existingComp === 'yes_new' || projectData?.existingComp === "Yes, in a newly formed company based on the idea" || projectData?.existingComp === 'No/Not yet') {
                    setContactKrinova(contactingChoices.newIdea.key);
                }
                else if (projectData?.existingComp === 'yes_established' || projectData?.existingComp === 'Yes, within an established company') {
                    setContactKrinova(contactingChoices.business.key);
                }
            }

            if (existingCompany == null || existingCompany === "") {
                if (projectData?.existingComp === 'no' || projectData?.existingComp === 'No/Not yet') {
                    setExistingCompany(companyChoices.no.key);
                }
                else if (projectData?.existingComp === 'yes_new' || projectData?.existingComp === "Yes, in a newly formed company based on the idea") {
                    setExistingCompany(companyChoices.yes.key);
                }
            }
        }
    }, [projectData, contactingKrinova, existingCompany])

    useEffect(() => {
        const localForms = answers;

        if (projectData?.value != null && projectData?.value !== "") {
            localForms['projName'] = projectData?.value;
        }

        if (projectData?.website != null && projectData?.website !== "") {
            localForms['website'] = projectData?.website;
        }
    }, [projectData]);

    useEffect(() => {
        if (compInfo?.compCity != null) {
            if (String(compInfo?.compCity).includes(';') === true) {
                const localForms = answers;

                let cityData = String(compInfo?.compCity).split(';');
                if (cityData[0] != null && cityData[0] !== "") {
                    localForms['county'] = { label: cityData[0] };
                }
                if (cityData[1] != null && cityData[1] !== "") {
                    localForms['region'] = { label: cityData[1] };
                }

                setAnswers(localForms)
            }
        }
    }, [compInfo])

    const handleChange = (e) => {
        const { name, value } = e.target;
        const localForms = answers;

        if (name === "region") {
            //console.log("Region: ", value?.label)
            if (localForms["county"] != null && localForms["county"] !== "") {
                localForms["county"] = "";
            }
            localForms['compCity'] = ";" + value?.label;
        }
        else if (name === "county") {
            //console.log("County: ", value?.label)
            if (localForms["region"] != null && localForms["region"] !== "") {
                localForms['compCity'] = value?.label + ";" + localForms["region"].label;
            }
            else {
                localForms['compCity'] = value?.label + ";";
            }
        }
        localForms[name] = value;

        setAnswers(localForms);
    };

    const saveProjectInfo = () => {
        setIsLoading(true);
        const data = {
            projectID: projectData?.id,
            projName: projectData?.value,
            existingComp: answers.existingComp,
        }

        if (answers.website != null && answers.website !== "") {
            data['website'] = answers.website
        }

        if (answers.existingComp === 'yes_new' || answers.existingComp === 'yes_established') {
            data['compForm'] = answers.compForm
            data['orgNum'] = answers.orgNum
            data['compName'] = answers.compName
            data['compCity'] = answers.compCity
            data['founded'] = answers.founded;
            data['compRole'] = answers.compRole;
            data['revenue'] = answers.revenue;
            data['numEmployees'] = answers.numEmployees;
        }

        updateCompInfo(data).then(() => {
            closeModal();
        }).catch((error) => {
            setErrorInfo(error);
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const getValueInitialQuestions = (key) => {
        switch (key) {
            case ProjectStartQuestions.contactingKrinova.key:
                return contactingKrinova;
            case ProjectStartQuestions.existingCompNewIdea.key:
                return existingCompany;
            default:
                return "";
        }
    }

    const handleChangeInitialQuestions = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        switch (name) {
            case ProjectStartQuestions.contactingKrinova.key:
                setContactKrinova(value);

                if (value === contactingChoices.business.key) {
                    setExistingCompany('')

                    handleChange({ target: { name: "existingComp", value: 'yes_established' } });
                    setSaveTrigger('yes_established');
                    setUpdate(!update);
                }
                break;
            case ProjectStartQuestions.existingCompNewIdea.key:
                setExistingCompany(value);

                if (value === companyChoices.yes.key) {
                    handleChange({ target: { name: "existingComp", value: 'yes_new' } });
                    setSaveTrigger('yes_new');
                    setUpdate(!update);
                }
                else if (value === companyChoices.no.key) {
                    handleChange({ target: { name: "existingComp", value: 'no' } });
                    setSaveTrigger('no');
                    setUpdate(!update);
                }
                break;
            default:
                break;
        }
    }

    const handleChangeContactingKrinova = (event) => {
        setContactKrinova(event.target.value);

        if (event.target.value === contactingChoices.business.key) {
            setExistingCompany('')

            handleChange({ target: { name: "existingComp", value: 'yes_established' } });
            setSaveTrigger('yes_established');
            setUpdate(!update);
        }
        //Set CompanyStaus if business
    }


    const handleChangeExistingCompany = (event) => {
        setExistingCompany(event.target.value);

        if (event.target.value === companyChoices.yes.key) {
            handleChange({ target: { name: "existingComp", value: 'yes_new' } });
            setSaveTrigger('yes_new');
            setUpdate(!update);
        }
        else if (event.target.value === companyChoices.no.key) {
            handleChange({ target: { name: "existingComp", value: 'no' } });
            setSaveTrigger('no');
            setUpdate(!update);
        }
        //Set CompanyStaus if business
    }

    const displayProjectName = () => {
        if (contactingKrinova === contactingChoices.newIdea.key && existingCompany === companyChoices.no.key) {
            return true;
        }
        else {
            return false;
        }
    }

    const displayCompanyInfo = () => {
        if (contactingKrinova === contactingChoices.business.key || (contactingKrinova === contactingChoices.newIdea.key && existingCompany === companyChoices.yes.key)) {
            return true;
        }
        else {
            return false;
        }
    }

    const isValidUpdate = useMemo(() => {
        let changedFields = false;

        if (displayProjectName()) {
            if (answers.projName != null && answers.projName !== "" && answers.projName !== projectData?.value) {
                changedFields = true;
            }
            else if (answers.projName == null || answers.projName === "") {
                return false;
            }

            if (answers.website !== projectData?.website) {
                changedFields = true;
            }
        }

        if (displayCompanyInfo()) {
            let companyKeys = Object.keys(CompanyQuestions);
            for (let index = 0; index < companyKeys.length; index++) {
                const key = companyKeys[index];

                if (CompanyQuestions[key].required === true) {
                    if (answers[key] != null && answers[key] !== "" && answers[key] !== compInfo[key]) {
                        changedFields = true;
                    }
                    else if (answers[key] == null || answers[key] === "") {
                        return false;
                    }
                }
                else {
                    if (answers[key] !== compInfo[key]) {
                        changedFields = true;
                    }
                }
            }
        }
        return changedFields;
    }, [compInfo, answers, displayCompanyInfo, displayProjectName]);

    const handleChangeLocal = (e) => {
        handleChange(e);
        if (e.target.name === 'compName') {
            handleChange({ target: { name: "projName", value: e.target.value } });
        }
        setSaveTrigger(e.target.value);
        setUpdate(!update);
    };

    const handleChangeRegion = (value) => {
        //console.log(value);
        handleChange({ target: { name: "region", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCounty = (value) => {
        //console.log(value);
        handleChange({ target: { name: "county", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCountry = (value) => {
        //console.log(value);
        handleChange({ target: { name: "country", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const onChangeYear = (value) => {
        setYear(value['$d']);
        handleChange({ target: { name: "founded", value: value['$d'].getFullYear() } });
    }

    function validateAllFields() {
        return false;
    }


    const handleSubmit = e => {
        e.preventDefault()
        //console.log("Submit: ", values)
        if (validateAllFields() === false) {
            setIsLoading(true);
            if (creatingProj === true) {
                setQueueSubmit(true);
            }
            else {
                saveProjectInfo();
            }
        }
    };

    const displayHeaderText = () => {
        if (projectData?.existingComp === "Yes, in a newly formed company based on the idea" || projectData?.existingComp === "Yes, within an established company") {
            return "Update company info:";
        }
        else if (projectData?.existingComp === 'yes_new' || projectData?.existingComp === 'yes_established') {
            return "Update company info:";
        }
        else {
            return "Update project info:";
        }
    }

    return (
        <>
            <Box className="update-comp-info">
                <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={closeModal}>
                    <CloseRoundedIcon />
                </IconButton>
                <span><strong>{displayHeaderText()}</strong></span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <form onSubmit={handleSubmit} id="project-form">
                        <Grid container spacing={3} justifyContent="center" padding={3}>
                            {Object.keys(ProjectStartQuestions).map((key) => {
                                if ((key === ProjectStartQuestions.existingCompNewIdea.key && contactingKrinova === contactingChoices.newIdea.key) || key !== ProjectStartQuestions.existingCompNewIdea.key) {
                                    if (ProjectStartQuestions[key]?.type === 'radioGroup') {
                                        return (
                                            <Grid key={key} item xs={12} sm={12} md={6}>
                                                <FormControl required={ProjectStartQuestions[key]?.required}>
                                                    <FormLabel id={key}>{ProjectStartQuestions[key]?.text}</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby={key}
                                                        name={key}
                                                        value={getValueInitialQuestions(key)}
                                                        onChange={handleChangeInitialQuestions}
                                                    >
                                                        {ProjectStartQuestions[key]?.options.map((option) => (
                                                            <FormControlLabel
                                                                key={option?.value}
                                                                value={option?.value}
                                                                control={<Radio />}
                                                                label={option?.label}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        );
                                    }
                                }
                            })}
                            {contactingKrinova !== contactingChoices.newIdea.key ? (
                                <Grid item xs={12} sm={12} md={6}></Grid>
                            ) : (<></>)}

                            {displayProjectName() ? (
                                <>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    {Object.keys(ProjectInfoQuestions).map((key) => {
                                        if (ProjectInfoQuestions[key]?.type === 'textField') {
                                            return (
                                                <Grid key={key} item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        required={ProjectInfoQuestions[key]?.required === true}
                                                        label={ProjectInfoQuestions[key]?.text}
                                                        name={key}
                                                        type={'text'}
                                                        onChange={handleChangeLocal}
                                                        value={answers[key] || ""}
                                                        placeholder={ProjectInfoQuestions[key]?.placeHolder != null ? ProjectInfoQuestions[key]?.placeHolder : ""}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            )
                                        }
                                    })}
                                </>
                            ) : ("")}

                            {displayCompanyInfo() ? (
                                <>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    {Object.keys(CompanyQuestions).map((key) => {
                                        if (CompanyQuestions[key]?.type === 'textField') {
                                            return (
                                                <Grid key={key} item xs={12} sm={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        required={CompanyQuestions[key]?.required === true}
                                                        label={CompanyQuestions[key]?.text}
                                                        name={key}
                                                        type={'text'}
                                                        onChange={handleChangeLocal}
                                                        value={answers[key] || ""}
                                                        placeholder={CompanyQuestions[key]?.placeHolder != null ? ProjectInfoQuestions[key]?.placeHolder : ""}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            )
                                        }
                                        else if (CompanyQuestions[key]?.type === 'radioGroup') {
                                            return (
                                                <Grid key={key} item xs={12} sm={12} md={6}>
                                                    <FormControl required={CompanyQuestions[key]?.required}>
                                                        <FormLabel id={key}>{CompanyQuestions[key]?.text}</FormLabel>
                                                        <RadioGroup
                                                            aria-labelledby={key}
                                                            name={key}
                                                            value={answers[key] || ""}
                                                            onChange={handleChangeLocal}
                                                        >
                                                            {CompanyQuestions[key]?.options.map((option) => (
                                                                <FormControlLabel
                                                                    key={option?.value}
                                                                    value={option?.value}
                                                                    control={<Radio />}
                                                                    label={option?.label}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            )
                                        }
                                        else if (CompanyQuestions[key]?.type === 'autoCompleteRegion') {
                                            return (
                                                <Grid key={key} item xs={12} sm={12} md={6}>
                                                    <Autocomplete
                                                        disablePortal
                                                        autoComplete
                                                        value={answers[key]}
                                                        options={CompanyQuestions[key]?.options}
                                                        onChange={(event, newValue) => {
                                                            handleChangeRegion(newValue);
                                                        }}
                                                        fullWidth
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                label={CompanyQuestions[key]?.text}
                                                                margin="normal"
                                                                required={CompanyQuestions[key]?.required}
                                                            />
                                                        }
                                                    />

                                                    {answers.region?.label === "Skåne" ? (
                                                        <Autocomplete
                                                            disablePortal
                                                            autoComplete
                                                            value={answers[CompanyQuestions[key]?.keyScania]}
                                                            options={CompanyQuestions[key]?.optionsScania}
                                                            onChange={(event, newValue) => {
                                                                handleChangeCounty(newValue);
                                                            }}
                                                            fullWidth
                                                            renderInput={(params) => <TextField {...params} label={CompanyQuestions[key]?.textScania} required={CompanyQuestions[key]?.required} margin="normal" />}

                                                        />) : answers.region?.label === "Outside Sweden" ? (
                                                            <Autocomplete
                                                                disablePortal
                                                                autoComplete
                                                                value={answers[CompanyQuestions[key]?.keyOutsideSweden]}
                                                                options={CompanyQuestions[key]?.optionsOutsideSweden}
                                                                onChange={(event, newValue) => {
                                                                    handleChangeCountry(newValue);
                                                                }}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} label={CompanyQuestions[key]?.textOutsideSweden} required={CompanyQuestions[key]?.required} margin="normal" />}
                                                            />
                                                        ) : ("")}
                                                </Grid>
                                            )
                                        }
                                        else if (CompanyQuestions[key]?.type === 'datePickerYear') {
                                            return (
                                                <Grid key={key} item xs={12} sm={12} md={6}>
                                                    <DesktopDatePicker

                                                        views={['year']}
                                                        label={CompanyQuestions[key]?.text}
                                                        format="YYYY"
                                                        value={year ? dayjs(year) : null}
                                                        disableFuture
                                                        onChange={onChangeYear}
                                                        slotProps={{ textField: { helperText: null, margin: 'normal', required: CompanyQuestions[key]?.required, fullWidth: true } }}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    })}
                                </>
                            ) : ("")}
                        </Grid>

                        <Divider />
                        <div className="backConfirm">
                            <FormHelperText error={isGeneralInvalid}>{isGeneralInvalid === true ? "Please fill the required fields." : ""}</FormHelperText>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {isLoading ? (
                                <Box sx={{ maxWidth: '310px', minWidth: '275px' }}>
                                    <LinearProgress color="info" />
                                </Box>
                            ) : (
                                <Box sx={{ marginBottom: "4px" }}>

                                </Box>
                            )}
                        </div>

                        <div className="backConfirm" style={{ paddingBottom: '10px' }}>
                            <Button
                                style={{ marginLeft: 20 }}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isLoading || isValidUpdate === false}
                            >
                                Update info
                            </Button>
                        </div>
                    </form>
                </LocalizationProvider>
            </Box>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    )
}
