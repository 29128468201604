import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";


export const useGetSystemDefaultMilestones = (tag) => {
    const [loading, setLoading] = useState(true);
    const [levels, setLevels] = useState(Object);

    useEffect(() => {
        if (!tag) return;

        const ref = onSnapshot(collection(db, "SettingsValues/Milestones/" + tag), (snapshot) => {
            let local = {};
            snapshot.docs.forEach((child) => {
                local[child.id] = child.data();
            });

            setLevels(local);
            setLoading(false);
        })

        firebaseRefList.push(ref);

    }, [tag])


    return { loading, levels };
}